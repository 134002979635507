define('analytics/main',['angular', './controllers', './directives', './filters', './routes', './services', './widgets'],
  function(angular) {
    'use strict';
    
    return angular.module('analytics', [
      'chart.js',
      'ngRoute', 
      'analytics.controllers',
      'analytics.directives', 
      'analytics.filters',
      'analytics.routes', 
      'analytics.services',
      'analytics.widgets'
    ]);
  });
define('analytics', ['analytics/main'], function (main) { return main; });

