define('admin/routes',['angular'], function(angular) {
    'use strict';

    return angular.module('admin.routes', [])

    .config(['$routeProvider', function($routeProvider) {
        $routeProvider
            .when('/admin', {
                templateUrl: '/assets/javascripts/admin/partials/admin.html',
                authorizedRoles: ['ADMIN']
            })
            .when('/admin/caseupload', {
                templateUrl: '/assets/javascripts/admin/partials/caseupload.html',
                authorizedRoles: ['ADMIN']
            })
            .when('/admin/caseuploadresults', {
                templateUrl: '/assets/javascripts/admin/partials/caseuploadresults.html',
                authorizedRoles: ['ADMIN']
            })
            .when('/admin/deflatorupload', {
                templateUrl: '/assets/javascripts/admin/partials/deflatorupload.html',
                authorizedRoles: ['ADMIN']
            })
            .when('/admin/featuretoggles', {
                templateUrl: '/assets/javascripts/admin/partials/featuretoggles.html',
                authorizedRoles: ['ADMIN']
            })
            .when('/admin/regemail', {
                templateUrl: '/assets/javascripts/admin/partials/regemail.html',
                authorizedRoles: ['ADMIN']
            })
            .when('/admin/regurl', {
                templateUrl: '/assets/javascripts/admin/partials/regurl.html',
                authorizedRoles: ['ADMIN']
            })
            .when('/admin/activity', {
                templateUrl: '/assets/javascripts/admin/partials/activity.html',
                authorizedRoles: ['ADMIN']
            })
            .when('/admin/validate/metrics', {
                templateUrl: '/assets/javascripts/admin/partials/validateMetrics.html',
                authorizedRoles: ['ADMIN']
            });
    }]);
});
