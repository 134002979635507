define('search/directives',['angular'], function(angular) {
  'use strict';

  return angular.module('search.directives', [])

      .directive('searchSummary', [function () {
        return {
          restrict: 'E',
          templateUrl: '/assets/javascripts/search/partials/searchSummary.html',
          scope: {
            descriptionFilters: '=',
            listFilters: '='
          }
        };
      }]);
});
