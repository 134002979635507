define('analytics/widgets/ungroupedAverage/ungroupedAverage',['angular'], function (angular) {
  'use strict';

  return angular.module('widgets-ungroupedAverage', [])
      .directive('widgetsUngroupedAverage', ['analyticsService', function(analyticsService) {
        return {
          restrict: 'A',
          templateUrl: '/assets/javascripts/analytics/widgets/ungroupedAverage/ungroupedAverage.html',
          scope: {
            widget: '=',
            filters: '=',
            suppression: '=',
            internal: '='
          },
          controller: ['$scope', 'securityService', function($scope, securityService){
            var config = $scope.widget.config;

            _refreshData();
            $scope.$on('refreshData', _refreshData);

            $scope.allowViewCaseTotals = function() {
                return securityService.isLoggedInAdmin();
            };

            function _refreshData(){
              //expects config to contain fact, title, format
              analyticsService.loadMetric(undefined, $scope.filters, config.fact, $scope.internal, $scope.suppression).then(function (metric) {
                $scope.metric = metric[0];
                $scope.totalCases = analyticsService.getMetricTotal(config, metric);
              });
            }
          }]
        };
      }]);
});
