/**
 * User service, exposes user model to the rest of the app.
 */
define('user/services',['angular', 'common'], function (angular) {
  'use strict';

  return angular.module('user.services', ['common', 'ngCookies'])

  .constant('userRoles', {
    admin: 'ADMIN',
    contributorAdmin: 'CONTRIBUTOR_ADMIN',
    contributor: 'CONTRIBUTOR',
    subscriber: 'SUBSCRIBER'
  })
  
  .factory('securityService', 
    ['$http', '$rootScope', '$q', '$cookieStore', '$modal', '$location', '_', 'userRoles',
    function ($http, $rootScope, $q, $cookieStore, $modal, $location, _, userRoles) {
      var permissions = {
        metric: {
          toggleSuppression: [userRoles.admin],
          showAltSaveAndDownloadFooter: [userRoles.contributorAdmin]
        }
      };

    var user;
    var token;

    var self = {
      /*
       * login will use the given credentials {username: value, password: value} and call the Oauth2 endpoint to 
       * retrieve an access token. The user information and token will be stored in a session cookie that will be available
       * as long as the current browser window is open
       */
      login: function (credentials) {
        $rootScope.$broadcast('pane_start');
        credentials.grant_type = 'password';
        var tokenResponse;
        credentials.client_id = window.Conf.envConfig.drdClientId; //Conf set as a global in index.scala.html
        return $http({method: 'POST', url: '/api/oauth2/access_token', data: credentials}).then(
          function (response) {
            tokenResponse = response.data;
            token = tokenResponse;
            return $http({method: 'GET', url: 'api/user/me', headers: {'Authorization': 'Bearer ' + tokenResponse.access_token}, });
        }).then(function (response) {
          user = response.data;
          return ({'user': user, 'token': tokenResponse});
        });
      },
      loginWithIp: function () {
        $rootScope.$broadcast('pane_start');
        var tokenResponse;
        return $http({method: 'POST', url: '/api/oauth2/ip/access_token'}).then(
          function (response) {
            tokenResponse = response.data;
            token = tokenResponse;
            return $http({method: 'GET', url: 'api/user/me', headers: {'Authorization': 'Bearer ' + tokenResponse.access_token}, });
        }).then(function (response) {
          user = response.data;
          return ({'user': user, 'token': tokenResponse});
        });
      },

      /**
       * Common handling after a user has successfully authenticated. Should typically be used as the success handler in
       * a future.then()
       * @param loginResponse response from auththention service call.
       */
      handleLoginSuccess: function (loginResponse) {
        $rootScope.$broadcast('pane_success');
        var user = loginResponse.user;
        if (self.isUserContributor(user) && self.isUserSubscriber(user)) {
          $modal.open({
            animation: true,
            backdrop: 'static',
            windowClass: 'feature-role-modal',
            templateUrl: 'assets/javascripts/user/partials/featureRole.html',
            size: 'lg'
          });
        } else {
          self.postLoginSuccess(_(user.roles).map(function (r) {
            return r;
          }).first());
          $location.path('/');
        }
      },

      /**
       * Because it can involve multiple steps (due to user's potential to have multiple roles) some of the login flow
       * must take place in the controller. Note that there are multiple places where login can occur such as the
       * standard login as well as resetting password. Once that flow is complete, this function MUST be called to set
       * all the appropriate session variables
       */
      postLoginSuccess: function(role){
        if (role === 'CONTRIBUTOR'){
          var user = self.getLoggingInUser();
          var contribRole = _.find(user.roles, function(role){
            if(_.startsWith(role, 'CONTRIBUTOR')){
              return role;
            }
          });
          self.setLoggedInUserActiveRole(contribRole);
        } else if (role){
          self.setLoggedInUserActiveRole(role);
        }
        else{
          self.setLoggedInUserActiveRole("");
        }
        $cookieStore.put('loggedIn', self.getLoggingInUser());
        $cookieStore.put('token', self.getLoggingInUserToken());
      },

      logout: function () {
        return $q(function(resolve){
          var cookieKeys = ['token', 'loggedIn', 'audit', 'loggedInActiveRole', 'noDataMsgDismissed'];
          _.forEach(cookieKeys, function(key){
            $cookieStore.remove(key);
          });

          resolve();
        });

      },
      isLoggedInAdmin: function() {
        return this.getLoggedInUserActiveRole() === 'ADMIN';
      },
      isLoggedInContributor: function() {
        return this.getLoggedInUserActiveRole() === 'CONTRIBUTOR';
      },
      isLoggedInContributorAdmin: function() {
        return this.getLoggedInUserActiveRole() === 'CONTRIBUTOR_ADMIN';
      },
      isLoggedInSubscriber: function() {
        return this.getLoggedInUserActiveRole() === 'SUBSCRIBER';
      },
      getLoggedInUserActiveRole: function () {
        return $cookieStore.get('loggedInActiveRole');
      },
      setLoggedInUserActiveRole: function (role) {
        return $cookieStore.put('loggedInActiveRole', role);
      },
      getLoggingInUserToken: function() {
         return token;
      },
      getLoggingInUser: function() {
         return user;
      },
      getLoggedInUserInfo: function () {
        return $cookieStore.get('loggedIn');
      },
      getAuthToken: function(){
        if ($cookieStore.get('token')){
          return $cookieStore.get('token').access_token;
        }
      },
      currentUserHasRole: function(role){
        if(this.getLoggedInUserInfo() !== undefined){
          return _.includes(this.getLoggedInUserInfo().roles, role);
        }
        else{
          return false;
        }
      },
      currentUserIsContributor: function(){
        if(this.getLoggedInUserInfo() !== undefined){
          return (_.includes(this.getLoggedInUserInfo().roles, 'CONTRIBUTOR') || _.includes(this.getLoggedInUserInfo().roles, 'CONTRIBUTOR_ADMIN'));
        }
        else{
          return false;
        }
      },
      currentUserIsSubscriber: function(){
          return (_.includes(['SUBSCRIBER'], this.getLoggedInUserActiveRole()));
      },
      isUserContributor: function(user){
        if(user.roles !== undefined){
          return (_.includes(user.roles, 'CONTRIBUTOR') || _.includes(user.roles, 'CONTRIBUTOR_ADMIN'));
        }
        else{
          return false;
        }
      },
      isUserSubscriber: function(user){
        if(user.roles !== undefined){
          return _.includes(user.roles, 'SUBSCRIBER');
        }
        else{
          return false;
        }
      },
      currentUserHasActiveRole: function(role){
        if(this.getLoggedInUserInfo() !== undefined){
          return _.includes(this.getLoggedInUserActiveRole(), role);
        }
        else{
          return false;
        }
      },
      hasPermission: function(resource, action) {
        return _.contains(permissions[resource][action], this.getLoggedInUserActiveRole());
      }

    };

    return self;
  }])

  .service('userService', [
    '$http', '$log', '$q',
    function($http, $log, $q){
    
      this.current = function(){
        return $http.get('api/user/me')
          .then(function(response){
            return response.data;
          });
      };
       this.create = function(user){
        return $http.post('api/users', user)
          .then(function(response){
            return response.data;
          });
      };

      this.get = function(userId) {
        //if the userId is present load the case from the db
        if (userId) {
          return $http.get('/api/users/' + userId)
            .then(function(response) {
              return response.data;
            });
        } else {
          //just return a new empty object
          return $q(function(resolve) {
            resolve({});
          });
        }
      };
      this.getLastLogin = function(userId) {
        //if the userId is present load the loginTime from the db
        if (userId) {
          return $http.get('/api/users/' + userId + '/lastLogin')
            .then(function(response) {
              return response.data;
            });
        } else {
          //just return a new empty object
          return $q(function(resolve) {
            resolve({});
          });
        }
      };

      this.setPassword = function(currentPwd, newPwd){
        return $http.put('/api/users/setpw/', {currentPwd: currentPwd, newPwd: newPwd})
          .then(
            function(response) {
              return response.data;
            });
      };

      this.setPwFromToken = function(token, password){
        return $http.put('/api/users/pwbytoken/' + token, {pwd: password})
          .then(
            function(response) {
              return response.data;
            });
      };

      this.save = function(user){
        $log.info('User.save(' + user + ')');

        if(user.id){
          return $http.put('/api/users/' + user.id, user)
            .then(
              function(response) {
                return response.data;
              });
        } else {
          return $http.post('/api/users', user)
            .then(
              function(response) {
                return response.data;
              });
        }
      };
  }])

  .factory('userTableDataService', ['$http', '$log', '_', function ($http, $log, _) {
    var persistentConfig;

    return {
      load: function (limit, offset, sort, filters) {
        return $http.get('/api/users',
            {params: buildRequestParams(limit, offset, sort, filters)})
            .then(function (response) {
              return response.data;
            });

        function buildRequestParams(limit, offset, sort, filters) {
          var params = {};

          params.take = limit;
          params.drop = offset * limit;

          if (sort) {
            params.sort = sort.field;
            params.dir = sort.direction;
          }

          if (filters) {
            //merge in all the defined filters
            _.merge(params, _.pick(filters, _.identity));
          }

          return params;
        }
      },
      setConfig: function (config) {
        persistentConfig = config;
      },
      getConfig: function(){
        return persistentConfig;
      }
    };
  }])

  .service('userProfileService', [
    '$http', '$log',
    function($http, $log){

      this.getFromUser = function(user){
        $log.info('userProfileService.getFromUser(' + user + ')');

        return $http.get('api/users/' + user.id + '/profile')
          .then(function(response){
            return response.data;
          });
      };
      this.getUserProfile = function(id){
        $log.info('userProfileService.getUserProfile(' + id + ')');

        return $http.get('api/userprofiles/' +id)
          .then(function(response){
            return response.data;
          });
      };

      this.save = function(userProfile){
        $log.info('UserProfile.update(' + this + ')');
        if(userProfile.id) {
          return $http.put('/api/userprofiles/' + userProfile.id, userProfile)
            .then(
              function(response) {
                return response.data;
              },
              function(err) {
                console.log(err);
                return err;
              });
        } else {
          return $http.post('/api/userprofiles/', userProfile)
            .then(
              function(response) {
                return response.data;
              },
              function(err) {
                console.log(err);
                return err;
              });
        }
      };
  }])
  .service('orgListDataService', [
    '$http', '$log',
    function($http, $log){
      this.getOrganizations = function(){
        $log.info('getorgs');
        return $http.get('/api/organizations?sort=name&dir=asc')
          .then(
            function(response) {
              return response.data;
            });
      };
  }])
  .service('userRoleService', [
    '$http', '$log', '$q',
    function($http, $log, $q){
      this.get = function(userId) {
        //if the userId is present load the case from the db
        if (userId) {
          return $http.get('/api/users/' + userId + '/roles')
            .then(function(response) {
              return response.data;
            });
        } else {
          //just return a new empty object
          return $q(function(resolve) {
            resolve({});
          });
        }
      };

    }])
  .factory('selfEnrollmentDataService', function() {
     var selfEnrollmentData = {};
     function set(data) {
       selfEnrollmentData = data;
     }
     function get() {
      return selfEnrollmentData;
     }

     return {
      set: set,
      get: get
     };
  })
  .service('selfEnrollmentService', [
    '$http',
    function($http){
      this.validatePlanAndUser = function(user) {
        return $http.post('/api/self-enrollment/validatePlanAndUser', user)
          .then(function(response) {
            return response.data;
          });
      };
      this.validatePayment = function(payment) {
        return $http.post('/api/self-enrollment/validatePayment', payment)
          .then(function(response) {
            return response.data;
          });
      };
      this.getMembershipPlan = function(id) {
        return $http.get('/api/membership-plans/' + id)
          .then(function(response) {
            return response.data;
          });
      };
      this.enroll = function(enrollment) {
        return $http.post('/api/self-enrollment', enrollment)
          .then(function(response) {
            return response.data;
          });
      };
    }]);
});

