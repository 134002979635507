define('common/services/services',['angular'], function (angular) {
	'use strict';

	return angular.module('common.services', [])
			.factory('serviceWrapper', ['$http', '_', function ($http, _) {
				return {
					get: function(url, data, successHandler, errorHandler) {
						return $http.get(url, data)
								.success(function (data) {
									if (successHandler && _.isFunction(successHandler)) {
										successHandler(data);
									}
								})
								.error (function (errors) {
									if (errorHandler && _.isFunction(errorHandler)) {
										errorHandler(errors);
									}
								});
					},
					post: function(url, data, successHandler, errorHandler) {
						return $http.post(url, data)
								.success(function (data) {
									if (successHandler && _.isFunction(successHandler)) {
										successHandler(data);
									}
								})
								.error (function (errors) {
									if (errorHandler && _.isFunction(errorHandler)) {
										errorHandler(errors);
									}
								});
					},
					put: function(url, data, successHandler, errorHandler) {
						return $http.put(url, data)
								.success(function (data) {
									if (successHandler && _.isFunction(successHandler)) {
										successHandler(data);
									}
								})
								.error (function (errors) {
									if (errorHandler && _.isFunction(errorHandler)) {
										errorHandler(errors);
									}
								});
					}
				};
			}]);
});
