define('admin/controllers',['angular'], function(angular) {
  'use strict';

  return angular.module('admin.controllers', [])

  .controller('AdminCtrl', ['$scope', function($scope){
    $scope.test = "TEST";
  }])

  .controller('ValidateMetricsCtrl', ['$scope', '$http', function($scope, $http) {
    $scope.content = "Loading...";
    $http.get('/api/validate/metrics')
      .success(function(data){
        $scope.content = data;
      })
      .error(function(err){
        $scope.content = "Error validating metrics. Please try again later. " + err;
      });
  }])

  .controller('CaseUploadCtrl', ['securityService', '$scope', '$upload', '$location', '$log', '$route', '$http', '$interval',
    function(securityService, $scope, $upload, $location, $log, $route, $http, $interval) {

    $scope.extraFields = {};

    $scope.submit = function(){
      if($scope.caseFile) {
        $upload.upload({
          url: '/api/caseupload/' + $scope.extraFields.userEmail,
          file: $scope.caseFile,
          fields: $scope.extraFields,
          headers: {Authorization: "Bearer " + securityService.getAuthToken()}
        })
        .progress(function(evt){
          $scope.uploadProgress = parseInt(100.0 * evt.loaded / evt.total);
        })
        .success(function(data){
          $scope.processingProgress = data;
          $scope.progressPromise = $interval($scope.checkProgress, 2000);
        })
        .error(function(err){
          $log.error(err);
          $scope.error = err;
        });
      } else {
        $scope.error = "No file selected.";
      }
    };

    $scope.reset = function(){
      $route.reload(); 
    };

    $scope.checkProgress = function() {
      $http.get('/api/caseupload/progress/' + $scope.processingProgress.id)
      .success(function(data){
        $scope.processingProgress = data;
        if(data.done) {
          $interval.cancel($scope.progressPromise);
          $location.url('/admin/caseuploadresults?id=' + $scope.processingProgress.id);
        }
      })
      .error(function(err){
        $scope.error = err;
      });
    };
  }])

  .controller('CaseUploadResultsCtrl', ['securityService', '$scope', '$location', '$log', '$route', '$http', '$interval', '_', 'standardOptions',
    function(securityService, $scope, $location, $log, $route, $http, $interval, _, standardOptions) {

    // list of case statuses and allowed status transitions
    $scope.statuses = standardOptions.caseStatusTransitions;
    $scope.resultsId = $location.search().id;
    $scope.allSelected = false;
    $scope.status = {};

    _getResults();

    $scope.getResultClass = function(result) {
      var resultClass;
      if(result.error || result.status === 'ERROR') {
        resultClass = 'alert alert-danger';
      } else {
        resultClass = '';
      }
      return resultClass;
    };

    $scope.doSelectAll = function() {
      _.forEach($scope.results, function(result) {
        // select all *except* cases with ERROR status
        if(result.status !== 'ERROR') {
          result.selected = $scope.allSelected;
        }
      });
      $scope.status.selected = undefined;
    };

    $scope.doSelect = function(selected) {
      if($scope.allSelected && !selected) {
        $scope.allSelected = false;
      }
      $scope.status.selected = undefined;
    };

    $scope.statusSelected = function(selection) {
      _.forEach($scope.results, function(result) {
        result.selected = (result.status === selection);
      });
      $scope.allSelected = false;
    };

    $scope.apply = function() {
      var statusUpdate = {
        'status': $scope.status.changeTo,
        'caseData': []
      };
      if($scope.status.changeTo) {
        _.filter($scope.results, { selected: true }).forEach(function(result) {
          if(result.status !== $scope.status.changeTo) {
            if(_.find($scope.statuses, { name: result.status }).transitions.indexOf($scope.status.changeTo) >= 0) {
              statusUpdate.caseData.push(result);
              result.error = false;
            } else {
              result.moreInfo = 'ERROR: cannot change status from ' + result.status + ' to ' + $scope.status.changeTo + '.';
              result.error = true;
            }
          }
        });
        if(statusUpdate.caseData.length > 0) {
          $http.post('/api/caseupload/statusupdate/' + $scope.resultsId, statusUpdate).then(function(response) {
            _.forEach(response.data, function(caseData) {
              var result = _.find($scope.results, { id: caseData.id });
              result.status = caseData.status;
              result.moreInfo = caseData.moreInfo;
              result.error = caseData.error;
            });
          });
        }
      }
      _clearSelections();
    };

    $scope.reset = function(){
      $location.url('/admin/caseupload');
    };

    $scope.done = function(){
      $http.put('/api/caseupload/delete/' + $scope.resultsId).then(function() {
        $log.debug('results deleted');
        $location.url('/admin/caseupload');
      });
    };

    function _getResults() {
      $http.get('/api/caseupload/result/' + $scope.resultsId)
      .success(function(data){
        $scope.results = data;
      })
      .error(function(err){
        $scope.error = err;
      });
    }

    function _clearSelections() {
      _.forEach($scope.results, function(result) {
        result.selected = false;
      });
      $scope.allSelected = false;
      $scope.status = {};
    }
  }])

  .controller('FeatureToggleCtrl', ['$scope', '$http', 'standardOptions', 
    function($scope, $http, standardOptions){
    
    $scope.userRoles = standardOptions.userRoles;
    $scope.featureToggle = {roles: []};

    $scope.toggleRoleSelection = function(role){
      var idx = $scope.featureToggle.roles.indexOf(role);

      //is currently selected
      if(idx > -1){
        $scope.featureToggle.roles.splice(idx, 1);
      } 

      //currently unselected
      else {
        $scope.featureToggle.roles.push(role);
      }
    };

    $scope.submit = function(){
      $http.post('/api/feature-toggles/', $scope.featureToggle)
        .then(function(){
          $scope.msg = "success";
          $scope.featureToggle = {roles: []};
        });
    };
  }])

  .controller('RegEmailCtrl', ['$scope', '$http', '$route',
    function($scope, $http, $route){

    $scope.submit = function(){
      $http.put('/api/email/newuser/' + $scope.email)
      .success(function(data){
        $scope.result = data;
      })
      .error(function(err){
        $scope.error = err;
      });
    };

    $scope.reset = function(){
      $route.reload();
    };
  }])

  .controller('RegURLCtrl', ['$scope', '$http', '$route',
    function($scope, $http, $route){

    $scope.submit = function(){
      $http.get('/api/email/pwurl/' + $scope.email)
      .success(function(data){
        $scope.results = data;
      })
      .error(function(err){
        $scope.error = err;
      });
    };

    $scope.reset = function(){
      $route.reload();
    };
  }])

  .controller('DeflatorUploadCtrl', ['securityService', '$scope', '$upload', '$log', '$route',
    function(securityService, $scope, $upload, $log, $route){

    $scope.extraFields = { };

    $scope.submit = function(){
      if($scope.deflFile) {
        $upload.upload({
          url: '/api/deflatorupload',
          file: $scope.deflFile,
          fields: $scope.extraFields,
          headers: {Authorization: "Bearer " + securityService.getAuthToken()}
        })
        .progress(function(evt){
          var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
          $log.info('progress: ' + progressPercentage + '% ' + evt.config.file.name);
        })
        .success(function(data){
          $scope.results = data;
        })
        .error(function(err){
          $log.error(err);
          $scope.error = err;
        });
      } else {
        $scope.error = "No file selected.";
      }
    };

    $scope.reset = function(){
      $route.reload();
    };
  }])

  .controller('ActivityCtrl', ['$scope', '$http', '_', function($scope, $http, _) {
    var pageSize = 50;
    $scope.drop = 0;
    $scope.activities = [];
    $scope.untilTime = '';

    $scope.loadMore = function() {
      var take = $scope.drop + pageSize;
      $http.get('/api/activities?drop=' + $scope.drop + '&take=' + take + '&until=' + $scope.untilTime)
          .success(function(data) {
            $scope.drop += pageSize;
            $scope.activities = $scope.activities.concat(data);
            if (!$scope.untilTime && _.size($scope.activities) > 0) {
              $scope.untilTime = $scope.activities[0].date;
            }
          })
          .error(function(err) {
            console.log(err);
            $scope.errors = ["Error loading activities."];
          });
    };

    $scope.loadMore();
  }]);
});

