define('analytics/widgets/numberWithDescription/numberWithDescription',['angular'], function (angular) {
  'use strict';

  return angular.module('widgets-numberWithDescription', [])
      .directive('widgetsNumberWithDescription', ['widgetHelpers', 'widgetColors', '$q', '_',
       function(widgetHelpers, widgetColors, $q, _){
        return {
          restrict: 'A',
          templateUrl: '/assets/javascripts/analytics/widgets/numberWithDescription/numberWithDescription.html',
          scope: {
            widget: '=',
            filters: '=',
            internal: '=',
            suppression: '=',
            filterMask: '=',
            displayConfig: '=',
            compareMode: '=',
            right: '='
          },
          controller: ['$scope', 'securityService', 'analyticsService', function($scope, securityService, analyticsService){
            /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
             Initialization
             ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

            var config = $scope.widget.config,
                DEFAULT_COLOR = 'stat13';

            $scope.state = 'LOADING';
            $scope.signalStrength = 0;

            $scope.allowViewCaseTotals = function() {
              return securityService.isLoggedInAdmin();
            };

            _refreshData();


            /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
             Scope Functions
             ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

            $scope.multiplePrimaryValues = function(){
              return _.filter($scope.values, function(v){
                return !v.secondary;
              }).length > 1;
            };

            $scope.multipleLines = function(){
              return config.forceMultiLine || _.find($scope.values, 'secondary');
            };

            $scope.isPrimaryValue = function(item){
              return !item.secondary;
            };

            $scope.hasSecondaryValue = function(idx){
              //if the next index in the values array exists and it is a secondary value
              return $scope.values.length > idx + 1 && $scope.values[idx+1].secondary && ($scope.values[idx+1].value || $scope.values[idx+1].value === 0);
            };

            $scope.getValueColor = function(v) {
              // note this works only for the primary value, we don't currently have a means to customize secondary values
              return widgetColors[v.color ? v.color : DEFAULT_COLOR];
            };


            /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
             Watches
             ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

            $scope.$on('refreshData', _refreshData);

            $scope.$watch('state', function(){
              if ($scope.state === 'NO_DATA'){
                $scope.$emit('noData');
              }
            });


            /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
             Private Functions
             ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

            function _refreshData(){
              $scope.state = 'LOADING';
              $scope.ssState = 'LOADING';
              var promises = _.map(config.values, function(v){
                  return widgetHelpers.getNumberForDescription(v, widgetHelpers.standardFiltersPlusCustomOnly($scope.filters, $scope.filterMask), $scope.internal, $scope.suppression);
              });

              $q.all(promises).then(function(numbers){
                $scope.values = _.map(numbers, function(n, idx){
                  return _.assign(_.clone(config.values[idx]), {value: n.number});
                });
                $scope.totalCases = analyticsService.getMetricTotal(config, numbers);

                $scope.chartData = $scope.values.map(function (item, idx) {
                  if (config.values[idx].field === 'percentage') {
                    item.key = config.values[idx].group + _.kebabCase(item.group);
                    item.label = _.first(config.values[idx].group);
                    item.rawData = {
                      avg: 0,
                      groups: [config.values[idx].group],
                      percentage: item.value,
                      total: $scope.totalCases
                    };
                  } else if (config.values[idx].field === 'avg') {
                    item.key = config.values[idx].fact + _.kebabCase(item.fact);
                    item.label = _.first(config.values[idx].fact);
                    item.rawData = {
                      avg: item.value,
                      groups: [config.values[idx].fact],
                      percentage: 0,
                      total: $scope.totalCases
                    };
                  }
                  return item;
                });
              })
              .then(function(){
                var hasData = _.find($scope.values, function(item){
                  return item.value || item.value === 0;
                });

                if (hasData) {
                  $scope.state = 'DONE';
                } else {
                  $scope.state = 'NO_DATA';
                }
              })
              .then(function() {
                if(config.showSignalStrength){
                  return analyticsService.getSignalStrength(
                    _.map($scope.chartData, function(item){
                      return item.rawData;
                    })
                  );
                }
              })
              .then(function(signalStrength){
                $scope.signalStrength = signalStrength;
                $scope.ssState = 'DONE';
              });
            }
          }]
        };
      }]);
});

