define('membership/controllers',['angular'], function (angular) {
	'use strict';

	return angular.module('membership.controllers', [])
			.controller('AddMembershipCtrl', ['$scope', '$http', '_', 'momentLocal', 'util', 'membershipService', 'planType', 'individualUserId', 'organizationId', 'membershipSummary', function ($scope, $http, _, moment, util, membershipService, planType, individualUserId, organizationId, membershipSummary) {
				$scope.plans = [];
				$scope.planRows = [];
				$scope.dateOptions = util.buildDateOptions(0, 1, false);
				$scope.timeOptions = util.buildTimeOptions();

				$scope.membershipSummary = membershipSummary;
				$scope.addMode = !$scope.membershipSummary;
				$scope.title = ($scope.addMode ? "Select a Subscription Plan" : "Edit Subscription");
				$scope.showStartTime = !$scope.addMode;
				$scope.showEnd = !$scope.addMode;
				$scope.showSeats = (planType === 'ORGANIZATION');

				var momentStart = ($scope.addMode ? moment().local() : moment($scope.membershipSummary.membership.effectiveTime).local());
				var momentEnd = ($scope.addMode ? moment().local() : moment($scope.membershipSummary.membership.expirationTime).local());
				$scope.model = {
					startDate: {
						"month" : momentStart.format('MMMM'),
						"day" : momentStart.date(),
						"year" : momentStart.year()
					},
					startTime: {
						"hour" : momentStart.format('h'),
						"minute" : momentStart.format('mm'),
						"ampm" : momentStart.format('A')
					},
					endDate: {
						"month" : momentEnd.format('MMMM'),
						"day" : momentEnd.date(),
						"year" : momentEnd.year()
					},
					endTime: {
						"hour" : momentEnd.format('h'),
						"minute" : momentEnd.format('mm'),
						"ampm" : momentEnd.format('A')
					}
				};

				if ($scope.addMode) {
					$scope.$watch('model.membershipPlanId', function() {
						var plan = _.find($scope.plans, {id: $scope.model.membershipPlanId});
						var orig = $scope.showStartTime;
						$scope.showStartTime = plan && plan.durationPrecision.precision === 'MINUTE';
						if (!orig && $scope.showStartTime) {
							momentStart = moment().local();
							$scope.model.startTime = {
								"hour" : momentStart.format('h'),
								"minute" : momentStart.format('mm'),
								"ampm" : momentStart.format('A')
							};
						}
					});

					$http.get('/api/membership-plans?planType=' + planType)
							.success(function(data) {
								$scope.plans = data;
								$scope.planRows = _.chunk(data, 2);
							})
							.error(function() {
								$scope.errorText = 'Error loading membership plans.';
							});
				} else {
					$scope.model.licensedUserCount = $scope.membershipSummary.membership.licensedUserCount;
				}

				$scope.saveMembership = function() {
					$scope.errors = [];

					if (!$scope.showStartTime) {
						$scope.model.startTime = {
							"hour" : '12',
							"minute" : '00',
							"ampm" : 'AM'
						};
					}

					var startDate = util.localMoment($scope.model.startDate, $scope.model.startTime);
					var params = _.assign(_.cloneDeep($scope.model), {"effectiveTime": startDate.toJSON()});
					if ($scope.showEnd) {
						var endDate = util.localMoment($scope.model.endDate, $scope.model.endTime);
						_.assign(params, {"expirationTime": endDate.toJSON()});
					}
					if (individualUserId) {
						_.assign(params, {"ownerUserId": individualUserId});
					}
					if (organizationId) {
						_.assign(params, {"ownerOrganizationId": organizationId});
					}

					if ($scope.addMode) {
						membershipService.createMembership(params, function () {
							$scope.$close();
						}, function (errors) {
							$scope.errors = errors;
						});
					} else {
						_.assign(params, {"id": $scope.membershipSummary.membership.id});
						membershipService.updateMembership(params, function () {
							$scope.$close();
						}, function (errors) {
							$scope.errors = errors;
						});
					}
				};
			}])

			.controller('AssignSeatsCtrl', ['$scope', '$http', '_', 'membership', function($scope, $http, _, membership) {
				$scope.membership = membership;
				$scope.model = {};
				$scope.filledCount = '';
				$scope.allSeatsFilled = false;
				$scope.userSelected = function(user) {
					$http.post('/api/user-memberships', {"userId": user.id, "membershipId": $scope.membership.id})
							.success(function() {
								$scope.model.selectedUser = '';
								_refreshUserData();
							})
							.error(function(err) {
								console.error(err);
							});
				};

				$scope.userRemoved = function(userMembershipId) {
					$http.delete('/api/user-memberships/'+userMembershipId)
							.success(function() {
								_refreshUserData();
							})
							.error(function(err) {
								console.error(err);
							});
				};

				$http.get('/api/organizations/'+$scope.membership.ownerOrganizationId+'/users')
						.success(function(data) {
							$scope.organizationUsers = _.sortBy(data, 'email');
							_refreshUserData();
						})
						.error(function(err) {
							console.error(err);
						});

				function _refreshUserData() {
					$http.get('/api/memberships/'+$scope.membership.id+'/users')
							.success(function(data) {
								$scope.membershipUsers = data;
								$scope.availableUsers = _.filter($scope.organizationUsers, function(user) {
									return !_.find($scope.membershipUsers, {"id": user.id});
								});
								$scope.filledCount = _.size($scope.membershipUsers);
								$scope.allSeatsFilled = ($scope.filledCount >= $scope.membership.licensedUserCount);
							})
							.error(function(err) {
								console.error(err);
							});
				}
			}])
			;
});
