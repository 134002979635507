define('common/directives/organization',['angular'], function(angular) {
  'use strict';

  return angular.module('common.directives.organization', [])

  .directive('orgheader', [function(){
    return {
      restrict: 'E',
      replace: false,
      transclude: true,
      templateUrl: '/assets/javascripts/common/partials/orgheader.html'
    };
  }])

  .directive('orgrow', [function() {
    return {
      restrict: 'E',
      replace: false,
      transclude: true,
      scope: {
        organization: '='
      },
      templateUrl: '/assets/javascripts/common/partials/organization.html'
    };

  }]);
});
