define('analytics/widgets',[
  'angular',
  'lodash',
  './widgets/basicCount/basicCount',
  './widgets/basicDuration/basicDuration',
  './widgets/bubbleMap/bubbleMap',
  './widgets/detailedStatsTable/detailedStatsTable',
  './widgets/donut/donut',
  './widgets/horizontalBar/horizontalBar',
  './widgets/verticalBar/verticalBar',
  './widgets/numberWithDescription/numberWithDescription',
  './widgets/percentTrue/percentTrue',
  './widgets/table/table',
  './widgets/regionMap/regionMap',
  './widgets/ungroupedAverage/ungroupedAverage',
  './widgets/tooltips/caseTypeOverviewTooltip'
], function (angular, _) {
  'use strict';


  //the distinct set of colors to use for statistics
  var colors = {
    stat01: '#b7db83',
    stat02: '#94bc61',
    stat03: '#7ba545',
    stat04: '#59a04a',
    stat05: '#4cac61',
    stat06: '#559c6e',
    stat07: '#449071',
    stat08: '#338872',
    stat09: '#3a928c',
    stat10: '#1b8a8c',
    stat11: '#1d95a9',
    stat12: '#1f859c',
    stat13: '#2b7586',
    stat14: '#2b71a5',
    stat15: '#1e84c4',
    stat16: '#4482b8',
    stat17: '#748cc6',
    stat18: '#5f72bd',
    stat19: '#646bb1',
    stat20: '#7f6cdd',
    stat21: '#8468b9',
    stat22: '#8b5f9a',

    wheel01: '#6eb074',
    wheel02: '#66ad72',
    wheel03: '#5ca077',
    wheel04: '#4d9679',
    wheel05: '#488d7d',
    wheel06: '#3e8580',
    wheel07: '#367d83',
    wheel08: '#2f7887',
    wheel09: '#2a7b94',
    wheel10: '#267ea4',
    wheel11: '#2681ab',
    wheel12: '#2285b6',
    wheel13: '#1b83c2',
    wheel14: '#2984c5',
    wheel15: '#3a83c8',
    wheel16: '#4d7bcf',
    wheel17: '#597ad1',
    wheel18: '#6a77d5',
    wheel19: '#7876d7',
    wheel20: '#7a7acc',
    wheel21: '#7881be',
    wheel22: '#7786b6',
    wheel23: '#758dac',
    wheel24: '#7492a4',
    wheel25: '#739a97',
    wheel26: '#70a38a',
    wheel27: '#71a784',
    wheel28: '#70ad7a',
    wheel29: '#6eab77',

    
    statCustom01: '#93c1cc' //used for monochromatic bar charts;
  };

  return angular.module('analytics.widgets', [
    'widgets-basicCount',
    'widgets-basicDuration',
    'widgets-bubbleMap',
    'widgets-detailedStatsTable',
    'widgets-donut',
    'widgets-horizontalBar',
    'widgets-verticalBar',
    'widgets-numberWithDescription',
    'widgets-percentTrue',
    'widgets-table',
    'widgets-regionMap',
    'widgets-ungroupedAverage',
    'widgets-caseTypeOverviewTooltip'
  ])
      .constant('widgetColorSchemes', {
        _placeholder: ['#FF00EF', '#FF00EF','#FF00EF','#FF00EF','#FF00EF','#FF00EF','#FF00EF','#FF00EF','#FF00EF','#FF00EF', '#FF00EF','#FF00EF','#FF00EF','#FF00EF','#FF00EF','#FF00EF','#FF00EF','#FF00EF','#FF00EF', '#FF00EF','#FF00EF','#FF00EF','#FF00EF','#FF00EF','#FF00EF','#FF00EF','#FF00EF','#FF00EF', '#FF00EF','#FF00EF','#FF00EF','#FF00EF','#FF00EF','#FF00EF','#FF00EF','#FF00EF'],
        _2Green1: [colors.stat01, colors.stat03],
        _2Green2: [colors.stat01, colors.stat04],
        _2Blue: [colors.statCustom01, colors.stat12],
        _2Blue2: [colors.stat15, colors.stat12],
        _2Blue3: [colors.stat15, colors.stat22],
        _2MultiA: [colors.stat01, colors.stat13],
        _2MultiB: [colors.stat16, colors.stat21],
        _2MultiC: [colors.stat11, colors.stat17],
        _2MultiD: [colors.stat03, colors.stat15],
        _2MultiE: [colors.stat20, colors.stat15],
        _3Green: [colors.stat01, colors.stat03, colors.stat05],
        _3Blue: [colors.stat10, colors.stat13, colors.stat16],
        _3Violet: [colors.stat20, colors.stat21, colors.stat22],
        _3MultiA: [colors.stat03, colors.stat15, colors.stat22],
        _3MultiA_VBG: [colors.stat22, colors.stat15, colors.stat03], // variant of _3MultiA to provide ordering with violet first, then blue, then green
        _3MultiB: [colors.stat05, colors.stat14, colors.stat20],
        _4Green: [colors.stat01, colors.stat04, colors.stat08, colors.stat10],
        _4Blue: [colors.stat11, colors.stat13, colors.stat14, colors.stat15],
        _4Violet: [colors.stat17, colors.stat20, colors.stat21, colors.stat22],
        _4MultiA: [colors.stat01, colors.stat05, colors.stat11, colors.stat19],
        _4MultiB: [colors.stat22, colors.stat15, colors.stat09, colors.stat04],
        _5Green: [colors.stat01, colors.stat04, colors.stat06, colors.stat08, colors.stat09],
        _5Blue: [colors.stat10, colors.stat13, colors.stat14, colors.stat15, colors.stat16],
        _5Violet: [colors.stat22, colors.stat21, colors.stat20, colors.stat18, colors.stat17],
        _5MultiA: [colors.stat01, colors.stat06, colors.stat11, colors.stat19, colors.stat21],
        _5MultiB: [colors.stat03, colors.stat11, colors.stat15, colors.stat17, colors.stat22],
        _6Green: [colors.stat01, colors.stat03, colors.stat05, colors.stat06, colors.stat08, colors.stat09],
        _6Blue: [colors.stat09, colors.stat12, colors.stat13, colors.stat14, colors.stat15, colors.stat16],
        _6Violet: [colors.stat22, colors.stat21, colors.stat20, colors.stat19, colors.stat18, colors.stat17],
        _6MultiA: [colors.stat02, colors.stat08, colors.stat11, colors.stat14, colors.stat18, colors.stat21],
        _6MultiAA: [colors.stat02, colors.stat11, colors.stat18, colors.stat08, colors.stat14, colors.stat21],
        _6MultiB: [colors.stat01, colors.stat05, colors.stat12, colors.stat15, colors.stat17, colors.stat22],
        _6MultiBB: [colors.stat01, colors.stat22, colors.stat15, colors.stat05, colors.stat17, colors.stat12],
        _6MultiBBB: [colors.stat15, colors.stat05, colors.stat17, colors.stat12, colors.stat01, colors.stat22],
        _7Green: [colors.stat01, colors.stat02, colors.stat03, colors.stat04, colors.stat05, colors.stat06, colors.stat08],
        _7Blue: [colors.stat11, colors.stat12, colors.stat13, colors.stat14, colors.stat15, colors.stat16, colors.stat18],
        _7Violet: [colors.stat22, colors.stat21, colors.stat20, colors.stat19, colors.stat18, colors.stat17, colors.stat16],
        _7MultiA: [colors.stat02, colors.stat05, colors.stat09, colors.stat12, colors.stat15, colors.stat17, colors.stat21],
        _7MultiB: [colors.stat01, colors.stat03, colors.stat11, colors.stat14, colors.stat17, colors.stat20, colors.stat22],
        all: _(colors).pick(function(v, k){return k.indexOf('custom') === -1 && k.indexOf('wheel') === -1;}).values().value(),
        wheel: _(colors).pick(function(v,k){return _(k).contains('wheel');}).values().value()
      })

      .constant('widgetColors', colors)

      .factory('widgetHelpers', ['analyticsService', 'geographyService', function(analyticsService, geographyService){
        var helpers = {
          /**
           * Given an array of items, finds the one that represents "Yes" or true.
           * @param metric []
           */
          findYesItem: function(metric){
            return metric && _.find(metric, function (item) {
              return item.groups && _.intersection(item.groups, ['t', 'Yes']).length;
            });
          },

          getNumberForDescription: function(config, filters, internal, useSuppression) {
            var group = config.group && [config.group],
                displayedGroup = config.displayedGroup,
                fact = config.fact,
                field = config.field;

            return analyticsService.loadMetric(group, filters, fact, internal, useSuppression).then(function(metric){

              if(_.isEmpty(metric)){
                return {number: null, total: null};
              }

              var item;

              //if no fact or displayedGroup then assume that we want to get the "yes/true" group
              if(!fact && !displayedGroup){
                  item = helpers.findYesItem(metric);
                 return {number: item ? item[field] : 0, total: item ? item.total : 0};

              //no fact, but we do have a specific group value to display (because previous condition was not met)
              } else if(!fact){
                //if displayedGroup was not defined as an array, convert it to one here
                if(!_.isArray(displayedGroup)){
                  displayedGroup = [displayedGroup];
                }
                var number = _(metric).filter(function (item) {
                  return _.includes(displayedGroup, _.first(item.groups)); //makes an assumption that there is only one group per item
                }).sum(function(item){
                  return item[field];
                });
                var total =  _(metric).filter(function (item) {
                    return _.includes(displayedGroup, _.first(item.groups)); //makes an assumption that there is only one group per item
                }).sum(function(item){
                    return item.total;
                });

                return {number: number, total: total};

              //we're querying for a specific fact so just get that value
              } else {
                return {number: _.first(metric)[field], total: _.first(metric).total};
              }
            });
          },

          alphaSortGroup: function(item){
            var label = item.groups[0];
            if(!isNaN(label.substr(0, 1))){
              return Number(label.substring(0, label.indexOf(label.match(/\D/))));
            } else {
              return label;
            }
          },

          detStatsValuesDesc: function(item){
            return -item.percentage;
          },

            maskFilters: function(filters, filterMask) {
                return (filterMask && _.isFunction(filterMask) ? filterMask(filters) : filters);
            },

          standardFiltersOnly: function(filters, filterMask){
            return _.pick(this.maskFilters(filters, filterMask), ['status', 'caseRegion', 'caseType', 'processType', 'organizationId', ]);
          },
          standardDetailedStatsFiltersOnly: function(filters, filterMask){
            return _.pick(this.maskFilters(filters, filterMask), ['status', 'caseRegion', 'caseType', 'processType', 'organizationId', 'minDemandAmt', 'maxDemandAmt', 'clmPartyRegion', 'rspPartyRegion', 'caseConclusion', 'medCaseConclusion', 'minMedReqFileYear','minDemandYear', 'maxMedReqFileYear', 'maxDemandYear', 'numArbitrators', 'medPartyRegion', 'claimantAwardToClaimQuartile', 'counterclaimantAwardToCounterclaimQuartile']);
          },
          standardFiltersPlusCustomOnly: function(filters, filterMask){
            return _.pick(this.maskFilters(filters, filterMask), ['status', 'caseRegion', 'caseType', 'filedAsMediation', 'processType', 'organizationId']);
          },

          getIcon: function(key){
            var geoData = geographyService.getLatLong(key);
            if(geoData && geoData.icon){
              return geoData.icon;
            }

            var cls = key.toLowerCase();
            cls = 'icon-region-'+cls.replace(' ', '-').replace('america', 'amer').replace('middle','mid');
            return cls;
          }


        };

        return helpers;
      }]);
});
