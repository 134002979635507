/****************************************************************************
* modal.js
* 
* Harrison DeStefano
* sparcedge.com
*
* Get and set modal contant.
* May, 5, 2015
***************************************************************************/

define('common/services/modal',['angular'], function (angular) {
  'use strict';
  
  var module = angular.module('common.modal', []);
  
  module.factory('modalService', [function(){
      
      // Setup modal object structure
      var modal = {
        attribute : {
          id : 'modal'
        },
        clearOnShow : true,
        content : {
          logo: null,
          button: null,
          title: null,
          body: null,
          footer: null,
        },
        display : false
      };

      // Setters & Getters
      modal.set = function(configuration){
        this.attribute.id = (configuration.attribute === undefined || configuration.attribute.id === undefined) ? 'modal' : configuration.attribute.id;
        this.content = configuration.content === undefined ? ' ': configuration.content;
        this.display = configuration.display === undefined ? false : configuration.display;
      };

      modal.get = function(preserve){
        if(preserve){
          console.log('preserve: '+preserve);
          return this;
        }
        else {
          var self = this;
          var tmp = {
            attribute : {
              id : this.attribute.id
            },
            content: {
              logo: this.content.logo,
              button: this.content.button,
              title: this.content.title,
              body: this.content.body,
              footer: this.content.footer
            },
            display: this.display
          };
          self.clear();
          return tmp;
        }
      };

      // helper functions
      modal.clear = function(){
        this.attribute = {
          id : 'modal'
        };
        this.display = false;
        this.clearOnShow = true;
        this.content = {
          button: null,
          title: null,
          body: null,
          footer: null
        };
      };

      return modal;
  }]);
  return module;
});
