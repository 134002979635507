define('organizations/directives',['angular'], function(angular) {
  'use strict';

  return angular.module('organizations.directives', [])
  
  .directive('orgPaginationMsg', [function(){
    return {
      restrict: 'E',
      template: 'Displaying <strong>{{paginationInfo.pageItemStart}}</strong> &ndash;  <strong>{{paginationInfo.pageItemEnd}}</strong> <span class="preposition">of</span> <strong>{{paginationInfo.totalItems}}</strong> results',
      scope: {paginationInfo: '='} 
    };
  }])
  .directive("editIpLoginsModalOpener", ['$modal', function ($modal) {
    return {
      restrict: 'A', scope: true, link: function (scope) {
        scope.openIpModal = function (organizationId, organizationIp, onClose) {
          var modal = $modal.open({
            backdrop: 'static',
            templateUrl: 'assets/javascripts/organizations/partials/editOrganizationIpModal.html',
            size: 'lg',
            controller: 'AddOrganizationIpLoginCtrl',
            resolve: {
              organizationId: function () {
                return organizationId;
              },
              organizationIp: function() {
                return organizationIp;
              }
            }
          });

          modal.result.then(function() {
            if (onClose) {
              onClose();
            }
          }).catch(function() {
            if (onClose) {
              onClose();
            }
          });
        };
      }
    };
  }]);
});
