define('analytics/routes',['angular'], function (angular) {
  'use strict';

  return angular.module('analytics.routes', [])

      .config(['$routeProvider', function ($routeProvider) {
        var partialsDir = '/assets/javascripts/analytics/partials/';

        $routeProvider
            .when('/analytics/overview', {
              templateUrl: partialsDir + 'analytics.html',
              authorizedRoles: ['ADMIN']
            })
            .when('/analytics/stats', {
              templateUrl: partialsDir + 'stats.html',
              reloadOnSearch: false,
              authorizedRoles: ['ADMIN', 'CONTRIBUTOR_ADMIN', 'SUBSCRIBER']
            })
            .when('/analytics/stats/save-metric', {
                templateUrl: partialsDir + 'saveMetric.html',
                controller: 'SaveMetricCtrl',
                reloadOnSearch: true,
                authorizedRoles: ['ADMIN', 'CONTRIBUTOR_ADMIN', 'CONTRIBUTOR', 'SUBSCRIBER', 'ANONYMOUS'] // todo
            })
            .when('/analytics/public/casetype', {
              templateUrl: partialsDir + 'publicCaseTypes.html',
              authorizedRoles: ['ADMIN', 'CONTRIBUTOR_ADMIN', 'CONTRIBUTOR', 'SUBSCRIBER', 'ANONYMOUS']
            })
            .when('/analytics/public/regionmap', {
              templateUrl: partialsDir + 'publicRegionMap.html',
              authorizedRoles: ['ADMIN', 'CONTRIBUTOR_ADMIN', 'CONTRIBUTOR', 'SUBSCRIBER', 'ANONYMOUS']
            });
      }]);
});
