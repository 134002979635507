define('status/directives',['angular'], function (angular) {
  'use strict';

  return angular.module('status.directives', [])

      .directive('statusWidget', ['$compile', function($compile){
        return {
          restrict: 'A',
          replace: false,
          scope: {
            widget: '='
          },
          link: function(scope, elem){
            //get the directive associated with the widget in scope
            var widgetDirective = 'status-widget-' + scope.widget.type;
            var html = '<' + widgetDirective + ' widget="widget"></' + widgetDirective + '>';
            var compiled = $compile(html)(scope);
            elem.append(compiled);
          }
        };
      }]);
});

