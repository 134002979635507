define('search/controllers',['angular'], function (angular) {
  'use strict';

  return angular.module('search.controllers', [])

      .controller('SavedSearchCtrl', ['$scope', 'savedSearchService', function ($scope, savedSearchService) {

        // Scope variables
        //$scope.savedSearch = {}; //?load from id if editing existing
        $scope.saveSearchName = savedSearchService.getSavedSearchModel() ? savedSearchService.getSavedSearchModel().name : ''; // Used to save a search
        $scope.steps = { // Used to set visibility 
          one : true,
          two : false
        };

        /*load the filters from the service. we need different functions applied
        based on the context of what we're using the filters for*/
        $scope.filters = {
          description: savedSearchService.getFiltersForDescription(),
          display: savedSearchService.getFiltersForDisplay(),
          save: savedSearchService.getFiltersForSave()
        };
        
        // Scope Functions
        $scope.cancel = function () {
         $scope.$close();
         savedSearchService.setSavedSearchModel(null);
        };
        
        $scope.deleteSearch = function ($event) {
          $event.preventDefault();
          savedSearchService.deleteSavedSearch();
          $scope.$close();
        };

        $scope.saveFilters = function ($event) {
          $event.preventDefault();
          var isValid = checkName();  
          if(isValid){
            var payload = buildSaveSearchPayload();
            savedSearchService.save(payload).then( 
              function() {
                $scope.steps.one = false;
                $scope.steps.two = true; 
              },
              function(){
                 $scope.errors = {message:'Error saving search, please try again later.'};
              });
          }
        };

        // Private functions
        /*
        * Create object to be saved
        */
        function buildSaveSearchPayload(){
          var store = { 
            name : $scope.saveSearchName, 
            searchCriteria : $scope.filters.save
          };
          return store;
        }

        /*
        * Check name constraints and return bool
        */
        function checkName(){
          if($scope.saveSearchName.length === 0){
            $scope.errors = {message: 'Please enter a name.'};
          }
          else if($scope.saveSearchName.length > 35){
           $scope.errors = {message:'A name cannot exceed 35 characters'};
          }
          else{
            return true;
          } 
          return false;
        }
        


      }]);
});
