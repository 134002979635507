/** Common filters. */
define('common/filters',['angular'], function (angular) {
  'use strict';

  return angular.module('common.filters', [])

      /**
       * Extracts a given property from the value it is applied to.
       * {{{
       * (user | property:'name')
       * }}}
       */
      .filter('property', function (value, property) {
        if (angular.isObject(value)) {
          if (value.hasOwnProperty(property)) {
            return value[property];
          }
        }
      })

      /**
       *  Formats dates and converts given dates from UTC to the local timezone.  Useful for displaying system generated
       *  dates like created or updated dates relative to the User's current time zone.
       */
      .filter('localDateFormat', ['moment', function (moment) {
        return function (string, format) {
          var outputFormat = format || 'D MMM YYYY';

          return string ? moment(Number(string)).local().format(outputFormat) : '';
        };
      }])

      /**
       * Just like the built in Number filter except that if the decimal value
       * is 0 it is removed from the output  ie. 5.0 becomes 5
       * todo: make this work with demcimals > 1
       */
      .filter('truncatingNumber', ['$filter', function ($filter) {
        return function(number, decimals){
          decimals = number % 1 === 0 ? 0 : decimals;
          return $filter('number')(number, decimals);
        };
      }])

      .filter('shortNumber', function(){
        // Filter to change the enum value to the text value defined in app run
        return function (num, metadata) {
          if(num === undefined){
            return num;
          }
          if(!metadata || metadata.numberType === 'rounded'){

          } else if(metadata.numberType === 'percent' ){
            return num.toFixed(1).replace(/\.0$/, '') + '<span class="numberDescriptor"> %</span>';
          } else if(metadata.numberType === 'minutes'){
            return num.toFixed(1).replace(/\.0$/, '') + '<span class="numberDescriptor"> mins</span>';
          } else {
            return num;
          }
        };
      })

      /**
       * Dynamically applies another filter based on the first argument
       */
      .filter('filterDynamic', ['$filter', function ($filter) {
        return function (input) {
          var filterName = [].splice.call(arguments, 1, 1)[0];
          return filterName ? $filter(filterName).apply(null, arguments) : input;
        };
      }])

      /**
       * Dynamically applies another filter based on the first argument
       */
      .filter('quote', function () {
        return function (string) {
          var quoted = '"'+string+'"';
          return quoted;
        };
      })
      
      /**
      * Replace "-" with formatted version using the word "to".
      */
      .filter('strToDash', [function () {
        return function (input, plainText) {
          if(plainText){
            return input && input.replace('-', ' to ');
          } else{
            return input && input.replace('-', '<i> to </i>');
          }
        };
      }])

      /**
      * Replace "+" with mo'better version.
      */
      .filter('numToMore', function () {
        return function (string) {
          return typeof(string) === 'string' && string.indexOf('+') !== -1 ? string.replace('+', '<sup>+</sup> ') : string;
        };
      })

      // TO DO - finish me
      /**
      * Wrap a string with sup tag.
      */
      .filter('wrapSup', [function () {
        return function (input, plainText) {
          if(plainText){
            return input.replace('-', ' to ');
          } else{
            return input.replace('-', '<i> to </i>');
          }
        };
      }])

      /**
       * Converts input to array if not already an array. If input is an array, original input is returned.
       */
        .filter('array', [function() {
            return function (input) {
                return (angular.isArray(input) ? input : [input]);
            };
        }])

        .filter('styleCurrencySymbol', ['standardOptions', '_', function(standardOptions, _) {
            return function (input, sc) {
                var currency = _.find(standardOptions.currencyTypes, function(currency) {
                    return (input === currency.name + ' ' + currency.symbol);
                });
                var styleClass = (_.isEmpty(sc) ? 'type-gray-01' : sc);

                return (_.isEmpty(currency) ? input : currency.name + ' <span class="' + styleClass + '">' + currency.symbol + '</span>');
            };
        }])

          .filter('encodeUri', ['util', function (util) {
              return util.encodeUri;
          }]);
});

