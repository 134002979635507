define('intake/main',['angular', './routes', './services', './directives', './controllers'],
  function(angular) {
    'use strict';
    var module = angular.module('intake', ['ngRoute', 'intake.routes', 
      'intake.services', 'intake.directives', 'intake.controllers'
    ]);

    module.service('questionTypes', ['moment', function(moment){
        return {
          "select": {},
          "date": {
            validateFn: function(value){
                //expects value to be a moment.js object
                if(value && !value.isMomentObject){
                    value = moment(value);
                }
                return !value || value.isValid();
            }
          },
          "choice": {},
          "boolean": {},
          "currency": {
            validateFn: function(value) {
              var valid = true;
              var REGEX = /^[+-]?[0-9]{1,3}(([0-9]*)|(,[0-9]{3})*|([.][0-9]{3})*)$/;
              valid = REGEX.test(value);

              //convert value to whole number and make sure it's not greater than max allowed
              if(valid){
                var cleaned = String(value).replace(/[,.]/g, "");
                if(Number(cleaned) > 999999999999999){
                  valid = false;
                }
              }
              return valid;
            }
          },
          "integer": {
            validateFn: function(value) {
              var valid = true;
              var REGEX = /^[+-]?[0-9]{1,3}(([0-9]*)|(,[0-9]{3})*|([.][0-9]{3})*)$/;
              valid = REGEX.test(value);

              //convert value to whole number and make sure it's not greater than max allowed
              if(valid){
                var cleaned = String(value).replace(/[,.]/g, "");
                if(Number(cleaned) > 999999999999999){
                  valid = false;
                }
              }
              return valid;
            }
          },
          "longText": {},
          "shortText": {},
          "otherFollowup": {}
      };

    }])

    /**
     * A reusable set of functions that will be used in triggered question
     * calculations as well as validations
     */
    .service('matchers', ['_', function(_) {

      function missing(value){
        //null, undefined, and empty string but not 0
        return value === null || value === undefined || value === ''; 
      }

      function isNaN(value){
        return _(numberize(value)).isNaN();
      }

      function numberize(value){
        return Number(value.toString().replace(/[,.]/g, ""));
      }

      return {
        eq: function(value, condition) {
          return value === condition; 
        },
        gt: function(value, condition){
          return missing(value) || isNaN(value) || numberize(value) > condition;
        },
        gte: function(value, condition){
          return missing(value) || isNaN(value) || numberize(value) >= condition;
        },
        lt: function(value, condition){
          return missing(value) || isNaN(value) || numberize(value) < condition;
        },
        lte: function(value, condition){
          return missing(value) || isNaN(value) || numberize(value) <= condition;
        },
        any: function(value, condition) {
          return _.includes(condition, value);
        },
        not: function(value, condition) {
          return _.isArray(condition) ? !_.includes(condition, value) : value !== condition;
        },
        present: function(value, condition) {
          /*typically we wouldn't ever define a falsey value in the config, but
          if the current question is only required in certain situations we can
          pass false to this function to make the current question not required*/
          if(!condition){
            return true;
          }

          //if the value is a moment object it be a valid moment
          if(value && value._isAMomentObject && !value.isValid()){
            return false; 
          }  
          return value === false || value === 0 || value;
        },
        integer: function(value) {
          var num = Number(value);
          return missing(value) || num%1 === 0;
        }            
      };
    }])

    .constant('months',[
        'January', 
        'February', 
        'March', 
        'April', 
        'May', 
        'June', 
        'July', 
        'August', 
        'September', 
        'October', 
        'November', 
        'December']);


    return module;
  });
define('intake', ['intake/main'], function (main) { return main; });

