define('user/directives',['angular'], function(angular) {
  'use strict';

  return angular.module('user.directives', [])
  
  .directive("changePlanModalOpener", ['$modal', function ($modal) {
    return {
      restrict: 'A',
      scope: true,
      link: function (scope) {
        scope.openChangePlanModal = function (selectedPlan, onClose) {
          var modal = $modal.open({
            backdrop: 'static',
            templateUrl: 'assets/javascripts/user/partials/changePlanModal.html',
            size: 'lg',
            controller: 'ChangePlanCtrl',
            resolve: {
              selectedPlan: function() {
                return selectedPlan;
              }
            }
          });

          modal.result.then(function(result) {
            if (onClose) {
              onClose(result);
            }
          }).catch(function() {
            if (onClose) {
              onClose();
            }
          });
        };
      }
    };
  }])
    ;

});
