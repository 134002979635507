define('analytics/services',['angular'], function (angular) {
  'use strict';

  return angular.module('analytics.services', [])

      .service('statusDialogService', [function() {

        var selectedStatus = { };

        this.setSelectedStatus = function(statusList) {
          selectedStatus = statusList;
        };

        this.getSelectedStatus = function() {
          return selectedStatus;
        };

        this.getStatusList = function() {
          return selectedStatus;
        };
      }])

      .factory('analyticsService', ['$http', '$log', '$filter', '$translate', 'statusDialogService', 'standardOptions', '$window', '_',
        function ($http, $log, $filter, $translate, statusDialogService, standardOptions, $window, _) {

        var getYesElement = function (data) {
          return data && _.find(data, function (element) {
                return element.groups && _.intersection(element.groups, ['t', 'Yes']).length;
              });
        };


        return {
          loadMetric: function (groups, filters, fact, internal, useSuppression, isPublic) {
//            var processType = filters.processType;
            filters = _(filters)
                .omit(_.isEmpty) //remove empty properties/arrays
                .omit('organizationName')
                .map(function (value, key) {
                  var v;
                  //also convert to arbPartyWithCaseRegions or medPartyWithCaseRegions
                  if((key === 'caseRegion' || key === 'clmPartyRegion' || key === 'rspPartyRegion' || key === 'medPartyRegion') && standardOptions.groupedRegions[value]) {
                    // convert super-regions into list of regions for case region filter
                    v = standardOptions.groupedRegions[value].join(",");
                  } else {
                    v = _.isArray(value) ? value.join(",") : value;
                  }
                  //ONLY ACTIVATED IF NEED TO INCLUDE PARTY REGIONS IN REGION FILTERS -- deactivated per #147842983

//                  if(key === 'caseRegion'){
//                    if(processType === 'ARBITRATION'){
//                      key = 'arbPartyWithCaseRegions';
//                    }
//                    else if (processType === 'MEDIATION'){
//                      key = 'medPartyWithCaseRegions';
//                    }
//                    else{
//                      key = 'partiesWithCaseRegions';
//                    }
//                  }
                  return {fieldName: key, value: v };
                })
                .value();

            var q = {groups: groups, filters: filters, fact: fact};

            var url = isPublic ? '/api/metrics/public' : '/api/metrics';

            return $http.get(url, {params: {q: JSON.stringify(q), internal: internal, useSuppression: useSuppression }})
                .then(
                function (response) {
                  return response.data;
                },
                function (err) {
                  $log.error(err);
                }
            );
          },

          buildMetric: function (filters, groups, fact, title, format, internal, useSuppression) {

            return this.loadMetric(groups, filters, fact, internal, useSuppression, false).then(function (response) {
              return {
                title: title,
                groups: groups,
                elements: response,
                format: format
              };
            });
          },
          getMetricTotal: function (config, response) {
            var total = _.sum(response, function(group){
              return group === undefined ? 0 : group.total;
            });
            if(config.fact && !config.group){
                total = _.first(response)[0].total;
            }
            return total;
          },
          getSignalStrength: function(data){
            return this.loadSignalStrength(data).then(function (response) {
              return !response ? {'signalStrength': 0} : response.signalStrength;
            });
          },
          getYesPercentage: function(data){
            var yesElement = getYesElement(data);
            return yesElement ? yesElement.percentage : 0;
          },
          getYesElement: function (data) {
            return data && _.find(data, function (element) {
              return element.groups && _.intersection(element.groups, ['t', 'Yes']).length;
            });
          },
          getOrganizations: function() {
            return $http.get('/api/organizations?sort=name&dir=asc')
            .then(function (response) {
              return response.data.data;
            });
          },
          roundPercentageMetric: function(data){

            return largestRemainderRound(data, 100);

            function largestRemainderRound(numbers, desiredTotal) {
              var result = _(numbers)
                  .map(function(number, index) {
                    return {
                      floor: Math.floor(number),
                      remainder: getRemainder(number),
                      index: index,
                    };
                  })
                  .sortBy(function(a, b) {
                    return b.remainder - a.remainder;
                  })
                  .value();

              var lowerSum = _.reduce(result, function(sum, current) {
                return sum + current.floor;
              }, 0);

              var delta = desiredTotal - lowerSum;
              for (var i = 0; i < delta; i++) {
                if (result[i] !== undefined && result[i].floor !== 0) {
                  result[i].floor++;
                }
              }

              return _(result).sortBy(function(a, b) {
                return a.index - b.index;
              }).map(function(result) {
                return result.floor;
              }).value();
            }

            function getRemainder(number) {
              var remainder = number - Math.floor(number);
              return remainder.toFixed(4);
            }
          },

          /**
           * Transforms the raw data from the api into a format that is consumable
           * by Chart.js. Inspects properties on dataElement to determine how to
           * format the data.
           * @param dataElement
           * @param rawData
           * @returns {{data: *[], labels: Array}}
           */
          transformForChart: function(dataElement){
            var type = dataElement.template;
            var isArrayData = _.includes(['bigBar', 'babyBar'], type);
            var metrics = dataElement.metrics;

            /*if there is only one metric just grab its data property. If there
            * is more than one metric, we'll make an assumption that we are
            * reporting the "yes" value for each metric so we'll need to transform
            * the data accordingly*/
            var rawData = metrics.length === 1 ? metrics[0].data :
                _(metrics).map(function(m){
                  return _.merge(getYesElement(m.data), {groups: [m.groups[0]]});
                }).compact().value();

            var sortFn = function(){
              if(type === 'donut'){
                return function(item){
                  return -1*item.percentage; //multiply by -1 for desc order
                };
              } else if(isArrayData){
                /*look at the data to figure out if the graph is representing
                 * numbers or ranges of numbers. if so:
                 sort by the numeric value of the first number in the range, this
                 handles ranges like 0-3 as well as 11+ */
                return function(item){
                  var label = item.groups[0];
                  if(!isNaN(label.substr(0, 1))){
                    return Number(label.substring(0, label.indexOf(label.match(/\D/))));
                  } else {
                    return label;
                  }
                };
              } else {
                return undefined;
              }
            };

            rawData = _.sortBy(rawData, sortFn());

            var data = _.map(rawData, function(d){return Math.round(d.percentage*100);});

            return {
              data: isArrayData ? [data] : data,
              labels: _.map(rawData, function (item) {
                return getLabel(item);
              })
            };


            function getLabel(dataItem){
              var key = dataElement.customLabels ? 'analytics.' + dataItem.groups[0] : dataItem.groups[0];
              var label =  $filter('translate')(key);
              /*if we can't find a translation the label will come back equal to key, in that case we just want
              to display the raw data from the group instead of the key which will start with "analytics."*/
              if(label === key){
                label = dataItem.groups[0];
              }
              return dataElement.labelSuffixKey ? label + ' ' + $filter('translate')('analytics.' + dataElement.labelSuffixKey) : label;
            }
          },
          buildOptions: function(standardOptionsKey, convertKeysToCaps, blankOption, omit, keys) {
            var keyList = keys? keys : standardOptions[standardOptionsKey];
            //turn standardOptions (string array into array of {label: , value: })
              var options = _.map(keyList, function (o) {
                 if(!_.contains(omit, o)){
                  return {
                    label: o,
                    value: (convertKeysToCaps ? o.toUpperCase() : o).toString()
                  };
                 }
              });
            if(blankOption){
              options.unshift(getBlankOption());
            }
            function getBlankOption(){
              if(blankOption){
                return {
                  label: blankOption,
                  value: ''
                };
              }
            }

              return _.without(options, undefined);
          },
          //will retreive the top ten case types from active cases
          getTopTenCaseTypesOptions: function(internal, suppression) {
              var url = '/api/metrics';
              var q = {groups: ['caseType'], filters:[{fieldName:"status",value:"ACTIVE"}]};
              return $http.get(url, {params: {q: JSON.stringify(q), internal: internal, useSuppression: suppression }})
              .then(
                function (response) {
                    return _(response.data)
                      .sortBy('percentage')
                      .takeRight(10)
                      .pluck('groups[0]')
                      .intersection(standardOptions.caseTypes)
                      .sort()
                      .value();
                },
                function (err) {
                  $log.error(err);
                }
              );
          },
          loadSignalStrength: function (data) {

            return $http.post($window.Conf.envConfig.signalStrengthApiUrl + "/signal-strength", data)
                .then(
                    function (response) {
                      return response.data;
                    },
                    function (err) {
                      $log.error(err);
                    }
                );
          }
        };
      }])

      .constant('analyticsDataElements',
      {
        Process: [
          {
            template: 'horizontalBar',
            metrics: [{groups: ['caseType']}],
            title: 'Case Types',
            showLabelIcons: true
          },
          {
            template: 'donut',
            metrics: [{groups: ['filedAsMediation']}],
            title: 'Process Filed'
          },
          {
            template: 'donut',
            metrics: [{groups: ['processType']}],
            title: 'Process Used'
          },
          {
            template: 'donut',
            metrics: [{groups: ['numArbitrators']}],
            standardOptions: 'numArbitrators',
            title: 'Number of Arbitrators',
            customLabels: true,
            restrictToStandardOptions: true
          },
          {
            template: 'bigBar',
            metrics: [{groups: ['arbSelMethod']}],
            title: 'Arbitrator Selection'
          },
          {
            template: 'bigPercent', metrics: [
            {
              groups: ['counterFiled'],
              field: 'percentage',
              description: 'of cases<br/>filed counterclaims'
            },
            {
              groups: ['counterPrevailed'],
              field: 'percentage',
              description: 'of these<br/> prevailed'
            }]
          },
          {
            template: 'bigBar',
            metrics: [{groups: ['numDepositions']}],
            title: 'Case Depositions',
            labelSuffixKey: 'numDepositionsSuffix'
          },
          {
            template: 'bigBar',
            metrics: [{groups: ['numInterrogatories']}],
            title: 'Case Interrogatories',
            labelSuffixKey: 'numInterrogatoriesSuffix'
          },
          {
            template: 'bigPercent',
            metrics: [{
              groups: ['ediscoveryUsed'],
              field: 'percentage',
              description: 'of cases used <br/> E-Discovery'
            }]
          },
          {
            template: 'bigBar',
            metrics: [{groups: ['numMotionsFiled']}],
            title: 'Motions Filed',
            labelSuffixKey: 'numMotionsFiledSuffix'
          },
          {
            template: 'bigPercent',
            metrics: [{
              groups: ['sumJudgementMotionMade'],
              field: 'percentage',
              description: 'of cases filed summary<br/> judgment motions'
            }]
          },
          {
            template: 'bigPercent',
            metrics: [{
              groups: ['hearingOccurred'],
              field: 'percentage',
              description: 'of cases had <br/> merits hearings'
            }]
          },
          {
            template: 'donut',
            metrics: [{groups: ['caseConclusion']}],
            title: 'Arbitration Outcome'
          },
          {
            template: 'donut',
            metrics: [{groups: ['rulesUsed']}],
            standardOptions: 'rulesUsed',
            title: 'Rules Used',
            customLabels: true,
            forceNewRow: true
          },
          {
            template: 'bigPercent',
            metrics: [{
              groups: ['nationalCourtsInvolved'],
              field: 'percentage',
              description: 'of cases had<br/> national court involvement'
            }]
          },
          {
            template: 'bigPercent',
            forceNewRow: true,
            metrics: [
            {
              groups: ['intervenerMotionMade'],
              field: 'percentage',
              description: 'of cases filed motions to<br/>include 3rd Party Interveners'
            },
            {
              groups: ['intervenerMotionGranted'],
              field: 'percentage',
              description: 'of these <br/>were granted'
            }]
          },
          {
            template: 'bigPercent',
            metrics: [{
              groups: ['amicusCuriaeBriefIncluded'],
              field: 'percentage',
              description: 'of cases included an<br/> Amicus Curiae brief'
            }]
          },
          {
            template: 'bigPercent',
            metrics: [{
              groups: ['emerArbUsed'],
              field: 'percentage',
              description: 'of cases saw emergency <br/>arbitration procedures availed'
            }]
          }

        ],
        Time: [
          {template: 'bigDuration', metrics: [{fact: 'medTotalDays', description: 'Mediation Process'}]},
          {template: 'bigDuration', metrics: [{fact: 'medConferenceDays', description: 'Mediation Conference'}]},
          {template: 'bigDuration', metrics: [{fact: 'arbTotalDays', description: 'Arbitration Process'}], forceNewRow: true},
          {template: 'bigDuration', metrics: [{fact: 'arbHearingDays', description: 'Arbitration Merits Hearing'}]},
          {template: 'bigDuration', metrics: [{fact: 'arbSettledDays', description: 'Settled Arbitration Cases'}], forceNewRow: true},
          // {template: 'bigDuration', metrics: [{fact: 'arbWithdrawnDays', description: 'Withdrawn Arbitration Cases'}]},
          {template: 'bigDuration', metrics: [{fact: 'arbAwardedDays', description: 'Awarded Arbitration Cases'}]},
        ],
        Money: [
          {template: 'donut', metrics: [{groups: ['demandType']}], title: 'Claim Types'},
          {template: 'bigMoney', metrics: [{fact: 'normDemandAmt', description: 'Claim Amount'}]},
          {template: 'donut', metrics: [{groups: ['awardedTo']}], title: 'Awarded Party'},

          {template: 'bigMoney', metrics: [{fact: 'normClaimantAwardAmt', description: 'Claimant Award Amount'}], forceNewRow: true},
          {template: 'bigMoney', metrics: [{fact: 'normRespondentAwardAmt', description: 'Respondent Award Amount'}]},
          {template: 'donut', metrics: [{groups: ['prevailingParty']}], title: 'Arbitration Prevailing Party'},

          {template: 'donut', metrics: [{groups: ['counterDemandType']}], title: 'Counterclaim Types', forceNewRow: true},
          {template: 'bigMoney', metrics: [{fact: 'normCounterDemandAmt', description: 'Counterclaim Amount'}]},
          {template: 'bigMoney', metrics: [{fact: 'normRespondentAwardAmt', description: 'Counterclaim Award'}]},

          {template: 'bigMoney', metrics: [{fact: 'normTotalArbMedFeeAmt', description: 'Arbitrator + Mediator Fees'}], forceNewRow: true},
          {template: 'bigMoney', metrics: [{fact: 'normTotalInstFeeAmt', description: 'Arbitration Institution Fees'}]},
          {template: 'bigMoney', metrics: [{fact: 'normAwardCostsAmt', description: 'Award Granted Costs'}]},

          {template: 'bigMoney', metrics: [{fact: 'normInstFeeAmt', description: 'Mediation Institution Fees'}], forceNewRow: true},
          {template: 'bigMoney', metrics: [{fact: 'normMediatorFeeAmt', description: 'Mediator Fees'}]}
        ],
        Place: [
          {
            template: 'bigBar',
            metrics: [{groups: ['caseRegion']}],
            title: 'Case Regions'
          },
          {
            template: 'bigBar',
            metrics: [{groups: ['medPartyRegion']}],
            title: 'Mediation Parties'
          },
          {
            template: 'bigBar',
            metrics: [{groups: ['clmPartyRegion']}],
            title: 'Claimants'
          },
          {
            template: 'bigBar',
            metrics: [{groups: ['rspPartyRegion']}],
            title: 'Respondents'
          },
          {
            template: 'horizontalBar',
            metrics: [{groups: ['hearingLocation']}],
            title: 'Merits Hearing Locations'
          }
        ]
      })

      .constant('widgets', {
        bars: {
          arbitrationCaseLength: {
            id: 'arbitrationCaseLength',
            type: 'horizontalBar',
            config: {
              title: 'Average Arbitration Case Duration',
              group: 'caseConclusion',
              implicitFilters: {processType: 'ARBITRATION'},
              expectedValues: ['Awarded', 'Settled/Withdrawn', 'Dismissed', 'Administrative Close'],
              fact: 'arbTotalDays',
              colorScheme: '_6MultiBB',
              field: 'avg',
              sort: 'expectedValue',
              format: 'analyticsDuration',
              height: 14,
              labelStyle: 'small',
              barItemStyle: 'tight',
              showCaseTotals: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.',
              allowDownload: true,
              secondary: {
                fact: 'arbTotalDays',
                field: 'avg',
                format: 'analyticsDuration',
                description: 'Average arbitration process duration was',
                descriptionLocation: 'prefix'
              }
            }
          },
          testVertBar: {
            id: 'testVertBar',
            type: 'verticalBar',
            config: {
              title: 'Average Claim Length (in weeks)',
              implicitFilters: {processType: 'ARBITRATION'},
              field: 'avg',
              fact: 'arbTotalDays',
              labelFormat: 'years',
              expectedValues: ['2012', '2013', '2014', '2015', '2016', '2017'],
              tooltipFormat: 'weeks',
              group: 'demandDateYears',
              showCaseTotals: true,
              allowDownload: true
            }
          },
          avgNumberMaleFemaleArbs: {
            id: 'avgNumberMaleFemaleArbs',
            type: 'horizontalBar',
            config: {
              title: 'Gender',
              implicitFilters: {processType: 'ARBITRATION'},
              fact: ['avgNumMaleArbs', 'avgNumFemaleArbs'],
              field: 'avg',
              colorScheme: '_2Blue3',
              labels: ['Male', 'Female'],
              format: 'analyticsPercentage',
              allowDownload: true,
              showCaseTotals: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.'
            }
          },
          awardAmount: {
            id: 'awardAmount',
            type: 'horizontalBar',
            config: {
              title: 'Prevailing Award Amounts',
              implicitFilters: {processType: 'ARBITRATION'},
              fact: ['normClaimantAwardAmt', 'normRespondentAwardAmt'],
              labels: ['Claimant', 'Respondent'],
              colorScheme: '_2MultiD',
              field: 'avg',
              format: 'analyticsCurrency',
              allowDownload: true,
              showCaseTotals: true,
                secondary: {
                group: 'awardCostsGranted',
                field: 'percentage',
                format: 'analyticsPercentage',
                description: 'of cases granted fees'
              }
            }
          },
          counterClaims: {
            id: 'counterClaims',
            type: 'horizontalBar',
            config: {
              title: 'Counterclaim vs Counterclaim Award',
              implicitFilters: {processType: 'ARBITRATION'},
              helperText: '(For cases where a counterclaim and counterclaim award were reported)',
              filters: {
                caseConclusion: 'Awarded',
                counterPrevailed: true,
                normCounterDemandAmt: 'isPresent',
                normRespondentAwardAmt: 'isPresent',
                prevailingParty: ['Respondent', 'Prevailed in Part']
              },
              fact: ['normCounterDemandAmt', 'normRespondentAwardAmt'],
              labels: ['Average Counterclaim', 'Average Counterclaim Award'],
              colorScheme: '_2Green1',
              field: 'avg',
              format: 'analyticsCurrency',
              allowDownload: true,
              showCaseTotals: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.',
              secondary: {
                group: 'counterDemandType',
                displayedGroup: ['Non-monetary', 'Both'],
                field: 'percentage',
                format: 'analyticsPercentage',
                description: 'of cases include a non-monetary counterclaim'
              }
            }
          },
           avgClaimAmountByConclusion: {
            id: 'avgClaimAmountByConclusion',
            type: 'horizontalBar',
            config: {
              title: 'Average Claim Amount by Conclusion',
              implicitFilters: {processType: 'ARBITRATION'},
              colorScheme: '_6MultiBB',
              field: 'avg',
              fact: 'normDemandAmt',
              labels: ['Conclusion', 'Claim Amount'],
              expectedValues: ['Settled/Withdrawn', 'Dismissed', 'Administrative Close'],
              format: 'analyticsCurrency',
              group: 'caseConclusion',
              allowDownload: true,
              showCaseTotals: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.',
              extraBar: {
                location: 'top',
                config: {
                  implicitFilters: {processType: 'ARBITRATION'},
                  filters: {
                    normDemandAmt: 'isPresent',
                    normClaimantAwardAmt: 'isPresent'
                  },
                  fact: 'normDemandAmt',
                  group: 'caseConclusion',
                  expectedValues: ['Awarded']
                }
              }
            }
          },
          claimVsAwardAmt: {
            id: 'claimVsAwardAmt',
            type: 'horizontalBar',
            config: {
              title: 'Claim vs Award',
              implicitFilters: {processType: 'ARBITRATION'},
              helperText: '(For cases where a claim and award were reported)',
              filters: {
                caseConclusion: 'Awarded',
                normDemandAmt: 'isPresent',
                normClaimantAwardAmt: 'isPresent',
                prevailingParty: ['Claimant', 'Prevailed in Part']
              },
              colorScheme: '_2Blue2',
              fact: ['normDemandAmt', 'normClaimantAwardAmt'],
              labels: ['Claim Amount', 'Award Amount'],
              field: 'avg',
              format: 'analyticsCurrency',
              allowDownload: true,
              showCaseTotals: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.'
            }
          },
          mediationLength: {
            id: 'mediationLength',
            type: 'horizontalBar',
            config: {
              title: 'Average Mediation Duration',
              implicitFilters: {processType: 'MEDIATION'},
              fact: ['medTotalDays', "realMedConfDays"],
              labels: ['Process', 'Conference'],
              colorScheme: '_2MultiC',
              field: 'avg',
              format: 'analyticsDuration',
              showCaseTotals: true,
              allowDownload: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.'
            }
          },
          groundsForChallenge: {
            id: 'groundsForChallenge',
            type: 'horizontalBar',
            config: {
              subtitle: 'Grounds for Challenge',
              showCaseTotals: true,
              group: 'groundsForChallenge',
              implicitFilters: {processType: 'ARBITRATION'},
              field: 'percentage',
              format: 'analyticsPercentage',
              colorScheme: '_2Blue',
              colorSchemeStrategy: 'MONO',
              labelStyle: 'small',
              expectedValues: ['Conflict of Interest-Financial', 'Conflict of Interest-Party Relationship', 'Unknown', 'Other'],
              overlay: {
                group: 'challengeGranted',
                field: 'percentage',
                format: 'analyticsPercentage',
                description: 'granted'
              }
            }
          },
          claimantRegion: {
            id: 'claimantRegion',
            type: 'horizontalBar',
            config: {
              icons: true,
              title: 'Claimant Region',
              group: 'clmPartyRegion',
              implicitFilters: {processType: 'ARBITRATION'},
              field: 'percentage',
              format: 'analyticsPercentage',
              colorScheme: '_7MultiA',
              expectedValues: ['Africa', 'Asia', 'Europe', 'Latin America', 'Middle East', 'North America'],
              allowDownload: true,
              showCaseTotals: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.'
            }
          },
          respondentRegion: {
            id: 'respondentRegion',
            type: 'horizontalBar',
            config: {
              icons: true,
              title: 'Respondent Region',
              group: 'rspPartyRegion',
              implicitFilters: {processType: 'ARBITRATION'},
              field: 'percentage',
              format: 'analyticsPercentage',
              colorScheme: '_7MultiA',
              expectedValues: ['Africa', 'Asia', 'Europe', 'Latin America', 'Middle East', 'North America'],
              showCaseTotals: true,
              allowDownload: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.'
            }
          }
        },
        donuts: {
          caseTypes: {
            id: 'caseTypes',
            type: 'donut',
            colClass: 'col-sm-12',
            config: {
              icons: true,
              topTen: true,
              legendClass: 'overview',
              title: 'Case Types',
              helperText: '<a target="_blank" href="http://www.disputeresolutiondata.com/aerospace_defense">Click here for case type definitions</a>',
              group: 'caseType',
              expectedValues: 'caseTypes',
              includeOther: true,
              sort: 'alpha',
              colorScheme: 'wheel',
              maxLegendColumnItems: 15,
              chartColClass: 'col-xs-12 col-md-5',
              legendColClass: 'col-xs-12 col-md-7',
              allowDownload: true,
              showCaseTotals: true,
              showSignalStrength: true,
              downloadWidth: 2400,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.',
              tooltip: {
                template: 'caseTypeOverviewTooltip',
                data:{
                  medTotalDays:{
                    group: 'caseType',
                    fact: 'medTotalDays',
                    filters: {processType: 'MEDIATION'}
                  },
                  arbTotalDays: {
                    group: 'caseType',
                    fact: 'arbTotalDays',
                    filters: {processType: 'ARBITRATION'}
                  },
                  arbDemandAmt: {
                    group: 'caseType',
                    fact: 'normDemandAmt',
                    filters: {processType: 'ARBITRATION'}
                  }
                },
                groupedData: {
                  forEach: 'caseTypes',
                  group: 'processType'
                }
              }
            }
          },
          arbitrationOutcome: {
            id: 'arbitrationOutcome',
            type: 'donut',
            config: {
              title: 'Arbitration Outcome',
              group: 'caseConclusion',
              implicitFilters: {processType: 'ARBITRATION'},
              expectedValues: ['Awarded', 'Settled/Withdrawn', 'Dismissed', 'Administrative Close'],
              sort: 'expectedValue',
              colorScheme: '_6MultiBB',
              allowDownload: true,
              showCaseTotals: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.'
            }
          },
          mediationOutcome: {
            id: 'mediationOutcome',
            type: 'donut',
            config: {
              title: 'Mediation Case Outcome',
              group: 'medCaseConclusion',
              implicitFilters: {processType: 'MEDIATION'},
              labels: ['Settled/Withdrawn', 'Administrative Close', 'Impasse'],
              expectedValues: ['Settled/Withdrawn', 'Administrative Close', 'Impasse'],
              sort: 'expectedValue',
              colorScheme: '_4MultiB',
              allowDownload: true,
              showCaseTotals: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.'
            }
          },
          prevailingParty: {
            id: 'prevailingParty',
            type: 'donut',
            config: {
              title: 'Prevailing Party in Awarded Cases',
              group: 'prevailingParty',
              implicitFilters: {processType: 'ARBITRATION'},
              sort: 'alpha',
              colorScheme: '_3MultiA',
              expectedValues: ['Claimant', 'Respondent', 'Prevailed in Part'],
              allowDownload: true,
              showCaseTotals: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.'
            }
          },
          numMotionsFiled: {
            id: 'numMotionsFiled',
            type: 'donut',
            config: {
              showCaseTotals: true,
              allowDownload: true,
              group: 'numMotionsFiled',
              implicitFilters: {processType: 'ARBITRATION'},
              title: 'Motions Filed',
              colorScheme: '_6Violet',
              sort: 'alpha',
              expectedValues: ['0', '1-3', '4-6', '7-10', '11+', 'Unknown'],
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.'
            }
          },
          numDepositions: {
            id: 'numDepositions',
            type: 'donut',
            config: {
              group: 'numDepositions',
              showCaseTotals: true,
              allowDownload: true,
              implicitFilters: {processType: 'ARBITRATION'},
              title: 'Depositions',
              colorScheme: '_6Blue',
              sort: 'alpha',
              expectedValues: ['0', '1-3', '4-6', '7-10', '11+', 'Unknown'],
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.'
            }
          },
          numInterrogatories: {
            id: 'numInterrogatories',
            type: 'donut',
            config: {
              group: 'numInterrogatories',
              showCaseTotals: true,
              allowDownload: true,
              implicitFilters: {processType: 'ARBITRATION'},
              title: 'Interrogatories',
              colorScheme: '_6Green',
              sort: 'alpha',
              expectedValues: ['0', '1-3', '4-6', '7-10', '11+', 'Unknown'],
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.'
            }
          },
          mediatorSelection: {
            id: 'mediatorSelection',
            type: 'donut',
            config: {
              group: 'howMedSelected',
              implicitFilters: {processType: 'MEDIATION'},
              title: 'Mediator Selection',
              legendTitle: 'Appointed by:',
              colorScheme: '_4MultiA',
              expectedValues: ['Institution Selected', 'Party Nominated', 'Court Appointed', 'Other'],
              allowDownload: true,
              showCaseTotals: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.'
            }
          },
          mediationParties: {
            id: 'mediationParties',
            type: 'donut',
            config: {
              group: 'numMediationParties',
              implicitFilters: {processType: 'MEDIATION'},
              title: 'Parties Per Case',
              colorScheme: 'all',
              sort: 'alpha',
              expectedValues: ['2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13'],
              expectedValuesBinned: ['2', '3', '4', '5'],
              labelSuffix: 'Parties',
              lastLabelSuffix: '+',
              allowDownload: true,
              showCaseTotals: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.'
            }
          },
          stayedBy: {
            id: 'stayedBy',
            type: 'donut',
            config: {
              group: 'stayedBy',
              showCaseTotals: true,
              implicitFilters: {processType: 'ARBITRATION'},
              subtitle: 'Proceeding Stayed By',
              colorScheme: '_2MultiA',
              expectedValues: ['Court Order', 'Agreement of Both Parties'],
              secondaryFact: {
                fact: 'stayDuration',
                field: 'avg',
                format: 'analyticsDuration',
                description: 'Average of',
                descriptionLocation: 'prefix'
              }
            }
          },
          thirdPartyFunding: {
            id: 'thirdPartyFunding',
            type: 'donut',
            config: {
              showCaseTotals: true,
              group: 'thirdPartyFunderInvolved',
              implicitFilters: {processType: 'ARBITRATION'},
              title: '3rd Party Funder Involved',
              colorScheme: '_3MultiA_VBG',
              expectedValues: ['Unknown', 'No', 'Yes'],
              sort: 'expectedValue'
            },
            inset: [
              {
                type: 'donut',
                config: {
                  group: 'thirdPartyFunderParty',
                  subtitle: 'FOR WHICH PARTY',
                  subtitleClass: 'inset',
                  colorScheme: '_3Green',
                  expectedValues: ['Claimant', 'Respondent', 'Both'],
                  rightCol: 12,
                  leftCol: 12,
                  legendClass: 'inset',
                  inset: true
                }
              }
            ]
          },
          postAwardMotions: {
            id: 'postAwardMotions',
            type: 'donut',
            config: {
              group: 'postAwdMotionsFiled',
              showCaseTotals: true,
              implicitFilters: {processType: 'ARBITRATION'},
              title: 'Post Award Motions',
              colorScheme: '_2MultiE',
              expectedValues: ['f', 't'],
              sort: 'expectedValue'
            },
            inset: [
              {
                type: 'donut',
                config: {
                  group: 'postAwdMotionsReason',
                  subtitle: 'REASON FOR POST AWARD MOTIONS',
                  colorScheme: '_2Blue2',
                  expectedValues: ['Technical Corrections'],
                  includeOther: true,
                  inset: true,
                  legendClass: 'inset',
                  subtitleClass: 'inset',
                  rightCol: 12,
                  leftCol: 12
                }
              }
            ]
          },
          arbitrationContractLanguage: {
            id: 'contractLanguage',
            type: 'donut',
            config: {
              showCaseTotals: true,
              allowDownload: true,
              group: 'contractLanguage',
              implicitFilters: {processType: 'ARBITRATION'},
              title: 'Principal Contract Language',
              legendTitle: 'Top Languages',
              colorScheme: '_6MultiB',
              limitToTop: 5,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.'
            }
          },
          mediationContractLanguage: {
            id: 'contractLanguage',
            type: 'donut',
            config: {
              group: 'contractLanguage',
              showCaseTotals: true,
              implicitFilters: {processType: 'MEDIATION'},
              title: 'Principal Contract Language',
              legendTitle: 'Top Languages',
              colorScheme: '_6MultiB',
              limitToTop: 5
            }
          },
          arbitrationContractCurrency: {
            id: 'arbitrationContractCurrency',
            type: 'donut',
            config: {
              group: 'contractCurrency',
              implicitFilters: {processType: 'ARBITRATION'},
              title: 'Contract Currency',
              legendTitle: 'Top Currencies',
              colorScheme: '_6MultiBBB',
              limitToTop: 5,
              legendClass: 'contract-currency',
              allowDownload: true,
              showCaseTotals: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.'
            }
          },
          mediationContractCurrency: {
            id: 'mediationContractCurrency',
            type: 'donut',
            config: {
              group: 'contractCurrency',
              implicitFilters: {processType: 'MEDIATION'},
              title: 'Contract Currency',
              legendTitle: 'Top Currencies',
              colorScheme: '_6MultiBBB',
              limitToTop: 5,
              legendClass: 'contract-currency',
              allowDownload: true,
              showCaseTotals: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.'
            }
          },
          numArbitrators: {
            id: 'numArbitrators',
            type: 'donut',
            config : {
              group: 'numArbitrators',
              implicitFilters: {processType: 'ARBITRATION'},
              title: 'Number of Arbitrators',
              colorScheme: '_4Green',
              expectedValues: ['1', '3', 'Other/Unknown'],
              sort: 'alpha',
              includeOther: false,
              showCaseTotals: true,
              allowDownload: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.'
            }
          }
        },
        maps: {
          caseRegion: {
            id: 'caseRegion',
            type: 'regionMap',
            mapContainer: 'caseRegion',
            colClass: 'col-sm-12',
            config: {
              expectedValues: ['africa', 'asia', 'europe', 'latin america', 'middle east', 'north america'],
              group: 'caseRegion',
              title: 'Case Regions'
            }
          },
          mediationParties: {
            id: 'mediationParties',
            type: 'bubbleMap',
            mapContainer: 'caseRegion',
            colClass: 'col-sm-12',
            config: {
              icons: true,
              iconFontSize: '16px',
              expectedValues: ["Africa", "Asia", "Europe", "Latin America", "Middle East", "North America"],
              group: 'medPartyRegion',
              implicitFilters: {processType: 'MEDIATION'},
              title: 'Mediation Parties',
              keyHelperText: [{key:"Asia", text:"(Includes Oceania)"}],
              tooltipText: ' of the parties <br/> were from here',
              allowDownload: true,
              showCaseTotals: true
            }
          },
          hearingLocations: {
            id: 'hearingLocations',
            type: 'bubbleMap',
            mapContainer: 'hearingLocationMap',
            colClass: 'col-sm-12',
            config: {
              bubbleColor: '#1b8a8c',
              legendTitle: 'Top Merits Hearing Locations',
              icons: true,
              expectedValues: 'hearingLocations',
              includeOther: true,
              showCaseTotals: true,
              group: 'hearingLocation',
              implicitFilters: {processType: 'ARBITRATION'},
              title: 'Merits Hearing Locations',
              limitToTop: 0.8, //show values that make up top 80% and group everything else into other
              tooltipText: ' of mertis hearings <br/> were located here'
            }
          }
        },
        numbers: {
          claims: {
            id: 'claims',
            type: 'numberWithDescription',
            colClass: 'col-md-12',
            config: {
              title: 'Case Claims',
              implicitFilters: {processType: 'ARBITRATION'},
              allowDownload: true,
              showCaseTotals: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.',
                values : [
                // {
                //   fact: 'normDemandAmt',
                //   field: 'avg',
                //   format: 'analyticsCurrency',
                //   description: 'average claim amount for all conclusions and'
                // },
                {
                  group: 'demandType',
                  displayedGroup: ['Non-monetary', 'Both'],
                  field: 'percentage',
                  format: 'analyticsPercentage',
                  description: 'of cases included a non-monetary claim'
                }
              ]
            }
          },
          counterclaims: {
            id: 'counterclaims',
            type: 'numberWithDescription',
            colClass: 'col-md-12',
            config: {
              title: 'Counterclaims',
              implicitFilters: {processType: 'ARBITRATION'},
              allowDownload: true,
              showCaseTotals: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.',
                values: [
                {
                  group: 'counterFiled',
                  field: 'percentage',
                  format: 'analyticsPercentage',
                  description: 'of cases filed counterclaims'
                },
                {
                  group: 'counterPrevailed',
                  field: 'percentage',
                  format: 'analyticsPercentage',
                  description: 'of counterclaims prevailed'
                }
              ]
            }
          },
          arbitrationHearings: {
            id: 'arbitrationHearings',
            type: 'numberWithDescription',
            config: {
              title: 'Arbitration Case Merits Hearings',
              showCaseTotals: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.',
              implicitFilters: {processType: 'ARBITRATION'},
              values: [
                {
                  group: 'hearingOccurred',
                  field: 'percentage',
                  format: 'analyticsPercentage',
                  description: 'of cases had merits hearings.'
                }
//                {
//                  fact: 'arbHearingDays',
//                  field: 'avg',
//                  format: 'analyticsDuration',
//                  secondary: true,
//                  description: 'The average arbitration hearing duration was',
//                  descriptionLocation: 'prefix'
//                }
              ]
            }
          },
            arbitrationHearingDays: {
                id: 'arbitrationHearingDays',
                type: 'numberWithDescription',
                colClass: 'col-md-12',
                config: {
                    title: 'Arbitration Case Merits Hearing Days',
                    implicitFilters: {processType: 'ARBITRATION'},
                    showCaseTotals: true,
                    showSignalStrength: true,
                    ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.',
                    allowDownload: true,
                    values: [
                        {
                            fact: 'realArbHearingDays',
                            field: 'avg',
                            format: 'analyticsDuration',
                            description: 'The average arbitration merits hearing duration was',
                            descriptionLocation: 'prefix'
                        }
                    ]
                }
            },
            mediationConferenceDays: {
                id: 'mediationConferenceDays',
                type: 'numberWithDescription',
                colClass: 'col-md-12',
                config: {
                    title: 'Mediation Case Conference Days',
                    implicitFilters: {processType: 'MEDIATION'},
                    showCaseTotals: true,
                    showSignalStrength: true,
                    ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.',
                    allowDownload: true,
                    values: [
                        {
                            fact: 'realMedConfDays',
                            field: 'avg',
                            format: 'analyticsDuration',
                            description: 'The average mediation conference duration was',
                            descriptionLocation: 'prefix'
                        }
                    ]
                }
            },
          mediationCases: {
            id: 'mediationCases',
            type: 'numberWithDescription',
            config: {
              title: 'Mediation Cases',
              implicitFilters: {processType: 'MEDIATION'},
              allowDownload: true,
              showCaseTotals: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.',
                values: [
                {
                  group: 'mediationConcluded',
                  field: 'percentage',
                  format: 'analyticsPercentage',
                  description: 'of cases successfully concluded during or after the mediation conference'
                }
              ]
            }
          },
          mediationCases2: {
            id: 'mediationCases2',
            type: 'numberWithDescription',
            config: {
              title: '',
              implicitFilters: {filedAsMediation: 'Mediation'},
              omitProcessTypeFilter: true,
              allowDownload: true,
              showCaseTotals: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.',
                values: [
                {
                  group: 'isMediationToArbitration',
                  field: 'percentage',
                  format: 'analyticsPercentage',
                  description: 'of cases went to arbitration.'
                }
              ]
            }
          },
          bifurcatedAwards: {
            id: 'bifurcatedAwards',
            type: 'numberWithDescription',
            config: {
              title: 'Award Bifurcation',
              implicitFilters: {processType: 'ARBITRATION'},
              forceMultiLine: true,
              showCaseTotals: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.',
                values: [
                {
                  group: 'awardBifurcated',
                  field: 'percentage',
                  format: 'analyticsPercentage',
                  description: 'of cases where awards were bifurcated between damages and merits'
                }
              ]
            }
          },
          arbitrationToMediation: {
            id: 'arbitrationToMediation',
            type: 'numberWithDescription',
            config: {
              title: 'Included Mediation',
              implicitFilters: {processType: 'ARBITRATION'},
              showCaseTotals: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.',
                values: [
                {
                  group: 'isArbitrationToMediation',
                  field: 'percentage',
                  format: 'analyticsPercentage',
                  description: 'of cases included mediation.'
                }
//                {
//                  group: 'isMediatorNotArbitrator',
//                  displayedGroup: 'f',
//                  field: 'percentage',
//                  format: 'analyticsPercentage',
//                  description: 'of these cases the mediator was also the arbitrator',
//                  secondary: true
//                }
              ]
            }
          },
          arbitratorChallenged: {
            id: 'arbitratorChallenged',
            type: 'numberWithDescription',
            config: {
              title: 'Challenge to an Arbitrator',
              implicitFilters: {processType: 'ARBITRATION'},
              showCaseTotals: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.',
                values: [
                {
                  group: 'arbitratorChallenged',
                  field: 'percentage',
                  format: 'analyticsPercentage',
                  color: 'stat12',
                  description: 'of cases.'
                },
                {
                  group: 'challengeGranted',
                  field: 'percentage',
                  format: 'analyticsPercentage',
                  description: 'granted',
                  secondary: true
                }
              ]
            }
          },
          proceedingStayed: {
            id: 'proceedingStayed',
            type: 'numberWithDescription',
            config: {
              title: 'Proceeding Stayed',
              implicitFilters: {processType: 'ARBITRATION'},
              showCaseTotals: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.',
                values: [
                {
                  group: 'proceedingStayed',
                  field: 'percentage',
                  format: 'analyticsPercentage',
                  color: 'stat13',
                  description: 'of cases.'
                },
                {
                  fact: 'stayDuration',
                  field: 'avg',
                  format: 'analyticsDuration',
                  description: 'Stayed an average of',
                  descriptionLocation: 'prefix',
                  secondary: true
                }
              ]
            }
          },
          localeDisputed: {
            id: 'localeDisputed',
            type: 'numberWithDescription',
            colClass: 'col-md-12',
            config: {
              title: 'Merits Hearing Locale',
              implicitFilters: {processType: 'ARBITRATION'},
              showCaseTotals: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.',
                values: [
                {
                  group: 'localeDisputed',
                  field: 'percentage',
                  format: 'analyticsPercentage',
                  description: 'of cases disputed the merits hearing locale'
                },
                {
                  group: 'localeChanged',
                  field: 'percentage',
                  format: 'analyticsPercentage',
                  description: 'of these cases changed merits hearing locale as a result'
                }
              ]
            }
          },
          multipleRegions: {
            id: 'multipleRegions',
            type: 'numberWithDescription',
            colClass: 'col-md-12',
            config: {
              implicitFilters: {processType: 'ARBITRATION'},
              alternateStyle: 'centered small-bold-figure',
              showCaseTotals: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.',
                values: [
                {
                  group: 'partyRegionsDifferent',
                  field: 'percentage',
                  format: 'analyticsPercentage',
                  description: 'of cases had claimants and respondents <strong>from different regions</strong>'
                }
              ]
            }
          }
        },
        tables: {
          arbitrationFigures: {
            id: 'arbitrationFigures',
            type: 'table',
            showCaseTotals: true,
            config: {
              title: 'Average Arbitration Fees',
              implicitFilters: {processType: 'ARBITRATION'},
              color: 'stat19',
              rows: [
                {
                  fact: 'normTotalArbMedFeeAmt',
                  field: 'avg',
                  format: 'analyticsCurrency',
                  label: 'Arbitrator Fees',
                  tooltip: 'If case included mediation these fees may be included'
                },
                {
                  fact: 'normTotalInstFeeAmt',
                  field: 'avg',
                  format: 'analyticsCurrency',
                  label: 'Arbitration Institution Fees'
                },
                {
                  fact: 'normAwardCostsAmt',
                  field: 'avg',
                  format: 'analyticsCurrency',
                  label: 'Award Granted Costs'
                }
              ]
            }
          },
          motionDetails: {
            id: 'motionDetails',
            type: 'table',
            config: {
              title: 'Motion Details',
              implicitFilters: {processType: 'ARBITRATION'},
              color: 'stat19',
              showCaseTotals: true,
              allowDownload: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.',
              dataDescription: '<em class="serif">of</em> cases',
              rows: [
                {
                  group: 'sumJudgementMotionMade',
                  field: 'percentage',
                  format: 'analyticsPercentage',
                  label: 'Motion for Summary Judgment'
                },
                {
                  group: 'ediscoveryUsed',
                  field: 'percentage',
                  format: 'analyticsPercentage',
                  label: 'Used E-Discovery'
                },
                {
                  group: 'emerArbUsed',
                  field: 'percentage',
                  format: 'analyticsPercentage',
                  label: 'Emergency Arbitration Procedures Availed'
                },
                {
                  group: 'amicusCuriaeBriefIncluded',
                  field: 'percentage',
                  format: 'analyticsPercentage',
                  label: 'Included Amicus Curiae Brief'
                },
                {
                  group: 'nationalCourtsInvolved',
                  field: 'percentage',
                  format: 'analyticsPercentage',
                  label: 'National Court Involvement'
                },
                {
                  group: 'intervenerMotionMade',
                  field: 'percentage',
                  format: 'analyticsPercentage',
                  label: 'Motion to Include 3rd Party Interveners',
                  secondary: {
                    group: 'intervenerMotionGranted',
                    field: 'percentage',
                    format: 'analyticsPercentage',
                    dataDescription: 'granted'
                  }
                },
                {
                  group: 'motionInterimReliefFiled',
                  field: 'percentage',
                  format: 'analyticsPercentage',
                  label: 'Motion for Interim Relief Filed',
                  secondary: {
                    group: 'motionInterimReliefGranted',
                    field: 'percentage',
                    format: 'analyticsPercentage',
                    dataDescription: 'granted'
                  }

                }
              ]
            }
          },
          arbSelMethod: {
            id: 'motionDetails',
            type: 'table',
            config: {
              title: 'Arbitrator selected by',
              implicitFilters: {processType: 'ARBITRATION'},
              color: 'stat19',
              showCaseTotals: true,
              allowDownload: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.',
              dataDescription: '<em class="serif">of</em> cases',
              rows: [
                {
                  group: 'arbSelMethod',
                  displayedGroup: 'Administrating institution',
                  field: 'percentage',
                  format: 'analyticsPercentage',
                  label: 'Administrating institution'
                },
                {
                  group: 'arbSelMethod',
                  displayedGroup: 'List procedure',
                  field: 'percentage',
                  format: 'analyticsPercentage',
                  label: 'List procedure'
                },
                {
                  group: 'arbSelMethod',
                  displayedGroup: 'Party nomination',
                  field: 'percentage',
                  format: 'analyticsPercentage',
                  label: 'Party nomination'
                },
                {
                  group: 'arbSelMethod',
                  displayedGroup: 'Other/Unknown',
                  field: 'percentage',
                  format: 'analyticsPercentage',
                  label: 'Other/Unknown'
                }
              ]
            }
          },
          mediationFigures: {
            id: 'mediationFigures',
            type: 'table',
            config: {
              title: 'Average Mediation Fees',
              implicitFilters: {processType: 'MEDIATION'},
              allowDownload: true,
              showCaseTotals: true,
              showSignalStrength: true,
              ssTooltip: 'For more information on signal stregnth, please visit the Data Researchers FAQ page.',
              helperText: 'Institution fees may include mediator fees',
              rows: [
                {
                  fact: 'normMediatorFeeAmt',
                  field: 'avg',
                  format: 'analyticsCurrency',
                  label: 'Mediator Fees'
                },
                {
                  fact: 'normInstFeeAmt',
                  field: 'avg',
                  format: 'analyticsCurrency',
                  label: 'Mediation Institution Fees'
                }
              ]
            }
          }
        },
        basicCounts: {
          caseTypesBreakdown: {
            id: 'caseTypesBreakdown',
            type: 'basicCount',
            config: {
              title: 'Case Types',
              field: 'percentage',
                expectedValues: [
                "Aerospace & Defense",
                "Agriculture",
                "Arts, Entertainment & Recreation",
                "Business Ownership",
                "Commercial Contracts",
                "Commercial Services",
                "Construction",
                "Education",
                "Employment",
                "Energy",
                "Environmental",
                "Financial Services & Banking",
                "Healthcare",
                "Hospitality & Travel",
                "Insurance & Reinsurance",
                "Intellectual Property",
                "Licensing Agreements",
                "Manufacturing",
                "Mass Claims & Class Actions",
                "Mining & Raw Materials",
                "Nonprofits & Public Administration",
                "Professional & Technical Services",
                "Real Estate",
                "Technology",
                "Telecommunications",
                "Transportation & Storage",
                "Wholesale & Retail Trade"
              ],
              group: 'caseType',
              allowDownload: false,
              showCaseTotals: true
            }
          }
        },
        detailedStats: {
          arbitrationHearingDays: {
            id: "arbitrationHearingDays",
            title: "Arbitration case merits hearing days",
            type: 'detailedStatsTable',
            config: {
              allowDownload: true,
              title: "Arbitration case merits hearing days",
              format: 'analyticsDuration',
              subType: "basicDuration",
              facts: [
                {
                    key: 'realArbHearingDays',
                    title: 'Average Arbitration Merits Hearing Duration'
                }
              ]
            }
          },
          mediationConferenceDays: {
            id: "mediationConferenceDays",
            title: "Mediation case conference days",
            type: 'detailedStatsTable',
            config: {
              allowDownload: true,
              title: "Mediation case conference days",
              format: 'analyticsDuration',
              subType: "basicDuration",
              facts: [
                {
                    key: 'realMedConfDays',
                    title: 'Average mediation conference duration'
                }
              ]
            }
          },
          caseType: {
            type: 'detailedStatsTable',
            id: 'caseType',
            title: 'Case types',
            config:{
              title: 'Case types',
              key: 'caseType',
              subType: 'count',
              sort: 'valuesDesc',
              useStandardOptions: true,
              allowDownload: true,
              expectedValues: 'caseTypes'
            }
          },
          caseTypeArbitrationAwarded: {
            type: 'detailedStatsTable',
            id: 'caseTypeArbitrationAwarded',
            title: 'Case types',
            config:{
              title: 'Case types',
              key: 'caseType',
              filters: {
                caseConclusion: 'Awarded',
              },
              subType: 'count',
              sort: 'valuesDesc',
              useStandardOptions: true,
              allowDownload: true,
              expectedValues: 'caseTypes'
            }
          },
          caseTypeArbitrationSettledWithdrawn: {
            type: 'detailedStatsTable',
            id: 'caseTypeArbitrationSettledWithdrawn',
            title: 'Case types',
            config:{
              title: 'Case types',
              key: 'caseType',
              filters: {
                caseConclusion: 'Settled/Withdrawn',
              },
              subType: 'count',
              sort: 'valuesDesc',
              useStandardOptions: true,
              allowDownload: true,
              expectedValues: 'caseTypes'
            }
          },
          caseTypeArbitrationDismissed: {
            type: 'detailedStatsTable',
            id: 'caseTypeArbitrationDismissed',
            title: 'Case types',
            config:{
              title: 'Case types',
              key: 'caseType',
              filters: {
                caseConclusion: 'Dismissed',
              },
              subType: 'count',
              sort: 'valuesDesc',
              useStandardOptions: true,
              allowDownload: true,
              expectedValues: 'caseTypes'
            }
          },
          caseTypeArbitrationAdministrativeClose: {
            type: 'detailedStatsTable',
            id: 'caseTypeArbitrationAdministrativeClose',
            title: 'Case types',
            config:{
              title: 'Case types',
              key: 'caseType',
              filters: {
                caseConclusion: 'Administrative Close',
              },
              subType: 'count',
              sort: 'valuesDesc',
              useStandardOptions: true,
              allowDownload: true,
              expectedValues: 'caseTypes'
            }
          },
          caseTypeMediationSettledWithdrawn: {
            type: 'detailedStatsTable',
            id: 'caseTypeMediationSettledWithdrawn',
            title: 'Case types',
            config:{
              title: 'Case types',
              key: 'caseType',
              filters: {
                medCaseConclusion: 'Settled/Withdrawn',
              },
              subType: 'count',
              sort: 'valuesDesc',
              useStandardOptions: true,
              allowDownload: true,
              expectedValues: 'caseTypes'
            }
          },
          caseTypeMediationAdminClose: {
            type: 'detailedStatsTable',
            id: 'caseTypeMediationAdminClose',
            title: 'Case types',
            config:{
              title: 'Case types',
              key: 'caseType',
              filters: {
                medCaseConclusion: 'Administrative Close',
              },
              subType: 'count',
              sort: 'valuesDesc',
              useStandardOptions: true,
              allowDownload: true,
              expectedValues: 'caseTypes'
            }
          },
          impasseCaseType: {
            type: 'detailedStatsTable',
            id: 'caseType',
            title: 'Case types',
            config:{
              title: 'Case types',
              filters: {
                medCaseConclusion: 'Impasse'
              },
              key: 'caseType',
              subType: 'count',
              useStandardOptions: true,
              allowDownload: true,
              expectedValues: 'caseTypes'
            }
          },
          caseRegionDetailedStats: {
            id: "caseRegionDetailedStats",
            type: 'detailedStatsTable',
            title: 'Regions where cases filed',
            config:{
              allowDownload: true,
              title: 'Regions where cases filed',
              subType: 'count',
              key: 'caseRegion',
              useStandardOptions: false,
              expectedValues: ["Africa", "Asia", "Europe", "Latin America", "Middle East", "North America"]
            }
          },
          caseRegionDetailedStatsArbitrationAwarded: {
            id: "caseRegionDetailedStatsArbitrationAwarded",
            type: 'detailedStatsTable',
            title: 'Regions where cases filed',
            config:{
              allowDownload: true,
              title: 'Regions where cases filed',
              filters: {
                caseConclusion: 'Awarded'
              },
              subType: 'count',
              key: 'caseRegion',
              useStandardOptions: false,
              expectedValues: ["Africa", "Asia", "Europe", "Latin America", "Middle East", "North America"]
            }
          },
          caseRegionDetailedStatsArbitrationSettledWithdrawn: {
            id: "caseRegionDetailedStatsArbitrationSettledWithdrawn",
            type: 'detailedStatsTable',
            title: 'Regions where cases filed',
            config:{
              allowDownload: true,
              title: 'Regions where cases filed',
              filters: {
                caseConclusion: 'Settled/Withdrawn'
              },
              subType: 'count',
              key: 'caseRegion',
              useStandardOptions: false,
              expectedValues: ["Africa", "Asia", "Europe", "Latin America", "Middle East", "North America"]
            }
          },
          caseRegionDetailedStatsArbitrationDismissed: {
            id: "caseRegionDetailedStatsArbitrationDismissed",
            type: 'detailedStatsTable',
            title: 'Regions where cases filed',
            config:{
              allowDownload: true,
              title: 'Regions where cases filed',
              filters: {
                caseConclusion: 'Dismissed'
              },
              subType: 'count',
              key: 'caseRegion',
              useStandardOptions: false,
              expectedValues: ["Africa", "Asia", "Europe", "Latin America", "Middle East", "North America"]
            }
          },
          caseRegionDetailedStatsArbitrationAdministrativeClose: {
            id: "caseRegionDetailedStatsArbitrationAdministrativeClose",
            type: 'detailedStatsTable',
            title: 'Regions where cases filed',
            config:{
              allowDownload: true,
              title: 'Regions where cases filed',
              filters: {
                caseConclusion: 'Administrative Close'
              },
              subType: 'count',
              key: 'caseRegion',
              useStandardOptions: false,
              expectedValues: ["Africa", "Asia", "Europe", "Latin America", "Middle East", "North America"]
            }
          },
          caseRegionDetailedStatsMediationSettledWithdrawn: {
            id: "caseRegionDetailedStatsMediationSettledWithdrawn",
            type: 'detailedStatsTable',
            title: 'Regions where cases filed',
            config:{
              allowDownload: true,
              title: 'Regions where cases filed',
              filters: {
                medCaseConclusion: 'Settled/Withdrawn'
              },
              subType: 'count',
              key: 'caseRegion',
              useStandardOptions: false,
              expectedValues: ["Africa", "Asia", "Europe", "Latin America", "Middle East", "North America"]
            }
          },
          caseRegionDetailedStatsMediationAdminClose: {
            id: "caseRegionDetailedStatsMediationAdminClose",
            type: 'detailedStatsTable',
            title: 'Regions where cases filed',
            config:{
              allowDownload: true,
              title: 'Regions where cases filed',
              filters: {
                medCaseConclusion: 'Administrative Close'
              },
              subType: 'count',
              key: 'caseRegion',
              useStandardOptions: false,
              expectedValues: ["Africa", "Asia", "Europe", "Latin America", "Middle East", "North America"]
            }
          },
          caseRegionDetailedStatsMediationImpasse: {
            id: "caseRegionDetailedStatsMediationImpasse",
            type: 'detailedStatsTable',
            title: 'Regions where cases filed',
            config:{
              allowDownload: true,
              title: 'Regions where cases filed',
              filters: {
                medCaseConclusion: 'Impasse'
              },
              subType: 'count',
              key: 'caseRegion',
              useStandardOptions: false,
              expectedValues: ["Africa", "Asia", "Europe", "Latin America", "Middle East", "North America"]
            }
          },
          clmPartyRegion: {
            id: "clmPartyRegion",
            type: 'detailedStatsTable',
            title: 'Claimant party regions',
            config: {
              allowDownload: true,
              title: 'Claimant party regions',
              subType: 'count',
              key: 'clmPartyRegion',
              useStandardOptions: false,
              expectedValues: ["Africa", "Asia", "Europe", "Latin America", "Middle East", "North America"]
            }
          },
          clmPartyRegionArbitrationAwarded: {
            id: "clmPartyRegionArbitrationAwarded",
            type: 'detailedStatsTable',
            title: 'Claimant party regions',
            config: {
              allowDownload: true,
              title: 'Claimant party regions',
              filters: {
                caseConclusion: 'Awarded'
              },
              subType: 'count',
              key: 'clmPartyRegion',
              useStandardOptions: false,
              expectedValues: ["Africa", "Asia", "Europe", "Latin America", "Middle East", "North America"]
            }
          },
          clmPartyRegionArbitrationSettledWithdrawn: {
            id: "clmPartyRegionArbitrationSettledWithdrawn",
            type: 'detailedStatsTable',
            title: 'Claimant party regions',
            config: {
              allowDownload: true,
              title: 'Claimant party regions',
              filters: {
                caseConclusion: 'Settled/Withdrawn'
              },
              subType: 'count',
              key: 'clmPartyRegion',
              useStandardOptions: false,
              expectedValues: ["Africa", "Asia", "Europe", "Latin America", "Middle East", "North America"]
            }
          },
          clmPartyRegionArbitrationDismissed: {
            id: "clmPartyRegionArbitrationDismissed",
            type: 'detailedStatsTable',
            title: 'Claimant party regions',
            config: {
              allowDownload: true,
              title: 'Claimant party regions',
              filters: {
                caseConclusion: 'Dismissed'
              },
              subType: 'count',
              key: 'clmPartyRegion',
              useStandardOptions: false,
              expectedValues: ["Africa", "Asia", "Europe", "Latin America", "Middle East", "North America"]
            }
          },
          clmPartyRegionArbitrationAdministrativeClose: {
            id: "clmPartyRegionArbitrationAdministrativeClose",
            type: 'detailedStatsTable',
            title: 'Claimant party regions',
            config: {
              allowDownload: true,
              title: 'Claimant party regions',
              filters: {
                caseConclusion: 'Administrative Close'
              },
              subType: 'count',
              key: 'clmPartyRegion',
              useStandardOptions: false,
              expectedValues: ["Africa", "Asia", "Europe", "Latin America", "Middle East", "North America"]
            }
          },
          rspPartyRegion: {
            id: "rspPartyRegion",
            type: 'detailedStatsTable',
            title: 'Respondent party regions',
            config: {
              allowDownload: true,
              title: 'Respondent party regions',
              subType: 'count',
              key: 'rspPartyRegion',
              useStandardOptions: false,
              expectedValues: ["Africa", "Asia", "Europe", "Latin America", "Middle East", "North America"]
            }
          },
          rspPartyRegionArbitrationAwarded: {
            id: "rspPartyRegionArbitrationAwarded",
            type: 'detailedStatsTable',
            title: 'Respondent party regions',
            config: {
              allowDownload: true,
              title: 'Respondent party regions',
              filters: {
                caseConclusion: 'Awarded'
              },
              subType: 'count',
              key: 'rspPartyRegion',
              useStandardOptions: false,
              expectedValues: ["Africa", "Asia", "Europe", "Latin America", "Middle East", "North America"]
            }
          },
          rspPartyRegionArbitrationSettledWithdrawn: {
            id: "rspPartyRegionArbitrationSettledWithdrawn",
            type: 'detailedStatsTable',
            title: 'Respondent party regions',
            config: {
              allowDownload: true,
              title: 'Respondent party regions',
              filters: {
                caseConclusion: 'Settled/Withdrawn'
              },
              subType: 'count',
              key: 'rspPartyRegion',
              useStandardOptions: false,
              expectedValues: ["Africa", "Asia", "Europe", "Latin America", "Middle East", "North America"]
            }
          },
          rspPartyRegionArbitrationDismissed: {
            id: "rspPartyRegionArbitrationDismissed",
            type: 'detailedStatsTable',
            title: 'Respondent party regions',
            config: {
              allowDownload: true,
              title: 'Respondent party regions',
              filters: {
                caseConclusion: 'Dismissed'
              },
              subType: 'count',
              key: 'rspPartyRegion',
              useStandardOptions: false,
              expectedValues: ["Africa", "Asia", "Europe", "Latin America", "Middle East", "North America"]
            }
          },
          rspPartyRegionArbitrationAdministrativeClose: {
            id: "rspPartyRegionArbitrationAdministrativeClose",
            type: 'detailedStatsTable',
            title: 'Respondent party regions',
            config: {
              allowDownload: true,
              title: 'Respondent party regions',
              filters: {
                caseConclusion: 'Administrative Close'
              },
              subType: 'count',
              key: 'rspPartyRegion',
              useStandardOptions: false,
              expectedValues: ["Africa", "Asia", "Europe", "Latin America", "Middle East", "North America"]
            }
          },
          filedAsMediation: {
            id: "filedAsMediation",
            type: 'detailedStatsTable',
            title: 'Case commenced as',
            config: {
              allowDownload: true,
              title: 'Case commenced as',
              subType: 'count',
              key: 'filedAsMediation',
              useStandardOptions: true,
              expectedValues: 'processTypes'
            }
          },
          filedAsMediationArbitrationAwarded: {
            id: "filedAsMediationArbitrationAwarded",
            type: 'detailedStatsTable',
            title: 'Case commenced as',
            config: {
              allowDownload: true,
              title: 'Case commenced as',
              filters: {
                caseConclusion: 'Awarded'
              },
              subType: 'count',
              key: 'filedAsMediation',
              useStandardOptions: true,
              expectedValues: 'processTypes'
            }
          },
          filedAsMediationArbitrationSettledWithdrawn: {
            id: "filedAsMediationArbitrationSettledWithdrawn",
            type: 'detailedStatsTable',
            title: 'Case commenced as',
            config: {
              allowDownload: true,
              title: 'Case commenced as',
              filters: {
                caseConclusion: 'Settled/Withdrawn'
              },
              subType: 'count',
              key: 'filedAsMediation',
              useStandardOptions: true,
              expectedValues: 'processTypes'
            }
          },
          filedAsMediationArbitrationDismissed: {
            id: "filedAsMediationArbitrationDismissed",
            type: 'detailedStatsTable',
            title: 'Case commenced as',
            config: {
              allowDownload: true,
              title: 'Case commenced as',
              filters: {
                caseConclusion: 'Dismissed'
              },
              subType: 'count',
              key: 'filedAsMediation',
              useStandardOptions: true,
              expectedValues: 'processTypes'
            }
          },
          filedAsMediationArbitrationAdministrativeClose: {
            id: "filedAsMediationArbitrationAdministrativeClose",
            type: 'detailedStatsTable',
            title: 'Case commenced as',
            config: {
              allowDownload: true,
              title: 'Case commenced as',
              filters: {
                caseConclusion: 'Administrative Close'
              },
              subType: 'count',
              key: 'filedAsMediation',
              useStandardOptions: true,
              expectedValues: 'processTypes'
            }
          },
          filedAsMediationMediationSettledWithdrawn: {
            id: "filedAsMediationMediationSettledWithdrawn",
            type: 'detailedStatsTable',
            title: 'Case commenced as',
            config: {
              allowDownload: true,
              title: 'Case commenced as',
              filters: {
                medCaseConclusion: 'Settled/Withdrawn'
              },
              subType: 'count',
              key: 'filedAsMediation',
              useStandardOptions: true,
              expectedValues: 'processTypes'
            }
          },
          filedAsMediationMediationAdminClose: {
            id: "filedAsMediationMediationAdminClose",
            type: 'detailedStatsTable',
            title: 'Case commenced as',
            config: {
              allowDownload: true,
              title: 'Case commenced as',
              filters: {
                medCaseConclusion: 'Administrative Close'
              },
              subType: 'count',
              key: 'filedAsMediation',
              useStandardOptions: true,
              expectedValues: 'processTypes'
            }
          },
          filedAsMediationMediationImpasse: {
            id: "filedAsMediationMediationImpasse",
            type: 'detailedStatsTable',
            title: 'Case commenced as',
            config: {
              allowDownload: true,
              title: 'Case commenced as',
              filters: {
                medCaseConclusion: 'Impasse'
              },
              subType: 'count',
              key: 'filedAsMediation',
              useStandardOptions: true,
              expectedValues: 'processTypes'
            }
          },
          demandType: {
            id: "demandType",
            type: 'detailedStatsTable',
            title: 'Nature of claim',
            config: {
              allowDownload: true,
              title: 'Nature of claim',
              subType: 'count',
              key: 'demandType',
              useStandardOptions: true,
              expectedValues: 'demandTypes'
            }
          },
          demandTypeArbitrationAwarded: {
            id: "demandTypeArbitrationAwarded",
            type: 'detailedStatsTable',
            title: 'Nature of claim',
            config: {
              allowDownload: true,
              title: 'Nature of claim',
              filters: {
                caseConclusion: 'Awarded'
              },
              subType: 'count',
              key: 'demandType',
              useStandardOptions: true,
              expectedValues: 'demandTypes'
            }
          },
          demandTypeArbitrationSettledWithdrawn: {
            id: "demandTypeArbitrationSettledWithdrawn",
            type: 'detailedStatsTable',
            title: 'Nature of claim',
            config: {
              allowDownload: true,
              title: 'Nature of claim',
              filters: {
                caseConclusion: 'Settled/Withdrawn'
              },
              subType: 'count',
              key: 'demandType',
              useStandardOptions: true,
              expectedValues: 'demandTypes'
            }
          },
          demandTypeArbitrationDismissed: {
            id: "demandTypeArbitrationDismissed",
            type: 'detailedStatsTable',
            title: 'Nature of claim',
            config: {
              allowDownload: true,
              title: 'Nature of claim',
              filters: {
                caseConclusion: 'Dismissed'
              },
              subType: 'count',
              key: 'demandType',
              useStandardOptions: true,
              expectedValues: 'demandTypes'
            }
          },
          demandTypeArbitrationAdministrativeClose: {
            id: "demandTypeArbitrationAdministrativeClose",
            type: 'detailedStatsTable',
            title: 'Nature of claim',
            config: {
              allowDownload: true,
              title: 'Nature of claim',
              filters: {
                caseConclusion: 'Administrative Close'
              },
              subType: 'count',
              key: 'demandType',
              useStandardOptions: true,
              expectedValues: 'demandTypes'
            }
          },
          counterDemandType:  {
            id: "counterDemandType",
            title: 'Nature of counterclaim (where filed)',
            type: 'detailedStatsTable',
            config: {
              allowDownload: true,
              title: 'Nature of counterclaim (where filed)',
              subType: 'countWithZeros',
              key: 'counterDemandType',
              useStandardOptions: true,
              expectedValues: 'demandTypes'
            }
          },
          counterDemandTypeArbitrationAwarded:  {
            id: "counterDemandTypeArbitrationAwarded",
            title: 'Nature of counterclaim (where filed)',
            type: 'detailedStatsTable',
            config: {
              allowDownload: true,
              title: 'Nature of counterclaim (where filed)',
              filters: {
                caseConclusion: 'Awarded'
              },
              subType: 'countWithZeros',
              key: 'counterDemandType',
              useStandardOptions: true,
              expectedValues: 'demandTypes'
            }
          },
          counterDemandTypeArbitrationSettledWithdrawn:  {
            id: "counterDemandTypeArbitrationSettledWithdrawn",
            title: 'Nature of counterclaim (where filed)',
            type: 'detailedStatsTable',
            config: {
              allowDownload: true,
              title: 'Nature of counterclaim (where filed)',
              filters: {
                caseConclusion: 'Settled/Withdrawn'
              },
              subType: 'countWithZeros',
              key: 'counterDemandType',
              useStandardOptions: true,
              expectedValues: 'demandTypes'
            }
          },
          counterDemandTypeArbitrationDismissed:  {
            id: "counterDemandTypeArbitrationDismissed",
            title: 'Nature of counterclaim (where filed)',
            type: 'detailedStatsTable',
            config: {
              allowDownload: true,
              title: 'Nature of counterclaim (where filed)',
              filters: {
                caseConclusion: 'Dismissed'
              },
              subType: 'countWithZeros',
              key: 'counterDemandType',
              useStandardOptions: true,
              expectedValues: 'demandTypes'
            }
          },
          counterDemandTypeArbitrationAdministrativeClose:  {
            id: "counterDemandTypeArbitrationAdministrativeClose",
            title: 'Nature of counterclaim (where filed)',
            type: 'detailedStatsTable',
            config: {
              allowDownload: true,
              title: 'Nature of counterclaim (where filed)',
              filters: {
                caseConclusion: 'Administrative Close'
              },
              subType: 'countWithZeros',
              key: 'counterDemandType',
              useStandardOptions: true,
              expectedValues: 'demandTypes'
            }
          },
          rulesUsed: {
            id: "rulesUsed",
            type: 'detailedStatsTable',
            title: 'Rules',
            config: {
              allowDownload: true,
              title: 'Rules',
              subType: 'countWithZeros',
              key: 'rulesUsed',
              useStandardOptions: false,
              expectedValues: ["Institutional Rules", "UNCITRAL Rules", "Other/Ad hoc"]
            }
          },
          rulesUsedArbitrationAwarded: {
            id: "rulesUsedArbitrationAwarded",
            type: 'detailedStatsTable',
            title: 'Rules',
            config: {
              allowDownload: true,
              title: 'Rules',
              filters: {
                caseConclusion: 'Awarded'
              },
              subType: 'countWithZeros',
              key: 'rulesUsed',
              useStandardOptions: false,
              expectedValues: ["Institutional Rules", "UNCITRAL Rules", "Other/Ad hoc"]
            }
          },
          rulesUsedArbitrationSettledWithdrawn: {
            id: "rulesUsedArbitrationSettledWithdrawn",
            type: 'detailedStatsTable',
            title: 'Rules',
            config: {
              allowDownload: true,
              title: 'Rules',
              filters: {
                caseConclusion: 'Settled/Withdrawn'
              },
              subType: 'countWithZeros',
              key: 'rulesUsed',
              useStandardOptions: false,
              expectedValues: ["Institutional Rules", "UNCITRAL Rules", "Other/Ad hoc"]
            }
          },
          rulesUsedArbitrationDismissed: {
            id: "rulesUsedArbitrationDismissed",
            type: 'detailedStatsTable',
            title: 'Rules',
            config: {
              allowDownload: true,
              title: 'Rules',
              filters: {
                caseConclusion: 'Dismissed'
              },
              subType: 'countWithZeros',
              key: 'rulesUsed',
              useStandardOptions: false,
              expectedValues: ["Institutional Rules", "UNCITRAL Rules", "Other/Ad hoc"]
            }
          },
          rulesUsedArbitrationAdministrativeClose: {
            id: "rulesUsedArbitrationAdministrativeClose",
            type: 'detailedStatsTable',
            title: 'Rules',
            config: {
              allowDownload: true,
              title: 'Rules',
              filters: {
                caseConclusion: 'Administrative Close'
              },
              subType: 'countWithZeros',
              key: 'rulesUsed',
              useStandardOptions: false,
              expectedValues: ["Institutional Rules", "UNCITRAL Rules", "Other/Ad hoc"]
            }
          },
          numArbitrators: {
            id: "numArbitrators",
            type: 'detailedStatsTable',
            title: 'Number of arbitrators',
            config: {
              allowDownload: true,
              title: 'Number of arbitrators',
              subType: 'countWithZeros',
              key: 'numArbitrators',
              useStandardOptions: false,
              expectedValues: ['1', '3', 'Other/Unknown']
            }
          },
          numArbitratorsArbitrationAwarded: {
            id: "numArbitratorsArbitrationAwarded",
            type: 'detailedStatsTable',
            title: 'Number of arbitrators',
            config: {
              allowDownload: true,
              title: 'Number of arbitrators',
              filters: {
                caseConclusion: 'Awarded'
              },
              subType: 'countWithZeros',
              key: 'numArbitrators',
              useStandardOptions: false,
              expectedValues: ['1', '3', 'Other/Unknown']
            }
          },
          numArbitratorsArbitrationSettledWithdrawn: {
            id: "numArbitratorsArbitrationSettledWithdrawn",
            type: 'detailedStatsTable',
            title: 'Number of arbitrators',
            config: {
              allowDownload: true,
              title: 'Number of arbitrators',
              filters: {
                caseConclusion: 'Settled/Withdrawn'
              },
              subType: 'countWithZeros',
              key: 'numArbitrators',
              useStandardOptions: false,
              expectedValues: ['1', '3', 'Other/Unknown']
            }
          },
          numArbitratorsArbitrationDismissed: {
            id: "numArbitratorsArbitrationDismissed",
            type: 'detailedStatsTable',
            title: 'Number of arbitrators',
            config: {
              allowDownload: true,
              title: 'Number of arbitrators',
              filters: {
                caseConclusion: 'Dismissed'
              },
              subType: 'countWithZeros',
              key: 'numArbitrators',
              useStandardOptions: false,
              expectedValues: ['1', '3', 'Other/Unknown']
            }
          },
          numArbitratorsArbitrationAdministrativeClose: {
            id: "numArbitratorsArbitrationAdministrativeClose",
            type: 'detailedStatsTable',
            title: 'Number of arbitrators',
            config: {
              allowDownload: true,
              title: 'Number of arbitrators',
              filters: {
                caseConclusion: 'Administrative Close'
              },
              subType: 'countWithZeros',
              key: 'numArbitrators',
              useStandardOptions: false,
              expectedValues: ['1', '3', 'Other/Unknown']
            }
          },
          processType: {
            id: "processType",
            title: 'Mediation vs. Arbitration',
            type: 'detailedStatsTable',
              config: {
                allowDownload: true,
                title: 'Mediation vs. Arbitration',
                subType: 'count',
                key: 'processType',
                expectedValues: ['ARBITRATION', 'MEDIATION'],
                displayMap: {'ARBITRATION': 'Arbitration', 'MEDIATION': 'Mediation'}
              }
          },
          medPartyRegion: {
            id: "medPartyRegion",
            title: 'Mediation party regions',
            type: 'detailedStatsTable',
            config:{
              title: 'Mediation party regions',
              subType: 'count',
              key: 'medPartyRegion',
              useStandardOptions: true,
              allowDownload: true,
              expectedValues: 'regions'
            }
          },
          caseConclusion: {
            id: "caseConclusion",
            type: 'detailedStatsTable',
            title: 'Arbitration outcomes',
            config: {
              title: 'Arbitration outcomes',
              subType: 'count',
              key:'caseConclusion',
              useStandardOptions: true,
              expectedValues: 'caseConclusions',
              allowDownload: true,
            }
          },
          caseConclusionSettledWithdrawn: {
            id: "caseConclusionSettledWithdrawn",
            type: 'detailedStatsTable',
            title: 'Arbitration outcomes',
            config: {
              title: 'Arbitration outcomes',
              filters: {
                caseConclusion: 'Settled/Withdrawn'
              },
              subType: 'count',
              key:'caseConclusion',
              useStandardOptions: true,
              expectedValues: 'caseConclusions',
              allowDownload: true,
            }
          },
          caseConclusionAdministrativeClose: {
            id: "caseConclusionAdministrativeClose",
            type: 'detailedStatsTable',
            title: 'Arbitration outcomes',
            config: {
              title: 'Arbitration outcomes',
              filters: {
                caseConclusion: 'Administrative Close'
              },
              subType: 'count',
              key:'caseConclusion',
              useStandardOptions: true,
              expectedValues: 'caseConclusions',
              allowDownload: true,
            }
          },
          dismissedReasons: {
            id: "caseConclusion",
            type: 'detailedStatsTable',
            title: 'Arbitration outcomes',
            config: {
              title: 'Arbitration outcomes',
              filters: {
                caseConclusion: 'Dismissed'
              },
              subType: 'boolean',
              facts: [
                {
                  key:'dismissedReason',
                  expectedValue: 'Lack of Jurisdiction',
                  title: 'Dismissed - lack of jurisdiction'
                },
                // {
                //   key: 'dismissedReason',
                //   expectedValue: 'Inadmissibility',
                //   title: 'Dismissed - Inadmissibility'
                // },
                {
                  key: 'dismissedReason',
                  expectedValue: ['Lack of Legal Merit', 'Inadmissibility'],
                  title: 'Dismissed - lack of legal merit'
                },
                {
                  key: 'dismissedReason',
                  expectedValue: 'Failure to Pursue a Claim',
                  title: 'Dismissed - failure to pursue a claim'
                },
              ],
              allowDownload: true,
            }
          },
          awardedReasons: {
            id: "awardedReasons",
            type: 'detailedStatsTable',
            title: 'Arbitration outcomes',
            config: {
              title: 'Arbitration outcomes',
              subType: 'boolean',
              facts: [
                {
                  key:'prevailingParty',
                  expectedValue: 'Claimant',
                  title: 'Claimant prevailing'
                },
                {
                  key: 'prevailingParty',
                  expectedValue: 'Respondent',
                  title: 'Counterclaim prevailing'
                },
                {
                  key: 'prevailingParty',
                  expectedValue: 'Prevailed in Part',
                  title: 'Each party prevailing in part'
                },
                {
                  key: 'awardCostsGranted',
                  title: 'Legal costs recovered'
                },
              ],
              allowDownload: true,
            }
          },
          awardedReasonsArbitrationAwarded: {
            id: "awardedReasonsArbitrationAwarded",
            type: 'detailedStatsTable',
            title: 'Arbitration outcomes',
            config: {
              title: 'Arbitration outcomes',
              filters: {
                caseConclusion: 'Awarded'
              },
              subType: 'boolean',
              facts: [
                {
                  key:'caseConclusion',
                  expectedValue: 'Awarded',
                  title: 'Awarded',
                  filters: {
                    caseConclusion: 'Awarded'
                  }
                },
                {
                  key:'prevailingParty',
                  expectedValue: 'Claimant',
                  title: 'Claimant prevailing'
                },
                {
                  key: 'prevailingParty',
                  expectedValue: 'Respondent',
                  title: 'Counterclaim prevailing'
                },
                {
                  key: 'prevailingParty',
                  expectedValue: 'Prevailed in Part',
                  title: 'Each party prevailing in part'
                },
                {
                  key: 'awardCostsGranted',
                  title: 'Legal costs recovered'
                },
              ],
              allowDownload: true,
            }
          },
          medCaseConclusion: {
            id: "medCaseConclusion",
            type: 'detailedStatsTable',
            title: 'Mediation outcomes',
            config: {
              title: 'Mediation outcomes',
              subType: 'count',
              key:'medCaseConclusion',
              useStandardOptions: true,
              expectedValues: 'mediationCaseConclusions',
              allowDownload: true,
            }
          },
          medCaseConclusionSettledWithdrawn: {
            id: "medCaseConclusionSettledWithdrawn",
            type: 'detailedStatsTable',
            title: 'Mediation outcomes',
            config: {
              title: 'Mediation outcomes',
              filters: {
                medCaseConclusion: 'Settled/Withdrawn'
              },
              subType: 'count',
              key:'medCaseConclusion',
              useStandardOptions: true,
              expectedValues: 'mediationCaseConclusions',
              allowDownload: true,
            }
          },
          medCaseConclusionAdminClose: {
            id: "medCaseConclusionAdminClose",
            type: 'detailedStatsTable',
            title: 'Mediation outcomes',
            config: {
              title: 'Mediation outcomes',
              filters: {
                medCaseConclusion: 'Administrative Close'
              },
              subType: 'count',
              key:'medCaseConclusion',
              useStandardOptions: true,
              expectedValues: 'mediationCaseConclusions',
              allowDownload: true,
            }
          },
          medCaseConclusionImpasse: {
            id: "medCaseConclusionImpasse",
            type: 'detailedStatsTable',
            title: 'Mediation outcomes',
            config: {
              title: 'Mediation outcomes',
              filters: {
                medCaseConclusion: 'Impasse'
              },
              subType: 'count',
              key:'medCaseConclusion',
              useStandardOptions: true,
              expectedValues: 'mediationCaseConclusions',
              allowDownload: true,
            }
          },
          arbSelMethod: {
            id: "arbSelMethod",
            type: 'detailedStatsTable',
            title: 'Arbitrator selected by',
            config: {
              title: 'Arbitrator selected by',
              subType: 'countWithZeros',
              key: 'arbSelMethod',
              useStandardOptions: true,
              allowDownload: true,
              showCaseTotals: true,
              expectedValues: 'arbitrationSelectionMethods'
            }
          },
          arbSelMethodArbitrationAwarded: {
            id: "arbSelMethodArbitrationAwarded",
            type: 'detailedStatsTable',
            title: 'Arbitrator selected by',
            config: {
              title: 'Arbitrator selected by',
              filters: {
                caseConclusion: 'Awarded'
              },
              subType: 'countWithZeros',
              key: 'arbSelMethod',
              useStandardOptions: true,
              allowDownload: true,
              showCaseTotals: true,
              expectedValues: 'arbitrationSelectionMethods'
            }
          },
          arbSelMethodArbitrationSettledWithdrawn: {
            id: "arbSelMethodArbitrationSettledWithdrawn",
            type: 'detailedStatsTable',
            title: 'Arbitrator selected by',
            config: {
              title: 'Arbitrator selected by',
              filters: {
                caseConclusion: 'Settled/Withdrawn'
              },
              subType: 'countWithZeros',
              key: 'arbSelMethod',
              useStandardOptions: true,
              allowDownload: true,
              showCaseTotals: true,
              expectedValues: 'arbitrationSelectionMethods'
            }
          },
          arbSelMethodArbitrationDismissed: {
            id: "arbSelMethodArbitrationDismissed",
            type: 'detailedStatsTable',
            title: 'Arbitrator selected by',
            config: {
              title: 'Arbitrator selected by',
              filters: {
                caseConclusion: 'Dismissed'
              },
              subType: 'countWithZeros',
              key: 'arbSelMethod',
              useStandardOptions: true,
              allowDownload: true,
              showCaseTotals: true,
              expectedValues: 'arbitrationSelectionMethods'
            }
          },
          arbSelMethodArbitrationAdministrativeClose: {
            id: "arbSelMethodArbitrationAdministrativeClose",
            type: 'detailedStatsTable',
            title: 'Arbitrator selected by',
            config: {
              title: 'Arbitrator selected by',
              filters: {
                caseConclusion: 'Administrative Close'
              },
              subType: 'countWithZeros',
              key: 'arbSelMethod',
              useStandardOptions: true,
              allowDownload: true,
              showCaseTotals: true,
              expectedValues: 'arbitrationSelectionMethods'
            }
          },
          numMotionsFiled: {
            id: "numMotionsFiled",
            title: 'Number of motions filed',
            type: 'detailedStatsTable',
            config: {
              title: 'Number of motions filed',
              subType: 'count',
              key: 'numMotionsFiled',
              useStandardOptions: true,
              expectedValues: 'numMotionsFiled',
              allowDownload: true,
            }
          },
          numMotionsFiledArbitrationAwarded: {
            id: "numMotionsFiledArbitrationAwarded",
            title: 'Number of motions filed',
            type: 'detailedStatsTable',
            config: {
              title: 'Number of motions filed',
              filters: {
                caseConclusion: 'Awarded'
              },
              subType: 'count',
              key: 'numMotionsFiled',
              useStandardOptions: true,
              expectedValues: 'numMotionsFiled',
              allowDownload: true,
            }
          },
          numMotionsFiledArbitrationSettledWithdrawn: {
            id: "numMotionsFiledArbitrationSettledWithdrawn",
            title: 'Number of motions filed',
            type: 'detailedStatsTable',
            config: {
              title: 'Number of motions filed',
              filters: {
                caseConclusion: 'Settled/Withdrawn'
              },
              subType: 'count',
              key: 'numMotionsFiled',
              useStandardOptions: true,
              expectedValues: 'numMotionsFiled',
              allowDownload: true,
            }
          },
          numMotionsFiledArbitrationDismissed: {
            id: "numMotionsFiledArbitrationDismissed",
            title: 'Number of motions filed',
            type: 'detailedStatsTable',
            config: {
              title: 'Number of motions filed',
              filters: {
                caseConclusion: 'Dismissed'
              },
              subType: 'count',
              key: 'numMotionsFiled',
              useStandardOptions: true,
              expectedValues: 'numMotionsFiled',
              allowDownload: true,
            }
          },
          numMotionsFiledArbitrationAdministrativeClose: {
            id: "numMotionsFiledArbitrationAdministrativeClose",
            title: 'Number of motions filed',
            type: 'detailedStatsTable',
            config: {
              title: 'Number of motions filed',
              filters: {
                caseConclusion: 'Administrative Close'
              },
              subType: 'count',
              key: 'numMotionsFiled',
              useStandardOptions: true,
              expectedValues: 'numMotionsFiled',
              allowDownload: true,
            }
          },
          numWitnessStatements: {
            id: "numWitnessStatements",
            title: 'Number of witness statements',
            type: 'detailedStatsTable',
            config: {
              title: 'Number of witness statements',
              subType: 'count',
              key: 'numWitnessStatements',
              useStandardOptions: true,
              expectedValues: 'numWitnessStatements',
              allowDownload: true,
            }
          },
          numWitnessStatementsArbitrationAwarded: {
            id: "numWitnessStatementsArbitrationAwarded",
            title: 'Number of witness statements',
            type: 'detailedStatsTable',
            config: {
              title: 'Number of witness statements',
              filters: {
                caseConclusion: 'Awarded'
              },
              subType: 'count',
              key: 'numWitnessStatements',
              useStandardOptions: true,
              expectedValues: 'numWitnessStatements',
              allowDownload: true,
            }
          },
          numWitnessStatementsArbitrationSettledWithdrawn: {
            id: "numWitnessStatementsArbitrationSettledWithdrawn",
            title: 'Number of witness statements',
            type: 'detailedStatsTable',
            config: {
              title: 'Number of witness statements',
              filters: {
                caseConclusion: 'Settled/Withdrawn'
              },
              subType: 'count',
              key: 'numWitnessStatements',
              useStandardOptions: true,
              expectedValues: 'numWitnessStatements',
              allowDownload: true,
            }
          },
          numWitnessStatementsArbitrationDismissed: {
            id: "numWitnessStatementsArbitrationDismissed",
            title: 'Number of witness statements',
            type: 'detailedStatsTable',
            config: {
              title: 'Number of witness statements',
              filters: {
                caseConclusion: 'Dismissed'
              },
              subType: 'count',
              key: 'numWitnessStatements',
              useStandardOptions: true,
              expectedValues: 'numWitnessStatements',
              allowDownload: true,
            }
          },
          numWitnessStatementsArbitrationAdministrativeClose: {
            id: "numWitnessStatementsArbitrationAdministrativeClose",
            title: 'Number of witness statements',
            type: 'detailedStatsTable',
            config: {
              title: 'Number of witness statements',
              filters: {
                caseConclusion: 'Administrative Close'
              },
              subType: 'count',
              key: 'numWitnessStatements',
              useStandardOptions: true,
              expectedValues: 'numWitnessStatements',
              allowDownload: true,
            }
          },
          // hearingLocation: {key: 'hearingLocation', title: 'Location of Hearing', expectedValues: standardOptions.hearingLocations},
          prevailingParty: {
            id: "prevailingParty",
            type: 'detailedStatsTable',
            title: 'Prevailing party',
            config: {
              title: 'Prevailing party',
              subType: 'count',
              key: 'prevailingParty',
              useStandardOptions: true,
              expectedValues: 'prevailingParties',
              allowDownload: true,
            }
          },
          awardedTo: {
            id: "awardedTo",
            title: 'Amounts awarded to',
            type: 'detailedStatsTable',
            config: {
              title: 'Amounts awarded to',
              subType: 'count',
              key: 'awardedTo',
              useStandardOptions: true,
              expectedVaues: 'awardParties',
              allowDownload: true,
            }
          },
          numDepositions: {
            id: "numDepositions",
            type: 'detailedStatsTable',
            title: 'Number of depositions',
            config: {
              title: 'Number of depositions',
              subType: 'count',
              key: 'numDepositions',
              useStandardOptions: true,
              allowDownload: true,
              expectedValues: 'numDepositions'
            }
          },
          numDepositionsArbitrationAwarded: {
            id: "numDepositionsArbitrationAwarded",
            type: 'detailedStatsTable',
            title: 'Number of depositions',
            config: {
              title: 'Number of depositions',
              filters: {
                caseConclusion: 'Awarded'
              },
              subType: 'count',
              key: 'numDepositions',
              useStandardOptions: true,
              allowDownload: true,
              expectedValues: 'numDepositions'
            }
          },
          numDepositionsArbitrationSettledWithdrawn: {
            id: "numDepositionsArbitrationSettledWithdrawn",
            type: 'detailedStatsTable',
            title: 'Number of depositions',
            config: {
              title: 'Number of depositions',
              filters: {
                caseConclusion: 'Settled/Withdrawn'
              },
              subType: 'count',
              key: 'numDepositions',
              useStandardOptions: true,
              allowDownload: true,
              expectedValues: 'numDepositions'
            }
          },
          numDepositionsArbitrationDismissed: {
            id: "numDepositionsArbitrationDismissed",
            type: 'detailedStatsTable',
            title: 'Number of depositions',
            config: {
              title: 'Number of depositions',
              filters: {
                caseConclusion: 'Dismissed'
              },
              subType: 'count',
              key: 'numDepositions',
              useStandardOptions: true,
              allowDownload: true,
              expectedValues: 'numDepositions'
            }
          },
          numDepositionsArbitrationAdministrativeClose: {
            id: "numDepositionsArbitrationAdministrativeClose",
            type: 'detailedStatsTable',
            title: 'Number of depositions',
            config: {
              title: 'Number of depositions',
              filters: {
                caseConclusion: 'Administrative Close'
              },
              subType: 'count',
              key: 'numDepositions',
              useStandardOptions: true,
              allowDownload: true,
              expectedValues: 'numDepositions'
            }
          },
          numInterrogatories: {
            id: "numInterrogatories",
            type: 'detailedStatsTable',
            title: 'Number of interrogatories',
            config: {
              title: 'Number of interrogatories',
              subType: 'countWithZeros',
              key: 'numInterrogatories',
              useStandardOptions: true,
              allowDownload: true,
              expectedValues: 'numInterrogatories'
            }
          },
          numInterrogatoriesArbitrationAwarded: {
            id: "numInterrogatoriesArbitrationAwarded",
            type: 'detailedStatsTable',
            title: 'Number of interrogatories',
            config: {
              title: 'Number of interrogatories',
              filters: {
                caseConclusion: 'Awarded'
              },
              subType: 'countWithZeros',
              key: 'numInterrogatories',
              useStandardOptions: true,
              allowDownload: true,
              expectedValues: 'numInterrogatories'
            }
          },
          numInterrogatoriesArbitrationSettledWithdrawn: {
            id: "numInterrogatoriesArbitrationSettledWithdrawn",
            type: 'detailedStatsTable',
            title: 'Number of interrogatories',
            config: {
              title: 'Number of interrogatories',
              filters: {
                caseConclusion: 'Settled/Withdrawn'
              },
              subType: 'countWithZeros',
              key: 'numInterrogatories',
              useStandardOptions: true,
              allowDownload: true,
              expectedValues: 'numInterrogatories'
            }
          },
          numInterrogatoriesArbitrationDismissed: {
            id: "numInterrogatoriesArbitrationDismissed",
            type: 'detailedStatsTable',
            title: 'Number of interrogatories',
            config: {
              title: 'Number of interrogatories',
              filters: {
                caseConclusion: 'Dismissed'
              },
              subType: 'countWithZeros',
              key: 'numInterrogatories',
              useStandardOptions: true,
              allowDownload: true,
              expectedValues: 'numInterrogatories'
            }
          },
          numInterrogatoriesArbitrationAdministrativeClose: {
            id: "numInterrogatoriesArbitrationAdministrativeClose",
            type: 'detailedStatsTable',
            title: 'Number of interrogatories',
            config: {
              title: 'Number of interrogatories',
              filters: {
                caseConclusion: 'Administrative Close'
              },
              subType: 'countWithZeros',
              key: 'numInterrogatories',
              useStandardOptions: true,
              allowDownload: true,
              expectedValues: 'numInterrogatories'
            }
          },
          casesWith: {
            id: "casesWith",
              type: 'detailedStatsTable',
              config: {
                title: "Cases having",
                subType: 'boolean',
                allowDownload: true,
                facts: [
                  {
                    key: 'hearingOccurred',
                    title: 'Merits hearing'
                  },
                  {
                    key: 'emerArbUsed',
                    title: 'Emergency arbitrator application'
                  },
                  {
                    key: 'sumJudgementMotionMade',
                    title: 'Summary judgment application'
                  },
                  {
                    key: 'intervenerMotionMade',
                    title: 'Intervener motion'
                  },
                  {
                    key: 'intervenerMotionGranted',
                    title: 'Intervener motion granted'
                  },
                  {
                    key: 'ediscoveryUsed',
                    title: 'E-discovery'
                  },
                  {
                    key: 'nationalCourtsInvolved',
                    title: 'National court involvement'
                  },
                  {
                    key: 'amicusCuriaeBriefIncluded',
                    title: 'Amicus curiae briefs'
                  },
                  {
                    key: 'counterPrevailed',
                    title: 'Counterclaim prevailing'
                  },
                ]
              }
            },
            casesWithArbitrationAwarded: {
              id: "casesWithArbitrationAwarded",
                type: 'detailedStatsTable',
                config: {
                  title: "Cases having",
                  filters: {
                    caseConclusion: 'Awarded'
                  },
                  subType: 'boolean',
                  allowDownload: true,
                  facts: [
                    {
                      key: 'hearingOccurred',
                      title: 'Merits hearing'
                    },
                    {
                      key: 'emerArbUsed',
                      title: 'Emergency arbitrator application'
                    },
                    {
                      key: 'sumJudgementMotionMade',
                      title: 'Summary judgment application'
                    },
                    {
                      key: 'intervenerMotionMade',
                      title: 'Intervener motion'
                    },
                    {
                      key: 'intervenerMotionGranted',
                      title: 'Intervener motion granted'
                    },
                    {
                      key: 'ediscoveryUsed',
                      title: 'E-discovery'
                    },
                    {
                      key: 'nationalCourtsInvolved',
                      title: 'National court involvement'
                    },
                    {
                      key: 'amicusCuriaeBriefIncluded',
                      title: 'Amicus curiae briefs'
                    },
                    {
                      key: 'counterPrevailed',
                      title: 'Counterclaim prevailing'
                    },
                  ]
                }
              },
              casesWithArbitrationSettledWithdrawn: {
                id: "casesWithArbitrationSettledWithdrawn",
                  type: 'detailedStatsTable',
                  config: {
                    title: "Cases having",
                    filters: {
                      caseConclusion: 'Settled/Withdrawn'
                    },
                    subType: 'boolean',
                    allowDownload: true,
                    facts: [
                      {
                        key: 'hearingOccurred',
                        title: 'Merits hearing'
                      },
                      {
                        key: 'emerArbUsed',
                        title: 'Emergency arbitrator application'
                      },
                      {
                        key: 'sumJudgementMotionMade',
                        title: 'Summary judgment application'
                      },
                      {
                        key: 'intervenerMotionMade',
                        title: 'Intervener motion'
                      },
                      {
                        key: 'intervenerMotionGranted',
                        title: 'Intervener motion granted'
                      },
                      {
                        key: 'ediscoveryUsed',
                        title: 'E-discovery'
                      },
                      {
                        key: 'nationalCourtsInvolved',
                        title: 'National court involvement'
                      },
                      {
                        key: 'amicusCuriaeBriefIncluded',
                        title: 'Amicus curiae briefs'
                      },
                      {
                        key: 'counterPrevailed',
                        title: 'Counterclaim prevailing'
                      },
                    ]
                  }
                },
                casesWithArbitrationDismissed: {
                  id: "casesWithArbitrationDismissed",
                    type: 'detailedStatsTable',
                    config: {
                      title: "Cases having",
                      filters: {
                        caseConclusion: 'Dismissed'
                      },
                      subType: 'boolean',
                      allowDownload: true,
                      facts: [
                        {
                          key: 'counterFiled',
                          title: 'Counterclaim'
                        },
                        {
                          key: 'hearingOccurred',
                          title: 'Merits hearing'
                        },
                        {
                          key: 'emerArbUsed',
                          title: 'Emergency arbitrator application'
                        },
                        {
                          key: 'sumJudgementMotionMade',
                          title: 'Summary judgment application'
                        },
                        {
                          key: 'nationalCourtsInvolved',
                          title: 'National court involvement'
                        },
                      ]
                    }
                  },
                  casesWithArbitrationAdministrativeClose: {
                    id: "casesWithArbitrationAdministrativeClose",
                      type: 'detailedStatsTable',
                      config: {
                        title: "Cases having",
                        filters: {
                          caseConclusion: 'Administrative Close'
                        },
                        subType: 'boolean',
                        allowDownload: true,
                        facts: [
                          {
                            key: 'counterFiled',
                            title: 'Counterclaim'
                          },
                          {
                            key: 'hearingOccurred',
                            title: 'Merits hearing'
                          },
                          {
                            key: 'emerArbUsed',
                            title: 'Emergency arbitrator application'
                          },
                          {
                            key: 'sumJudgementMotionMade',
                            title: 'Summary judgment application'
                          },
                          {
                            key: 'intervenerMotionMade',
                            title: 'Intervener motion'
                          },
                          {
                            key: 'intervenerMotionGranted',
                            title: 'Intervener motion granted'
                          },
                          {
                            key: 'ediscoveryUsed',
                            title: 'E-discovery'
                          },
                          {
                            key: 'nationalCourtsInvolved',
                            title: 'National court involvement'
                          },
                          {
                            key: 'amicusCuriaeBriefIncluded',
                            title: 'Amicus curiae briefs'
                          }
                        ]
                      }
                    },
          dollarAmountsAwarded: {
            id: "dollarAmounts",
            title: "Average US dollar amounts",
            type: 'detailedStatsTable',
            config: {
              title: "Average US dollar amounts",
              format: 'analyticsCurrency',
              subType: "ungroupedAverage",
              allowDownload: true,
              facts: [
                {
                  key: 'normDemandAmt',
                  title: 'Claim',
                  filters: {
                    prevailingParty: ['Claimant', 'Prevailed in Part'],
                    normDemandAmt: 'isPresent',
                    normClaimantAwardAmt: 'isPresent'
                  },
                },
                {
                  key: 'normClaimantAwardAmt',
                  title: 'Claimant award',
                  filters: {
                    prevailingParty: ['Claimant', 'Prevailed in Part'],
                  }
                },
                {
                  key: 'normTotalInstFeeAmt',
                  title: "Arbitration institution's fees",
                  filters: {
                    prevailingParty: ['Claimant', 'Prevailed in Part'],
                  }
                },
                {
                  key: 'normTotalArbMedFeeAmt',
                  title: "Arbitrator's fees",
                  filters: {
                    prevailingParty: ['Claimant', 'Prevailed in Part'],
                  }
                },
                {
                  key: 'normAwardCostsAmt',
                  title: 'Legal costs recovered',
                  filters: {
                    prevailingParty: ['Claimant', 'Prevailed in Part'],
                  }
                }
              ]
            }
          },
          averageClaimantAwardToClaimRatioQuartilesArbitrationAwarded: {
            id: "averageClaimantAwardToClaimRatioQuartilesArbitrationAwarded",
            title: "Quartile ratios",
            type: 'detailedStatsTable',
            config: {
              title: "Quartile ratios",
              filters: {
                caseConclusion: 'Awarded'
              },
              subType: 'ungroupedAverage',
              format: 'analyticsPercentage100Cutoff',
              allowDownload: true,
              includeTooltip: true,
              titleTooltip: 'For more information on quartiles, please visit the Data Researchers FAQ page.',
              facts: [
                {
                  key: 'claimantAwardToClaimRatio',
                  title: 'Q1-averaged ratio of claimant award to claim',
                  filters: {
                    prevailingParty: ['Claimant', 'Prevailed in Part'],
                    normDemandAmt: 'isPresent',
                    normClaimantAwardAmt: 'isPresent',
                    claimantAwardToClaimQuartile: 'Q1'
                  },
                  tooltip: 'First quartile (Q1): the range of values below the 25th percentile'
                },
                {
                  key: 'claimantAwardToClaimRatio',
                  title: 'Q2-averaged ratio of claimant award to claim',
                  filters: {
                    prevailingParty: ['Claimant', 'Prevailed in Part'],
                    normDemandAmt: 'isPresent',
                    normClaimantAwardAmt: 'isPresent',
                    claimantAwardToClaimQuartile: 'Q2'
                  },
                  tooltip: 'Second quartile (Q2): the range of values at or above the 25th percentile and below the median (50th percentile)'
                },
                {
                  key: 'claimantAwardToClaimRatio',
                  title: 'Q3-averaged ratio of claimant award to claim',
                  filters: {
                    prevailingParty: ['Claimant', 'Prevailed in Part'],
                    normDemandAmt: 'isPresent',
                    normClaimantAwardAmt: 'isPresent',
                    claimantAwardToClaimQuartile: 'Q3'
                  },
                  tooltip: 'Third quartile (Q3): the range of values at or above the median (50th percentile) and below the 75th percentile'
                },
                {
                  key: 'claimantAwardToClaimRatio',
                  title: 'Q4-averaged ratio of claimant award to claim',
                  filters: {
                    prevailingParty: ['Claimant', 'Prevailed in Part'],
                    normDemandAmt: 'isPresent',
                    normClaimantAwardAmt: 'isPresent',
                    claimantAwardToClaimQuartile: 'Q4'
                  },
                  tooltip: 'Fourth quartile (Q4): the range of values at or above the 75th percentile'
                }
              ]
            }
          },
          averageCounterclaimantAwardToCounterclaimRatioQuartilesArbitrationAwarded: {
            id: "averageCounterclaimantAwardToCounterclaimRatioQuartilesArbitrationAwarded",
            title: "Quartile ratios w/ counterclaim",
            type: 'detailedStatsTable',
            config: {
              title: "Quartile ratios w/ counterclaim",
              filters: {
                caseConclusion: 'Awarded'
              },
              subType: 'ungroupedAverage',
              format: 'analyticsPercentage100Cutoff',
              allowDownload: true,
              includeTooltip: true,
              titleTooltip: 'For more information on quartiles, please visit the Data Researchers FAQ page.',
              facts: [
                {
                  key: 'counterclaimantAwardToCounterclaimRatio',
                  title: 'Q1-averaged ratio of counterclaimant award to counterclaim',
                  filters: {
                    prevailingParty: ['Respondent', 'Prevailed in Part'],
                    normCounterDemandAmt: 'isPresent',
                    normRespondentAwardAmt: 'isPresent',
                    counterclaimantAwardToCounterclaimQuartile: 'Q1'
                  },
                  tooltip: 'First quartile (Q1): the range of values below the 25th percentile'
                },
                {
                  key: 'counterclaimantAwardToCounterclaimRatio',
                  title: 'Q2-averaged ratio of counterclaimant award to counterclaim',
                  filters: {
                    prevailingParty: ['Respondent', 'Prevailed in Part'],
                    normCounterDemandAmt: 'isPresent',
                    normRespondentAwardAmt: 'isPresent',
                    counterclaimantAwardToCounterclaimQuartile: 'Q2'
                  },
                  tooltip: 'Second quartile (Q2): the range of values at or above the 25th percentile and below the median (50th percentile)'
                },
                {
                  key: 'counterclaimantAwardToCounterclaimRatio',
                  title: 'Q3-averaged ratio of counterclaimant award to counterclaim',
                  filters: {
                    prevailingParty: ['Respondent', 'Prevailed in Part'],
                    normCounterDemandAmt: 'isPresent',
                    normRespondentAwardAmt: 'isPresent',
                    counterclaimantAwardToCounterclaimQuartile: 'Q3'
                  },
                  tooltip: 'Third quartile (Q3): the range of values at or above the median (50th percentile) and below the 75th percentile'
                },
                {
                  key: 'counterclaimantAwardToCounterclaimRatio',
                  title: 'Q4-averaged ratio of counterclaimant award to counterclaim',
                  filters: {
                    prevailingParty: ['Respondent', 'Prevailed in Part'],
                    normCounterDemandAmt: 'isPresent',
                    normRespondentAwardAmt: 'isPresent',
                    counterclaimantAwardToCounterclaimQuartile: 'Q4'
                  },
                  tooltip: 'Fourth quartile (Q4): the range of values at or above the 75th percentile'
                }
              ]
            }
          },
          averageClaimAndClaimantAwardRatioQuartilesArbitrationAwarded: {
            id: "averageClaimAndClaimantAwardRatioQuartilesArbitrationAwarded",
            title: "Quartile amounts",
            type: 'detailedStatsTable',
            config: {
              title: "Quartile amounts",
              filters: {
                caseConclusion: 'Awarded'
              },
              format: 'analyticsCurrency',
              subType: 'ungroupedAverage',
              allowDownload: true,
              includeTooltip: true,
              titleTooltip: 'For more information on quartiles, please visit the Data Researchers FAQ page.',
              facts: [
                {
                  key: 'normDemandAmt',
                  title: 'Average claim in Q1 of ratios of claimant award to claim',
                  filters: {
                    prevailingParty: ['Claimant', 'Prevailed in Part'],
                    normDemandAmt: 'isPresent',
                    normClaimantAwardAmt: 'isPresent',
                    claimantAwardToClaimQuartile: 'Q1'
                  },
                  tooltip: 'First quartile (Q1): the range of values below the 25th percentile'
                },
                {
                  key: 'normClaimantAwardAmt',
                  title: 'Average claimant award in Q1 of ratios of claimant award to claim',
                  filters: {
                    prevailingParty: ['Claimant', 'Prevailed in Part'],
                    normDemandAmt: 'isPresent',
                    normClaimantAwardAmt: 'isPresent',
                    claimantAwardToClaimQuartile: 'Q1',
                  },
                  tooltip: 'First quartile (Q1): the range of values below the 25th percentile'
                },
                {
                  key: 'normDemandAmt',
                  title: 'Average claim in Q2 of ratios of claimant award to claim',
                  filters: {
                    prevailingParty: ['Claimant', 'Prevailed in Part'],
                    normDemandAmt: 'isPresent',
                    normClaimantAwardAmt: 'isPresent',
                    claimantAwardToClaimQuartile: 'Q2'
                  },
                  tooltip: 'Second quartile (Q2): the range of values at or above the 25th percentile and below the median (50th percentile)'
                },
                {
                  key: 'normClaimantAwardAmt',
                  title: 'Average claimant award in Q2 of ratios of claimant award to claim',
                  filters: {
                    prevailingParty: ['Claimant', 'Prevailed in Part'],
                    normDemandAmt: 'isPresent',
                    normClaimantAwardAmt: 'isPresent',
                    claimantAwardToClaimQuartile: 'Q2'
                  },
                  tooltip: 'Second quartile (Q2): the range of values at or above the 25th percentile and below the median (50th percentile)'
                },
                {
                  key: 'normDemandAmt',
                  title: 'Average claim in Q3 of ratios of claimant award to claim',
                  filters: {
                    prevailingParty: ['Claimant', 'Prevailed in Part'],
                    normDemandAmt: 'isPresent',
                    normClaimantAwardAmt: 'isPresent',
                    claimantAwardToClaimQuartile: 'Q3'
                  },
                  tooltip: 'Third quartile (Q3): the range of values at or above the median (50th percentile) and below the 75th percentile'
                },
                {
                  key: 'normClaimantAwardAmt',
                  title: 'Average claimant award in Q3 of ratios of claimant award to claim',
                  filters: {
                    prevailingParty: ['Claimant', 'Prevailed in Part'],
                    normDemandAmt: 'isPresent',
                    normClaimantAwardAmt: 'isPresent',
                    claimantAwardToClaimQuartile: 'Q3'
                  },
                  tooltip: 'Third quartile (Q3): the range of values at or above the median (50th percentile) and below the 75th percentile'
                },
                {
                  key: 'normDemandAmt',
                  title: 'Average claim in Q4 of ratios of claimant award to claim',
                  filters: {
                    prevailingParty: ['Claimant', 'Prevailed in Part'],
                    normDemandAmt: 'isPresent',
                    normClaimantAwardAmt: 'isPresent',
                    claimantAwardToClaimQuartile: 'Q4'
                  },
                  tooltip: 'Fourth quartile (Q4): the range of values at or above the 75th percentile'
                },
                {
                  key: 'normClaimantAwardAmt',
                  title: 'Average claimant award in Q4 of ratios of claimant award to claim',
                  filters: {
                    prevailingParty: ['Claimant', 'Prevailed in Part'],
                    normDemandAmt: 'isPresent',
                    normClaimantAwardAmt: 'isPresent',
                    claimantAwardToClaimQuartile: 'Q4'
                  },
                  tooltip: 'Fourth quartile (Q4): the range of values at or above the 75th percentile'
                },
              ]
            }
          },
          averageCounterclaimAndCounterclaimantAwardRatioQuartilesArbitrationAwarded: {
            id: "averageCounterclaimAndCounterclaimantAwardRatioQuartilesArbitrationAwarded",
            title: "Quartile amounts w/ counterclaim",
            type: 'detailedStatsTable',
            config: {
              title: "Quartile amounts w/ counterclaim",
              filters: {
                caseConclusion: 'Awarded'
              },
              format: 'analyticsCurrency',
              subType: 'ungroupedAverage',
              allowDownload: true,
              includeTooltip: true,
              titleTooltip: 'For more information on quartiles, please visit the Data Researchers FAQ page.',
              facts: [
                {
                  key: 'normCounterDemandAmt',
                  title: 'Average counterclaim in Q1 of ratios of counterclaimant award to counterclaim',
                  filters: {
                    prevailingParty: ['Respondent', 'Prevailed in Part'],
                    normCounterDemandAmt: 'isPresent',
                    normRespondentAwardAmt: 'isPresent',
                    counterclaimantAwardToCounterclaimQuartile: 'Q1'
                  },
                  tooltip: 'First quartile (Q1): the range of values below the 25th percentile'
                },
                {
                  key: 'normRespondentAwardAmt',
                  title: 'Average counterclaimant award in Q1 of ratios of counterclaimant award to counterclaim',
                  filters: {
                    prevailingParty: ['Respondent', 'Prevailed in Part'],
                    normCounterDemandAmt: 'isPresent',
                    normRespondentAwardAmt: 'isPresent',
                    counterclaimantAwardToCounterclaimQuartile: 'Q1'
                  },
                  tooltip: 'First quartile (Q1): the range of values below the 25th percentile'
                },
                {
                  key: 'normCounterDemandAmt',
                  title: 'Average counterclaim in Q2 of ratios of counterclaimant award to counterclaim',
                  filters: {
                    prevailingParty: ['Respondent', 'Prevailed in Part'],
                    normCounterDemandAmt: 'isPresent',
                    normRespondentAwardAmt: 'isPresent',
                    counterclaimantAwardToCounterclaimQuartile: 'Q2'
                  },
                  tooltip: 'Second quartile (Q2): the range of values at or above the 25th percentile and below the median (50th percentile)'
                },
                {
                  key: 'normRespondentAwardAmt',
                  title: 'Average counterclaimant award in Q2 of ratios of counterclaimant award to counterclaim',
                  filters: {
                    prevailingParty: ['Respondent', 'Prevailed in Part'],
                    normCounterDemandAmt: 'isPresent',
                    normRespondentAwardAmt: 'isPresent',
                    counterclaimantAwardToCounterclaimQuartile: 'Q2'
                  },
                  tooltip: 'Second quartile (Q2): the range of values at or above the 25th percentile and below the median (50th percentile)'
                },
                {
                  key: 'normCounterDemandAmt',
                  title: 'Average counterclaim in Q3 of ratios of counterclaimant award to counterclaim',
                  filters: {
                    prevailingParty: ['Respondent', 'Prevailed in Part'],
                    normCounterDemandAmt: 'isPresent',
                    normRespondentAwardAmt: 'isPresent',
                    counterclaimantAwardToCounterclaimQuartile: 'Q3'
                  },
                  tooltip: 'Third quartile (Q3): the range of values at or above the median (50th percentile) and below the 75th percentile'
                },
                {
                  key: 'normRespondentAwardAmt',
                  title: 'Average counterclaimant award in Q3 of ratios of counterclaimant award to counterclaim',
                  filters: {
                    prevailingParty: ['Respondent', 'Prevailed in Part'],
                    normCounterDemandAmt: 'isPresent',
                    normRespondentAwardAmt: 'isPresent',
                    counterclaimantAwardToCounterclaimQuartile: 'Q3'
                  },
                  tooltip: 'Third quartile (Q3): the range of values at or above the median (50th percentile) and below the 75th percentile'
                },
                {
                  key: 'normCounterDemandAmt',
                  title: 'Average counterclaim in Q4 of ratios of counterclaimant award to counterclaim',
                  filters: {
                    prevailingParty: ['Respondent', 'Prevailed in Part'],
                    normCounterDemandAmt: 'isPresent',
                    normRespondentAwardAmt: 'isPresent',
                    counterclaimantAwardToCounterclaimQuartile: 'Q4'
                  },
                  tooltip: 'Fourth quartile (Q4): the range of values at or above the 75th percentile'
                },
                {
                  key: 'normRespondentAwardAmt',
                  title: 'Average counterclaimant award in Q4 of ratios of counterclaimant award to counterclaim',
                  filters: {
                    prevailingParty: ['Respondent', 'Prevailed in Part'],
                    normCounterDemandAmt: 'isPresent',
                    normRespondentAwardAmt: 'isPresent',
                    counterclaimantAwardToCounterclaimQuartile: 'Q4'
                  },
                  tooltip: 'Fourth quartile (Q4): the range of values at or above the 75th percentile'
                }
              ]
            }
          },
          averageCostsAndFeesAwardedArbitrationAwarded: {
            id: "averageCostsAndFeesAwardedArbitrationAwarded",
            title: "Average costs and fees in US dollar amounts",
            type: 'detailedStatsTable',
            config: {
              title: "Average costs and fees in US dollar amounts",
              filters: {
                caseConclusion: 'Awarded'
              },
              format: 'analyticsCurrency',
              subType: "ungroupedAverage",
              allowDownload: true,
              facts: [
                {
                  key: 'normTotalInstFeeAmt',
                  title: "Arbitration institution's fees",
                  filters: {
                    prevailingParty: ['Claimant', 'Prevailed in Part'],
                  }
                },
                {
                  key: 'normTotalArbMedFeeAmt',
                  title: "Arbitrator's fees",
                  filters: {
                    prevailingParty: ['Claimant', 'Prevailed in Part'],
                  }
                },
                {
                  key: 'normAwardCostsAmt',
                  title: 'Legal costs recovered',
                  filters: {
                    prevailingParty: ['Claimant', 'Prevailed in Part'],
                  }
                }
              ]
            }
          },
          dollarAmountsAwardedRespondent: {
            id: "dollarAmounts",
            title: "Average US dollar amounts",
            type: 'detailedStatsTable',
            config: {
              title: "Average US dollar amounts with counterclaim",
              format: 'analyticsCurrency',
              subType: "ungroupedAverage",
              allowDownload: true,
              facts: [
                {
                  key: 'normCounterDemandAmt',
                  title: 'Counterclaim',
                  filters: {
                    prevailingParty: ['Respondent', 'Prevailed in Part'],
                    normCounterDemandAmt: 'isPresent',
                    normRespondentAwardAmt: 'isPresent'
                  }
                },
                {
                  key: 'normRespondentAwardAmt',
                  title: 'Counterclaimant award',
                  filters: {
                    prevailingParty: ['Respondent', 'Prevailed in Part'],
                    normCounterDemandAmt: 'isPresent',
                    normRespondentAwardAmt: 'isPresent'
                  }
                },
                {
                  key: 'normTotalInstFeeAmt',
                  title: "Arbitration institution's fees",
                  filters: {
                    prevailingParty: ['Respondent', 'Prevailed in Part'],
                  }
                },
                {
                  key: 'normTotalArbMedFeeAmt',
                  title: "Arbitrator's fees",
                  filters: {
                    prevailingParty: ['Respondent', 'Prevailed in Part'],
                  }
                },
                {
                  key: 'normAwardCostsAmt',
                  title: 'Legal costs recovered',
                  filters: {
                    prevailingParty: ['Respondent', 'Prevailed in Part'],
                  }
                }
              ]
            }
          },
          averageCostsAndFeesAwardedRespondentArbitrationAwarded: {
            id: "dollarAmountsAwardedRespondentArbitrationAwarded",
            title: "Average costs and fees in US dollar amounts with counterclaim",
            type: 'detailedStatsTable',
            config: {
              title: "Average costs and fees in US dollar amounts with counterclaim",
              filters: {
                caseConclusion: 'Awarded'
              },
              format: 'analyticsCurrency',
              subType: "ungroupedAverage",
              allowDownload: true,
              facts: [
                {
                  key: 'normTotalInstFeeAmt',
                  title: "Arbitration institution's fees",
                  filters: {
                    prevailingParty: ['Respondent', 'Prevailed in Part'],
                  }
                },
                {
                  key: 'normTotalArbMedFeeAmt',
                  title: "Arbitrator's fees",
                  filters: {
                    prevailingParty: ['Respondent', 'Prevailed in Part'],
                  }
                },
                {
                  key: 'normAwardCostsAmt',
                  title: 'Legal costs recovered',
                  filters: {
                    prevailingParty: ['Respondent', 'Prevailed in Part'],
                  }
                }
              ]
            }
          },
          dollarAmountsSettledWithdrawn: {
            id: "dollarAmounts",
            title: "Average US dollar amounts",
            type: 'detailedStatsTable',
            config: {
              title: "Average US dollar amounts",
              filters: {
                caseConclusion: 'Settled/Withdrawn'
              },
              format: 'analyticsCurrency',
              subType: "ungroupedAverage",
              allowDownload: true,
              facts: [
                {
                  key: 'normDemandAmt',
                  title: 'Claim',
                },
                {
                  key: 'normCounterDemandAmt',
                  title: 'Counterclaim',
                },
                {
                  key: 'normTotalInstFeeAmt',
                  title: "Arbitration institution's fees"
                },
                {
                  key: 'normTotalArbMedFeeAmt',
                  title: "Arbitrator's fees"
                }
              ]
            }
          },
          dollarAmountsDismissed: {
            id: "dollarAmounts",
            title: "Average US dollar amounts",
            type: 'detailedStatsTable',
            config: {
              title: "Average US dollar amounts",
              filters: {
                caseConclusion: 'Dismissed'
              },
              format: 'analyticsCurrency',
              subType: "ungroupedAverage",
              allowDownload: true,
              facts: [
                {
                  key: 'normDemandAmt',
                  title: 'Claim',
                },
                {
                  key: 'normCounterDemandAmt',
                  title: 'Counterclaim',
                },
                {
                  key: 'normTotalInstFeeAmt',
                  title: "Arbitration institution's fees"
                },
                {
                  key: 'normTotalArbMedFeeAmt',
                  title: "Arbitrator's fees"
                },
                {
                  key: 'normAwardCostsAmt',
                  title: 'Legal costs recovered'
                }
              ]
            }
          },
          dollarAmountsAdminClose: {
            id: "dollarAmounts",
            title: "Average US dollar amounts",
            type: 'detailedStatsTable',
            config: {
              title: "Average US dollar amounts",
              filters: {
                caseConclusion: 'Administrative Close'
              },
              format: 'analyticsCurrency',
              subType: "ungroupedAverage",
              allowDownload: true,
              facts: [
                {
                  key: 'normDemandAmt',
                  title: 'Claim',
                },
                {
                  key: 'normCounterDemandAmt',
                  title: 'Counterclaim',
                },
                {
                  key: 'normTotalInstFeeAmt',
                  title: "Arbitration institution's fees"
                },
                {
                  key: 'normTotalArbMedFeeAmt',
                  title: "Arbitrator's fees"
                }
              ]
            }
          },
          dollarAmountsArb: {
            id: "dollarAmounts",
            title: "Average US dollar amounts",
            type: 'detailedStatsTable',
              config: {
                title: "Average US dollar amounts",
                format: 'analyticsCurrency',
                subType: "ungroupedAverage",
                allowDownload: true,
                facts: [
                  {
                    key: 'normDemandAmt',
                    title: 'Claim',
                  },
                  {
                    key: 'normClaimantAwardAmt',
                    title: 'Claimant award',
                  },
                  {
                    key: 'normCounterDemandAmt',
                    title: 'Counterclaim',
                  },
                  {
                    key: 'normTotalInstFeeAmt',
                    title: "Arbitration institution's fees"
                  },
                  {
                    key: 'normTotalArbMedFeeAmt',
                    title: "Arbitrator's fees"
                  },
                  {
                    key: 'normAwardCostsAmt',
                    title: 'Legal costs recovered'
                  }
                ]
              }
          },
          dollarAmountsMed: {
            id: "dollarAmounts",
            title: "Average US dollar amounts",
            type: 'detailedStatsTable',
            config: {
              title: "Average US dollar amounts",
              format: 'analyticsCurrency',
              subType: "ungroupedAverage",
              allowDownload: true,
              facts: [
                {
                  key: 'normInstFeeAmt',
                  title: 'Mediation institutional fees'
                },
                {
                  key: 'normMediatorFeeAmt',
                  title: 'Mediator fees'
                },
              ]
            }
          },
          dollarAmountsMedSettledWithdrawn: {
            id: "dollarAmountsMedSettledWithdrawn",
            title: "Average US dollar amounts",
            type: 'detailedStatsTable',
            config: {
              title: "Average US dollar amounts",
              filters: {
                medCaseConclusion: 'Settled/Withdrawn'
              },
              format: 'analyticsCurrency',
              subType: "ungroupedAverage",
              allowDownload: true,
              facts: [
                {
                  key: 'normInstFeeAmt',
                  title: 'Mediation institutional fees'
                },
                {
                  key: 'normMediatorFeeAmt',
                  title: 'Mediator fees'
                },
              ]
            }
          },
          dollarAmountsMedAdminClose: {
            id: "dollarAmountsMedAdminClose",
            title: "Average US dollar amounts",
            type: 'detailedStatsTable',
            config: {
              title: "Average US dollar amounts",
              filters: {
                medCaseConclusion: 'Administrative Close'
              },
              format: 'analyticsCurrency',
              subType: "ungroupedAverage",
              allowDownload: true,
              facts: [
                {
                  key: 'normInstFeeAmt',
                  title: 'Mediation institutional fees'
                },
                {
                  key: 'normMediatorFeeAmt',
                  title: 'Mediator fees'
                },
              ]
            }
          },
          dollarAmountsMedImpasse: {
            id: "dollarAmountsMedImpasse",
            title: "Average US dollar amounts",
            type: 'detailedStatsTable',
            config: {
              title: "Average US dollar amounts",
              filters: {
                medCaseConclusion: 'Impasse'
              },
              format: 'analyticsCurrency',
              subType: "ungroupedAverage",
              allowDownload: true,
              facts: [
                {
                  key: 'normInstFeeAmt',
                  title: 'Mediation institutional fees'
                },
                {
                  key: 'normMediatorFeeAmt',
                  title: 'Mediator fees'
                },
              ]
            }
          },
          keyMedDurations: {
            id: "keyMedDurations",
            title: 'Average mediation durations',
            type: 'detailedStatsTable',
              config: {
                title: 'Average mediation durations',
                subType: 'basicDuration',
                allowDownload: true,
                facts: [
                  {
                    key: 'medTotalDays',
                    title: 'Duration of Mediation'
                  },
                  {
                    key: 'realMedConfDays',
                    title: 'Duration of Mediation Conference'
                  }

                ]
              }
          },
          keyMedDurationsSettledWithdrawn: {
            id: "keyMedDurationsSettledWithdrawn",
            title: 'Average mediation durations',
            type: 'detailedStatsTable',
              config: {
                title: 'Average mediation durations',
                filters: {
                  medCaseConclusion: 'Settled/Withdrawn'
                },
                subType: 'basicDuration',
                allowDownload: true,
                facts: [
                  {
                    key: 'medTotalDays',
                    title: 'Duration of Mediation'
                  },
                  {
                    key: 'realMedConfDays',
                    title: 'Duration of Mediation Conference'
                  }

                ]
              }
          },
          keyMedDurationsAdminClose: {
            id: "keyMedDurationsAdminClose",
            title: 'Average mediation durations',
            type: 'detailedStatsTable',
              config: {
                title: 'Average mediation durations',
                filters: {
                  medCaseConclusion: 'Administrative Close'
                },
                subType: 'basicDuration',
                allowDownload: true,
                facts: [
                  {
                    key: 'medTotalDays',
                    title: 'Duration of Mediation'
                  },
                  {
                    key: 'realMedConfDays',
                    title: 'Duration of Mediation Conference'
                  }

                ]
              }
          },
          keyMedDurationsImpasse: {
            id: "keyMedDurationsImpasse",
            title: 'Average mediation durations',
            type: 'detailedStatsTable',
              config: {
                title: 'Average mediation durations',
                filters: {
                  medCaseConclusion: 'Impasse'
                },
                subType: 'basicDuration',
                allowDownload: true,
                facts: [
                  {
                    key: 'medTotalDays',
                    title: 'Duration of Mediation'
                  },
                  {
                    key: 'realMedConfDays',
                    title: 'Duration of Mediation Conference'
                  }

                ]
              }
          },
          keyArbDurations: {
            id: "keyArbDurations",
            title: 'Average durations',
            type: 'detailedStatsTable',
            config: {
              title: 'Average durations',
              subType: 'basicDuration',
              allowDownload: true,
              facts: [
                {
                  key: 'arbTotalDays',
                  title: 'Commencement to final award',
                },
                {
                  key: 'arbSettledWithdrawnDays',
                  title: 'Commencement to settlement or withdraw',
                },
                {
                  key: 'realArbHearingDays',
                  title: 'Merits hearing',
                },
                // {
                //   key: 'arbWithdrawnDays',
                //   title: 'Duration to Withdrawn'
                // },
                {
                  key: 'arbAdminCloseDays',
                  title: 'Commencement to administrative close',
                },
                {
                  key: 'arbDismissedDays',
                  title: 'Commencement to dismissal',
                },
                {
                  key: 'arbHearingToAwardDays',
                  title: 'End of merits hearing to final award',
                }
              ]
            }
          },
          keyArbDurationsAwarded: {
              id: "keyArbDurationsAwarded",
              title: 'Average durations',
              type: 'detailedStatsTable',
              config: {
                title: 'Average durations',
                implicitProcessType: 'ARBITRATION',
                subType: 'basicDuration',
                allowDownload: true,
                facts: [
                  {
                    key: 'arbTotalDays',
                    title: 'Commencement to final award',
                  },
                  {
                    key: 'realArbHearingDays',
                    title: 'Merits hearing',
                  },
                  {
                    key: 'arbHearingToAwardDays',
                    title: 'End of merits hearing to final award',
                  }
                ]
              }
          },
          keyArbDurationsAwardedArbitrationAwarded: {
            id: "keyArbDurationsArbitrationAwarded",
            title: 'Average durations',
            type: 'detailedStatsTable',
            config: {
              title: 'Average durations',
              filters: {
                caseConclusion: 'Awarded'
              },
              implicitProcessType: 'ARBITRATION',
              subType: 'basicDuration',
              allowDownload: true,
              facts: [
                {
                  key: 'arbTotalDays',
                  title: 'Commencement to final award',
                },
                {
                  key: 'realArbHearingDays',
                  title: 'Merits hearing',
                },
                {
                  key: 'arbHearingToAwardDays',
                  title: 'End of merits hearing to final award',
                }
              ]
            }
        },
          keyArbDurationsSettledWithdrawn: {
              id: "keyArbDurations",
              title: 'Average durations',
              type: 'detailedStatsTable',
              config: {
                title: 'Average durations',
                filters: {
                  caseConclusion: 'Settled/Withdrawn'
                },
                implicitProcessType: 'ARBITRATION',
                subType: 'basicDuration',
                allowDownload: true,
                facts: [
                  {
                    key: 'arbSettledWithdrawnDays',
                    title: 'Commencement to settlement or withdraw',
                  },
                  {
                    key: 'realArbHearingDays',
                    title: 'Merits hearing',
                  },
                ]
              }
          },
          keyArbDurationsAdminClosed: {
            id: "keyArbDurations",
            title: 'Average durations',
            filters: {
              caseConclusion: 'Administrative Close'
            },
            type: 'detailedStatsTable',
            config: {
              title: 'Average durations',
              implicitProcessType: 'ARBITRATION',
              subType: 'basicDuration',
              allowDownload: true,
              facts: [

                {
                  key: 'arbAdminCloseDays',
                  title: 'Commencement to administrative close',
                },
                {
                  key: 'realArbHearingDays',
                  title: 'Merits hearing',
                },
              ]
            }
          },
          keyArbDurationsDismissed: {
            id: "keyArbDurations",
            title: 'Average durations',
            type: 'detailedStatsTable',
            config: {
              title: 'Average durations',
              filters: {
                caseConclusion: 'Dismissed'
              },
              implicitProcessType: 'ARBITRATION',
              subType: 'basicDuration',
              allowDownload: true,
              facts: [
                {
                  key: 'arbDismissedDays',
                  title: 'Commencement to dismissal',
                },
                {
                  key: 'realArbHearingDays',
                  title: 'Merits hearing',
                },
              ]
            }
          }
        }
      });
});
