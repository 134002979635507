/**
 * Configure routes of user module.
 */
define('user/routes',['angular', 'common'], function(angular) {
  'use strict';

  var mod = angular.module('user.routes', ['user.services', 'common']);
  mod.config(['$routeProvider', function($routeProvider) {
    $routeProvider
      .when('/login', {
        templateUrl:'/assets/javascripts/user/partials/login.html',
        authorizedRoles: ['ANONYMOUS']
      })
      .when('/signup', {
        templateUrl:'/assets/javascripts/user/partials/signup.html',
        authorizedRoles: ['ANONYMOUS']
      })
      .when('/signup/payment', {
        templateUrl:'/assets/javascripts/user/partials/payment-info.html',
        authorizedRoles: ['ANONYMOUS']
      })
      .when('/signup/summary', {
        templateUrl:'/assets/javascripts/user/partials/summary.html',
        authorizedRoles: ['ANONYMOUS']
      })
      .when('/users/setpassword', {
        templateUrl:'/assets/javascripts/user/partials/setpassword.html',
        authorizedRoles: ['ANONYMOUS']
      })
      .when('/users/subscriptions', {
        templateUrl:'/assets/javascripts/user/partials/subscriptions.html',
        authorizedRoles: ['', 'ANONYMOUS', 'ADMIN', 'CONTRIBUTOR_ADMIN', 'CONTRIBUTOR', 'SUBSCRIBER_ADMIN', 'SUBSCRIBER']
      })
      .when('/users/changepassword', {
        templateUrl:'/assets/javascripts/user/partials/changepassword.html',
        authorizedRoles: ['', 'ANONYMOUS', 'ADMIN', 'CONTRIBUTOR_ADMIN', 'CONTRIBUTOR', 'SUBSCRIBER']
      })
      .when('/forgot', {
        templateUrl:'/assets/javascripts/user/partials/forgotpassword.html',
        authorizedRoles: ['ANONYMOUS']
      })
      .when('/users/view', {
        templateUrl: '/assets/javascripts/user/partials/view.html',
        authorizedRoles: ['ADMIN']
      })
      .when('/users', {
        templateUrl: '/assets/javascripts/user/partials/user.html',
        authorizedRoles: ['ADMIN']
      });
  }]);
  return mod;
});

