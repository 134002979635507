define('analytics/widgets/donut/donut',['angular'], function (angular) {
  'use strict';

  return angular.module('widgets-donut', [])

      .directive('widgetsDonut', ['$log', '$window', '$q', '$timeout', 'securityService', 'analyticsService', 'fonticonService',
                 'widgetHelpers', 'widgetColorSchemes', 'widgetColors', 'standardOptions', '_',
        function ($log, $window, $q, $timeout, securityService, analyticsService, fonticonService, widgetHelpers,
                  widgetColorSchemes, widgetColors, standardOptions, _) {

          var linkFn = function (scope, elem, attrs) {
            if (attrs) {
            } //make JSLint happy



            /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
             Initialization
             ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

            var d3 = $window.d3,
                config = scope.widget.config,
                isPublic = scope.widget.isPublic;

            scope.state = 'LOADING';
            scope.ssState = 'LOADING';
            scope.d3DonutId = config.group + '-d3-donut' + (!_.isEmpty(scope.id) ? '-'+scope.id : '');
            scope.donutInfoId = config.group + '-donut-info' + (!_.isEmpty(scope.id) ? '-'+scope.id : '');

              if (!config.inset) {
                  // Parent widgets will broadcast an event to refresh data for inset widgets after the parent has rendered.
                  // So only call refreshData here for widgets that are not inset.
                  _refreshData();
              }

            scope.signalStrength = 0;


            /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
             Watches and scope functions
             ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

              if (!config.inset) {
                  // Parent widgets will listen for refreshData event.
                  scope.$on('refreshData', _refreshData);
              } else {
                  // Inset (child) widgets will listen for the refreshChildren event that the parent widgets will
                  // broadcast after they have rendered.
                  scope.$on('refreshChildren', _refreshData);
              }

            scope.$watch('state', function(){
              if (scope.state === 'NO_DATA'){
                scope.$emit('noData');
              }
            });

            //when the window is resized redraw the chart so that it remains proportional to the window size
            $window.addEventListener('resize', function () {
              _buildChart();
            });

            scope.chartColClass = function(){
              var leftCol = (config.leftCol ? config.leftCol : '7');
              return (config.chartColClass ? config.chartColClass : 'col-xs-' + leftCol);
            };

            scope.legendColClass = function(){
              var rightCol = (config.rightCol ? config.rightCol : '5');
              return (config.legendColClass ? config.legendColClass : 'col-xs-' + rightCol);
            };

            scope.useEmptyLegendBullet = function(item){
              return !scope.legendBulletColor(item);
            };

            scope.legendBulletColor = function(item){
              if (config.expectedValues || item.value){
                return item.color;
              }
            };
            scope.getItemStyle = function(item){
              var style = {};
              if(item.bold){
                _.assign(style, {"font-weight" : 'bold'});
              }
              return style;
            };


            /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
             Private Functions
             ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

            function _refreshData() {
              scope.state = 'LOADING';
              scope.ssState = 'LOADING';

              //scope.chartData = undefined;
              var filters = widgetHelpers.standardFiltersOnly(scope.filters, scope.filterMask),
                  promises = {primary: analyticsService.loadMetric([config.group], filters, undefined, scope.internal, scope.suppression, isPublic)};

              if (config.secondaryFact) {
                promises.secondary = analyticsService.loadMetric([config.group], filters, config.secondaryFact.fact, scope.internal, scope.suppression, isPublic);
              }

              if (config.tooltip && config.tooltip.data){
                //assign mutates source object so no reassignment needed
                _.assign(promises, _.mapValues(config.tooltip.data, function(d){
                  return analyticsService.loadMetric([d.group], _.merge(filters, d.filters), d.fact, scope.internal, scope.suppression, isPublic);
                }));

              }

              //groupeddata is a specific use case to support the caseType donut. if we need this to be more general
              //we should rework this (including the api)
              if(config.tooltip && config.tooltip.groupedData){
                _.forEach(_.clone(standardOptions[config.tooltip.groupedData.forEach]).concat('Other'), function(t){
                  promises['process_' + t] = analyticsService.loadMetric([config.tooltip.groupedData.group], {caseType: t, status: filters.status, caseRegion: filters.caseRegion, organizationId: filters.organizationId}, undefined, scope.internal, scope.suppression, isPublic);
                });
              }

              $q.all(promises)
                  .then(function (results) {
                    _transformResults(results);
                    scope.totalCases = analyticsService.getMetricTotal(config, results.primary);
                    //default the hilighted slice so that the tooltip will be initialized with a height (will still be hidden)
                    scope.hilighted = scope.chartData.primary[0];
                  })
                  .then(function () {
                    _buildChart();
                  })
                  .then(function(){
                    if (scope.chartData && _(scope.chartData.primary).find('value')) {
                      scope.state = 'DONE';
                    } else {
                      scope.state = 'NO_DATA';
                    }
                  })
                  .then(function() {
                      // If the widget has inset (child) widgets, then broadcast refreshChildren message to refresh them.
                      if (_.size(scope.widget.inset) > 0) {
                          scope.$broadcast('refreshChildren');
                      }
                  })
                  .then(function(){
                    if(config.showSignalStrength){
                      var ssData = _.map(_.filter(scope.chartData.primary, function(item){
                        return item.rawData.total !== 0;
                      }), function(item){
                        return item.rawData;
                      });
                      if (ssData.length !== 0) {
                        return analyticsService.getSignalStrength(ssData); 
                      }
                    }
                  })
                  .then(function(signalStrength){
                      scope.signalStrength = signalStrength;
                      scope.ssState = 'DONE';
                  });
            }

            function _transformResults(results) {


              var primary = results.primary,
                  placeholders = _buildPlaceholderItems();

              if (!_.isEmpty(placeholders)) {
                //must re-sort the data since we're mapping into an unsorted array of placeholder values
                primary = _.map(placeholders, _findMatchInPrimaryData);
              }

              if(config.limitToTop){
                  //if we get "Other" as a top x percentage, lets combine them with the "Other" category
                  var wrappedSortedData = _(primary).sortBy('percentage').reverse(),
                    top = wrappedSortedData.take(config.limitToTop).value(),
                    other = wrappedSortedData.takeRight(primary.length - top.length)
                    .reduceRight(function (acc, d) {
                      acc.percentage += d.percentage;
                      acc.total += d.total;
                      return acc;
                    }, {groups: ['Other'], percentage: 0, avg: 0, total: 0});

                  //does top contain other
                  var primaryOther = _.find(top, function (item) {
                    return item.groups[0] === "Other";
                  });
                  //if it does add it to the other category and get it out of primaries
                  if(primaryOther){
                     top = _.reject(top, primaryOther);
                     other.percentage += primaryOther.percentage;
                  }
                primary = other.percentage > 0 ? top.concat(other) : top;
              }
              var percentages = _(primary)
                  .map('percentage')
                  .map(function(item){
                    return item * 100;
                  }).value();
              var rounded = analyticsService.roundPercentageMetric(percentages, 100);
              primary = _.forEach(primary, function(item, i){
                item.percentage = rounded[i]/100;
              });
              scope.chartData = {
                primary: _(primary)
                    .sortBy(widgetHelpers.alphaSortGroup)
                    .sortBy(_sortFn())
                    .sortBy(function(item){
                      return _.first(item.groups) === 'Other' ? 1 : -1;
                    })
                    .map(function (item, idx) {
                      var label = _buildLabel(item, idx === primary.length - 1),
                          dataElement = {
                        key: _buildKey(item),
                        label: label,
                        value: item.percentage,
                        color: widgetColorSchemes[config.colorScheme][idx],
                        icon:  fonticonService.getIcon(label),
                        rawData: item,
                      };

                      if (config.secondaryFact) {
                        //see if we can find a matching secondaryValue
                        var match = _.find(results.secondary, function (s) {
                          return _.first(item.groups) === _.first(s.groups);
                        });

                        if (match) {
                          dataElement.secondaryValue = match[config.secondaryFact.field];
                        }
                      }
                      if (config.topTen){
                        dataElement.bold = _isTopTen(item, primary);
                      }

                      return dataElement;
                    })
                    .value(),
                raw: results
              };


              scope.legendColumns = config.maxLegendColumnItems ? _.chunk(scope.chartData.primary, config.maxLegendColumnItems) : [scope.chartData.primary];

              function _buildKey(item){
                return config.group + _.kebabCase(item.groups[0]);
              }

              function _isTopTen(item, primary){
                if(item){
                  var wrappedSortedData = _(primary).sortBy('percentage').reverse().take(10).value();
                  return _.some(wrappedSortedData, item);
                }
              }

              function _buildLabel(item, includeLastSuffix) {
                var label = item.groups[0];
                if(includeLastSuffix && config.lastLabelSuffix) {
                  label = label + config.lastLabelSuffix;
                }
                return config.labelSuffix ? label + " " + config.labelSuffix : label;
              }

              function _sortFn() {
                if (config.sort === 'alpha') {
                  return widgetHelpers.alphaSortGroup;
                } else if(config.sort === 'expectedValue') {
                  return function(item) {
                    return _.indexOf(config.expectedValues, item.groups[0]);
                  };
                } else {
                  return function (item) {
                    return -1 * item.percentage;
                  };
                }
              }

              function _buildPlaceholderItems() {
                var ev = ((securityService.currentUserIsSubscriber() || scope.suppression) && config.expectedValuesBinned) ? config.expectedValuesBinned : config.expectedValues;
                var exp = _.isArray(ev) ? ev : standardOptions[ev];
                if(config.includeOther){
                  exp = _.clone(exp).concat('Other');
                }
                return _.map(exp, function (v) {
                  return {groups: [v], percentage: 0, total: 0, avg: null};
                });
              }

              function _findMatchInPrimaryData(p) {
                return _.find(primary, function (item) {
                      return p.groups[0] === item.groups[0];
                    }) || p;
              }
            }

            function _removeOldChart() {
              d3.select('#' + scope.d3DonutId + ' svg').remove();
            }

            function _buildChart() {
              //first remove the old chart, we'll be appending a new chart to the container element
              _removeOldChart();

              var container = d3.select('#' + scope.d3DonutId),
                  maxDiameter = 450,
                  diameter = Math.min((elem.width() * 0.5), maxDiameter),
                  color = _buildColorScale(),
                  radius = diameter / 2,
                  innerRadius = radius * 0.55,
                  tooltip = d3.select('#' + scope.donutInfoId),

                  svg = container.append("svg")
                      .attr("width", diameter)
                      .attr("height", diameter)
                      .append("g")
                      .attr("transform", "translate(" + radius + "," + radius + ")")
                      .attr("stroke", "white")
                      .attr("stroke-width", "2px"),

                  arc = d3.svg.arc()
                      .outerRadius(radius)
                      .innerRadius(innerRadius),

                  pie = d3.layout.pie()
                      .sort(null)
                      .value(function (d) {
                        return d.value;
                      });

              svg.selectAll('path')
                  .data(pie(_.filter(scope.chartData.primary, 'value')))
                  .enter()
                  .append('path')
                  .attr('d', arc)
                  .attr('id', function(d){
                    return scope.buildId(d.data.key);
                  })
                  .attr('data-radius', function(){
                    return radius;
                  })
                  .attr('data-c-x', function(d){
                    return arc.centroid(d)[0];
                  })
                  .attr('data-c-y', function(d){
                    return arc.centroid(d)[1];
                  })
                  .attr('fill', function (d, i) {
                    if (d) {
                    }
                    return color(i);
                  })
                  .on("mousemove", function() {
                    //don't show highlights or tooltips if there is a select menu open as that may cause overlap issues
                    if(document.querySelector('.ui-select-container.open')){
                      return;
                    }

                    var slice = d3.select(this),
                        mouse = d3.mouse(this);

                    slice.classed("active", true);

                    scope.hilighted = slice.data()[0].data;
                    scope.$apply();

                    var tooltipHeight = tooltip[0][0].clientHeight,
                        tooltipWidth = tooltip[0][0].clientWidth,
                        svg = angular.element(this).closest("svg"),
                        xbuffer = svg.offset().left - svg.parent().offset().left, // calculate how far the svg donut graphic is away from the parent div (when it's centered)
                        ybuffer = -18;

                    tooltip
                        .attr("pointer-events", "none")
                        .style("left", (radius+mouse[0] - (tooltipWidth/2)) + xbuffer + "px")
                        .style("top", (radius+mouse[1] - tooltipHeight + ybuffer) + "px")
                        .style("opacity", "1");
                  })
                  .on("mouseleave", function () {
                    //remove higlighting
                    d3.select(this).classed("active", false);

                    //hide the tooltip
                    tooltip.style("opacity", "0").style("left", "-6000px");
                  });


              function _buildColorScale() {
                return d3.scale.ordinal().range(_(scope.chartData.primary).filter('value').pluck('color').value());
              }
            }
          };


          var controllerFn = ['$scope', 'securityService', '$window', function($scope, securityService, $window){

            var d3 = $window.d3;

            $scope.allowViewCaseTotals = function() {
              return securityService.isLoggedInAdmin();
            };

            $scope.buildId = function (elementName) {
              return elementName + (!_.isEmpty($scope.id) ? '-'+$scope.id : '');
            };

            $scope.showTooltip = function (key) {
              //don't show tooltips if there is a select menu open as that may cause overlap issues
              if(document.querySelector('.ui-select-container.open')){
                return;
              }

              var slice = d3.select('#' + key);

              // We won't have a slice if the legend is showing a 0% value. The [0][0] is how we need to reference the D3 element
              if (slice[0][0]) {
                var svg = angular.element('#' + key).closest("svg"),
                  xbuffer = svg.offset().left - svg.parent().offset().left; // calculate how far the svg donut graphic is away from the parent div (when it's centered)
              
              } else {
                return;
              }

              $scope.hilighted = slice.data()[0].data;

              //wrap in $timeout so that the height/width calculations take new scope variables into account
              $timeout(function () {
                var tooltip = d3.select('#' + $scope.donutInfoId),
                    radius = slice && parseFloat(slice.attr('data-radius')),
                    xOffset = tooltip[0][0] ? radius + parseFloat(slice.attr('data-c-x')) + xbuffer - (0.5 * tooltip[0][0].clientWidth) : undefined,
                    yOffset = tooltip[0][0] ? radius + parseFloat(slice.attr('data-c-y') - tooltip[0][0].clientHeight) : undefined;

                slice.classed("active", true);


                //show the tooltip. centroid gives us the distance we need from the center point of the svg adding radius
                //to that point translates to the necessary x,y coordinates for absolute positioning
                tooltip
                    .attr("pointer-events", "none")
                    .style("left", xOffset + "px")
                    .style("top", yOffset + "px")
                    .style("opacity", "1");
              });

            };

            $scope.hideTooltip = function(key){
              var slice = d3.select('#' + key),
                  tooltip = d3.select('#' + $scope.donutInfoId);

              if(!slice){
                return;
              }

              slice.classed("active", false);
              tooltip.style("opacity", "0").style("left", "-6000px");
            };

          }];

          return {
            restrict: 'A',
            templateUrl: '/assets/javascripts/analytics/widgets/donut/donut.html',
            scope: {
              widget: '=',
              filters: '=',
              internal: '=',
              right: '=',
              suppression: '=',
              filterMask: '=',
              id: '@',
              displayConfig: '=',
              compareMode: '=',
              allowViewSignalStrength: '='
            },
            link: linkFn,
            controller: controllerFn
          };
        }]);
});

