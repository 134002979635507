define('analytics/widgets/bubbleMap/bubbleMap',['angular'], function (angular) {
  'use strict';

  return angular.module('widgets-bubbleMap', [])
      .directive('widgetsBubbleMap', ['analyticsService', '$filter', function(analyticsService, $filter) {
        return {
          restrict: 'A',
          templateUrl: '/assets/javascripts/analytics/widgets/bubbleMap/bubbleMap.html',
          scope: {
            widget: '=',
            filters: '=',
            right: '=',
            internal: '=',
            suppression: '=',
            filterMask: '=',
            id: '@',
            compareMode: '=',
            displayConfig: '='
          },
          controller: ['$scope', '_', 'securityService', '$window', 'geographyService', 'standardOptions', 'widgetHelpers',
           function($scope, _, securityService, $window, geographyService, standardOptions, widgetHelpers){
            var config = $scope.widget.config;

           $scope.allowViewCaseTotals = function() {
               return securityService.isLoggedInAdmin();
           };


            $scope.state = 'LOADING';
            $scope.mapContainerId = $scope.widget.mapContainer + (!_.isEmpty($scope.id) ? '-'+$scope.id : '');

            /**
              Scope functions
            */
            $scope.icon = function(key){
              var geoData = geographyService.getLatLong(key);
              if(geoData && geoData.icon){
                return geoData.icon;
              }

              var cls = key.toLowerCase();
              cls = 'icon-region-'+cls.replace(' ', '-').replace('america', 'amer').replace('middle','mid'); 
              return cls;
            };

            $scope.showTooltip = function(item) {
              var bubble = _findItemBubble(item);
              angular.element(bubble).css('fillOpacity', 0.85);
              angular.element(_findTooltip())
                      .html(_tooltipTemplate(bubble.__data__))
                      .css('top', bubble.cy.baseVal.value + 'px')
                      .css('left', bubble.cx.baseVal.value + 'px')
                      .css('display', 'block');
            };

            $scope.hideTooltip = function(item) {
              angular.element(_findItemBubble(item)).css('fillOpacity', 0.75);
              angular.element(_findTooltip()).hide();
            };

            /*
              Private Functions
            */

            function _findItemBubble(name) {
                var bubbles = angular.element(document).find('#' + $scope.mapContainerId + ' circle');
                return _.find(bubbles, function(circle) {
                    return circle.__data__.name === name;
                });
            }

            function _findTooltip() {
                return angular.element(document).find('#' + $scope.mapContainerId + ' div.datamaps-hoverover');
            }

            function _tooltipTemplate(data) {
               return '<div class="hoverinfo">' +
                                '<div class="hoverinfo-icon"><i class="' + data.icon + '"></i></div>' +
                                '<div class="hoverinfo-title">' + data.name + '</div>' +
                                '<div class="hoverinfo-helper">' + data.helperText + '</div>' +
                                '<div class="hoverinfo-content">' +
                                  '<span class="hoverinfo-label">' + $filter('analyticsPercentageValue')(data.percentage) + '<sup>%</sup></span>' +
                                  config.tooltipText +
                                '</div>' +
                              '</div>';
            }

            /**
            * Loads one topojson map into the DOM and set event listener
            * @param mapContainer
            */
            function _loadMap(mapContainer){
              var hasMap = document.getElementById(mapContainer).getElementsByTagName('svg').length;
              if(hasMap === 0){
                $scope.mapWorld = new $window.Datamap({
                  element: document.getElementById(mapContainer),
                  responsive: true,
                  scope: 'world', 
                  geographyConfig: {
                    popupOnHover: false,
                    highlightOnHover: false,
                    borderColor: '#94BC61',
                    borderWidth: 1
                  },
                  fills:{
                    defaultFill:'#94BC61', // @stat-green-02
                    defaultBubble: '#1b8a8c' // @stat-blue-05
                  },
                  // Note: the bubblesConfig in the region map and bubble map must be the same.  See regionMap.js for details.
                  bubblesConfig: {
                    borderWidth: 0,
                    borderColor: '#FFFFFF',
                    popupOnHover: true,
                    radius: null,
                    fillKey: 'defaultBubble',
                    fillOpacity: 0.75,
                    animate: true,
                    highlightOnHover: true,
                    highlightFillColor: '#1b8a8c',
                    highlightBorderWidth: 0,
                    highlightFillOpacity: 0.85,
                    exitDelay: 100
                  },
                  done: function(){
                    window.addEventListener('resize', function() {
                     $scope.mapWorld.resize();
                    });
                  }
                });
                drawBubbles();
              }
              else{
                drawBubbles();
              }
            }
            /**
            * Append bubble(s) and tooltip(s) on topojson map
            * @param geographyService, icon
            */
            function drawBubbles(){
              var bombs = [];
              // build data
              _.forEach($scope.keyData, function(n){
                var coordinates = geographyService.getLatLong(n.groups[0]);
                if(_.isEmpty(coordinates)){
                  return; //will definitely happen in case of "Other"
                }
                var bomb = {
                  name : n.groups[0],
                  helperText : n.helperText,
                  radius: n.percentage*100 < 5 ? 5 : n.percentage*100,
                  latitude: coordinates.latitude,
                  longitude: coordinates.longitude,
                  icon: $scope.icon(n.groups[0]),
                  percentage:  n.percentage,
                  fillKey: 'defaultBubble'
                };
                bombs.push(bomb);
              });
              $scope.mapWorld.bubbles(bombs, {
                popupTemplate: function (geo, data) {
                   return _tooltipTemplate(data);
                }
              });
            } 

            _refreshData();
            $scope.$on('refreshData', _refreshData);


            $scope.$watch('state', function(){
              if ($scope.state === 'NO_DATA'){
                $scope.$emit('noData');
              }
            });

            function _refreshData(){
              $scope.state = 'LOADING';

              var filters = widgetHelpers.standardFiltersOnly($scope.filters, $scope.filterMask);
              analyticsService.loadMetric([config.group], filters, undefined, $scope.internal, $scope.suppression).then(function(response){
                var exp = _.isArray(config.expectedValues) ? config.expectedValues : standardOptions[config.expectedValues];
                $scope.totalCases = analyticsService.getMetricTotal(config, response);
                if(config.includeOther){
                  exp = _.clone(exp).concat('Other');
                }

                var data = _(exp)
                    .map(function (r) {
                      var match = _.find(response, function (item) {
                        return item.groups[0] === r;
                      });
                      return match || {groups: [r], percentage: 0, avg: 0};
                    })
                    .map(function(d){
                      d.icon = $scope.icon(d.groups[0]);
                      return d;
                    })
                    .map(function(h){
                        var helperText = "";
                        var keyHelper = _.find(config.keyHelperText, function(ht){
                            return ht.key === h.groups[0];
                        });
                        h.helperText = keyHelper ? keyHelper.text : helperText;
                        return h;
                    })
                    .value();


                if (config.limitToTop) {
                  var acc = 0,
                      wrappedSortedData = _(data)
                          .sortBy(function (d) {
                            return d.groups[0];
                          }).reverse()
                          .sortBy('percentage').reverse(),
                      topData = wrappedSortedData
                          .takeWhile(function (d) {
                            if (acc <= config.limitToTop) {
                              acc += d.percentage;
                              return true;
                            }
                            return false;
                          })
                          .value(),
                      otherData = wrappedSortedData
                          .takeRight(data.length - topData.length)
                          .reduceRight(function (acc, d) {
                            acc.percentage += d.percentage;
                            return acc;
                          }, {groups: ['Other'], percentage: 0, icon: 'icon-flag-other'});

                  $scope.keyData = otherData.percentage > 0 ? topData.concat(otherData) : topData;
                } else {
                  $scope.keyData = data;
                }
                _loadMap($scope.mapContainerId);
              })
                  .then(function(){
                    if (!$scope.keyData || _.find($scope.keyData, 'percentage')) {
                      $scope.state = 'DONE';
                    } else {
                      $scope.state = 'NO_DATA';
                    }
                  });
            }
          }]
        };
      }]);
});
