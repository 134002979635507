define('pattern/routes',['angular', 'common'], function(angular) {
  'use strict';

  var mod = angular.module('pattern.routes', ['common']);
  mod.config(['$routeProvider', function($routeProvider) {
    $routeProvider
      .when('/pattern',  {
        templateUrl: '/assets/javascripts/pattern/pattern.html',
        authorizedRoles: ['ADMIN']
      });
  }]);
  return mod;
});
