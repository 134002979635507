define('dashboard/directives',['angular'], function(angular) {
  'use strict';

  return angular.module('dashboard.directives', [])
  .directive('canvasimg', function(){
    var controller = [ '$scope', '$window', function($scope, $window){
      $scope.window = angular.element($window);
      $scope.oldHeightReading = 0;
    }];
    return {
      restrict: 'E',
      templateUrl: '/assets/javascripts/dashboard/partials/canvasimg.html',
      scope: '=',
      controller : controller,  
      /*link: function postLink(scope){
        _setCanvasHeight();
        _watchCanvasHeight();

        // Private Functions
        function _setCanvasHeight(){
          var heightCheck = setInterval( function(){
            var bodyHeight = angular.element('#app-content')[0].offsetHeight;
            var footerHeight = document.getElementsByTagName('footer')[0].offsetHeight;
            var newHeight = footerHeight + bodyHeight;
            if(scope.oldHeightReading !== newHeight){
              var canvas = angular.element('.dashboard-canvas').css('min-height', +newHeight+ 'px').css('height', +newHeight+ 'px');
              canvas.attr('min-height', bodyHeight);
              scope.oldHeightReading = newHeight;
            }
            else
            {
              clearInterval(heightCheck);
            }
          }, 500);
        }
        function _watchCanvasHeight(){
          var w = scope.window; 
          w.bind('resize', function () {
           _setCanvasHeight();
          });
        }
         // Unbind all the event listners we've set
        scope.$on('$locationChangeSuccess', function() {
           angular.element(window).unbind();
        });
       // Watch for change start, trigger by role swap
        scope.$on('$locationChangeStart', function() {
         setTimeout(function(){
          _setCanvasHeight();
         }, 200); 
        });
      }*/
    };
  })
  .directive('actions', [function(){
    return {
      restrict: 'E',
      templateUrl: '/assets/javascripts/dashboard/partials/actions.html',
      scope: '=' 
    };
  }])
  .directive('notifications', function(){
	return {
      restrict: 'E',
      templateUrl: '/assets/javascripts/dashboard/partials/notifications.html',
      scope: '=' 
    };
  })
  .directive('recentCases', function(){
	return {
      restrict: 'E',
      templateUrl: '/assets/javascripts/dashboard/partials/recentCases.html',
      scope: '=' 
    };
  })
  .directive('subscriberLinks', function(){
    return {
      restrict: 'E',
      templateUrl: '/assets/javascripts/dashboard/partials/subscriberLinks.html',
      scope: '='
    };
  })
  .directive('subscriberOverview', function(){
    return {
      restrict: 'E',
      templateUrl: '/assets/javascripts/dashboard/partials/subscriberOverview.html',
      scope: '='
    };
  })
  .directive('search', function(){
    var controller = [
      '$scope', 'standardOptions', '$location', '_',
      function($scope, standardOptions, $location, _){
        /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
          Scope Variables
        ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/        
        $scope.filters = {}; // Placeholder for selected options 
        $scope.options = {}; // Placeholder for serach options 

        _initOptions();

        /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
          Scope Functions
        ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
        $scope.go = function(){
          var url = _buildURI();
          $location.url(url);
        };

        /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
          Private Functions
        ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
        function _buildURI(){
          var industry, region, path, uri;
          industry = $scope.filters.caseType ? $scope.filters.caseType.selected : undefined;
          region =  $scope.filters.caseRegion ? $scope.filters.caseRegion.selected : undefined;
          path = 'analytics/overview';
          
          if(industry && region){
            uri = path + '?caseType='+industry+'&caseRegion='+region;
          }
          else if(industry){
             uri = path + '?caseType='+industry;
          }
          else if(region){
             uri = path + '?caseRegion='+region;
          }
          else{
            uri = path;
          }
          return uri;
        }

        function _initOptions() {
          $scope.options = {
            caseType: buildOptions('caseTypes'),
            caseRegion: buildOptions('regions')
          };
        }

        function buildOptions(standardOptionsKey, convertKeysToCaps) {
          return _.union(
                  [{label: 'All', value: ''}],
                  _.map(standardOptions[standardOptionsKey], function (o) {
                    return {
                      label: o,
                      value: convertKeysToCaps ? o.toUpperCase() : o
                    };
                  })
              );
        }
      }
    ];
    return {
      controller: controller,
      restrict: 'E',
      transclude: true,
      templateUrl: '/assets/javascripts/dashboard/partials/search.html',
      scope: '=' 
    };
  }) 
  .directive('searchlist', function(){
    var controller = [ '$scope', 'savedSearchService', '_', '$anchorScroll', '$location',
      function($scope, savedSearchService, _, $anchorScroll, $location){
        
        // Scope Variables
        $scope.list = {};//Empty object to hold recent searchs
        _loadSearchList();

        // Scope functions
        $scope.skipTo = function(hashedAnchor){
          if($location.hash() === hashedAnchor){
             $anchorScroll();
          }
        };
        // Private Functions
        function _loadSearchList(){
          savedSearchService.list().then(
            function(response){
              // Spec requires custom message if user has no saved searches
              $scope.list = response;
              if(_.isEmpty($scope.list)){
                $scope.errors = 'No saved searches yet.';
              }
            },
            function(){
              $scope.errors = 'Error loading serach list';
            }
          );
        } 
      }
    ];
    return {
      controller: controller,
      restrict: 'E',
      templateUrl: '/assets/javascripts/dashboard/partials/searchList.html',
      scope: '=',
      link: function postLink(scope){
        setTimeout(function(){
           scope.skipTo('savedsearch');
        }, 400);
       
      }
    };
  });
});
