define('intake/controllers',['angular'], function(angular) {
  'use strict';

  var module = angular.module('intake.controllers', []);

  /**
   * Handles the collection of case information using the intake workflow.
   */
  module.controller('IntakeCtrl', [
    '$scope', '$rootScope', '$location', '$modal', '$log', 'surveyService', 'caseService', 'validationService', 'modalService', '$anchorScroll','moment', '_',
    function($scope, $rootScope, $location, $modal, $log, surveyService, caseService, validationService, modalService, $anchorScroll, moment, _) {

      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
       Initialization                            
       ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
      // Start pane loader sequence
      $rootScope.$broadcast('pane_start');

      $scope.surveyType = $location.search().type;
      $scope.caseId = $location.search().id;
      $scope.mode = $location.search().mode;

      /*survey and caseEntry are just wrappers for the corresponding values in
      the respective services. We initialize them to empty objects here so 
      that the controller can continue to compile before the actual values are
      loaded*/
      $scope.survey = {};
      $scope.caseEntry = {};
      /*load the case and the survey. Note that _loadCase may just initialize
      the case to an empty object*/

      _loadCase()
        .then(
          function(caseResponse) {
            $scope.caseEntry = caseResponse;

            // per https://www.pivotaltracker.com/story/show/156454983 - merged asia/oceania region must be displayed as seperate regions on survey, but represented as one on stats
            caseService.decomposeAsiaOceaniaRegions($scope.caseEntry);
            caseService.convertPartiesToArrays($scope.caseEntry);
            if (!$scope.caseEntry.caseEntryType) {
              $scope.caseEntry.caseEntryType = $scope.surveyType;
            }

            if (!$scope.caseEntry.status) {
              $scope.caseEntry.status = 'DRAFT';
            }
          },
          function(err) {
            console.log('error loading case ' + err);
          }

        /*we've set the caseEntry in $scope even if its just {} so now we need
        to load the survey.*/
        ).then(
          function() {
            /*todo: if we start saving the surveyId on the case we'll need
            to look up the specific survey associated with the case*/
            $scope.surveyType =
              $scope.surveyType || $scope.caseEntry.caseEntryType;
            return _loadSurvey();
          }

        /*now we've loaded the survey so set it in the $scope and initialize any
        survey-related metadata*/
        ).then(
          function(surveyResponse) {

            $scope.survey = surveyResponse;
            $scope.pageCount = $scope.survey.length;
            _setPage($location.search().page || 1);

            /*now that the survey is loaded, perform any necessary type 
            conversions so that we can use the caseEntry correctly*/ 
            $scope.caseEntry = _.mapValues($scope.caseEntry, function(value, key){
              var question = $scope.survey.questionFor(key);
              if(value && question && question.type === 'date'){
                return moment(value);
              } 
              return value;
            });

            _initializeQuestionData();

            /*when one of the triggering questions changes recalculate the 
            visible questions. Must create the $watch inside the then() function 
            because it depends on the triggeringCaseEntryFields being set after 
            the survey is loaded*/
            $scope.$watchGroup($scope.triggeringCaseEntryFields, function() {
              $scope.visibleQuestions = _calcCurrentlyVisibleQuestions();
            });

          },
          function(err) {
            console.log("error loading survey " + err);
          });

      
      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
       Scope Functions                           
       ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/    
      
      $scope.submitLabel = function() {
        if ($scope.mode === 'REVIEW') {
          return 'Update';
        } else {
          return $scope.isLastPage() ? 'Finish' : 'Next';
        }
      };

      $scope.isLastPage = function() {
        return $scope.pageNumber === $scope.pageCount;
      };

      $scope.showQuestion = function(q, sL, sK, qL, qK) {
       
        // check for last question in last section
        $scope.isLastQuestion(sL, sK, qL, qK);

        return $scope.visibleQuestions &&
          _.findWhere($scope.visibleQuestions, {
            id: q.id
          });
      };

      /* 
      * Test for last section and question, emit broadcast
      * @prams section length, section key, question length, question key
      * @return $broadcast (event)
      */
      $scope.uiTimer = null;
        $scope.isLastQuestion = function(sL, sK, qL, qK){
        if($scope.uiTimer === null && sL === sK + 1 && qL === qK + 1)
        {
          $scope.uiTimer = setTimeout(function(){ 
            // Complete pane loader sequence
            $rootScope.$broadcast('pane_success');
          }, 500);
          
        }else if(sL === sK + 1 && qL === qK + 1){
          clearTimeout($scope.uiTimer);
          $scope.uiTimer = setTimeout(function(){ 
            // Complete pane loader sequence
            $rootScope.$broadcast('pane_success');
          }, 500);
        }
      };
      /**
       * indicates if the question is required by either draft or REVIEW 
       * validations. we can use this to indicate the question is required in
       * the ui
       */
      $scope.isRequired = function(q) {
        return validationService.isRequired(q, $scope.survey, $scope.caseEntry);
      };

      $scope.showSection = function(s) {
        return surveyService.showTriggered($scope.survey, s, $scope.caseEntry);
      };

      $scope.setSkipToReview = function(){
        $scope.skipToReview = true;
      };

      $scope.showBackButton = function(){
        return $scope.pageNumber > 1 || $scope.mode === 'REVIEW';
      };

      $scope.back = function(){
        if($scope.mode === 'REVIEW'){
          $location.search('intake', 'true');
          $location.path('/intake/review');    
        } else {
          $rootScope.back({id: $scope.caseEntry.id});
        } 
      };

      $scope.next = function() {
        /*we might have invoked the next function with the intention of skipping
        to the review page - if that is the case the $scope.skipToReview variable
        will be set. Go ahead and clear the scope variable after retrieving so
        that subsequent attempts (in the case of validation errors) to submit the
        form can determine if it should skip to review or not.*/
        var skip = $scope.skipToReview;
        $scope.skipToReview = undefined;

        var isReview = $location.search().mode === 'REVIEW';

        var hasVisibleQuestionErrors = !!_.find($scope.visibleQuestions, function(q){
          return _.find(q.errors, function(e){
            return _.includes(['BASIC', 'DRAFT'], e.type);
          });
        });

        if (!hasVisibleQuestionErrors) {
          //handle any type conversions before passing to the caseService
          $scope.caseEntry = _.mapValues($scope.caseEntry, function(value, key){
            var question = $scope.survey.questionFor(key);
            if(value && question) {
              if(question.type === 'date'){
                //expect that dates are bound to moment.js objects at this point
                value = value.format('YYYY-MM-DD');
              } else if(question.type === 'currency'){
                // clear any ',' or '.' from monetary values
                value = String(value).replace(/[,.]/g, "");
              } else if(question.type === 'integer'){
                // clear any ',' or '.' from monetary values
                value = Number(String(value).replace(/[,.]/g, ""));
              } else if(question.draftValidations && question.draftValidations.integer){
                value = Number(value);
              } 
            }
            return value;
          });

          //convert the hacked up party_region/country fields into a proper array
          // per https://www.pivotaltracker.com/story/show/156454983 - merged asia/oceania region must be displayed as seperate regions on survey, but represented as one on stats
          caseService.composeAsiaOceaniaRegions($scope.caseEntry);
          caseService.convertArraysToParties($scope.caseEntry);


          /*set the answers to null for any hidden questions on this page*/
          var hiddenQuestions = _.xor($scope.visibleQuestions, $scope.allQuestions);
          _.forEach(hiddenQuestions, function(q) {
            $scope.caseEntry[q.modelField] = null;
          });

          /*set the answers to null for any empty string or undefined values*/
          $scope.caseEntry = _.mapValues($scope.caseEntry, function(value){
            if(value === "" || value === undefined){
              return null;
            }
            return value;
          });

          caseService.createOrUpdate($scope.caseEntry)
            .then(function(caseEntry) {
              //make sure the case id is set in the url
              $location.search('id', caseEntry.id);
              $scope.caseEntry = caseEntry;
            }, function(err) {
              if (err) {}
            })
            .then(function() {
              if ($scope.isLastPage() || isReview || skip) {
                $location.search('page', null);
                $location.search('intake', 'true');
                $location.path('/intake/review');
                
              } else {
                /*loop through pages until we find one with some questions to 
                display*/
                var visibleQuestionsOnNextPage;
                for(var i=$scope.pageNumber; i < $scope.survey.asPages().length; i++){
                  visibleQuestionsOnNextPage  = 
                    _calcCurrentlyVisibleQuestions($scope.survey.questionsForPage(i));
                  if(visibleQuestionsOnNextPage.length){
                    _setPage(i+1);
                    return;  
                  }
                }
                
                $location.search('page', null);
                $location.search('intake', 'true');
                $location.path('/intake/review');                
              }
            });
        } else {
          /*persist the errors as submitted so that the error messaging directive
          can base some logic off of that*/
          _.forEach($scope.allQuestions, function(q){
            q.submittedErrors = q.errors;
          });

          $scope.errors = true;
          $anchorScroll('errorAnchor');
        }
      };

      $scope.discard = function() {
        if($scope.isUpdate()) {
          $scope.discardText = 'edits';
        } else {
          $scope.discardText = 'this case';
        }
        $modal.open({
          animation: true,
          backdrop: 'static',
          windowClass: 'confirm-cancel-modal',
          templateUrl: 'assets/javascripts/intake/partials/confirmDiscard.html',
          size: 'lg'
        });
      };

      $scope.discardText = function() {
        if($scope.isUpdate()) {
          return 'edits';
        } else {
          return 'this case';
        }
      };

      $scope.confirmDiscard = function() {
        if($scope.caseEntry.id && !$scope.isUpdate()) {
          $scope.caseEntry.status = 'CANCELLED';
          $scope.caseEntry.statusComment = 'Case entry cancelled by user.';
          caseService.createOrUpdate($scope.caseEntry).then( function(){
            $location.path('/');
          });
        } else {
          $location.path('/');
        }
        $scope.$close();
      };

      $scope.templateUrl = function(question) {
        return '/assets/javascripts/intake/partials/inputs/' +
          question.type + '.html';
      };

      $scope.isError = function(question){
         var visibleErrors = _(question.errors)
            .filter(function(e){
              if(e.type === 'BASIC' && question.type === 'date') {
                var m = $scope.caseEntry[question.modelField];
                var partiallyFilled = m && m._i && (m._i.indexOf('false') > -1 || m._i.indexOf('undefined') > -1);
                return !partiallyFilled || _.findWhere(question.submittedErrors, {type: 'BASIC'});
              }
              return true;
            })
            .value();

        return _.find(visibleErrors, function(e){
          return _.includes(['DRAFT', 'BASIC'], e.type);
        });
      };

      $scope.isWarn = function(question){
        return _.find(question.errors, function(e){
          return _.includes(['REVIEW', 'DATE'], e.type);
        });
      };

      $scope.showReviewErrors = function(){
        return $location.search().mode === 'REVIEW';  
      };

      $scope.isUpdate = function() {
        return $location.search().update;
      };


      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
       Private Functions                            
       ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
      
      function _loadSurvey() {
        //return the promise for chaining
        return surveyService.loadSurvey($scope.surveyType);
      }

      function _setPage(page) {
        $scope.pageNumber = Number(page); //force to number

        $scope.page = $scope.survey.asPages()[page - 1];
     
        /*set the page in the query params for navigation/refresh/etc. Use
        replace() when navigating from the dashboard (no page number) so that 
        the browser history can navigate backwards correctly*/
        if(!$location.search().page){
          $location.search('page', $scope.pageNumber).replace();
        } else {
          $location.search('page', $scope.pageNumber);  
        }
      }

      function _loadCase() {
        return caseService.load($scope.caseId);
      }

      /**
      * Calculates a bunch of metadata about the survey that we can use for 
      * things like triggering follow up questions or entire sections or 
      * validating only the responses for questions that are shown on the
      * current page. This MUST be run after the survey is loaded.
      */
      function _initializeQuestionData() {
        /*create an array of all the questions that might be shown on the 
        page*/
        $scope.allQuestions = $scope.survey.questionsForPage($scope.pageNumber-1);

        /*create an array of the visible questions for the current page
        based on the values stored in the case entry */
        $scope.visibleQuestions = _calcCurrentlyVisibleQuestions($scope.allQuestions);

        /*next create an array of all the questions on the current page that
        could cause changes in visibility of other questions on the page*/
        $scope.triggeringQuestionIds = $scope.survey.triggers($scope.allQuestions);

        $scope.triggeringCaseEntryFields = _.map($scope.triggeringQuestionIds, function(id) {
          return 'caseEntry.' + $scope.survey.modelField(id);
        });

        _.forEach($scope.allQuestions, function(q){
          q.errors =  validationService.getErrors(
              ['BASIC', 'DRAFT', 'REVIEW', 'ACTIVE', 'ADMIN', 'DATE'],
              q, $scope.survey, $scope.caseEntry);
        });
      }

      /**
       * determine which questions are visibile either using the given set of
       * questions or the allQuestions property of $scope if none given
       */
      function _calcCurrentlyVisibleQuestions(questions) {
        questions = questions || $scope.allQuestions;
        return _.filter(questions, function(q) {
          return surveyService.showTriggered($scope.survey, q, $scope.caseEntry);
        });
      }
    }
  ])

  /**
   * Display of case information prior to submitting
   */
  .controller('ReviewCtrl', [
    '$scope', '$location', '$anchorScroll', '$filter', '$rootScope', 'moment', 'userProfileService', 'organizationService', 'surveyService', 'securityService', 'standardOptions', 'caseService', 'validationService', '_', 'modalService',
    function($scope, $location, $anchorScroll, $filter, $rootScope, moment, userProfileService, organizationService, surveyService, securityService, standardOptions, caseService, validationService, _, modalService) {

      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
       Initialization                           
       ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

      $scope.surveyType = $location.search().type;
      $scope.caseId = $location.search().id;

      /*intake indicates that we're editing the case either through initial entry
      or updating an existing case*/ 
      $scope.intake = $location.search().intake;

      /*update indicates viewing or editing an existing case as opposed ot 
      entering a new one*/
      $scope.update = $location.search().update;
      $scope.caseEntry = {}; //just a wrapper to be populated later

      var userInfo = securityService.getLoggedInUserInfo();
      $scope.isAdmin = _.includes(userInfo.roles, "ADMIN");
      $scope.isDCAdmin = _.includes(userInfo.roles, "CONTRIBUTOR_ADMIN");
      $scope.showAdminFeature = $scope.isAdmin ? true : false;
      $scope.showContributorAdminFeature = ($scope.isAdmin || $scope.isDCAdmin) ? true : false;
      



      _loadCase()
        .then(
          function(caseResponse) {
            $scope.caseEntry = caseResponse;
            $scope.caseStatuses = $scope.buildOptions('caseStatuses', true);

            // per https://www.pivotaltracker.com/story/show/156454983 - merged asia/oceania region must be displayed as seperate regions on survey, but represented as one on stats
            caseService.decomposeAsiaOceaniaRegions($scope.caseEntry);
            caseService.convertPartiesToArrays($scope.caseEntry);

            var caseEditorId = $scope.caseEntry.editorUserId;
            if($scope.isAdmin){
              userProfileService.getUserProfile(caseEditorId).then(
                function(profileResponse) {
                    organizationService.getOrganizationUsers(profileResponse.orgId).then(
                      function(caseEditorUsersResponse){
                        $scope.caseEditorUsers = _.map(caseEditorUsersResponse, function(o){
                            return {label: o.email, value: o.id};
                        });
                      }
                    );
                }
              );
              $scope.caseEditor.editorUserId = $scope.caseEntry.editorUserId;

              //safe to say that in draft mode, someone might want to submit a case straight away
              //in flagged they should probably edit it since it was kicked back to them, but only the first time
              if($scope.caseEntry.status === 'DRAFT' || $scope.caseEntry.status === 'FLAGGED'){
                  $location.search('intake', 'true');
              }
            }
            else if($scope.isDCAdmin){
              $scope.caseEditorUsers = organizationService.getOrganizationUsers(userInfo.orgId).then(
                function(caseEditorUsersResponse){
                  $scope.caseEditorUsers = _.map(caseEditorUsersResponse, function(o){
                    return {label: o.email, value: o.id};
                  });
              });
              $scope.caseEditor.editorUserId = $scope.caseEntry.editorUserId;
            }
          },
          function(err) {
            console.log('error loading case ' + err);
          }
        )
        .then(
          function() {
            /*todo: if we start saving the surveyId on the case we'll need
            to look up the specific survey associated with the case*/
            $scope.surveyType = $scope.caseEntry.caseEntryType;
            return _loadSurvey();
          }
        )
        /*now we've loaded the survey so set it in the $scope*/
        .then(
          function(surveyResponse) {

            var validationTypes = ['BASIC', 'DRAFT', 'REVIEW', 'ACTIVE', 'ADMIN', 'DATE'];

            $scope.survey = surveyResponse;

            /* run the validations on all questions.
             it's somewhat strange that we have errors on the survey instead of
             caseEntry. At this point it would be a major refactor to change so we're
             just going to roll with it*/
            _.forEach($scope.survey.asFlattenedQuestions(), function(q){
              q.errors = validationService.getErrors(validationTypes, q, $scope.survey, $scope.caseEntry);
            });

            $scope.survey.errors = validationService.getSurveyLevelErrors(validationTypes, $scope.survey, $scope.caseEntry);


            var questionErrorTypes = _($scope.survey.asFlattenedQuestions())
                .filter(function(q){
                  return surveyService.showTriggered($scope.survey, q, $scope.caseEntry);
                })
                .pluck('errors')
                .flatten()
                .pluck('type');
            var surveyErrorTypes = _($scope.survey.errors).pluck('type');

            $scope.hasAdminQuestionErrors = _(questionErrorTypes).includes('ADMIN');
            $scope.hasAdminSurveyErrors = _(surveyErrorTypes).includes('ADMIN');
            // ADMIN validations are just ACTIVE validations that can't be overridden, so we consider them the same here
            $scope.hasActiveQuestionErrors = _(questionErrorTypes).includes('ACTIVE') || $scope.hasAdminQuestionErrors;
            $scope.hasActiveSurveyErrors = _(surveyErrorTypes).includes('ACTIVE') || $scope.hasAdminSurveyErrors;
          },
          function(err) {
            console.log("error loading survey " + err);
          }
        );

      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
       Scope Functions                           
       ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

      $scope.show = function(triggered) {
        return surveyService.showTriggered(
          $scope.survey, triggered, $scope.caseEntry);
      };

      //Input a question and get back a UI friendly value for question field
      $scope.display = function(question) {
        var modelVal = $scope.caseEntry[question.modelField];
        var format;
        if(modelVal === false || modelVal === 0 || modelVal) {
          switch (question.type) {
            case 'date':
              var m = moment(modelVal);
              format = m.format("LL").toString();
              break;
            case 'boolean':
              if(question.possibleValues) {
                var possible = question.possibleValues;
                if(modelVal) {
                  format = (possible.length>0)?possible[0]:"Yes";
                }
                else {
                  format = (possible.length>1)?possible[1]:"No";
                }
              }
              else {
                format = modelVal ? 'Yes' : 'No';
              }
              break;
            case 'currency':
              var fieldDescriptionRef = question.fieldDescriptionRef;
              format = modelVal;
              if(fieldDescriptionRef && $scope.caseEntry[fieldDescriptionRef]){
                  format += ' ' + $scope.caseEntry[fieldDescriptionRef];
              }
              break;
            case 'select':
            case 'choice':
              /*this is pretty ugly stuff, basically we're just trying to find 
              the option in the possibleValues array that matches what is stored
              on the model so we can get the "pretty" label instead of displaying
              the raw value from the database*/
              if(_.isObject(question.possibleValues[0])){
                format = _.keys(_.find(question.possibleValues, function(pv){
                  return _.values(pv)[0] === modelVal;
                }))[0];
              } else {
                format = modelVal;
              }
              break;
            default:
              format = modelVal;
          }
        }
        return format;
      };


      $scope.isRequired = function(q) {
        return validationService.isRequired(q, $scope.survey, $scope.caseEntry);
      };

      $scope.editPage = function(page){
        $location.search('mode', 'REVIEW');
        $location.search('page', page);
        $location.path('/intake');
        // href="#/intake?id={{caseEntry.id}}&page={{pageNum}}&mode=REVIEW"  
      };

      $scope.localBack = function(){
        //special handling for when the survey only has one page
          $rootScope.back(
            {
              id: $scope.caseEntry.id,
              mode: 'REVIEW'
            });
      };

      $scope.submit = function() {
        $scope.submitted = true;

        // per https://www.pivotaltracker.com/story/show/156454983 - merged asia/oceania region must be displayed as seperate regions on survey, but represented as one on stats
        caseService.composeAsiaOceaniaRegions($scope.caseEntry);
        caseService.submit($scope.survey, $scope.caseEntry)
          .then(
            function() {
              //$scope.caseId = $location.search().id;
              // Build modal
              var content = {
                  footer: '<div class="modal-case">'+
                            '<span class="modal-case-label">DRD Case ID:&nbsp;'+
                              '<span class="modal-case-number">'+$scope.caseEntry.caseNum+'</span>'+
                            '</span>'+
                          '</div>'+
                          '<p>has been submitted successfully</p>',
                  button: 'Return to dashboard',
                  title: 'Thank you!',
                  body:'<h4>Thank you for strengthening global dispute resolution with the power of your data.</h4>'
                  };
              var attribute = {
                id : 'case-submitted-modal'
              };
              modalService.set({ content : content, attribute: attribute, display : true });
              
              // redirect to root
              $location.url('/');
            },
            function(err) {
              $scope.errors = err;
              $anchorScroll('errorAnchor');
            }
          );
      };

      $scope.showErrorAlert = function() {
        return ($scope.submitted && $scope.errors) ||
            ($scope.survey && _($scope.survey.errors).pluck('type').includes('REVIEW'));
      };

      $scope.showActiveErrors = function(){
        var isAdmin = $scope.isAdmin;
        var isReview = $scope.caseEntry && 'REVIEW' === $scope.caseEntry.status;

        return isAdmin && isReview &&
            ($scope.hasActiveQuestionErrors || $scope.hasActiveSurveyErrors);

      };

      $scope.hasActiveErrors = function(question){
        return _(question.errors).pluck('type').includes('ACTIVE');
      };

      $scope.editable = function(){
        return $scope.caseEntry && _.includes(['DRAFT', 'FLAGGED'], $scope.caseEntry.status);
      };

      $scope.buildOptions = function(standardOptionsKey, convertKeysToCaps) {
        var keyOverrides = {
          "In Review": "REVIEW"
        };
        //turn standardOptions (string array into array of {label: , value: })
        return _.map(standardOptions[standardOptionsKey], function(o){
          var value = keyOverrides[o] || o;
          return (convertKeysToCaps ? value.toUpperCase() : value);
        });
      };

      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
       Private Functions                            
       ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

      function _loadSurvey() {
        //return the promise for chaining
        return surveyService.loadSurvey($scope.surveyType);
      }

      function _loadCase() {
        return caseService.load($scope.caseId);
      }
    }
  ]);

  
});

