define('analytics/widgets/table/table',['angular'], function (angular) {
  'use strict';

  return angular.module('widgets-table', [])
      .directive('widgetsTable', [ function(){
        return {
          restrict: 'A',
          templateUrl: '/assets/javascripts/analytics/widgets/table/table.html',
          scope: {
            widget: '=',
            filters: '=',
            internal: '=',
            suppression: '=',
            filterMask: '=',
            right: '=',
            displayConfig: '=',
            compareMode: '=',
          },
          controller: ['$scope', '$q', 'widgetHelpers', 'widgetColors', '_', 'securityService', 'analyticsService', function($scope, $q, widgetHelpers, widgetColors, _, securityService, analyticsService){
            var config = $scope.widget.config,
                DEFAULT_COLOR = 'stat13';

              $scope.state = 'LOADING';
              $scope.signalStrength = 0;

            $scope.allowViewCaseTotals = function() {
                return securityService.isLoggedInAdmin();
            };

            _refreshData();
            $scope.$on('refreshData', _refreshData);


            $scope.$watch('state', function(){
              if ($scope.state === 'NO_DATA'){
                $scope.$emit('noData');
              }
            });

            $scope.getContentColor = function() {
              // note this works only for the primary value, we don't currently have a means to customize secondary values
              return widgetColors[config.color ? config.color : DEFAULT_COLOR];
            };

            function _refreshData(){
              $scope.state = 'LOADING';
              $scope.ssState = 'LOADING';

              var rowPromises = _.map(config.rows, function(row){
                return widgetHelpers.getNumberForDescription(row, widgetHelpers.standardFiltersOnly($scope.filters, $scope.filterMask), $scope.internal, $scope.suppression);
              });

              var secondaryPromises = _(config.rows)
                  .pluck('secondary')
                  .filter(_.identity)
                  .map(function (secondary) {
                    return widgetHelpers.getNumberForDescription(secondary, widgetHelpers.standardFiltersOnly($scope.filters, $scope.filterMask), $scope.internal, $scope.suppression);
                  }).value();

              var allPromises = rowPromises.concat(secondaryPromises);

              $q.all(allPromises).then(function(numbers){
                var values = _.map(config.rows, function(row, idx){
                  var result = {primary: numbers[idx].number};
                  return result;
                });


                $scope.totalCases =  analyticsService.getMetricTotal(config, numbers);

                var secondaryNumbers = numbers.slice(config.rows.length); //get only the secondary numbers if any
                _.forEach(config.rows, function(row, idx){
                  if(row.secondary){
                    values[idx].secondary = secondaryNumbers.shift(); //pop the first element off
                  }
                });

                $scope.values = values;

                $scope.chartData = $scope.values.map(function (item, idx) {
                  if (config.rows[idx].field === 'percentage') {
                    item.key = config.rows[idx].group + _.kebabCase(item.group);
                    item.label = _.first(config.rows[idx].group);
                    item.rawData = {
                      avg: 0,
                      groups: [config.rows[idx].group],
                      percentage: item.primary,
                      total: $scope.totalCases
                    };
                  } else if (config.rows[idx].field === 'avg') {
                    item.key = config.rows[idx].group + _.kebabCase(item.group);
                    item.label = _.first(config.rows[idx].group);
                    item.rawData = {
                      avg: item.primary,
                      groups: [config.rows[idx].group],
                      percentage: 0,
                      total: $scope.totalCases
                    };
                  }
                  return item;
                });
              })
              .then(function() {
                if(config.showSignalStrength){
                  var ssData = _.map(_.filter($scope.chartData, function(item){
                    return item.rawData.total !== 0;
                  }), function(item){
                    return item.rawData;
                  });
                  if (ssData.length !== 0) {
                    return analyticsService.getSignalStrength(ssData); 
                  }
                }
              })
              .then(function(signalStrength){
                $scope.signalStrength = signalStrength;
                $scope.ssState = 'DONE';
              });
            }
          }]
        };
      }]);
});
