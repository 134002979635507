define('dashboard/routes',['angular'], function(angular) {
    'use strict';

    return angular.module('dashboard.routes', [])
        .config(['$routeProvider', function($routeProvider) {
            $routeProvider
                .when('/dashboard', {
                    reloadOnSearch: false,
                    templateUrl: '/assets/javascripts/dashboard/dashboard.html',
                    authorizedRoles: ['ADMIN', 'CONTRIBUTOR_ADMIN', 'CONTRIBUTOR', 'SUBSCRIBER']
                });
        }]);
});
