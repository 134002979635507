define('analytics/widgets/percentTrue/percentTrue',['angular'], function (angular) {
  'use strict';

  return angular.module('widgets-percentTrue', [])
      .directive('widgetsPercentTrue', ['analyticsService', function (analyticsService) {
        return {
          restrict: 'A',
          templateUrl: '/assets/javascripts/analytics/widgets/percentTrue/percentTrue.html',
          scope: {
            widget: '=',
            filters: '=',
            internal: '=',
            suppression: '='
          },
          controller: ['$scope', '_', 'widgetHelpers', 'securityService', function ($scope, _, widgetHelpers, securityService) {
            var config = $scope.widget.config;

            $scope.getYesPercentage = function () {
              var yesElement = widgetHelpers.findYesItem($scope.metric);
              return yesElement ? yesElement.percentage : 0;
            };
            $scope.allowViewCaseTotals = function() {
                return securityService.isLoggedInAdmin();
            };

            _refreshData();
            $scope.$on('refreshData', _refreshData);

            function _refreshData(){
              //expects config to contain group, title
              analyticsService.loadMetric([config.group], $scope.filters, undefined, $scope.internal, $scope.suppression).then(function (metric) {
                $scope.metric = metric;
                $scope.totalCases = analyticsService.getYesElement(metric).total;
              });
            }

          }]
        };
      }]);
});
