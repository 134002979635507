define('analytics/controllers',['angular'], function (angular) {
  'use strict';

  return angular.module('analytics.controllers', [])

      .factory('statsViews', ['widgets', function(widgets){
        return {
          all: [
            {
              id: 'detailedStats',
              title: 'Detailed Stats',
              advancedFilters: true,
              persistentFilters: ['status', 'caseType', 'caseRegion', 'organizationId'],
              // implicitFilters: {processType: 'ARBITRATION'},
              adminOnly: false,
              detailedStatsHeaders: true,
              sections: [
                {
                  title: 'Arbitration: All Outcomes',
                  processType: 'ARBITRATION',
                  cols:[
                    {
                      widgets:[
                        widgets.detailedStats.caseType,
                        widgets.detailedStats.caseRegionDetailedStats,
                        widgets.detailedStats.clmPartyRegion,
                        widgets.detailedStats.rspPartyRegion,
                        widgets.detailedStats.filedAsMediation,
                        widgets.detailedStats.demandType,
                        widgets.detailedStats.counterDemandType,
                        widgets.detailedStats.rulesUsed,
                      ],
                      style: "col-md-6"
                    },
                    {
                      widgets:[
                        widgets.detailedStats.arbSelMethod,
                        widgets.detailedStats.numArbitrators,
                        widgets.detailedStats.casesWith,
                        widgets.detailedStats.keyArbDurations,
                        widgets.detailedStats.caseConclusion,
                        widgets.detailedStats.dollarAmountsArb,
                        widgets.detailedStats.numInterrogatories,
                        widgets.detailedStats.numMotionsFiled,
                        widgets.detailedStats.numDepositions,
                        widgets.detailedStats.numWitnessStatements,
                      ],
                      style: "col-md-6"
                    },
                  ]
                },
                {
                  title: 'Arbitration: Awarded',
                  processType: 'ARBITRATION',
                  cols: [ 
                    {
                      style: "col-md-6",
                      widgets: 
                        [
                          widgets.detailedStats.caseTypeArbitrationAwarded,
                          widgets.detailedStats.caseRegionDetailedStatsArbitrationAwarded,
                          widgets.detailedStats.clmPartyRegionArbitrationAwarded,
                          widgets.detailedStats.rspPartyRegionArbitrationAwarded,
                          widgets.detailedStats.filedAsMediationArbitrationAwarded,
                          widgets.detailedStats.demandTypeArbitrationAwarded,
                          widgets.detailedStats.counterDemandTypeArbitrationAwarded,
                          widgets.detailedStats.rulesUsedArbitrationAwarded,
                          widgets.detailedStats.arbSelMethodArbitrationAwarded,
                          widgets.detailedStats.numArbitratorsArbitrationAwarded,
                          widgets.detailedStats.casesWithArbitrationAwarded,
                          widgets.detailedStats.keyArbDurationsAwardedArbitrationAwarded
                        ]
                    },
                    {
                      style: "col-md-6", 
                      widgets: 
                        [
                          widgets.detailedStats.awardedReasonsArbitrationAwarded,
                          widgets.detailedStats.averageCostsAndFeesAwardedArbitrationAwarded,
                          widgets.detailedStats.averageClaimantAwardToClaimRatioQuartilesArbitrationAwarded,
                          widgets.detailedStats.averageClaimAndClaimantAwardRatioQuartilesArbitrationAwarded,
                          widgets.detailedStats.averageCostsAndFeesAwardedRespondentArbitrationAwarded,
                          widgets.detailedStats.averageCounterclaimantAwardToCounterclaimRatioQuartilesArbitrationAwarded,
                          widgets.detailedStats.averageCounterclaimAndCounterclaimantAwardRatioQuartilesArbitrationAwarded,
                          widgets.detailedStats.numInterrogatoriesArbitrationAwarded,
                          widgets.detailedStats.numMotionsFiledArbitrationAwarded,
                          widgets.detailedStats.numDepositionsArbitrationAwarded,
                          widgets.detailedStats.numWitnessStatementsArbitrationAwarded
                        ]
                    }
                  ]
                },
                {
                  title: 'Arbitration: Settled/Withdrawn',
                  processType: 'ARBITRATION',
                  cols:[
                    {
                      widgets:[
                        widgets.detailedStats.caseTypeArbitrationSettledWithdrawn,
                        widgets.detailedStats.caseRegionDetailedStatsArbitrationSettledWithdrawn,
                        widgets.detailedStats.clmPartyRegionArbitrationSettledWithdrawn,
                        widgets.detailedStats.rspPartyRegionArbitrationSettledWithdrawn,
                        widgets.detailedStats.filedAsMediationArbitrationSettledWithdrawn,
                        widgets.detailedStats.demandTypeArbitrationSettledWithdrawn,
                        widgets.detailedStats.counterDemandTypeArbitrationSettledWithdrawn,
                        widgets.detailedStats.rulesUsedArbitrationSettledWithdrawn,
                      ],
                      style: "col-md-6"
                    },
                    {
                      widgets:[
                        widgets.detailedStats.arbSelMethodArbitrationSettledWithdrawn,
                        widgets.detailedStats.numArbitratorsArbitrationSettledWithdrawn,
                        widgets.detailedStats.casesWithArbitrationSettledWithdrawn,
                        widgets.detailedStats.keyArbDurationsSettledWithdrawn,
                        widgets.detailedStats.caseConclusionSettledWithdrawn,
                        widgets.detailedStats.dollarAmountsSettledWithdrawn,
                        widgets.detailedStats.numInterrogatoriesArbitrationSettledWithdrawn,
                        widgets.detailedStats.numMotionsFiledArbitrationSettledWithdrawn,
                        widgets.detailedStats.numDepositionsArbitrationSettledWithdrawn,
                        widgets.detailedStats.numWitnessStatementsArbitrationSettledWithdrawn,
                      ],
                      style: "col-md-6"
                    },
                  ]
                },
                {
                  title: 'Arbitration: Dismissed',
                  processType: 'ARBITRATION',
                  cols:[
                    {
                      widgets:[
                        widgets.detailedStats.caseTypeArbitrationDismissed,
                        widgets.detailedStats.caseRegionDetailedStatsArbitrationDismissed,
                        widgets.detailedStats.clmPartyRegionArbitrationDismissed,
                        widgets.detailedStats.rspPartyRegionArbitrationDismissed,
                        widgets.detailedStats.filedAsMediationArbitrationDismissed,
                        widgets.detailedStats.demandTypeArbitrationDismissed,
                        widgets.detailedStats.counterDemandTypeArbitrationDismissed,
                        widgets.detailedStats.rulesUsedArbitrationDismissed,
                      ],
                      style: "col-md-6"
                    },
                    {
                      widgets:[
                        widgets.detailedStats.arbSelMethodArbitrationDismissed,
                        widgets.detailedStats.numArbitratorsArbitrationDismissed,
                        widgets.detailedStats.casesWithArbitrationDismissed,
                        widgets.detailedStats.keyArbDurationsDismissed,
                        widgets.detailedStats.dismissedReasons,
                        widgets.detailedStats.dollarAmountsDismissed,
                        widgets.detailedStats.numInterrogatoriesArbitrationDismissed,
                        widgets.detailedStats.numMotionsFiledArbitrationDismissed,
                        widgets.detailedStats.numDepositionsArbitrationDismissed,
                        widgets.detailedStats.numWitnessStatementsArbitrationDismissed,
                      ],
                      style: "col-md-6"
                    },
                  ]
                },
                {
                  title: 'Arbitration: Administrative Close',
                  processType: 'ARBITRATION',
                  cols:[
                    {
                      widgets:[
                        widgets.detailedStats.caseTypeArbitrationAdministrativeClose,
                        widgets.detailedStats.caseRegionDetailedStatsArbitrationAdministrativeClose,
                        widgets.detailedStats.clmPartyRegionArbitrationAdministrativeClose,
                        widgets.detailedStats.rspPartyRegionArbitrationAdministrativeClose,
                        widgets.detailedStats.filedAsMediationArbitrationAdministrativeClose,
                        widgets.detailedStats.demandTypeArbitrationAdministrativeClose,
                        widgets.detailedStats.counterDemandTypeArbitrationAdministrativeClose,
                        widgets.detailedStats.rulesUsedArbitrationAdministrativeClose,
                      ],
                      style: "col-md-6"
                    },
                    {
                      widgets:[
                        widgets.detailedStats.arbSelMethodArbitrationAdministrativeClose,
                        widgets.detailedStats.numArbitratorsArbitrationAdministrativeClose,
                        widgets.detailedStats.casesWithArbitrationAdministrativeClose,
                        widgets.detailedStats.keyArbDurationsAdminClosed,
                        widgets.detailedStats.caseConclusionAdministrativeClose,
                        widgets.detailedStats.dollarAmountsAdminClose,
                        widgets.detailedStats.numInterrogatoriesArbitrationAdministrativeClose,
                        widgets.detailedStats.numMotionsFiledArbitrationAdministrativeClose,
                        widgets.detailedStats.numDepositionsArbitrationAdministrativeClose,
                        widgets.detailedStats.numWitnessStatementsArbitrationAdministrativeClose,
                      ],
                      style: "col-md-6"
                    },
                  ]
                },
                {
                  title: 'Mediation: All Outcomes',
                  processType: 'MEDIATION',
                  cols:[
                    {
                      widgets:[
                        widgets.detailedStats.caseType,

                      ],
                      style: "col-md-6"
                    },
                    {
                      widgets:[
                        widgets.detailedStats.caseRegionDetailedStats,
                        widgets.detailedStats.filedAsMediation,
                        widgets.detailedStats.keyMedDurations,
                        widgets.detailedStats.dollarAmountsMed,
                        widgets.detailedStats.medCaseConclusion
                      ],
                      style: "col-md-6"
                    },
                  ]
                },
                {
                  title: 'Mediation: Settled/Withdrawn',
                  processType: 'MEDIATION',
                  cols:[
                    {
                      widgets:[
                        widgets.detailedStats.caseTypeMediationSettledWithdrawn,

                      ],
                      style: "col-md-6"
                    },
                    {
                      widgets:[
                        widgets.detailedStats.caseRegionDetailedStatsMediationSettledWithdrawn,
                        widgets.detailedStats.filedAsMediationMediationSettledWithdrawn,
                        widgets.detailedStats.keyMedDurationsSettledWithdrawn,
                        widgets.detailedStats.dollarAmountsMedSettledWithdrawn,
                        widgets.detailedStats.medCaseConclusionSettledWithdrawn
                      ],
                      style: "col-md-6"
                    },
                  ]
                },
                {
                  title: 'Mediation: Administrative Close',
                  processType: 'MEDIATION',
                  cols:[
                    {
                      widgets:[
                        widgets.detailedStats.caseTypeMediationAdminClose,

                      ],
                      style: "col-md-6"
                    },
                    {
                      widgets:[
                        widgets.detailedStats.caseRegionDetailedStatsMediationAdminClose,
                        widgets.detailedStats.filedAsMediationMediationAdminClose,
                        widgets.detailedStats.keyMedDurationsAdminClose,
                        widgets.detailedStats.dollarAmountsMedAdminClose,
                        widgets.detailedStats.medCaseConclusionAdminClose
                      ],
                      style: "col-md-6"
                    },
                  ]
                },
                {
                  title: 'Mediation: Impasse',
                  processType: 'MEDIATION',
                  cols:[
                    {
                      widgets:[
                        widgets.detailedStats.impasseCaseType,

                      ],
                      style: "col-md-6"
                    },
                    {
                      widgets:[
                        widgets.detailedStats.caseRegionDetailedStatsMediationImpasse,
                        widgets.detailedStats.filedAsMediationMediationImpasse,
                        widgets.detailedStats.keyMedDurationsImpasse,
                        widgets.detailedStats.dollarAmountsMedImpasse,
                        widgets.detailedStats.medCaseConclusionImpasse
                      ],
                      style: "col-md-6"
                    },
                  ]
                },
              ]
            },
            {
              id: 'overview',
              title: 'Overview',
              hideFilters: true,
              persistentFilters: ['status', 'organizationId'],
              allowCompare: false,
              model: {
                rows: [
//                  {widgets: [widgets.maps.caseRegion]},
                  {widgets: [widgets.donuts.caseTypes]}
                ]
              }
            },
            {
              id: 'arbitration',
              title: 'Arbitration',
              implicitFilters: {processType: 'ARBITRATION'}, // todo we now have this duplicated in the widget config; it needs to be removed here and refactored to work based on widget config definition
              persistentFilters: ['status', 'caseType', 'caseRegion', 'organizationId', 'caseType2', 'caseRegion2'],
              allowCompare: true,
              sections: [
                {
                  title: 'Region',
                  rows: [
                    {style: 'tight-top',widgets: [widgets.bars.claimantRegion, widgets.bars.respondentRegion]},
                    {style: 'tight-bottom', widgets: [widgets.numbers.multipleRegions]}
                  ]
                },
                {
                  title: 'Motions Practice',
                  rows: [
                    {widgets: [widgets.donuts.numMotionsFiled, widgets.tables.motionDetails]},
                    {widgets: [widgets.donuts.numInterrogatories, widgets.donuts.numDepositions]}
                  ]
                },
                {
                  title: 'Claims & Counterclaims',
                  rows: [
                      {widgets: [widgets.numbers.claims]},
                      {widgets: [widgets.bars.avgClaimAmountByConclusion, widgets.bars.claimVsAwardAmt]},
                      {widgets: [widgets.numbers.counterclaims]},
                      {widgets: [widgets.bars.counterClaims]}
                  ]
                },
                {
                  title: 'Outcome & Duration',
                  rows: [
                    {widgets: [widgets.donuts.arbitrationOutcome, widgets.bars.arbitrationCaseLength]},
                    {widgets: [widgets.numbers.arbitrationHearingDays]},
                    {widgets: [widgets.donuts.prevailingParty]}
                  ]
                },
                {
                  title: 'Arbitrator Details',
                  rows: [
                      {widgets: [widgets.tables.arbSelMethod]},
                      {widgets: [widgets.bars.avgNumberMaleFemaleArbs, widgets.donuts.numArbitrators]}
                  ]
                },
                {
                  showLabel: true,
                  title: 'Language & Currency',
                  rows: [
                    {widgets: [widgets.donuts.arbitrationContractLanguage, widgets.donuts.arbitrationContractCurrency]},
                  ]
                },
              ]
            },
            {
              id: 'mediation',
              title: 'Mediation',
              showLabel: true,
              implicitFilters: {processType: 'MEDIATION'}, // todo we now have this duplicated in the widget config; it needs to be removed here and refactored to work based on widget config definition
              persistentFilters: ['status', 'caseType', 'caseRegion', 'organizationId', 'caseType2', 'caseRegion2'],
              allowCompare: true,
              sections: [
                {
                  title: 'Parties',
                  rows: [
                    {widgets: [widgets.maps.mediationParties]},
                    {widgets: [widgets.donuts.mediationParties]}
                  ]
                },
                {
                  title: 'Duration, Disposition & Fees',
                  rows: [
                    {widgets: [widgets.bars.mediationLength, widgets.donuts.mediatorSelection]},
                    {widgets: [widgets.donuts.mediationOutcome]},
                    {widgets: [widgets.numbers.mediationCases, widgets.tables.mediationFigures]}
//                    {widgets: [widgets.numbers.mediationCases2]},
                  ]
                },
                {
                  title: 'Currency',
//                  title: 'Language & Currency',
                  rows: [
                    {widgets: [widgets.donuts.mediationContractCurrency]}
//                    {widgets: [widgets.donuts.mediationContractLanguage, widgets.donuts.mediationContractCurrency]}
                  ]
                }
              ]
            },
            {
              id: 'developingStats',
              title: 'Developing Stats',
              hideFilters: true,
              persistentFilters: ['status', 'organizationId'],
              allowCompare: false,

              model: {
                rows: [
                    {widgets: [widgets.basicCounts.caseTypesBreakdown]},
                    {widgets: [widgets.numbers.localeDisputed]},
                    {widgets: [widgets.numbers.arbitrationHearings]},
                    {widgets: [widgets.numbers.bifurcatedAwards]},
                    {widgets: [widgets.tables.arbitrationFigures]},
                    {widgets: [widgets.donuts.numInterrogatories, widgets.donuts.numDepositions]},
                    {widgets: [widgets.donuts.numMotionsFiled, widgets.tables.motionDetails]},
                    {widgets: [widgets.numbers.arbitratorChallenged, widgets.numbers.proceedingStayed]},
                    {widgets: [widgets.bars.groundsForChallenge, widgets.donuts.stayedBy]},
                    {widgets: [widgets.donuts.thirdPartyFunding, widgets.donuts.postAwardMotions]},
                    {widgets: [widgets.donuts.arbitrationContractLanguage]},
                    {widgets: [widgets.detailedStats.arbitrationHearingDays, widgets.detailedStats.mediationConferenceDays]},
                    {widgets: [widgets.bars.awardAmount]}
                ]
              }
            },
            {
              id: 'raw',
              title: 'Detailed Stats - Legacy',
              advancedFilters: true,
              persistentFilters: ['status', 'caseType', 'caseRegion', 'organizationId'],
              allowCompare: false,
              adminOnly:true,
              model: {
                legacyLayout: 'assets/javascripts/analytics/partials/layouts/admin.html'
              }
            },
            // {
            //   id: 'detailedStats2',
            //   title: 'Detailed Stats',
            //   advancedFilters: true,
            //   persistentFilters: ['status', 'caseType', 'caseRegion', 'organizationId'],
            //   defaultFilters: {processType: 'ARBITRATION'},
            //   allowCompare: false,
            //   adminOnly: false,
            //   detailedStatsHeaders: true,
            //   model: {
            //     layouts: {
            //       arbitration: {
            //         awarded:{
            //           cols:[
            //             {
            //               widgets:[
            //                 widgets.detailedStats.caseType,
            //                 widgets.detailedStats.caseRegionDetailedStats,
            //                 widgets.detailedStats.clmPartyRegion,
            //                 widgets.detailedStats.rspPartyRegion,
            //                 widgets.detailedStats.filedAsMediation,
            //                 widgets.detailedStats.demandType,
            //                 widgets.detailedStats.counterDemandType,
            //                 widgets.detailedStats.rulesUsed,
            //               ],
            //               style: "col-md-6"
            //             },
            //             {
            //               widgets:[
            //                 widgets.detailedStats.arbSelMethod,
            //                 widgets.detailedStats.numArbitrators,
            //                 widgets.detailedStats.casesWith,
            //                 widgets.detailedStats.numInterrogatories,
            //                 widgets.detailedStats.numMotionsFiled,
            //                 widgets.detailedStats.numDepositions,
            //                 widgets.detailedStats.keyArbDurationsAwarded,
            //                 widgets.detailedStats.awardedReasons,
            //                 widgets.detailedStats.dollarAmountsAwarded,
            //                 widgets.detailedStats.dollarAmountsAwardedRespondent,
            //               ],
            //               style: "col-md-6"
            //             },
            //           ]
            //         },
            //         settled_withdrawn:{
            //           cols:[
            //             {
            //               widgets:[
            //                 widgets.detailedStats.caseType,
            //                 widgets.detailedStats.caseRegionDetailedStats,
            //                 widgets.detailedStats.clmPartyRegion,
            //                 widgets.detailedStats.rspPartyRegion,
            //                 widgets.detailedStats.filedAsMediation,
            //                 widgets.detailedStats.demandType,
            //                 widgets.detailedStats.counterDemandType,
            //                 widgets.detailedStats.rulesUsed,
            //               ],
            //               style: "col-md-6"
            //             },
            //             {
            //               widgets:[
            //                 widgets.detailedStats.arbSelMethod,
            //                 widgets.detailedStats.numArbitrators,
            //                 widgets.detailedStats.casesWith,
            //                 widgets.detailedStats.numInterrogatories,
            //                 widgets.detailedStats.numMotionsFiled,
            //                 widgets.detailedStats.numDepositions,
            //                 widgets.detailedStats.keyArbDurationsSettledWithdrawn,
            //                 widgets.detailedStats.caseConclusion,
            //                 widgets.detailedStats.dollarAmountsSettledWithdrawn,
            //               ],
            //               style: "col-md-6"
            //             },
            //           ]
            //         },
            //         dismissed:{
            //           cols:[
            //             {
            //               widgets:[
            //                 widgets.detailedStats.caseType,
            //                 widgets.detailedStats.caseRegionDetailedStats,
            //                 widgets.detailedStats.clmPartyRegion,
            //                 widgets.detailedStats.rspPartyRegion,
            //                 widgets.detailedStats.filedAsMediation,
            //                 widgets.detailedStats.demandType,
            //                 widgets.detailedStats.counterDemandType,
            //                 widgets.detailedStats.rulesUsed,
            //               ],
            //               style: "col-md-6"
            //             },
            //             {
            //               widgets:[
            //                 widgets.detailedStats.arbSelMethod,
            //                 widgets.detailedStats.numArbitrators,
            //                 widgets.detailedStats.casesWith,
            //                 widgets.detailedStats.numInterrogatories,
            //                 widgets.detailedStats.numMotionsFiled,
            //                 widgets.detailedStats.numDepositions,
            //                 widgets.detailedStats.keyArbDurationsDismissed,
            //                 widgets.detailedStats.dismissedReasons,
            //                 widgets.detailedStats.dollarAmountsDismissed,
            //               ],
            //               style: "col-md-6"
            //             },
            //           ]
            //         },
            //         administrative_close:{
            //           cols:[
            //             {
            //               widgets:[
            //                 widgets.detailedStats.caseType,
            //                 widgets.detailedStats.caseRegionDetailedStats,
            //                 widgets.detailedStats.clmPartyRegion,
            //                 widgets.detailedStats.rspPartyRegion,
            //                 widgets.detailedStats.filedAsMediation,
            //                 widgets.detailedStats.demandType,
            //                 widgets.detailedStats.counterDemandType,
            //                 widgets.detailedStats.rulesUsed,
            //               ],
            //               style: "col-md-6"
            //             },
            //             {
            //               widgets:[
            //                 widgets.detailedStats.arbSelMethod,
            //                 widgets.detailedStats.numArbitrators,
            //                 widgets.detailedStats.casesWith,
            //                 widgets.detailedStats.numInterrogatories,
            //                 widgets.detailedStats.numMotionsFiled,
            //                 widgets.detailedStats.numDepositions,
            //                 widgets.detailedStats.keyArbDurationsAdminClosed,
            //                 widgets.detailedStats.caseConclusion,
            //                 widgets.detailedStats.dollarAmountsAdminClose,
            //               ],
            //               style: "col-md-6"
            //             },
            //           ]
            //         },
            //         all:{
            //           cols:[
            //             {
            //               widgets:[
            //                 widgets.detailedStats.caseType,
            //                 widgets.detailedStats.caseRegionDetailedStats,
            //                 widgets.detailedStats.clmPartyRegion,
            //                 widgets.detailedStats.rspPartyRegion,
            //                 widgets.detailedStats.filedAsMediation,
            //                 widgets.detailedStats.demandType,
            //                 widgets.detailedStats.counterDemandType,
            //                 widgets.detailedStats.rulesUsed,
            //               ],
            //               style: "col-md-6"
            //             },
            //             {
            //               widgets:[
            //                 widgets.detailedStats.arbSelMethod,
            //                 widgets.detailedStats.numArbitrators,
            //                 widgets.detailedStats.casesWith,
            //                 widgets.detailedStats.numInterrogatories,
            //                 widgets.detailedStats.numMotionsFiled,
            //                 widgets.detailedStats.numDepositions,
            //                 widgets.detailedStats.keyArbDurations,
            //                 widgets.detailedStats.caseConclusion,
            //                 // widgets.detailedStats.prevailingParty,
            //                 widgets.detailedStats.dollarAmountsArb,
            //                 // widgets.detailedStats.medPartyRegion,
            //                 // widgets.detailedStats.awardedTo,
            //                 // widgets.detailedStats.processType,
            //               ],
            //               style: "col-md-6"
            //             },
            //           ]
            //         },
            //       },
            //       mediation: {
            //         settled_withdrawn:{
            //           cols:[
            //             {
            //               widgets:[
            //                 widgets.detailedStats.caseType,

            //               ],
            //               style: "col-md-6"
            //             },
            //             {
            //               widgets:[
            //                 widgets.detailedStats.caseRegionDetailedStats,
            //                 widgets.detailedStats.filedAsMediation,
            //                 widgets.detailedStats.keyMedDurations,
            //                 widgets.detailedStats.dollarAmountsMed,
            //                 widgets.detailedStats.medCaseConclusion
            //               ],
            //               style: "col-md-6"
            //             },
            //           ]
            //         },
            //         administrative_close:{
            //           cols:[
            //             {
            //               widgets:[
            //                 widgets.detailedStats.caseType,

            //               ],
            //               style: "col-md-6"
            //             },
            //             {
            //               widgets:[
            //                 widgets.detailedStats.caseRegionDetailedStats,
            //                 widgets.detailedStats.filedAsMediation,
            //                 widgets.detailedStats.keyMedDurations,
            //                 widgets.detailedStats.dollarAmountsMed,
            //                 widgets.detailedStats.medCaseConclusion
            //               ],
            //               style: "col-md-6"
            //             },
            //           ]
            //         },
            //         impasse:{
            //           cols:[
            //             {
            //               widgets:[
            //                 widgets.detailedStats.impasseCaseType,

            //               ],
            //               style: "col-md-6"
            //             },
            //             {
            //               widgets:[
            //                 widgets.detailedStats.caseRegionDetailedStats,
            //                 widgets.detailedStats.filedAsMediation,
            //                 widgets.detailedStats.keyMedDurations,
            //                 widgets.detailedStats.dollarAmountsMed,
            //                 widgets.detailedStats.medCaseConclusion
            //               ],
            //               style: "col-md-6"
            //             },
            //           ]
            //         },
            //         unknown:{
            //           cols:[
            //             {
            //               widgets:[
            //                 widgets.detailedStats.caseType,

            //               ],
            //               style: "col-md-6"
            //             },
            //             {
            //               widgets:[
            //                 widgets.detailedStats.caseRegionDetailedStats,
            //                 widgets.detailedStats.filedAsMediation,
            //                 widgets.detailedStats.keyMedDurations,
            //                 widgets.detailedStats.dollarAmountsMed,
            //                 widgets.detailedStats.medCaseConclusion
            //               ],
            //               style: "col-md-6"
            //             },
            //           ]
            //         },
            //         all:{
            //           cols:[
            //             {
            //               widgets:[
            //                 widgets.detailedStats.caseType,

            //               ],
            //               style: "col-md-6"
            //             },
            //             {
            //               widgets:[
            //                 widgets.detailedStats.caseRegionDetailedStats,
            //                 widgets.detailedStats.filedAsMediation,
            //                 widgets.detailedStats.keyMedDurations,
            //                 widgets.detailedStats.dollarAmountsMed,
            //                 widgets.detailedStats.medCaseConclusion
            //               ],
            //               style: "col-md-6"
            //             },
            //           ]
            //         },
            //       },
            //       def: {
            //         cols:[
            //           {
            //             widgets:[
            //               widgets.detailedStats.caseType,
            //               widgets.detailedStats.caseRegionDetailedStats,
            //               widgets.detailedStats.clmPartyRegion,
            //               widgets.detailedStats.rspPartyRegion,
            //               widgets.detailedStats.filedAsMediation,
            //               widgets.detailedStats.demandType,
            //               widgets.detailedStats.counterDemandType,
            //               widgets.detailedStats.rulesUsed,

            //             ],
            //             style: "col-md-6"
            //           },
            //           {
            //             widgets:[
            //               widgets.detailedStats.arbSelMethod,
            //               widgets.detailedStats.numArbitrators,
            //               widgets.detailedStats.casesWith,
            //               widgets.detailedStats.numInterrogatories,
            //               widgets.detailedStats.numMotionsFiled,
            //               widgets.detailedStats.numDepositions,
            //               widgets.detailedStats.keyArbDurations,
            //               widgets.detailedStats.keyMedDurations,
            //               widgets.detailedStats.caseConclusion,
            //               widgets.detailedStats.medCaseConclusion,
            //               // widgets.detailedStats.prevailingParty,
            //               widgets.detailedStats.dollarAmountsMed,
            //               // widgets.detailedStats.medPartyRegion,
            //               // widgets.detailedStats.awardedTo,
            //               // widgets.detailedStats.processType,
            //             ],
            //             style: "col-md-6"
            //           },
            //         ]
            //       }
            //     }
            //   }
            // }
          ]
        };
      }])

      .controller('StatsCtrl', ['$log', '$scope', '$location', '$cookieStore', '$anchorScroll', '$analytics', '$modal', '$timeout', '$window',
                  'analyticsService', 'securityService', 'savedSearchService', 'widgets', 'statsViews', '_', 'moment',
                  'statusDialogService',
        function ($log, $scope, $location, $cookieStore, $anchorScroll, $analytics, $modal, $timeout, $window, analyticsService,
                  securityService, savedSearchService, widgets, statsViews, _, moment, statusDialogService) {

        /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
         Initialization
         ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

        _initViewInfo();

        _initFilters();

        _initOptions();

        $scope.compareMode = false;
        $scope.useSuppression = false;

        /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
         Scope Functions
         ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

        /**
         * Used to pass in an indicator that stats should be restricted to the Contributor's organization only. An
         * additional check is performed server side, but this handles the case where a user has both CONTRIBUTOR and
         * SUBSCRIBER roles but is viewing the application as a CONTRIBUTOR
         * @returns {boolean}
         */
        $scope.isInternalStats = function() {
          return _.includes(['CONTRIBUTOR', 'CONTRIBUTOR_ADMIN'], securityService.getLoggedInUserActiveRole());
        };

        $scope.showOrganizations = _showOrganizations;

        $scope.allOptionsClick = function(filter){
          $scope.filters[filter] = {};
          $scope.viewAllSwitches[filter] = true;
        };

        $scope.individualOptionClick = function(filter){
          $scope.viewAllSwitches[filter] = false;
        };

        $scope.showCurrencyLabel = function(currentView){
          if(currentView.sections && $scope.selectedView.sectionIndex){
            return currentView.sections[$scope.selectedView.sectionIndex].showLabel;
          } else{
            return currentView.showLabel;
          }
        };

        $scope.setSection = function (idx) {
          var currentViewSections = $scope.views[$scope.selectedView.index].sections;
          var totalSections = currentViewSections && currentViewSections.length;

          //handle index of -1 or gt available sections
          if(idx === -1){
            idx = totalSections -1; //fix to 0 index
          } else if(idx === totalSections){
            idx = 0;
          }
          $scope.selectedView.sectionIndex = idx;
        };

        $scope.getOutcomeKey = function (outcome) {
          if(outcome === ""){
            return "all";
          }
          else{
            return outcome.toLowerCase().replace('/', '_').replace(' ', '_');
          }
        };

        $scope.viewSelected = function(item) {
          $scope.selectedView.index = item.parent;
          if(item.isParent) {
            $scope.selectedView.sectionIndex = 0; //set to the first section
          } else {
            $scope.selectedView.sectionIndex = item.value;
          }
        };

        $scope.getSelectedViewTitleForSelectMenu = function(selected){
          //if the selected item is a parent item then display the title as the first child
          var firstChild = (selected.isParent ? _.findWhere($scope.options.viewOptions, {isParent: false, parent: selected.value, value: 0}) : undefined),
              displayItem = (selected.isParent && firstChild ? firstChild : selected),
              parent = !displayItem.isParent && _.find($scope.options.viewOptions, {isParent: true, value: displayItem.parent}),
              parentPart = parent ? parent.title + ' | ' : '';

          return parentPart + displayItem.title;
        };

        $scope.getSectionTitle = function(){
          var parentView = $scope.views[$scope.selectedView.index],
              childView = parentView.sections && parentView.sections[$scope.selectedView.sectionIndex];

          return childView && (parentView.title + ': ' + childView.title);
        };

        $scope.dismissNoDataMsg = function(){
          $scope.showNoDataMsg = false;
          $cookieStore.put('noDataMsgDismissed', true);
        };

        $scope.allowStatusSelection = function() {
          return securityService.isLoggedInAdmin() || securityService.isLoggedInContributorAdmin();
        };

        $scope.allowToggleSuppression = function() {
          return securityService.isLoggedInAdmin();
        };
        $scope.allowViewCaseTotals = function() {
            return securityService.isLoggedInAdmin();
        };
        $scope.showDisclaimerText = function() {
          return securityService.currentUserIsSubscriber();
        };

        $scope.showStatusSelectionModal = function(){
          statusDialogService.setSelectedStatus($scope.filters.status);
          $modal.open({
            animation: true,
            backdrop: 'static',
            windowClass: 'stats-select-status-modal',
            templateUrl: 'assets/javascripts/analytics/partials/selectStatuses.html',
            size: 'large'
          }).result.then(function() {
            $scope.filters.status = statusDialogService.getSelectedStatus();
          });
        };

        $scope.openCompare = function() {
          $scope.compareMode = true;
        };

        $scope.compareFilterMaskLeft = function (filters) {
          return _.omit(filters, 'organizationId');
        };

        $scope.compareFilterMaskRight = function(filters) {
          var compareFilters = angular.copy(filters);
          if (_.has(filters, 'caseRegion') && _.has(filters, 'caseRegion2')) {
            compareFilters.caseRegion = compareFilters.caseRegion2;
          }
          if (_.has(filters, 'caseType') && _.has(filters, 'caseType2')) {
            compareFilters.caseType = compareFilters.caseType2;
          }
          compareFilters.organizationId = '';
          return compareFilters;
        };

        $scope.flattenRows = function() {
          return _.flattenDeep(_.pluck($scope.model.rows, 'widgets'));
        };

        $scope.closeCompareLeft = function() {
          // return filters to non-compare state and then turn off compare mode inside of 0 second timeout block
          // this order of operations solves both #113181879 and #116339863
          $scope.filters.caseRegion = $scope.filters.caseRegion2;
          $scope.filters.caseType = $scope.filters.caseType2;
          $scope.filters.caseRegion2 = '';
          $scope.filters.caseType2 = '';

          $timeout(function() {
            $scope.compareMode = false;
          }, 0);
        };

        $scope.closeCompareRight = function() {
          // return filters to non-compare state and then turn off compare mode inside of 0 second timeout block
          // this order of operations solves both #113181879 and #116339863
          $scope.filters.caseRegion2 = '';
          $scope.filters.caseType2 = '';

          $timeout(function() {
            $scope.compareMode = false;
          }, 0);
        };

        $scope.displayFilters = function() {
          var displayFilters = [];
          if ($scope.views[$scope.selectedView.index].id === 'overview'){
            displayFilters.push('caseRegion');
          }
          // if ($scope.views[$scope.selectedView.index].id === 'detailedStats') {
          //   if($scope.filters.processType === 'ARBITRATION'){
          //     displayFilters.push('caseConclusion');
          //   }
          //   if($scope.filters.processType === 'MEDIATION') {
          //     displayFilters.push('medCaseConclusion');
          //   }
          //   displayFilters.push('processType');
          // }
          if (_showOrganizations()) {
            displayFilters.push('organizations');
          }
          if (!$scope.views[$scope.selectedView.index].hideFilters) {
            displayFilters.push('caseRegion');
            displayFilters.push('caseType');
          }

          return displayFilters;
        };

        $scope.displayFilter = function(f) {
          return _.contains($scope.displayFilters(), f);
        };

        $scope.filterColumnSize = function() {
          var numFilters = $scope.displayFilters().length;
          if(numFilters === 5) {
            return 2;
          } else if (numFilters === 4) {
            return 3;
          } else if (numFilters === 3) {
            return 4;
          } else if (numFilters === 2) {
            return 6;
          }  else {
            return 12;
          }
        };

        $scope.toggleSuppression = function() {
          /*
           * What we want to do here is just update useSuppression and refresh data.  But when we do that,
           * the refreshData fires before Angular is done updating the new useSuppression value throughout
           * the model.  We have to fire the refreshData in a timeout to let the model update finish first.
           */
          $scope.useSuppression = $scope.useSuppression ? false : true;
          $scope.showNoDataMsg = false;
          $timeout(function() {
            $scope.$broadcast('refreshData');
          }, 0, false);
        };

        $scope.suppressionText = function() {
          return 'Suppression: ' + ($scope.useSuppression ? '<strong>On</strong> | Off' : 'On | <strong>Off</strong>');
        };

        $scope.showWidget = function(widget) {
          return (_configShowWidget(widget) && _filterShowWidget(widget));

        };


        /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
         Watches
         ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

        /**
         * Whenever the filters change refresh the data. Because of the way that
         * the controllers are nested. This controller will load before the inner
         * role-specific controller so the initial setting of filters won't cause
         * data to be refreshed
         */
        $scope.$watch(function () {
          return $scope.filters;
        }, function (newVal) {
          if (newVal) {
            $scope.showNoDataMsg = false;
            $scope.$broadcast('refreshData');
          }
        }, true);
        $scope.$watch('filters.processType', function() {
          $scope.filters.medCaseConclusion = '';
          $scope.filters.caseConclusion = '';
        });
        $scope.$watch('selectedView', function () {
          var view;
          if ($scope.selectedView) {
            $scope.showNoDataMsg = false;
            view = $scope.views[$scope.selectedView.index];
            $scope.model = view.model || view.sections[$scope.selectedView.sectionIndex];
            if ($scope.compareMode && !view.allowCompare) {
              $scope.closeCompareRight();
            }
          }

          // reset non-persistent filters
          $scope.filters = _.merge(_calcDefaultFilters(), _.pick($scope.filters, view.persistentFilters), view.defaultFilters);

          // if (view.sections[$scope.selectedView.sectionIndex].title === 'Arbitration: Awarded') {
          //   $scope.filters.caseConclusion = 'Awarded';
          // console.log($scope.filters);
          // }

          if (view.sections) {
            switch (view.sections[$scope.selectedView.sectionIndex].title) {
              case 'Arbitration: All Outcomes':
                $scope.filters.processType = 'ARBITRATION';
                break;
              case 'Arbitration: Awarded':
                $scope.filters.processType = 'ARBITRATION';
                $scope.filters.caseConclusion = 'Awarded';
                break;
              case 'Arbitration: Settled/Withdrawn':
                $scope.filters.processType = 'ARBITRATION';
                $scope.filters.caseConclusion = 'Settled/Withdrawn';
                break;
              case 'Arbitration: Dismissed':
                $scope.filters.processType = 'ARBITRATION';
                $scope.filters.caseConclusion = 'Dismissed';
                break;
              case 'Arbitration: Administrative Close':
                $scope.filters.processType = 'ARBITRATION';
                $scope.filters.caseConclusion = 'Administrative Close';
                break;
              case 'Mediation: All Outcomes':
                $scope.filters.processType = 'MEDIATION';
                break;
              case 'Mediation: Settled/Withdrawn':
                $scope.filters.processType = 'MEDIATION';
                $scope.filters.medCaseConclusion = 'Settled/Withdrawn';
                break;
              case 'Mediation: Administrative Close':
                $scope.filters.processType = 'MEDIATION';
                $scope.filters.medCaseConclusion = 'Administrative Close';
                break;
              case 'Mediation: Impasse':
                $scope.filters.processType = 'MEDIATION';
                $scope.filters.medCaseConclusion = 'Impasse';
                break;
              default:
                break;
            }
          }

          //$log.debug('setting selectedViewDropdown value');
          //$log.debug($scope.selectedView);

          if ($scope.selectedView){

            var selectedViewOption = _.findWhere($scope.options.viewOptions, {
              isParent: false,
              parent: $scope.selectedView.index,
              value: $scope.selectedView.sectionIndex
            }) || _.findWhere($scope.options.viewOptions, {isParent: true, value: $scope.selectedView.index});

            $scope.selectedViewDropdown = {value: selectedViewOption};
          }

          _updateLocationToMatchCurrentView();

          $anchorScroll(); //scroll to top of page

         }, true);

         $scope.$on('$routeUpdate', function (){
           $scope.showNoDataMsg = false;
           _initViewInfo();
           _initOptions();
         });


        $scope.$on('noData', function(){
          if(!$cookieStore.get('noDataMsgDismissed')) {
            $scope.showNoDataMsg = true;
          }
        });


        /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
         Private Functions
         ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

        function _initViewInfo() {
          var requestedLayout = $location.search().layout;
          $scope.layout = requestedLayout ? requestedLayout : (securityService.currentUserIsSubscriber() ? 'dr' : 'dc');


          //initialize the views and corresponding models
          //todo: this is a quick and dirty filter to remove the raw data view from subscriber, probably want something more robust long-term
          $scope.views = _.reject(statsViews.all, function (view) {
            return (view.id === 'developingStats' && !securityService.isLoggedInAdmin()) ||  (view.id === 'raw' && !securityService.isLoggedInAdmin());
          });

          //initialize the selectedView indices
          var requestedViewIndex = _.findIndex($scope.views, {id: $location.search().view}); //1-based
          var requestedSection = $location.search().section;
          $scope.selectedView = {
            index: requestedViewIndex !== -1 ? requestedViewIndex : 0,
            sectionIndex: requestedSection ? requestedSection -1 : 0
          };

          _updateLocationToMatchCurrentView();
        }
        function _configShowWidget(widget) {
            return !_.includes($window.Conf.envConfig.metricsOmittedWidgets.split(','), widget.id);
        }
        function _filterShowWidget(widget) {
          var showWidget = true;
          if($scope.filters.processType){
            showWidget = widget.config.implicitProcessType ? widget.config.implicitProcessType === $scope.filters.processType : true;
          }
          if($scope.filters.processType){
            showWidget = widget.config.implicitConclusions ? _.includes(widget.config.implicitConclusions, $scope.filters.caseConclusion) : true;
          }
          return showWidget;
        }

        function _updateLocationToMatchCurrentView(){
          var view = $scope.views[$scope.selectedView.index];
          var viewParams = {
            layout: $scope.layout,
            view: view.id
          };
          if(view.sections){
            viewParams.section = $scope.selectedView.sectionIndex + 1;
          }

          //make sure the url matches the current view
          $location.search(viewParams);

          //explicit call to $analytics service to track the updated url
          $analytics.pageTrack($location.url());

        }

        function _initOptions() {
          var getViewsOptions = function() {
            return _.flatten(_.map($scope.views, function(v){
              if(v.adminOnly && !securityService.isLoggedInAdmin()){
                return;
              }
              else{
                  var parent = [{title: v.title, value: $scope.views.indexOf(v), isParent: true, parent: $scope.views.indexOf(v), class: 'hierarchy1'}];
                  if (v.sections){
                      parent = parent.concat(_.map(v.sections, function(section){
                          return {title: section.title, value: v.sections.indexOf(section), isParent: false, parent: $scope.views.indexOf(v), class: 'hierarchy2'};
                      }));
                  }
                  return parent;
              }
            }));
          };

          var allYears = _(_.range(2005, moment().year() + 1))
              .reverse()
              .map(function (x) {
                return {label: x.toString(), value: x.toString()};
              })
              .value();

          $scope.options = {
            views: analyticsService.buildOptions('processTypes', true),
            organizations: [],
            numArbitrators: analyticsService.buildOptions('numArbitrators', false, 'All'),
            caseType: [],
            caseRegion: analyticsService.buildOptions('regions', false, undefined, ['Oceania']), //per https://www.pivotaltracker.com/story/show/156454983 remove oceania
            caseRegionsWithSuperRegions: _buildCaseRegionSuperRegionOptions(),
            caseRegionWithAll: _buildAllRegionsList(),
            processType: (analyticsService.buildOptions('processTypes',true)),
            caseConclusion: analyticsService.buildOptions('arbitrationOutcomes', undefined, 'All Arbitration Outcomes', undefined),
            mediationCaseConclusions: analyticsService.buildOptions('mediationCaseConclusions', undefined, 'All Mediation Outcomes'),
            yearsWithMin: [{label: 'Min', value: ''}].concat(allYears),
            yearsWithMax: [{label: 'Max', value: ''}].concat(allYears),
            viewOptions: getViewsOptions(),
            caseStatuses: _(analyticsService.buildOptions('caseStatuses', true))
                .reject({value: 'CANCELLED'}) //don't allow selection of 'CANCELLED'
                .map(function(o){ //fix key for 'REVIEW'
                  if(o.value === 'IN REVIEW'){
                    o.value = 'REVIEW';
                  }

                  return o;
                }
              ).value()
          };

          if (_showOrganizations()) {
            analyticsService.getOrganizations().then(function (organizations) {
              $scope.options.organizations = [{label: 'All Organizations', value: ''}].concat(_.map(organizations, function (x) {
                return {label: x.name.toString(), value: x.id.toString()};
              }));
            });
          }
          analyticsService.getTopTenCaseTypesOptions($scope.isInternalStats, $scope.useSuppression)
              .then(function (topTenCaseTypes) {
                $scope.options.caseType = analyticsService.buildOptions(undefined, false, 'All Case Types', undefined, topTenCaseTypes);
              });

        }

        function _initFilters() {
          $scope.filters = _calcDefaultFilters();
        }

        function _calcDefaultFilters(){
          // Reset "View All" switches on detailed stats page; if those filters were ever to be made persistent,
          // then we would need to be smarter about how we do this.  But this is fine for now.
          $scope.viewAllSwitches = {
            medPartyRegion: true,
            clmPartyRegion: true,
            rspPartyRegion: true,
            caseConclusion: true,
            medCaseConclusion: true
          };

          return _.assign({caseConclusion: 'All'} ,{organizationName: 'All'}, {caseRegion2: ''}, {caseType2: ''}, {processType: 'ARBITRATION'},
            _.cloneDeep(savedSearchService.filterTemplate), _calcImplicitFilters());
        }

        function _calcImplicitFilters() {
          var view = $scope.views[$scope.selectedView.index];
          return view && view.implicitFilters;
        }

        //build case options with super regions - no authorization
        function _buildCaseRegionSuperRegionOptions(){
            var regions = [ { label: 'All Case Regions', value: '' } ];
            regions = regions.concat(analyticsService.buildOptions('regions',undefined, undefined, ['Oceania'])); //https://www.pivotaltracker.com/story/show/156454983 - remove oceania
            regions = regions.concat(analyticsService.buildOptions('superRegions'));
            return regions;
        }

        function _buildAllRegionsList() {

          // the list of regions shown to the user depends on the user's role:
          // - Subscribers get only super-regions
          // - Contributors get only regions
          // - Admins get both super-regions and regions
          var regions = [ { label: 'All Case Regions', value: '' } ];
          var superRegionsEnabled = window.Conf.envConfig.superRegionsEnabled; //Conf set as a global in index.scala.html
          if((securityService.isLoggedInSubscriber() && superRegionsEnabled === 'true') || securityService.isLoggedInAdmin()) {
            regions = regions.concat(analyticsService.buildOptions('superRegions'));
          }
          if(securityService.isLoggedInContributor() || securityService.isLoggedInAdmin() || securityService.isLoggedInSubscriber() || securityService.isLoggedInContributorAdmin()) {
            regions = regions.concat(analyticsService.buildOptions('regions',undefined, undefined, ['Oceania'])); //https://www.pivotaltracker.com/story/show/156454983 - remove oceania
          }
          return regions;
        }

        function _showOrganizations() {
          return securityService.getLoggedInUserActiveRole() === 'ADMIN';
        }
      }])

      .controller('SaveMetricCtrl', ['$scope', '$location', '_', 'moment', 'widgets', 'util', 'savedSearchService', 'organizationService', function($scope, $location, _, moment, widgets, util, savedSearchService, organizationService) {
        $scope.showHeader = false;
        $scope.displayConfig = {};
        $scope.displayConfig2 = {};
        $scope.displayConfig.hideDownloadButton = true;
        $scope.displayConfig2.hideDownloadButton = true;
        $scope.internal = util.getOrElse($location.search().internal, "false");

        var widgetType = $location.search().widgetType;
        var widgetId = $location.search().widgetId;
        var organization = $location.search().organization;
        $scope.compareMode = $location.search().isCompareMode;
        $scope.detailedStatsHeaders = $location.search().isDetailedStats;
        $scope.showSS = $location.search().showSS;
        $scope.isRightSide = $location.search().isRightSide;
        $scope.widget = _.findWhere(_.flatten(_.map(widgets, function(v) {return _.map(v);})), {"id":widgetId,"type":widgetType});

        var queryStringFilters = _.pick($location.search(), ['caseType', 'caseRegion', 'status', 'processType', 'minDemandYear', 'maxDemandYear', 'minDemandAmt', 'maxDemandAmt', 'clmPartyRegion', 'rspPartyRegion', 'numArbitrators', 'caseConclusion', 'medCaseConclusion', 'minMedReqFileYear', 'maxMedReqFileYear', 'medPartyRegion', 'claimantAwardToClaimQuartile', 'counterclaimantAwardToCounterclaimRatio']);
        $scope.filters = _.merge(_.cloneDeep(savedSearchService.filterTemplate), queryStringFilters);
        var queryStringFilters2 = _convertComparativeFilters(_.pick($location.search(), ['caseType2', 'caseRegion2', 'status']));
        $scope.filters2 = _.merge(_.cloneDeep(savedSearchService.filterTemplate), queryStringFilters2);
        if ($scope.widget.config.implicitFilters) {
          _.assign($scope.filters, $scope.widget.config.implicitFilters);
          _.assign($scope.filters2, $scope.widget.config.implicitFilters);
        }
        if (_isId(organization)) {
          _.assign($scope.filters, {organizationId: organization});
          _.assign($scope.filters2, {organizationId: organization});
        }

        $scope.displayConfig.caseTypeFilterText = util.getOrElse($scope.filters.caseType, "All Case Types");
        $scope.displayConfig2.caseTypeFilterText = util.getOrElse($scope.filters2.caseType, "All Case Types");
        $scope.displayConfig.caseTypesLabel = ($scope.displayConfig.caseTypeFilterText !== 'All Case Types' ? ' cases' : '');
        $scope.displayConfig2.caseTypesLabel = ($scope.displayConfig2.caseTypeFilterText !== 'All Case Types' ? ' cases' : '');
        $scope.displayConfig.caseRegionFilterText = util.getOrElse($scope.filters.caseRegion, "All Case Regions");
        $scope.displayConfig2.caseRegionFilterText = util.getOrElse($scope.filters2.caseRegion, "All Case Regions");
        $scope.displayConfig.caseConclusionText = util.getOrElse($scope.filters.caseConclusion, "All");
        $scope.displayConfig.numArbitratorsText = util.getOrElse($scope.filters.numArbitrators, "All");
        $scope.displayConfig.minDemandYearText = util.getOrElse($scope.filters.minDemandYear, "2005");
        $scope.displayConfig.maxDemandYearText = util.getOrElse($scope.filters.maxDemandYear, moment().format('YYYY'));
        $scope.displayConfig.minDemandAmtText = util.getOrElse($scope.filters.minDemandAmt, "0");
        $scope.displayConfig.maxDemandAmtText = util.getOrElse($scope.filters.maxDemandAmt, "max");
        $scope.displayConfig.clmPartyRegionText = util.getOrElse($scope.filters.clmPartyRegion, "All Regions");
        $scope.displayConfig.rspPartyRegionText = util.getOrElse($scope.filters.rspPartyRegion, "All Regions");

        if (_isId(organization)) {
          organizationService.getOrganization(organization).then(function (org) {
            $scope.displayConfig.organization = org.name;
          });
        } else if (organization === 'All') {
          $scope.displayConfig.organization = "All Organizations";
        }

        var now = new Date();
        var utcOffset = parseInt(util.getOrElse($location.search().utcOffset, '0')); // utcOffset is the number of minutes ahead or behind UTC time, for example -300 would be ET (-5 hours)
        $scope.displayConfig.date = moment(now).utcOffset(utcOffset).format('D MMM YYYY');
        $scope.displayConfig2.date = moment(now).utcOffset(utcOffset).format('D MMM YYYY');
        $scope.displayConfig.date2 = moment(now).utcOffset(utcOffset).format('h:mm A [&middot;] [<span class="light">]UTC Z[</span>]');
        $scope.displayConfig2.date2 = moment(now).utcOffset(utcOffset).format('h:mm A [&middot;] [<span class="light">]UTC Z[</span>]');

        var suppressionParam = $location.search().suppressionEnabled;
        if (!_.isEmpty(suppressionParam)) {
          $scope.suppressionEnabled = util.stringToBool($location.search().suppressionEnabled);
          $scope.displayConfig.suppression = ($scope.suppressionEnabled ? 'ON' : 'OFF');
          $scope.displayConfig2.suppression = ($scope.suppressionEnabled ? 'ON' : 'OFF');
        }

        $scope.displayConfig.footerText = "Dispute Resolution Data is dynamic, aggregated, and updated as new data is input. Please note the specific date and time above when this image was saved.";
        if ('alt' === $location.search().footer) {
          $scope.displayConfig.footerText = "Dispute Resolution Data is aggregated and specific to your organization. It is updated as you provide additional data. Please note the specific date and time above when this image was saved.";
        }

        function _isId(s) {
          return (s && s !== 'All');
        }

        function _convertComparativeFilters(filters){
          var newFilters = _.cloneDeep(filters);
          if (_.has(filters, 'caseType2')) {
            newFilters = _.assign({'caseType': filters.caseType2}, newFilters);
          }
          if (_.has(filters, 'caseRegion2')) {
            newFilters = _.assign({'caseRegion': filters.caseRegion2}, newFilters);
          }
          return newFilters;
        }
      }])

      .controller('WidgetHeaderCtrl', ['$scope', '$window', 'securityService', 'util', '_', function($scope, $window, securityService, util, _) {
        $scope.showDownloadButton = util.stringToBool($window.Conf.envConfig.saveMetricEnabled) &&
                $scope.widget.config.allowDownload &&
                !$scope.widget.isPublic &&
                (!$scope.displayConfig || !$scope.displayConfig.hideDownloadButton) &&
                ($scope.pageId === "detailedStats" ? securityService.isLoggedInAdmin(): true);
        $scope.downloadUrl = _generateDownloadUrl();
        $scope.filename = 'drd-'+$scope.widget.id+'-'+$scope.widget.type;
        $scope.token = securityService.getAuthToken();

        $scope.allowViewSignalStrength = function(){
          return securityService.isLoggedInAdmin();
        };

        $scope.$watch('filters', function (newVal) {
          if (newVal) {
            $scope.downloadUrl = _generateDownloadUrl();
          }
        }, true);

        $scope.$watch('suppression', function() {
          $scope.downloadUrl = _generateDownloadUrl();
        });

        $scope.applyFill = function(bar) {
          return $scope.signalStrength >= bar ? 'filled' : 'not-filled';
        };

        function _convertObjectToArray(value) {
          if (!_.isObject(value) || _.isArray(value)) {
            return value;
          } else {
            return _(value).pick(_.identity).keys().value();
          }
        }

        function _generateDownloadUrl() {
          var mappedFilters = _.mapValues($scope.filters, _convertObjectToArray);
          var filters = (!$scope.compareMode && $scope.filterMask && _.isFunction($scope.filterMask) ? $scope.filterMask(mappedFilters) : mappedFilters);
          var downloadUrl = '/api/metrics/save?widgetType='+util.encodeUri($scope.widget.type) +
                  '&widgetId=' + util.encodeUri($scope.widget.id) +
                  '&utcOffset=' + util.encodeUri((new Date().getTimezoneOffset()*-1));

          if (filters.caseRegion) {
            downloadUrl += '&caseRegion='+util.encodeUri(filters.caseRegion);
          }
          if (filters.caseType) {
            downloadUrl += '&caseType='+util.encodeUri(filters.caseType);
          }
          if (filters.organizationId) {
            downloadUrl += '&organization='+util.encodeUri(filters.organizationId);
          } else if (securityService.isLoggedInContributorAdmin() && securityService.getLoggedInUserInfo().orgId) {
            downloadUrl += '&organization='+util.encodeUri(securityService.getLoggedInUserInfo().orgId);
          } else if (securityService.isLoggedInAdmin()) {
            downloadUrl += '&organization=All';
          }
          if (filters.processType){
            downloadUrl += '&processType='+util.encodeUri(filters.processType);
          }
          if(filters.minDemandYear){
            downloadUrl += '&minDemandYear='+util.encodeUri(filters.minDemandYear);
          }
          if(filters.maxDemandYear){
            downloadUrl += '&maxDemandYear='+util.encodeUri(filters.maxDemandYear);
          }
          if(filters.minDemandAmt){
            downloadUrl += '&minDemandAmt='+util.encodeUri(filters.minDemandAmt);
          }
          if(filters.maxDemandAmt){
            downloadUrl += '&maxDemandAmt='+util.encodeUri(filters.maxDemandAmt);
          }
          if(filters.clmPartyRegion && !_.isEmpty(filters.clmPartyRegion)){
            downloadUrl += '&clmPartyRegion='+util.encodeUri(filters.clmPartyRegion);
          }
          if(filters.rspPartyRegion && !_.isEmpty(filters.rspPartyRegion)){
            downloadUrl += '&rspPartyRegion='+util.encodeUri(filters.rspPartyRegion);
          }
          if(filters.numArbitrators){
            downloadUrl += '&numArbitrators='+util.encodeUri(filters.numArbitrators);
          }
          if(filters.caseConclusion && !_.isEmpty(filters.caseConclusion)){
            downloadUrl += '&caseConclusion='+util.encodeUri(filters.caseConclusion);
          }
          if(filters.medCaseConclusion && !_.isEmpty(filters.medCaseConclusion)){
            downloadUrl += '&medCaseConclusion='+util.encodeUri(filters.medCaseConclusion);
          }
          if(filters.minMedReqFileYear){
            downloadUrl += '&minMedReqFileYear='+util.encodeUri(filters.minMedReqFileYear);
          }
          if(filters.maxMedReqFileYear){
            downloadUrl += '&maxMedReqFileYear='+util.encodeUri(filters.maxMedReqFileYear);
          }
          if(filters.medPartyRegion && !_.isEmpty(filters.medPartyRegion)){
            downloadUrl += '&medPartyRegion='+util.encodeUri(filters.medPartyRegion);
          }
          if (filters.status) {
            downloadUrl += '&status='+util.encodeUri(filters.status);
          }
          if (securityService.hasPermission("metric", "toggleSuppression")) {
            downloadUrl += '&suppressionEnabled='+util.encodeUri($scope.suppression);
          }
          if (securityService.hasPermission("metric", "showAltSaveAndDownloadFooter")) {
            downloadUrl += '&footer=alt';
          }
          if ($scope.widget.config.downloadWidth > 0) {
            downloadUrl += '&width='+$scope.widget.config.downloadWidth;
          }
          if (filters.caseRegion2){
            downloadUrl += '&caseRegion2='+util.encodeUri(filters.caseRegion2);
          }
          if (filters.caseType2){
            downloadUrl += '&caseType2='+util.encodeUri(filters.caseType2);
          }
          if($scope.pageId === "detailedStats"){
            downloadUrl += '&isDetailedStats=true';
          }
          downloadUrl += '&internal=' + $scope.internal;
          return downloadUrl;
        }
      }])

      .controller('StatusDlgCtrl', ['$scope', '$log', '_', 'statusDialogService',
          function($scope, $log, _, statusDialogService) {

        var allStatusesSelected = {
          DRAFT: true,
          FLAGGED: true,
          ACTIVE: true,
          REVIEW: true,
          STAGED: true,
          INACTIVE: true
        };
        var noStatusesSelected = {
          DRAFT: false,
          FLAGGED: false,
          ACTIVE: false,
          REVIEW: false,
          STAGED: false,
          INACTIVE: false
        };

        var statusList = statusDialogService.getSelectedStatus();
        $scope.statusObj = _.cloneDeep(noStatusesSelected);
        _.forEach(statusList, function(s) {
          $scope.statusObj[s] = true;
        });
        $scope.allSelected = (statusList.length === 6);
        $scope.hasError = false;

        $scope.doSelectAll = function() {
          $scope.hasError = false;
          if($scope.allSelected) {
            $scope.statusObj = _.cloneDeep(allStatusesSelected);
          } else {
            $scope.statusObj = _.cloneDeep(noStatusesSelected);
          }
        };

        $scope.itemSelected = function() {
          $scope.hasError = false;
          $scope.allSelected = (_getStatusList().length === 6);
        };

        $scope.applyStatusFilter = function() {
          var statusList = _getStatusList();
          if(statusList.length === 0) {
            $scope.hasError = true;
          } else {
            // apply the status filter here
            statusDialogService.setSelectedStatus(statusList);
            $scope.$close();
          }
        };

        function _getStatusList() {
          return _($scope.statusObj).pick(_.identity).keys().value();
        }
      }])

      .controller('DashboardStatsCtrl', ['$scope', 'widgets', function($scope, widgets){
        $scope.filters = {
          status: ['ACTIVE']
        };
        $scope.model = {
          rows: [
//            {widgets: [widgets.maps.caseRegion]},
            {widgets: [widgets.donuts.caseTypes]}
          ]
        };
      }])

      .controller('PublicCaseTypesCtrl', ['$scope', 'widgets', '_', function($scope, widgets, _){
        var defaultConfig = _.cloneDeep(widgets.donuts.caseTypes.config);
        $scope.filters = {
          status: ['ACTIVE']
        };
        $scope.model = {
          rows: [
            {widgets: [_.merge(_.cloneDeep(widgets.donuts.caseTypes), {isPublic: true, config: _.merge(defaultConfig, {leftCol:8, rightCol: 12, showDisclaimer: true, title: defaultConfig.title + ' - Fictional Demonstration Data<sup>*</sup>'})})]}
          ]
        };
      }])

      .controller('PublicRegionMapCtrl', ['$scope', 'widgets', '_', function($scope, widgets, _){
        var defaultConfig = _.cloneDeep(widgets.maps.caseRegion.config);
        $scope.model = {
          rows: [
            {widgets: [_.merge(_.cloneDeep(widgets.maps.caseRegion), {isPublic: true, config: _.merge(defaultConfig, {showDisclaimer: true, title: defaultConfig.title + ' - Fictional Demonstration Data<sup>*</sup>'})})]}
          ]
        };
      }])

      .controller('AdminAnalyticsCtrl', ['$scope', 'analyticsService', 'standardOptions', '_',
          function ($scope, analyticsService, standardOptions, _) {

        //hopefully will result in scope.filters.processType being arb or med
        $scope.implicitArbFilters = ['sumJudgementMotionMade','nationalCourtsInvolved','hearingOccurred','counterFiled','counterPrevailed',
        'awardCostsGranted','amicusCuriaeBriefIncluded','emerArbUsed','intervenerMotionMade','intervenerMotionGranted','ediscoveryUsed',
        'normDemandAmt', 'normCounterDemandAmt', 'normCounterAwardAmt', 'normClaimantAwardAmt', 'normRespondentAwardAmt', 'normTotalInstFeeAmt',
        'normTotalArbMedFeeAmt', 'normAwardCostsAmt', 'arbHearingDays', 'arbTotalDays', 'arbSettledDays', 'arbWithdrawnDays', 'arbAwardedDays',
        'numArbitrators', 'caseConclusion', 'arbSelMethod', 'numMotionsFiled', 'clmPartyRegion', 'rspPartyRegion', 'hearingLocation', 'demandType',
        'counterDemandType', 'prevailingParty', 'awardedTo', 'rulesUsed', 'numDepositions', 'numInterrogatories', 'claimantAwardToClaimQuartile',
        'counterclaimantAwardToCounterclaimQuartile'
        ];

        $scope.implicitMedFilters = ['normInstFeeAmt', 'normMediatorFeeAmt', 'medTotalDays', 'medConferenceDays', 'medPartyRegion'];

        _refreshData();
        $scope.$on('refreshData', _refreshData);

        function _refreshData() {
          _refreshBreakdowns();
          _refreshBooleans();
          _refreshAverageCurrencies();
          _refreshDurations();
        }

        function _refreshBreakdowns() {
          $scope.breakdowns = [
            {key: 'caseType', title: 'Case Types', expectedValues: standardOptions.caseTypes},
            {key: 'caseRegion', title: 'Regions Where Cases Filed', expectedValues: standardOptions.regions},
            {key: 'clmPartyRegion', title: 'Claimant Party Regions', expectedValues: standardOptions.regions},
            {key: 'rspPartyRegion', title: 'Respondent Party Regions', expectedValues: standardOptions.regions},
            {key: 'filedAsMediation', title: 'Case Commenced As', expextedValues: standardOptions.processTypes},
            {key: 'demandType', title: 'Nature of Claim', expectedValues: standardOptions.demandTypes},
            {key: 'counterDemandType', title: 'Nature of Counterclaim', expectedValues: standardOptions.demandTypes},
            {key: 'rulesUsed', title: 'Rules Used', expectedValues: standardOptions.rulesUsed},
            {key: 'numArbitrators', title: 'Number of Arbitrators', expectedValues: standardOptions.numArbitrators},
            {key: 'processType', title: 'Mediation vs. Arbitration', expectedValues: ['ARBITRATION', 'MEDIATION'], displayMap: {'ARBITRATION': 'Arbitration', 'MEDIATION': 'Mediation'}},
            {key: 'medPartyRegion', title: 'Mediation Party Regions', expectedValues: standardOptions.regions},
            {key: 'caseConclusion', title: 'Arbitration Outcomes', expectedValues: standardOptions.caseConclusions},
            {key: 'arbSelMethod', title: 'Arbitrator Selection Methods', expectedValues: standardOptions.arbitrationSelectionMethods},
            {key: 'numMotionsFiled', title: 'Number of Motions Filed', expectedValues: standardOptions.numMotionsFiled},
//            {key: 'hearingLocation', title: 'Location of Hearing', expectedValues: standardOptions.hearingLocations},
            {key: 'prevailingParty', title: 'Prevailing Party', expectedValues: standardOptions.prevailingParties},
            {key: 'awardedTo', title: 'Amounts Awarded To', expectedVaues: standardOptions.awardParties},
            {key: 'numDepositions', title: 'Number of Depositions', expectedValues: standardOptions.numDepositions},
            {key: 'numInterrogatories', title: 'Number of Interrogatories', expectedValues: standardOptions.numInterrogatories}
          ];


          $scope.firstBreakdowns  = $scope.breakdowns.slice(0, 10);
          $scope.secondBreakdowns = $scope.breakdowns.slice(10, $scope.breakdowns.length);

          _.forEach($scope.breakdowns, function (breakdown, index) {

            var additionalFilters = {};
            //if the item.key is in implicitArbFilters then send a filter of processType = 'Arbitration'
            if(_.includes($scope.implicitArbFilters, breakdown.key) && !$scope.filters.processType){
              additionalFilters = {processType:'ARBITRATION'};
            }
            //if the item.key is in implicitMedFilters then send a filter of processType = 'Mediation'
            else if(_.includes($scope.implicitMedFilters, breakdown.key) && !$scope.filters.processType){
              additionalFilters = {processType:'MEDIATION'};
            }
            analyticsService.buildMetric(_.assign(_.mapValues($scope.filters, _convertObjectToArray), additionalFilters), [breakdown.key], undefined,
                breakdown.title, undefined, $scope.isInternalStats(), $scope.useSuppression.value).then(function (metric) {
              $scope.breakdowns[index].data = _buildBreakdownData(breakdown, metric);
            });
          });
        }

        function _buildBreakdownData(breakdown, metric) {
          var data;

          if (!breakdown.expectedValues || _.size(metric.elements) === 0 || !metric.elements[0].groups) {
            data = metric;
          } else {
            var groups = _.union(breakdown.expectedValues, _.map(metric.elements, function (e) {
              return e.groups[0];
            }));
            data = {
              elements: _.map(groups, function (group) {
                var metricElement = _.find(metric.elements, function (e) {
                  return e.groups && e.groups[0] === group;
                });
                var groupDisplay = (breakdown.displayMap && breakdown.displayMap[group] ? breakdown.displayMap[group] : group);
                return {groups: [groupDisplay], percentage: metricElement ? metricElement.percentage : 0};
              })
            };
          }

          return data;
        }

        function _refreshBooleans() {
          $scope.booleans = [//title starts with "% of Cases "
            {key: 'sumJudgementMotionMade', title: 'Summary Judgment Motions Filed'},
            {key: 'hearingOccurred', title: 'Merits Hearing'},
            {key: 'counterFiled', title: 'Counterclaim Filed'},
            {key: 'counterPrevailed', title: 'Counterclaim Prevailing'},
            {key: 'awardCostsGranted', title: 'Legal Costs Recovered'},
            {key: 'amicusCuriaeBriefIncluded', title: 'Third Party Amicus Curiae Brief'},
            {key: 'emerArbUsed', title: 'Request for appointment of emergency arbitrator'},
            {key: 'intervenerMotionMade', title: 'Intervener Motion Made'},
            {key: 'intervenerMotionGranted', title: '3rd Party Interveners Motion Granted'},
            {key: 'ediscoveryUsed', title: 'E-Discovery Used'},
            {key: 'nationalCourtsInvolved', title: 'National Courts Involved'}

          ];

          _.forEach($scope.booleans, function (item, index) {
            var additionalFilters = {};
            //if the item.key is in implicitArbFilters then send a filter of processType = 'Arbitration'
            if(_.includes($scope.implicitArbFilters, item.key) && !$scope.filters.processType){
              additionalFilters = {processType:'ARBITRATION'};
            }
            //if the item.key is in implicitMedFilters then send a filter of processType = 'Mediation'
            else if(_.includes($scope.implicitMedFilters, item.key) && !$scope.filters.processType){
              additionalFilters = {processType:'MEDIATION'};
            }
            analyticsService.buildMetric(_.assign(_.mapValues($scope.filters, _convertObjectToArray),additionalFilters), [item.key], undefined,
                item.title, undefined, $scope.isInternalStats(), $scope.useSuppression.value).then(function (metric) {
              $scope.booleans[index].data = metric;
            });
          });
        }

        function _refreshAverageCurrencies() {
          $scope.averageCurrencies = [
            {key: 'normInstFeeAmt', title: 'Average Institutional Fees'},
            {key: 'normMediatorFeeAmt', title: 'Average Mediator Fees'},
            {key: 'normDemandAmt', title: 'Average Claim'},
            {key: 'normClaimantAwardAmt', title: 'Average Claimant Award'},
            {key: 'normRespondentAwardAmt', title: 'Average Respondent Award'},
            {key: 'normCounterDemandAmt', title: 'Average Counterclaim'},
            {key: 'normCounterAwardAmt', title: 'Average Counterclaim Award'},
            {key: 'normTotalInstFeeAmt', title: 'Average Total Institution Fees'},
            {key: 'normTotalArbMedFeeAmt', title: 'Average Total Arbitrator/Mediator Fees'},
            {key: 'normAwardCostsAmt', title: 'Legal Costs Recovered'}
          ];

          _.forEach($scope.averageCurrencies, function (item, index) {
            var additionalFilters = {};
            //if the item.key is in implicitArbFilters then send a filter of processType = 'Arbitration'
            if(_.includes($scope.implicitArbFilters, item.key) && !$scope.filters.processType){
              additionalFilters = {processType:'ARBITRATION'};
            }
            //if the item.key is in implicitMedFilters then send a filter of processType = 'Mediation'
            else if(_.includes($scope.implicitMedFilters, item.key) && !$scope.filters.processType){
              additionalFilters = {processType:'MEDIATION'};
            }
            analyticsService.buildMetric(_.assign(_.mapValues($scope.filters, _convertObjectToArray), additionalFilters), undefined, item.key,
                item.title, 'analyticsCurrency', $scope.isInternalStats(), $scope.useSuppression.value).then(function (metric) {
              $scope.averageCurrencies[index].data = metric;
            });
          });
        }

        function _refreshDurations() {
          $scope.medDurations = [
            {key: 'medTotalDays', title: 'Duration of Mediation'},
            // {key: 'realMedConfDays', title: 'Duration of Mediation Conference'}
          ];
          $scope.arbDurations = [
            // {key: 'realArbHearingDays', title: 'Duration of Arbitration Hearing'},
            {key: 'arbTotalDays', title: 'Duration of Arbitration'},
            {key: 'arbSettledDays', title: 'Duration to Settlement'},
            {key: 'arbWithdrawnDays', title: 'Duration to Withdrawn'},
            {key: 'arbHearingToAwardDays', title: 'End of Merits Hearing to Award'}
          ];

          _.forEach($scope.medDurations, function (item, index) {
            var additionalFilters = {};
            //if the item.key is in implicitArbFilters then send a filter of processType = 'Arbitration'
            if(_.includes($scope.implicitArbFilters, item.key) && !$scope.filters.processType){
              additionalFilters = {processType:'ARBITRATION'};
            }
            //if the item.key is in implicitMedFilters then send a filter of processType = 'Mediation'
            else if(_.includes($scope.implicitMedFilters, item.key) && !$scope.filters.processType){
              additionalFilters = {processType:'MEDIATION'};
            }
            analyticsService.buildMetric(_.assign(_.mapValues($scope.filters, _convertObjectToArray), additionalFilters), undefined, item.key,
                item.title, undefined, $scope.isInternalStats(), $scope.useSuppression.value).then(function (metric) {
              $scope.medDurations[index].data = metric;
            });
          });
          _.forEach($scope.arbDurations, function (item, index) {
            var additionalFilters = {};
            //if the item.key is in implicitArbFilters then send a filter of processType = 'Arbitration'
            if(_.includes($scope.implicitArbFilters, item.key) && !$scope.filters.processType){
              additionalFilters = {processType:'ARBITRATION'};
            }
            //if the item.key is in implicitMedFilters then send a filter of processType = 'Mediation'
            else if(_.includes($scope.implicitMedFilters, item.key) && !$scope.filters.processType){
              additionalFilters = {processType:'MEDIATION'};
            }
            analyticsService.buildMetric(_.assign(_.mapValues($scope.filters, _convertObjectToArray), additionalFilters), undefined, item.key,
                item.title, undefined, $scope.isInternalStats(), $scope.useSuppression.value).then(function (metric) {
              $scope.arbDurations[index].data = metric;
            });
          });
        }

        function _convertObjectToArray(value) {
          if (!_.isObject(value) || _.isArray(value)) {
            return value;
          } else {
            return _(value).pick(_.identity).keys().value();
          }
        }
      }])

      .controller('AnalyticsCtrl', ['$scope', '$location', '$route', 'analyticsService', 'securityService', 'standardOptions', '_', 'moment', '$modal', 'savedSearchService', '$filter', '$anchorScroll',
        function ($scope, $location, $route, analyticsService, securityService, standardOptions, _, moment, $modal, savedSearchService, $filter, $anchorScroll) {

          /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
           Initialization
           ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
          $scope.currentTab = 'Process';
          $scope.viewAllSwitches = {
            partyRegion: true,
            claimantRegion: true,
            respondentRegion: true,
            caseConclusion: true
          };
          $scope.tabs = ['Process', 'Time', 'Money', 'Place'];

          $scope.orgSelected = function(item){
            $scope.filters.organizationName = item.label;
          };

          $scope.showOrganizations = function(){
            var userInfo = securityService.getLoggedInUserInfo();
            var roles = userInfo.roles;
            return _.includes(roles, 'ADMIN');
          };

          _initLayout();
          _initOptions();
          _loadFilters($location.search().id);
          if($scope.filters){
            $scope.filters.organizationName = 'All';
          }

          /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
           Scope Functions
           ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
          $scope.showTabs = function(){
            var layout = $scope.layoutSrc;
            return _.includes(layout, 'subscriber') || _.includes(layout, 'contributor');
          };

          $scope.changeTab = function(tab){
            $scope.currentTab = tab;
            $scope.$broadcast('refreshData');
            $anchorScroll();
          };

          $scope.loadDeleteSearchDialog = function(size){
            savedSearchService.setSavedSearchModel($scope.savedSearch);
            savedSearchService.setFilters($scope.savedSearch.filters); //use savedSearchFilters.
            //open new dialog window for user to save filters
            $modal.open({
              animation: true,
              backdrop: 'static',
              windowClass: 'save-search-modal',
              templateUrl: 'assets/javascripts/search/partials/deleteSearch.html',
              size: size
            });
          };

          $scope.loadSaveSearchDialog = function(size){
            /*
             load the save search modal dialog. pass the current filters to the
             saved search service
             */
            savedSearchService.setFilters($scope.filters);
            /*
             open new dialog window for user to save filters
             */
            $modal.open({
              animation: true,
              backdrop: 'static',
              windowClass: 'save-search-modal',
              templateUrl: 'assets/javascripts/search/partials/saveSearch.html',
              size: size
            });
          };

          $scope.allOptionsClick = function(filter){
            $scope.viewAllSwitches[filter] = true;
            $scope.filters[filter] = {};
          };

          $scope.individualOptionClick = function(filter){
            $scope.viewAllSwitches[filter] = false;
          };

          $scope.showSaveSearchButton = function(){
            return !_($scope.filters).omit(_.isEmpty).omit("organizationName").isEmpty() && $scope.view.actions.save;
          };

          /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
           Watches
           ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

          /**
           * Whenever the filters change refresh the data. Because of the way that
           * the controllers are nested. This controller will load before the inner
           * role-specific controller so the initial setting of filters won't cause
           * data to be refreshed
           */
          $scope.$watch('filters', function (newVal) {
            if (newVal) {
              $scope.$broadcast('refreshData');
            }
          }, true);

          $scope.$on('searchDeleted', function(){
            $scope.savedSearch = null;
            $scope.view = {
              title: $scope.defaultTitle,
              actions: {
                delete: false,
                save: true
              }
            };
          });

          $scope.$on('searchSaved', function(event, id){
            if(event){} //make jslint happy

            savedSearchService.getFilter(id).then(function(response){
              //reset the filters so if they use delete it will work...
              $scope.savedSearch = response.data;
              _loadFilters(id);
              $scope.view = {
                title: $filter('quote')(response.data.name),
                actions: {
                  delete: true,
                  save: false
                }
              };
            });

          });


          /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
           Private Functions
           ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

          function _initLayout() {
            var partialBase = 'assets/javascripts/analytics/partials/layouts/';
            var layout = $location.search().layout;
            if (!layout) {
              var userInfo = securityService.getLoggedInUserInfo();
              layout = userInfo.roles[0].toLowerCase();
              if(layout === 'contributor_admin') {
                layout = 'contributor';
              }
            }
            if(layout === 'subscriber') {
              $scope.defaultTitle = 'Aggregated Dispute Resolution Data';
            } else {
              $scope.defaultTitle = 'Data Overview';
            }
            $scope.layoutSrc = partialBase + layout + '.html';
          }

          function _initOptions() {
            var allYears = _(_.range(2005, moment().year()+1))
                .reverse()
                .map(function(x){
                  return {label: x.toString(), value: x.toString()};
                })
                .value();


            $scope.options = {
              organizations: [],
              numArbitrators: buildOptions('numArbitrators', false, 'All'),
              caseType: [],
              caseRegion: buildOptions('regions'),
              caseRegionWithAll: buildOptions('regions', false, 'All'),
              processType: buildOptions('processTypes', true, 'Both'),
              caseConclusion: buildOptions('arbitrationOutcomes'),
              yearsWithMin: [{label: 'Min', value: ''}].concat(allYears),
              yearsWithMax: [{label: 'Max', value: ''}].concat(allYears)
            };

            if($scope.showOrganizations()){
              analyticsService.getOrganizations().then(function (organizations) {
                $scope.options.organizations = [{label: 'All', value: ''}].concat(_.map(organizations, function(x){
                  return {label: x.name.toString(), value: x.id.toString()};
                }));
              });
            }

            analyticsService.getTopTenCaseTypesOptions($scope.isInternalStats, $scope.useSuppression)
                .then(function (topTenCaseTypes) {
                    $scope.options.caseType = analyticsService.buildOptions(undefined, false, 'All Case Types', undefined, topTenCaseTypes);
                });

            function buildOptions(standardOptionsKey, convertKeysToCaps, blankOption, omit) {
              //turn standardOptions (string array into array of {label: , value: })
              var options = _.map(standardOptions[standardOptionsKey], function (o) {
                 if(!_.contains(omit, o)){
                  return {
                    label: o,
                    value: (convertKeysToCaps ? o.toUpperCase() : o).toString()
                  };
                 }
              });
              if(blankOption){
                options.unshift(getBlankOption());
              }
              function getBlankOption(){
                if(blankOption){
                  return {
                    label: blankOption,
                    value: ''
                  };
                }
              }

              return options;
            }
          }

          /*
           * Load previous saved filters from API.
           */
          function _loadFilters(savedSearchId){
            if(savedSearchId){
              savedSearchService.getFilter(savedSearchId).then(
                  function(response) {
                    $scope.savedSearch = response.data;
                    $scope.filters = _.merge(_.cloneDeep(savedSearchService.filterTemplate), response.data.searchCriteria);
                    $scope.savedSearch.filters = _.merge(_.cloneDeep(savedSearchService.filterTemplate), response.data.searchCriteria);


                    $scope.view = { // Used to set / get UI visibility and text
                      actions : {
                        delete : true,
                        save : false
                      },
                      title: $filter('quote')(response.data.name)
                    };
                  },
                  function(){ //error
                    /*most common error situation is when there is a
                     savedSearchId in the $location.search(), but the savedSearch
                     does not exist. This would happen if the user hits refresh
                     after deleting a search. In any case, just load the page with
                     the default filters*/
                    $scope.filters = _.cloneDeep(savedSearchService.filterTemplate);
                    $scope.view = { // Used to set / get UI visibility and text
                      actions : {
                        delete : false,
                        save : true
                      },
                      title: $scope.defaultTitle
                    };
                  }
              );
            } else {
              /*subscriber may optionally initiate a search via the dashboard which
               passes in filters in the query string. The query string filters are
               only applicable when NOT loading from a saved search*/
              var queryStringFilters = _.pick($location.search(), ['caseType', 'caseRegion']);

              $scope.filters = _.merge(_.cloneDeep(savedSearchService.filterTemplate), queryStringFilters);
              $scope.view = { // Used to set / get UI visibility and text
                actions : {
                  delete : false,
                  save : true
                },
                title: $scope.defaultTitle
              };
            }
          }
        }])

      .controller('ContributorAnalyticsCtrl', ['$scope', '$q', '$log', 'analyticsService', 'analyticsDataElements', '_',
        function ($scope, $q, $log, analyticsService, analyticsDataElements, _) {

          //initialize data the first time
          _refreshData();

          /*the outer controller will broadcast this even whenever the filters
           change. including the initialization*/
          $scope.$on('refreshData', _refreshData);

          function _refreshData() {
            $scope.dataElements = [];
            var currentElements = _.cloneDeep(analyticsDataElements[$scope.currentTab]);
            _.forEach(currentElements, function (element) {

              var promises = _.map(element.metrics, function (metric) {
                $log.debug('<><> ContributorAnalyticsCtrl loadMetric');
                return analyticsService.loadMetric(metric.groups, _.mapValues($scope.filters, _convertObjectToArray), metric.fact);
              });

              $q.all(promises).then(function (data) {
                _.forEach(data, function (d, index) {
                  element.metrics[index].data = d;
                });
              });
            });

            $scope.dataElements = _.reduce(currentElements, _addItemToRows, [[]]);


            function _itemSize(item){
              return _.includes(['bigBar', 'horizontalBar'], item.template) ? 3 : 1;
            }

            function _rowSize(row){
              return _.reduce(row, function(acc,i){return acc+_itemSize(i);}, 0);
            }

            function _addItemToRows(rows, item){
              if(item.forceNewRow || (_rowSize(_.last(rows)) + _itemSize(item)) > 3){
                rows.push([item]);
                return rows;
              } else {
                _(rows).last().push(item);
                return rows;
              }
            }
          }

          function _convertObjectToArray(value){
            if(!_.isObject(value)){
              return value;
            } else {
              return _(value).pick(_.identity).keys().value();
            }
          }
        }]);
});

