/**
 * Home routes.
 */
define('home/routes',['angular'], function(angular) {
  'use strict';

  return angular.module('home.routes', [])
    .config(['$routeProvider', function ($routeProvider) {
      $routeProvider
        .otherwise({
          templateUrl: '/assets/javascripts/home/notFound.html',
          authorizedRoles: ['ADMIN', 'CONTRIBUTOR_ADMIN', 'CONTRIBUTOR', 'SUBSCRIBER', 'ANONYMOUS']
        });
  }]);
});

