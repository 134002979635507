/**
 * The app module, as both AngularJS as well as RequireJS module.
 * Splitting an app in several Angular modules serves no real purpose in Angular 1.2.
 * (Hopefully this will change in the near future.)
 * Splitting it into several RequireJS modules allows async loading. We cannot take full advantage
 * of RequireJS and lazy-load stuff because the angular modules have their own dependency system.
 */
define('app',['angular', 'lodash', 'moment', 'admin', 'analytics', 'cases', 'home', 'user', 'dashboard', 'intake', 'organizations', 'pattern', 'membership', 'search', 'status'], function(angular, _, moment) {
  'use strict';

  // We must already declare most dependencies here (except for common), or the submodules' routes
  // will not be resolved
  return angular.module('app', ['admin', 'analytics', 'cases', 'home', 'user', 'dashboard', 'intake',
    'organizations', 'pattern', 'membership', 'angulartics', 'angulartics.google.analytics', 'search', 'status', 'angular-stripe'])

  /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
   Config Blocks                           
   ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

      /**
       * By default log debug messages should be disabled, you can set the flag to true to enable logging. This is done
       * for you if you use runLocal.sh
       */
      .config(['$logProvider', function($logProvider){
        $logProvider.debugEnabled(window.Conf.envConfig.angularDebug === 'true'); //check for string value of true
      }])
      .config([
        '$analyticsProvider',
        function ($analyticsProvider) {
            $analyticsProvider.settings.ga.additionalAccountNames = ['wkgoogleanalytics'];
        }
      ])

       /**
       * register the authInterceptor by name, it will be resolved at runtime
       */
      .config(['$httpProvider', function ($httpProvider) {
        $httpProvider.interceptors.push('authInterceptor');

        //initialize get if not there
        if (!$httpProvider.defaults.headers.get) {
          $httpProvider.defaults.headers.get = {};
        }

        //disable ajax request caching
        //todo: we probably need to be more selective in our caching strategy rather than just globally disabling (perhaps make use of e-tags)
        $httpProvider.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
        // extra
        $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
        $httpProvider.defaults.headers.get.Pragma = 'no-cache';
      }])

      .config(['stripeProvider', function(stripeProvider) {
        stripeProvider.setPublishableKey(window.Conf.envConfig.stripePublishableKey);
      }])


  /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
   Run Blocks                           
   ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

  .run(['$log', '$rootScope', '$injector', '$location', 'loadIndicatorService', '$window', '$cookieStore', 'securityService', 'httpBuffer', '_',
    function($log, $rootScope, $injector, $location, loadIndicatorService, $window, $cookieStore, securityService, httpBuffer, _) {
    
    //if present automatically add the Bearer token to all $http requests
    $injector.get("$http").defaults.transformRequest = function(data, headersGetter) {
      //$log.debug('adding Auhtorization header');
      var token = securityService.getAuthToken();
      if (token) {
        headersGetter().Authorization = "Bearer " + token;
      }
      if (data) {
        return angular.toJson(data);
      }
    };
    
    $rootScope.$on('$locationChangeStart', function() {
      // Start page loader sequence
      $rootScope.$broadcast('page_start');
    });

    $rootScope.$on("$routeChangeStart", function(event, next) {
      var role = securityService.getLoggedInUserActiveRole();
      if (_.isEmpty(role)) {
        role = "ANONYMOUS";
      }

      if (!_.isEmpty(next.authorizedRoles) && !_.includes(next.authorizedRoles, role)) {
        redirect();
      } else if (!$location.path() || $location.path() === '/') { //redirect the root path to the role-appropriate home page
        redirect();
      }

      function redirect() {
        if (securityService.getAuthToken()) {
          redirectAuthenticated();
        } else {
          redirectUnauthenticated();
        }
      }

      function redirectAuthenticated() {
        event.preventDefault();
        if(securityService.getLoggedInUserActiveRole() === 'ADMIN'){
          $location.url('/admin').replace();
        }
        else if(securityService.getLoggedInUserActiveRole() === ''){
          $location.url('/users/subscriptions').replace();
        }
        else {
          $location.url('/dashboard').replace();
        }
      }

      function redirectUnauthenticated() {
        //halt the requested route change and redirect as appropriate
        event.preventDefault();

        var redirect = (!_.isEmpty($window.Conf.envConfig.publicSiteUrl) ? $window.Conf.envConfig.publicSiteUrl : '/login');
        if(_.startsWith(redirect, 'http')) {
          $window.location.href = redirect;
        } else {
          $location.url(redirect).replace();
        }
      }
    });
    
    $rootScope.$on('$routeChangeSuccess', function(){
      // Complete page loader sequence
      $rootScope.$broadcast('page_success');
    });

      var tokenRefreshLock = false;
      $rootScope.$on('event:token-refresh-required', function() {
        $log.debug('processing event:token-refresh-required');
        if(tokenRefreshLock){
          $log.debug('token refresh in progress, skipping...');
          return;
        }
        tokenRefreshLock = true;
        var $http = $injector.get('$http');
        var credentials = {};
        credentials.grant_type = 'refresh_token';
        credentials.client_id = window.Conf.envConfig.drdClientId; //Conf set as a global in index.scala.html
        credentials.refresh_token = $cookieStore.get('token').refresh_token;
        return $http({method: 'POST', url: '/api/oauth2/access_token', data: credentials}).then(
            function (response) {
              $cookieStore.put('token', response.data);
              httpBuffer.retryAll();
              tokenRefreshLock = false;
            });
      });
  }])

  .run(['$rootScope', '$location', '_', function($rootScope, $location, _){
    
    var history = [];

    $rootScope.$on('$routeChangeSuccess', function() {
      history.push($location.url());
    });

    $rootScope.back = function it(extraParams, excludedPath) {
      var prevUrl = history.length > 1 ? history.splice(-2)[0] : "/";
      //if the prevUrl evaluates to something that is excluded then try again
      if(prevUrl.indexOf(excludedPath) !== -1){
        return it(extraParams, excludedPath);
      }
      var l = $location.url(prevUrl);
      
      _.forEach(extraParams, function(v, k){
        l.search(k, v).replace();  
      }); 
    };
  }])

  /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
   Factories                            
   ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

    .factory('httpBuffer', ['$injector', '$log', function($injector, $log) {
        /** Holds all the requests, so they can be re-requested in future. */
        var buffer = [];

        /** Service initialized later because of circular dependency problem. */
        var $http;

        function retryHttpRequest(config, deferred) {
          function successCallback(response) {
            deferred.resolve(response);
          }
          $http = $http || $injector.get('$http');
          $http(config).then(successCallback);
        }

        return {
          /**
           * Appends HTTP request configuration object with deferred response attached to buffer.
           */
          append: function(config, deferred) {
            buffer.push({
              config: config,
              deferred: deferred
            });
          },

          /**
           * Retries all the buffered requests clears the buffer.
           */
          retryAll: function() {
            $log.debug('retryAll called...');
            $log.debug(buffer);
            for (var i = 0; i < buffer.length; ++i) {
              retryHttpRequest(buffer[i].config, buffer[i].deferred);
            }
            buffer = [];
          }
        };
    }])

    /**
     * Intercept all http errors. If the response is a 401 (unauthorized) then
     * redirect the user to the login page.
     */
   .factory('authInterceptor', ['$log', '$location', '$q', '$cookieStore', '$injector', '$rootScope', 'httpBuffer',
      function($log, $location, $q, $cookieStore, $injector, $rootScope, httpBuffer){

        return {
          responseError: function(rejection) {
            if (rejection.status === 401 && rejection.headers("www-authenticate") === 'Bearer error="invalid_token", error_description="The access token expired"') {
              var deferred = $q.defer();
              httpBuffer.append(rejection.config, deferred);
              $log.debug('broadcasting event:token-refresh-required');
              $rootScope.$broadcast('event:token-refresh-required', rejection);
              return deferred.promise;
            }
            else if (rejection.status === 401 && rejection.data.error_description ==='Membership Expired') {
              clientSideLogout();
              $location.path('/login').search('errors', 'membership_expired');
            }
            else if (rejection.status === 401) {
              clientSideLogout();
              $location.path('/login');             
            }
              // else if(rejection.status === 400 && rejection.data.error ==='invalid_request') {
              //   clientSideLogout();
              //   $location.path('/login');
              // }
            return $q.reject(rejection);
          }  
        }; 

        /**
         * replicate the logout functionality in user service. We can't depend on
         * securityService because that would introduce a circular dependency
         */
        function clientSideLogout(){
          $cookieStore.remove('token');
          $cookieStore.remove('loggedInActiveRole');
          $cookieStore.remove('loggedIn');
          $cookieStore.remove('audit'); 
        }


   }])

  //make lodash accessible to Angular as _
  .factory("_", function() {
    return (_);
  })

  //make moment accessible to Angular as moment
  .factory("moment", function() {
    return (moment.utc);
  })

  .factory("momentLocal", function() {
    return (moment);
  });
});

