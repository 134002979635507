define('common/services/standardOptions',['angular'], function (angular) {
  'use strict';

      return angular.module('common.standardOptions', [])

      .constant('standardOptions', {

        arbitrationOutcomes: [
            "Awarded",
            "Settled/Withdrawn",
            "Dismissed",
            "Administrative Close"
        ],
        userRoles: [
            "ADMIN",
            "CONTRIBUTOR",
            "CONTRIBUTOR_ADMIN",
            "SUBSCRIBER"
        ],
        orgTypes: [
            "Contributor",
            "Subscriber"
        ],
        caseEntryTypes: [
          "Current",
          "Historic"
        ],
        casePaymentStatuses: [
            "Paid",
            "Unpaid"
        ],
        caseStatuses: [
          "Active",
          "Cancelled",
          "Draft",
          "Flagged",
          "Inactive",
          "In Review",
          "Staged"
        ],
        caseStatusTransitions: [
          { name: "ACTIVE",    transitions: [ "ACTIVE",   "STAGED",   "REVIEW",   "FLAGGED", "DRAFT", "INACTIVE", "CANCELLED" ] },
          { name: "STAGED",    transitions: [ "ACTIVE",   "STAGED",   "REVIEW",   "FLAGGED", "DRAFT", "INACTIVE", "CANCELLED" ] },
          { name: "REVIEW",    transitions: [ "ACTIVE",   "STAGED",   "REVIEW",   "FLAGGED", "DRAFT", "INACTIVE", "CANCELLED" ] },
          { name: "FLAGGED",   transitions: [ "FLAGGED",  "DRAFT",    "INACTIVE", "CANCELLED" ] },
          { name: "DRAFT",     transitions: [ "DRAFT",    "INACTIVE", "CANCELLED" ] },
          { name: "INACTIVE",  transitions: [ "INACTIVE", "CANCELLED" ] },
          { name: "CANCELLED", transitions: [ "CANCELLED" ] }
        ],
        caseTypes: [
          "Aerospace & Defense",
          "Agriculture",
          "Arts, Entertainment & Recreation",
          "Business Ownership",
          "Commercial Contracts",
          "Commercial Services",
          "Construction",
          "Education",
          "Employment",
          "Energy",
          "Environmental",
          "Financial Services & Banking",
          "Healthcare",
          "Hospitality & Travel",
          "Insurance & Reinsurance",
          "Intellectual Property",
          "Licensing Agreements",
          "Manufacturing",
          "Maritime",
          "Mass Claims & Class Actions",
          "Mining & Raw Materials",
          "Nonprofits & Public Administration",
          "Professional & Technical Services",
          "Real Estate",
          "Technology",
          "Telecommunications",
          "Transportation & Storage",
          "Wholesale & Retail Trade"
        ],
        caseConclusions: [
          "Awarded",
          "Settled/Withdrawn",
          "Dismissed",
          "Administrative Close"
        ],
        mediationCaseConclusions: [
          "Settled/Withdrawn",
          "Administrative Close",
          "Impasse",
          "Unknown"
        ],
        dismissedReasons: [
          "Lack of Jurisdiction",
          "Inadmissibility",
          "Lack of Legal Merit",
          "Failure to Pursue a Claim"
        ],
        caseViews: [
            "INDIVIDUAL",
            "ORGANIZATION"
        ],
        processTypes: [
          "Arbitration",
          "Mediation"
        ],
        activeInactive: [
          "Active",
          "Inactive"
        ],
        currencyTypes: [
          {name: "British Pound", symbol: "\u00a3"},
          {name: "Euro", symbol: "\u20ac"},
          {name: "U.S. Dollar", symbol: "$"},
          {name: "Brazilian Real", symbol: "R$"},
          {name: "Canadian Dollar", symbol: "$"},
          {name: "Chinese Yuan", symbol: "\u00a5"},
          {name: "Egyptian Pound", symbol: "E\u00a3"},
          {name: "Hong Kong Dollar", symbol: "$"},
          {name: "Japanese Yen", symbol: "\u00a5"},
          {name: "Singapore Dollar", symbol: "$"},
          {name: "S. Korean Won", symbol: "\u8361"},
          {name: "Swedish Krona", symbol: "kr"},
          {name: "Swiss Franc", symbol: "SFr"},
          {name: "Venezuelan Bol\u00EDvar", symbol: "Bs."},
          {name: "Afghanistan Afghani"},
          {name: "Albanian Lek"},
          {name: "Algerian Dinar"},
          {name: "Angolan Kwanza"},
          {name: "Arab Emirates Dirham"},
          {name: "Argentine Peso"},
          {name: "Armenian Dram"},
          {name: "Aruban Guilder"},
          {name: "Australian Dollar"},
          {name: "Azerbaijan New Manat"},
          {name: "Bahamian Dollar"},
          {name: "Bahraini Dinar"},
          {name: "Bangladeshi Taka"},
          {name: "Barbados Dollar"},
          {name: "Belarussian Ruble"},
          {name: "Belize Dollar"},
          {name: "Bermudian Dollar"},
          {name: "Bhutan Ngultrum"},
          {name: "Boliviano"},
          {name: "Bosnia-Herzegovina Convertible Mark"},
          {name: "Botswana Pula"},
          {name: "Brunei Dollar"},
          {name: "Bulgarian Lev"},
          {name: "Burundi Franc"},
          {name: "CFA Franc BCEAO"},
          {name: "CFA Franc BEAC"},
          {name: "CFP Franc"},
          {name: "Cape Verde Escudo"},
          {name: "Cayman Islands Dollar"},
          {name: "Chilean Peso"},
          {name: "Colombian Peso"},
          {name: "Comoros Franc"},
          {name: "Congolese Franc"},
          {name: "Costa Rican Colon"},
          {name: "Croatian Kuna"},
          {name: "Cuban Peso"},
          {name: "Czech Koruna"},
          {name: "Danish Krone"},
          {name: "Djibouti Franc"},
          {name: "Dominican Peso"},
          {name: "East Caribbean Dollar"},
          {name: "El Salvador Colon"},
          {name: "Eritrean Nakfa"},
          {name: "Ethiopian Birr"},
          {name: "Falkland Islands Pound"},
          {name: "Fiji Dollar"},
          {name: "Gambian Dalasi"},
          {name: "Georgian Lari"},
          {name: "Ghanaian Cedi"},
          {name: "Gibraltar Pound"},
          {name: "Guatemalan Quetzal"},
          {name: "Guinea Franc"},
          {name: "Guyana Dollar"},
          {name: "Haitian Gourde"},
          {name: "Honduran Lempira"},
          {name: "Hungarian Forint"},
          {name: "Iceland Krona"},
          {name: "Indian Rupee"},
          {name: "Indonesian Rupiah"},
          {name: "Iranian Rial"},
          {name: "Iraqi Dinar"},
          {name: "Israeli New Shekel"},
          {name: "Jamaican Dollar"},
          {name: "Jordanian Dinar"},
          {name: "Kampuchean Riel"},
          {name: "Kazakhstan Tenge"},
          {name: "Kenyan Shilling"},
          {name: "Kuwaiti Dinar"},
          {name: "Kyrgyzstani Som"},
          {name: "Lao Kip"},
          {name: "Latvian Lats"},
          {name: "Lebanese Pound"},
          {name: "Lesotho Loti"},
          {name: "Liberian Dollar"},
          {name: "Libyan Dinar"},
          {name: "Lithuanian Litas"},
          {name: "Macau Pataca"},
          {name: "Macedonian Denar"},
          {name: "Malagasy Ariary"},
          {name: "Malawi Kwacha"},
          {name: "Malaysian Ringgit"},
          {name: "Maldive Rufiyaa"},
          {name: "Mauritanian Ouguiya"},
          {name: "Mauritius Rupee"},
          {name: "Mexican Nuevo Peso"},
          {name: "Mexican Unidad de Inversion"},
          {name: "Moldovan Leu"},
          {name: "Mongolian Tugrik"},
          {name: "Moroccan Dirham"},
          {name: "Mozambique Metical"},
          {name: "Myanmar Kyat"},
          {name: "Namibian Dollar"},
          {name: "Nepalese Rupee"},
          {name: "Netherlands Antillean Guilder"},
          {name: "New Zealand Dollar"},
          {name: "Nicaraguan Cordoba Oro"},
          {name: "Nigerian Naira"},
          {name: "North Korean Won"},
          {name: "Norwegian Krone"},
          {name: "Offshore Yuan Renminbi"},
          {name: "Omani Rial"},
          {name: "Pakistan Rupee"},
          {name: "Panamanian Balboa"},
          {name: "Papua New Guinea Kina"},
          {name: "Paraguay Guarani"},
          {name: "Peruvian Nuevo Sol"},
          {name: "Philippine Peso"},
          {name: "Polish Zloty"},
          {name: "Qatari Rial"},
          {name: "Romanian New Leu"},
          {name: "Russian Ruble"},
          {name: "Rwanda Franc"},
          {name: "Samoan Tala"},
          {name: "Saudi Riyal"},
          {name: "Serbian Dinar"},
          {name: "Seychelles Rupee"},
          {name: "Sierra Leone Leone"},
          {name: "Solomon Islands Dollar"},
          {name: "Somali Shilling"},
          {name: "South African Rand"},
          {name: "Sri Lanka Rupee"},
          {name: "St. Helena Pound"},
          {name: "Sudanese Pound"},
          {name: "Surinam Dollar"},
          {name: "Swaziland Lilangeni"},
          {name: "Syrian Pound"},
          {name: "S\u00e3o Tom\u00e9 and Pr\u00edncipe Dobra"},
          {name: "Taiwan Dollar"},
          {name: "Tajik Somoni"},
          {name: "Tanzanian Shilling"},
          {name: "Thai Baht"},
          {name: "Tongan Pa'anga"},
          {name: "Trinidad and Tobago Dollar"},
          {name: "Tunisian Dollar"},
          {name: "Turkish Lira"},
          {name: "Turkmenistani Manat"},
          {name: "Uganda Shilling"},
          {name: "Ukraine Hryvnia"},
          {name: "Unidad de Fomento"},
          {name: "Uruguayan Peso"},
          {name: "Uzbekistan Sum"},
          {name: "Vanuatu Vatu"},
          {name: "Vietnamese Dong"},
          {name: "Yemeni Rial"},
          {name: "Zambian Kwacha"},
          {name: "Zimbabwe Dollar"}
        ],
        regions: [
          "Africa",
          "Asia",
          "Europe",
          "Latin America",
          "Middle East",
          "North America",
          "Oceania"
        ],
        superRegions: [
          "Americas",
          "EMEA",
          "Asia Pacific"
        ],
        groupedRegions: {
          "Americas": [ "North America", "Latin America" ],
          "EMEA": [ "Africa", "Europe", "Middle East", ],
          "Asia Pacific": [ "Asia", "Oceania" ]
        },
        groupedCountries: {
          "Africa": [
            "Algeria",
            "Angola",
            "Benin",
            "Botswana",
            "Burkina Faso",
            "Burundi",
            "Cameroon",
            "Cape Verde",
            "Central African Republic",
            "Chad",
            "Comoros",
            "Congo (Brazzaville)",
            "Congo, Democratic Republic of the",
            "Cote d'Ivoire (Ivory Coast)",
            "Djibouti",
            "Egypt",
            "Equatorial Guinea",
            "Eritrea",
            "Ethiopia",
            "Gabon",
            "Gambia",
            "Ghana",
            "Guinea",
            "Guinea-Bissau",
            "Kenya",
            "Lesotho",
            "Liberia",
            "Libyan Arab Jamahiriya",
            "Madagascar",
            "Malawi",
            "Mali",
            "Mauritania",
            "Mauritius",
            "Mayotte",
            "Morocco",
            "Mozambique",
            "Namibia",
            "Niger",
            "Nigeria",
            "Reunion",
            "Rwanda",
            "Saint Helena",
            "Sao Tome and Principe",
            "Senegal",
            "Seychelles",
            "Sierra Leone",
            "Somalia",
            "South Africa",
            "South Sudan",
            "Sudan",
            "Swaziland",
            "Tanzania, United Republic of",
            "Togo",
            "Tunisia",
            "Uganda",
            "Western Sahara",
            "Zambia",
            "Zimbabwe"
          ],
          "Latin America": [
            "Anguilla",
            "Antigua and Barbuda",
            "Argentina",
            "Aruba",
            "Bahamas",
            "Barbados",
            "Belize",
            "Bolivia",
            "Bonaire, Saint Eustatius and Saba",
            "Brazil",
            "British Virgin Islands",
            "Cayman Islands",
            "Chile",
            "Colombia",
            "Costa Rica",
            "Cuba",
            "Cura\u00e7ao",
            "Dominica",
            "Dominican Republic",
            "Ecuador",
            "El Salvador",
            "Falkland Islands (Malvinas)",
            "French Guiana",
            "Grenada",
            "Guadeloupe",
            "Guatemala",
            "Guyana",
            "Haiti",
            "Honduras",
            "Jamaica",
            "Martinique",
            "Mexico",
            "Montserrat",
            "Nicaragua",
            "Panama",
            "Paraguay",
            "Peru",
            "Puerto Rico",
            "Saint Lucia",
            "Saint Martin",
            "Saint Vincent and the Grenadines",
            "Saint-Barth\u00e9lemy",
            "Sint Maarten",
            "St. Kitts and Nevis",
            "Suriname",
            "Trinidad and Tobago",
            "Turks and Caicos Islands",
            "Uruguay",
            "Venezuela",
            "Virgin Islands (US)"
          ],
          "North America": [
            "Bermuda",
            "Canada",
            "Greenland",
            "Saint Pierre and Miquelon",
            "United States"
          ],
          "Asia": [
            "Afghanistan",
            "Armenia",
            "Azerbaijan",
            "Bangladesh",
            "Bhutan",
            "Brunei Darussalam",
            "Cambodia",
            "China",
            "Georgia",
            "Hong Kong",
            "India",
            "Indonesia",
            "Japan",
            "Kazakhstan",
            "Korea, North",
            "Korea, South",
            "Kyrgyzstan",
            "Laos",
            "Macao",
            "Malaysia",
            "Maldives",
            "Mongolia",
            "Myanmar (ex-Burma)",
            "Nepal",
            "Pakistan",
            "Philippines",
            "Singapore",
            "Sri Lanka (ex-Ceilan)",
            "Taiwan",
            "Tajikistan",
            "Thailand",
            "Timor Leste (West)",
            "Turkmenistan",
            "Uzbekistan",
            "Vietnam"
          ],
          "Europe": [
            "Albania",
            "Andorra",
            "Austria",
            "Belarus",
            "Belgium",
            "Bosnia",
            "Bulgaria",
            "Croatia",
            "Cyprus",
            "Czech Republic",
            "Denmark",
            "Estonia",
            "Faroe Islands",
            "Finland",
            "France",
            "Germany",
            "Gibraltar",
            "Greece",
            "Guernsey and Alderney",
            "Hungary",
            "Iceland",
            "Ireland",
            "Italy",
            "Jersey",
            "Kosovo",
            "Latvia",
            "Liechtenstein",
            "Lithuania",
            "Luxembourg",
            "Macedonia",
            "Malta",
            "Man, Island of",
            "Moldova",
            "Monaco",
            "Montenegro",
            "Netherlands",
            "Norway",
            "Poland",
            "Portugal",
            "Romania",
            "Russia",
            "San Marino",
            "Serbia",
            "Slovakia",
            "Slovenia",
            "Spain",
            "Svalbard and Jan Mayen Islands",
            "Sweden",
            "Switzerland",
            "Turkey",
            "Ukraine",
            "United Kingdom",
            "Vatican City State (Holy See)"
          ],
          "Middle East": [
            "Bahrain",
            "Iraq",
            "Iran",
            "Israel",
            "Jordan",
            "Kuwait",
            "Lebanon",
            "Oman",
            "Palestine",
            "Qatar",
            "Saudi Arabia",
            "Syria",
            "United Arab Emirates",
            "Yemen"
          ],
          "Oceania": [
            "Australia",
            "Fiji",
            "French Polynesia",
            "Guam",
            "Kiribati",
            "Marshall Islands",
            "Micronesia",
            "New Caledonia",
            "New Zealand",
            "Papua New Guinea",
            "Samoa",
            "Samoa, American",
            "Solomon Islands",
            "Tonga",
            "Vanuatu"
          ]
        },
        numArbitrators: ['1', '3', 'Other/Unknown'],
        rulesUsed: [
          "Institutional Rules",
          "UNCITRAL Rules",
          "Other/Ad hoc"
        ],
        hearingLocations: [
          "New York",
          "Miami",
          "San Francisco",
          "Geneva",
          "Stockholm",
          "Paris",
          "Milano",
          "Beijing",
          "Hong Kong",
          "Singapore",
          "Vienna",
          "Frankfurt",
          "London",
          "Sao Paulo",
          "Mexico City"
        ],
        numDepositions: [
          "0","1-3","4-6","7-10","11+","Unknown"
        ],
        numInterrogatories: [
          "0","1-3","4-6","7-10","11+","Unknown"
        ],
        numMotionsFiled: [
          "0","1-3","4-6","7-10","11+","Unknown"
        ],
        numWitnessStatements: [
          "0","1-3","4-6","7-10","11+","Unknown"
        ],
        arbitrationSelectionMethods: [
          "Administrating institution",
          "List procedure",
          "Party nomination",
          "Other/Unknown"
        ],
        demandTypes: [
          "Monetary",
          "Non-monetary",
          "Both"
        ],
        prevailingParties: [
          "Claimant",
          "Respondent",
          "Prevailed in Part"
        ],
        awardParties: [
          "Claimant",
          "Respondent",
          "Both"
        ]

      });

});

