define('intake/services',['angular', 'common'], function(angular) {
  'use strict';

  return angular.module('intake.services', [])

  .factory('surveyService', ['$http', '$q', '_', 'matchers', 'standardOptions',
    function($http, $q, _, matchers, standardOptions) {
      var surveys = {};

      /**
       * The prototype for Surveys. Contains easy access to various forms
       * of the survey, such as a flattened list of questions, the raw, 
       * nested form of the survey, a map of questionIds to model fields,
       * etc.
       */
      function Survey(surveyData){
        
        /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
         Construction                           
         ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
        
        var self = this;

        /**
         * the core survey data just like it was loaded from the db. the
         * basic form of the survey is [pages]-> [sections]-> [questions]
         */
        var data = surveyData;

        /**
         * the pages of the survey
         */
        var pages = data.pages;

        /**
         * a flattend list of all the sections in the survey, no longer
         * grouped by page
         */
        var flattenedSections = _.flatten(_.map(pages, 'sections')); 
     
        /**
         * a flattend list of all the questions in the survey, no longer
         * grouped by page or section
         */
        var flattenedQuestions = _.flattenDeep(
          _.map(pages, function(page) {
            return _.map(page.sections, function(section) {
              return section.questions;
            });
          })
        );

        /**
         * an object containing {questionId: modelField} for all questions
         * in the survey
         */
        var questionIdModelMap = _.reduce(flattenedQuestions, function(acc, q){
          acc[q.id] = q.modelField;
          return acc;
        }, {});
        

        /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
         Public Functions                            
         ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

        /**
         * the basic nested form of the survey
         */
        this.asPages = function(){
          return pages;
        };

        /**
         * all sections in the survey flattened into a single array
         */
        this.asFlattenedSections = function(){
          return flattenedSections;
        };

        /**
         * all the questions in the survey flattened into a single array
         */
        this.asFlattenedQuestions = function(){
          return flattenedQuestions;
        };

        /**
         * get the modelField property that corresponds to the given
         * question id
         */
        this.modelField = function(questionId){
          return questionIdModelMap[questionId];
        };

        /**
         * Get the question that corresponds to the given modelField
         */
        this.questionFor = function(modelField){
          return _.findWhere(flattenedQuestions, {modelField: modelField});  
        };

        /**
         * get the section that contains the question for the given 
         * question id
         */
        this.section = function(questionId) {
          return _.find(self.asFlattenedSections(),
            function(s) {
              return _.some(s.questions, {
                id: questionId
              });
            });
        };

        /**
         * get all the questions on the given page, not grouped by section
         */
        this.questionsForPage = function(pageIndex){
          return _.flatten(_.map(pages[pageIndex].sections, 'questions'));
        };

        /**
         * Given an array or either questions or question ids, calculates
         * the distinct set of triggers for those questions. This includes
         * inspecting the sections that contain the questions as section
         * visibility impacts question visibility. In other words, if a
         * section isn't shown then the questions contained in that
         * section won't be shown either.
         */
        this.triggers = function(questionsOrIds){
          /*first we need to determine what type of argument we received
          and convert questionIds to questions if necessary*/
          if(_.isEmpty(questionsOrIds)){
            return;
          }

          if(!_.isObject(questionsOrIds[0])){
            questionsOrIds = _.filter(flattenedQuestions, function(q){
              return _.includes(questionsOrIds, q.id);
            });
          }

          var result = [];
  
          //get the triggers associated with each question
          var mapFn = function(questionOrSection){
            return _.map(questionOrSection.triggers, _.keys);   
          };
          result = result.concat(_.flattenDeep(_.map(questionsOrIds, mapFn)));

          //get the triggers associated with each section
          var sections = _.uniq(_.map(questionsOrIds, function(q){
            return self.section(q.id);
          }));
          result = result.concat(_.flattenDeep(_.map(sections, mapFn)));

          return _.uniq(result);
        };

        this.validations = function(){
          return data.validations;
        };
      }
      
      return {
        /**
         * Loads the correct survey, based on type param from the API,
         * populates the survey in this service and returns the result as a
         * Promise
         * @param {string} surveyType
         */
        loadSurvey: function(surveyType) {
          //if we already have the survey return it as a promise
          if (surveys && surveys[surveyType]) {
            return $q(function(resolve) {
              resolve(surveys[surveyType]);
            });
          }

          return $http.get('/api/surveys/' + surveyType)
            .then(function(response) {
              surveys[surveyType] = new Survey(response.data);
              return surveys[surveyType];
            });
        },

        /**
         * determines if a question or section should be shown or not. Evaluates
         * triggers property (if any) based on the answers to previous questions
         * Assumes that questions that are part of a trigger have already been
         * answered.
         * @param {Survey} survey
         * @param {question|section} triggered - an object with a triggers
         * property to check if it should be shown
         * @param {caseEntry} caseEntry - case entry to check triggers against
         */
        showTriggered: function(survey, triggered, caseEntry) {
          var self = this;
          var triggers = triggered.triggers; //an array

          /*if the triggered is a question then make sure that the section that
          it is contained in is shown*/
          if (triggered.text) { //questions have text field surveys do not
            var section = survey.section(triggered.id);
            var sectionShown = self.showTriggered(survey, section, caseEntry);
            
            if(!sectionShown){
              return false;
            }
          }

          /*if the section or question doesn't have any triggers defined just 
          automatically show it*/
          if (!triggers) {
            return true;
          }

          //make sure that the questions for any triggers are shown
          var parentsShown = _.every(triggers, function(trigger){
            //lookup the question associated with this trigger
            var question = _.findWhere(survey.asFlattenedQuestions(), {id: _.keys(trigger)[0]});

            return question && self.showTriggered(survey, question, caseEntry);
          });

          if(!parentsShown){
            return false;
          }  

          /*evaluate each set of triggers if ALL of the conditions for ANY of 
          the sets of triggers evaluate to true then the question or section 
          should be shown */
          return _.some(triggers, function(t) {
            var satisfied = true;
            var pairs = _.pairs(t); //creates an array like [[5, {eq:"ex1"}]]
            _.forEach(pairs, function(p) {
              var value = caseEntry[
                survey.modelField(p[0])
              ];

              _.forEach(p[1], function(condition, f) {
                satisfied = satisfied && matchers[f](value, condition);
              });
            });
            return satisfied;
          });
        },

        /**
         * Creates an array of objects {name: 'X', value: 'Y'} that can be used 
         * in select elements in the user interface
         */
        /**
         * Creates an array of objects {name: 'X', value: 'Y'} that can be used 
         * in select elements in the user interface
         */
        createOptions: function(question, filterValue){
          var possibleValues = question.possibleValues;
          if(_.isString(possibleValues)){
            possibleValues = standardOptions[possibleValues];
          }

          var values = filterValue ? 
              possibleValues[filterValue] : possibleValues;

          /*values should always be an array of either strings/numbers or 
          {key: value} we want to convert to [{name: "", value: ""}]
          look at the first value and figure out the type*/
          var options = [];
          if (!_.isEmpty(question.possibleValuesMap)) {
            options = _.map(values, this[question.possibleValuesMap]);
          } else if (_.isObject(_.first(values))) {
            options = _.map(values, function(o) {
              return {
                name: _.first(_.keys(o)),
                value: _.first(_.values(o))
              };
            });
          } else {
            options = _.map(values, function(p) {
              return {
                name: p,
                value: p
              };
            });
          }

          //add "Please Select" onto the front of the list
          if ('select' === question.type){
            options.unshift({name: 'Please Select', value: '-'});
          }

          if (question.addOtherOption) {
            options.push({
              name: 'Other',
              value: 'Other'
            });
          }

          if (question.addOtherUnknownOption) {
            options.push({
              name: 'Other/Unknown',
              value: 'Other/Unknown'
            });
          }

          return options;  
        },

        mapCurrencyTypes: function(currency) {
          var value = (_.isEmpty(currency.symbol) ? currency.name : currency.name + ' ' + currency.symbol);
          return { name: value, value: value };
        }
      };
    }
  ])

  .factory('caseService', [
    '$http', '$q', '$log', '_', 'questionTypes', 'matchers', 'surveyService', 'validationService', 'securityService', 'userService', 'standardOptions',
    function($http, $q, $log, _, questionTypes, matchers, surveyService, validationService, securityService, userService, standardOptions) {
      if(validationService){}

      return {
        createOrUpdate: function(caseEntry) {
          if (caseEntry.id) { //existing case
            return $http.put('/api/cases/' + caseEntry.id, caseEntry)
              .then(
                function(response) {
                  return response.data;
                },
                function(err) {
                  console.log(err);
                  return err;
                });
          } else {
            //create the case, then look up by the id
            return $http.post('/api/cases', caseEntry)
              .then(function(response) {
                return $http.get('/api/cases/' + response.data.id);
              }, function(err) {
                return err;
              })
              .then(function(response) {
                return response.data;
              });
          }
        },

        //returns a promise of a CaseEntry
        load: function(caseId) {

          //if the caseId is present load the case from the db
          if (caseId) {
            return $http.get('/api/cases/' + caseId)
              .then(function(response) {
                return response.data;
              });
          } else {
            //just return a new empty object
            return $q(function(resolve) {
              resolve({});
            });
          }
        },

        submit: function (survey, caseEntry) {
          var self = this;
          return _calculateStatusForSubmit(survey, caseEntry).then(function(status){
            if (_.includes(['ERROR', 'DRAFT'], status)) {
              return $q.reject("Validation Errors");
            }
            var forUpdate = _.cloneDeep(caseEntry);
            forUpdate.status = status;
            return self.createOrUpdate(forUpdate);
          });

        },

        // general function to "flatten" array properties used for original mediation parties, but
        // no longer sufficient since the addition of arbitration parties
        /*if the value is an array, map it to the corresponding flattened
        fields mainProp_innerProp_index(+1)*/
        convertArrayProperties: function(caseEntry) {
          _.forOwn(caseEntry, function(value, key) {
              if (_.isArray(value)) {
                _.forEach(value, function(obj, index) {
                  _.forEach(obj, function(objValue, objKey) {
                    caseEntry[key + '_' + objKey + '_' + (index + 1)] = objValue;
                  });
                });
              }
          });
        },

        composeAsiaOceaniaRegions: function(caseEntry) {
          if(caseEntry.caseRegion === 'Oceania'){
            caseEntry.caseRegion = 'Asia';
          }
          if(caseEntry.party){
              _.forEach(caseEntry.party, function(party){
                  if(party.partyRegion === 'Oceania'){
                      party.partyRegion = 'Asia';
                  }
              });
          }
        },
        decomposeAsiaOceaniaRegions: function(caseEntry) {
            if(caseEntry.caseRegion === 'Asia' && _.includes(standardOptions.groupedCountries.Oceania, caseEntry.caseCountry)){
                caseEntry.caseRegion = 'Oceania';
            }
            if(caseEntry.party){
              _.forEach(caseEntry.party, function(party){
                if(party.partyRegion === 'Asia' && _.includes(standardOptions.groupedCountries.Oceania, party.partyCountry)){
                  party.partyRegion = 'Oceania';
                }
              });
            }
        },
        // flatten parties into a list of discrete properties to match surveys
        convertPartiesToArrays: function(caseEntry) {
          var partyMap = _.groupBy(caseEntry.party, function(party) {
            return party.partyType;
          });
          _.forEach(partyMap, function(pList, pType) {
            _.forEach(pList, function(party, idx) {
              _.forEach(party, function(value, key) {
                caseEntry['party_' + pType + '_' + key + '_' + (idx + 1)] = value;
              });
            });
          });
        },

        // condense separate party properties back into single party array
        convertArraysToParties: function(caseEntry) {
          var medParties = _buildPartyArray(caseEntry, 'mediation', caseEntry.numMediationParties);
          var claimParties = _buildPartyArray(caseEntry, 'claimant', caseEntry.numClaimantParties);
          var respParties = _buildPartyArray(caseEntry, 'respondent', caseEntry.numRespondentParties);
          caseEntry.party = _.union(medParties, claimParties, respParties);
        }
      };

      function _calculateStatusForSubmit(survey, caseEntry) {
        var types = ["ERROR", "DRAFT", "REVIEW", "ACTIVE"],
            isAdmin = securityService.currentUserHasRole('ADMIN'),
            existingStatus = caseEntry.status,
            userPromise = userService.current(),
            organizationId = securityService.getLoggedInUserInfo().orgId,
            autoActiveByOrgPromise,
            maxStatus;

        if (organizationId) {
          autoActiveByOrgPromise = $http.get('/api/organizations/' + organizationId).then(function (response) {
            return response.data.autoActiveCases;
          });
        } else {
          autoActiveByOrgPromise = $q(function (resolve) {
            resolve(true);
          });
        }

        return $q.all({
          user: userPromise,
          autoActiveByOrg: autoActiveByOrgPromise
        }).then(function (promises) {
              if (!isAdmin && existingStatus === 'FLAGGED') {
                maxStatus = 'REVIEW';
              } else if (isAdmin || (promises.user.autoActiveCases && promises.autoActiveByOrg)) {
                maxStatus = 'ACTIVE';
              } else {
                maxStatus = 'REVIEW';
              }

              var minErrorIdx =
                  _(survey.asFlattenedQuestions())
                      .filter(function (q) {
                        return surveyService.showTriggered(survey, q, caseEntry);
                      })
                      .push(survey)
                      .map('errors')
                      .flatten()
                      .map(function (e) {
                        //treat DATE errors as REVIEW, ADMIN errors as ACTIVE
                        var type;
                        if(e.type === 'DATE') {
                          type = 'REVIEW';
                        }
                        else if(e.type === 'ADMIN') {
                          type = 'ACTIVE';
                        }
                        else {
                          type = e.type;
                        }
                        return types.indexOf(type);
                      })
                      .min();

              return minErrorIdx === Infinity ? maxStatus : types[minErrorIdx - 1];
            }
        );
      }

      function _buildPartyArray(caseEntry, partyType, numParties) {
        var parties = _.reduce(caseEntry, function(acc, value, key){
          var parts = key.split('_');

          if(parts.length === 4 && parts[0] === 'party' && parts[1] === partyType){
            var index = parts[3] - 1;
            var prop = parts[2];

            var obj = acc[index] || { 'partyType': partyType };
            obj[prop] = value;

            acc[index] = obj;
          }
          return acc;
        }, []);
        if(numParties) {
          parties = _.take(parties, numParties);
        }
        return parties;
      }
    }
  ])

  .factory('caseServiceHistory', ['$http', '_', '$q',
    function($http, _, $q){
      return{
      load : function(caseId){
        //if the caseId is present load the case from the db
        if(caseId) {
          return $http.get('/api/case-status-history/' + caseId)
            .then(function(response) {
              return response.data;
            });
        }
        else {
          //just return a new empty object
          return $q(function(resolve) {
            resolve({});
          });
        }
      }
    };
  }])
  .factory('organizationService', ['$http',
    function($http){
      return{
      getOrganizationUsers : function(orgId){
        return $http.get('/api/organizations/' + orgId + '/users')
          .then(
            function(response) {
              return response.data;
            });
      },
      getOrganization : function(organizationId) {
        return $http.get('/api/organizations/' + organizationId).then(function (response) {
          return response.data;
        });
      }
    };
  }])




  .factory('validationService', ['surveyService', 'matchers', 'questionTypes', 'moment', '_',
      function(surveyService, matchers, questionTypes, moment, _){
    return {
      getSurveyLevelErrors: function(types, survey, caseEntry){
        var errors = [];
        var surveyLevelValidations = survey.validations();
        if(!surveyLevelValidations){
          return errors;
        }
        _.forEach(types, function(t){
          _.forEach(surveyLevelValidations[t.toLowerCase() + 'Validations'], function(condition, key){
            //do the validation
            if(!customValidator(key)(survey, caseEntry, condition)){
              errors.push({type: t, customValidator: key, condition: condition});
            }
          });
        });
        return errors;
      },

      getErrors: function(types, question, survey, caseEntry){
        var value = caseEntry[survey.modelField(question.id)];
        //validation types are BASIC | DRAFT | REVIEW | ACTIVE | ADMIN
        var errors = [];

        for (var i = 0; i < types.length; i++) {
          var t = types[i];
          switch (t) {
            case 'BASIC':
              var questionType = question.type;
              var validateFn = questionTypes[questionType].validateFn;
              if (value && validateFn && !validateFn(value)) {
                errors.push({type: t, questionType: questionType});
              }
              break;
            case 'DATE':
              //get date validation rules from the question
              _.forEach(question.earlierDates, validateDate);
              validateDateBeforeNow();
              break;
            default: //DRAFT | REVIEW | ACTIVE | ADMIN
              /*if no basic errors then check the other validations. this prevents
              us from showing errors like "the number has to be greater than 0"
              in cases where the input isn't even a valid number*/
              if (continueAdditionalValidations(errors)) {
                _.forEach(question[t.toLowerCase() + 'Validations'], validateCondition);
              }
          }
        }

        return errors;

        function continueAdditionalValidations(errors){
          return !_.some(errors, function(e){
            return e.type === 'BASIC' || e.matcher === 'integer';
          });
        }

        function validateCondition(condition, matcher) {
          //if condition is a reference to some other field look if up on the case entry
          if(_.includes(['gt', 'gte', 'lt', 'lte'], matcher) && _.isString(condition)){
            if(_.startsWith(condition, "$")){
              condition = Number(condition.substring(1));
              value = Math.round(caseEntry["norm"+ _.capitalize(question.modelField)]);
            } else {
              condition = caseEntry[condition];
            }
          }


          /*if the condition is an array it's a reference to one or more other fields
          on the case entry, our current field is required if one or more of those fields
          are present as well*/
          if(matcher === 'present' && _.isArray(condition)){
            condition = _.some(condition, function(otherField){
              return caseEntry[otherField];
            });
          }
          if(matcher === 'presentIf' && _.isObject(condition)){
            var otherValue = caseEntry[condition.modelField];
            var otherMatcher = _.first(_.filter(_.keys(condition), function(x){
              return _.includes(_.keys(matchers), x);
            }));
            var otherCondition = condition[otherMatcher];
            if(matchers[otherMatcher](otherValue, otherCondition)){
              matcher = 'present';
              condition = true;
            }
          }
          // this is going to be a one off for the num arbs questions
          //TODO: generalize composition and how to hold fields accountable that are apart of composition
          if(matcher === 'composition' && _.isObject(condition)){
            var isUnknown = _.some(
                            _.map(condition.lte.sumOf, function(x){
                              return caseEntry[x];}
                            ), function(x){
                              return x === "Unknown" ;
                            });

            value = _.sum(_.map(condition.sumOf, function(x){
              return caseEntry[x];})
            );
            condition = _.sum(_.map(condition.lte.sumOf, function(x){
              return caseEntry[x];})
            );
            if(!_.isUndefined(condition) && !matchers.lte(value, condition) && !isUnknown){
              errors.push({type: t, matcher: "composition", condition: condition, modelField: question.modelField, questionType: question.type});
            }
          }

          //most normal validation checks will occur here in matchers[matcher](value, condition)
          if(!_.isUndefined(matchers[matcher])){
            if(!_.isUndefined(condition) && !matchers[matcher](value, condition)){
              errors.push({type: t, matcher: matcher, condition: condition, modelField: question.modelField, questionType: question.type});
            }
          }
        }

        function validateDate(ref){
          var refDate = caseEntry[ref];
          var bothPresent = value && moment(value).isValid() && refDate && moment(refDate).isValid();
          if(bothPresent && moment(value).isBefore(moment(refDate))){
            errors.push({type: t, ref: ref, refDate: moment(refDate)});
          }
        }
        function validateDateBeforeNow(){
          var valid = value && moment(value).isValid();
          if(valid && moment(value).utcOffset(14).isAfter(moment().utcOffset(14), 'day')){
            errors.push({type: t, dateErr: 'notbefore'});
          }
        }
      },

      isRequired: function(question, survey, caseEntry) {
        //look up any present validations (hardcoded for draft and REVIEW for now)
        var possibleRequiredValidations = _.pick(question, 
            ['draftValidations', 'reviewValidations']);
        return _.some(possibleRequiredValidations, function(v){
          if(v.presentIf) {
            // todo assumes all presentIf validations use an 'eq' check which is currently true, but may need to generalize in future
            return (caseEntry[v.presentIf.modelField] === v.presentIf.eq);
          } else if(_.isArray(v.present)) {
            return _.some(v.present, function(modelField){
              return caseEntry[modelField];
            });
          } else {
            return v.present;
          }
        });
      }
    };

    function customValidator(key) {

      var validators = {

        exactlyOneOf: function(survey, caseEntry, modelFields){
          //none of the relevant questions are applicable or exactly one has some value
          return !anyShown(survey, caseEntry, modelFields) ||
              _(caseEntry).pick(modelFields).values().compact().value().length === 1;
        },

        atLeastOneOf: function(survey, caseEntry, modelFields){
          //none of the relevant questions are applicable or at least one has some value
          return !anyShown(survey, caseEntry, modelFields) ||
              _(caseEntry).pick(modelFields).values().compact().value().length > 0;
        }
      };

      function anyShown(survey, caseEntry, modelFields){
        return _(modelFields).find(function(field){
          return surveyService.showTriggered(survey, survey.questionFor(field), caseEntry);
        });
      }

      return validators[key];
    }
  }]);
});
