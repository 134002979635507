define('organizations/controllers',['angular'], function(angular) {
  'use strict';

  return angular.module('organizations.controllers', [])

  .controller('OrganizationTableCtrl', ['$scope', '$log', '$location', 'securityService', 'organizationDataService', 'standardOptions', '_', 'moment', '$anchorScroll',
    function($scope, $log, $location, securityService, organizationDataService, standardOptions, _, moment, $anchorScroll) {

      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Initialization                            
      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
      /*load the config if loadPrevConfig is set in the query string, otherwise
      * initialize the config to an empty/default object. This allows us to
      * selectively reinitialize the page with previously saved searches/filters*/
      var prevConfig = $location.search().loadPrevConfig && organizationDataService.getConfig();
      $scope.config = prevConfig || {
        limit: 10,
        offset: 0,
        sort: {},
        filters: {
          name: '',
          organizationType: '',
          status: ''
        }
      };
      _initOptions();

      //run the data population function once to initialize
      _refreshData();


      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Scope Functions                           
      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

      $scope.changeLimit = function(){
        //changing the limit throws off pagination so start back at page 0
        $scope.config.offset = 0;
        _refreshData();
      };

      $scope.prev = function(){
        $scope.config.offset--;
        _refreshData();
        $anchorScroll();
      };

      $scope.next = function(){
        $scope.config.offset++;
        _refreshData();
        $anchorScroll();
      };

      $scope.goToPage = function(page){
        $scope.config.offset = page-1; //pages are 1-based offset is 0-based
        _refreshData();
        $anchorScroll();
      };

      $scope.updateSort = function(field){
        if(field === $scope.config.sort.field){
          if($scope.config.sort.direction === 'asc'){
            $scope.config.sort.direction = 'desc';
          } else {
            $scope.config.sort = {};
          }
        } else {
          $scope.config.sort.field = field;
          $scope.config.sort.direction = 'asc';
        }
        _refreshData();
      };

      $scope.sortClass = function(field){
        if(field === $scope.config.sort.field){
          return $scope.config.sort.direction;
        } else {
          return 'none';
        }
      };

      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Watches                           
      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

      $scope.$watch('config', function(newVal){
        organizationDataService.setConfig(newVal);
      }, true);

      //whenever the filters change, refresh the table data
      $scope.$watch('config.filters', function(newVal, oldVal){
        if(!_.isEqual(newVal, oldVal) && !$scope.cancelFilterChange){
            $scope.config.offset = 0;
            _clearSelection();
            _refreshData();
        } else {
          $scope.cancelFilterChange = false;
        }
      }, true);

      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Private Functions                           
      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

      function _refreshData() {
        var sort = _.isEmpty($scope.config.sort) ? {field: 'updatedTime', direction: 'desc'} : $scope.config.sort;

        organizationDataService.load($scope.config.limit, $scope.config.offset, sort, $scope.config.filters)
          .then(function (result) {
            
            $scope.organizations = result.data;
            $scope.total = result.total;

            $scope.pagination = new Pagination($scope.config.limit, $scope.config.offset, $scope.organizations, $scope.total);


            function Pagination(limit, offset, organizations, total){

              var self = this;

              //the current page based on the offset
              this.page = offset + 1;

              //the index of the first item on the current page
              this.pageItemStart = offset*limit+1;

              //the index of the last item on the current page
              this.pageItemEnd = offset*limit+organizations.length;

              //the total number of un-paginated items
              this.totalItems = total;

              //the total number of pages 
              this.totalPages = Math.ceil(self.totalItems/limit);

              this.showPrevious = function(){
                return self.page > 1;
              };

              this.showNext = function(){
                return self.page < self.totalPages;
              };

              this.getPreviousLinkablePages = function(){
                var i = self.page - 1;
                var prevPages = [];
                while(i > 0 && prevPages.length <= 2){
                  prevPages.unshift(i);
                  i--;
                }
                return prevPages;
              };

              this.getNextLinkablePages = function(){
                var i = self.page + 1;
                var nextPages = [];
                while(i <= self.totalPages && nextPages.length <= 2){
                  nextPages.push(i);
                  i++;
                }
                return nextPages;
              };

            } 
             
          });
      }

        function _clearSelection() {
          _.forEach($scope.cases, function(caseEntry) {
            caseEntry.selected = false;
          });
          $scope.selectAll = false;
          $scope.caseSelections = [];
        }

      function _initOptions(){
        $scope.options = {
          orgTypes: _buildOptions('orgTypes', true),
          status: _buildOptions('activeInactive', true),
          limits: _buildLimitOptions()
        };

        function _buildOptions(standardOptionsKey, convertKeysToCaps){
          var keyOverrides = {
          };
          //turn standardOptions (string array into array of {label: , value: })
          return _.union(
            [{label: 'All', value: ''}],
            _.map(standardOptions[standardOptionsKey], function(o){
              var value = keyOverrides[o] || o;
              return {label: o, value: convertKeysToCaps ? value.toUpperCase() : value};
            })
          );
        }

        function _buildLimitOptions(){
          return [
            {label: '10 per page',   value: 10},
            {label: '25 per page',   value: 25},
            {label: '50 per page',   value: 50},
            {label: '100 per page',  value: 100},
            {label: '1000 per page', value: 1000}
          ];
        }
      }
  }])

  .controller('OrganizationDetailCtrl', ['$scope', '$location', '$http', 'organizationDataService', 'membershipService',
    function($scope, $location, $http, organizationDataService, membershipService) {
      $scope.organization = {};
      var organizationId = $location.search().id;
      $scope.addMode = !organizationId;

      $scope.refreshMemberships = function() {
        membershipService.fetchOrganizationMemberships(organizationId, function(data) {
          $scope.membershipSummaries = data;
        }, function() {
          $scope.errorText = "Error loading memberhsips";
        });
      };
      $scope.refreshOrganizationIps = function() {
        organizationDataService.fetchOrganizationIpLogins(organizationId, function(data) {
          $scope.organizationIps = data;
        }, function() {
          $scope.errorText = "Error loading organization ips";
        });
      };

      $scope.deleteOrganizationIp = function(organizationIp) {
        $http.delete('/api/organizations/ips/' + organizationIp)
            .success(function() {
              $scope.refreshOrganizationIps();
            })
            .error(function() {
              $scope.errorText = "Error deleting org ip";
            });
      };

      $scope.saveOrganization = function() {
        if (!$scope.addMode) {
          organizationDataService.update($scope.organization, function () {
            $location.path('/organizations/view');
          }, function (errors) {
            $scope.errors = errors;
          });
        } else {
          organizationDataService.create($scope.organization, function (id) {
            $location.path('/organization').search({id: id});
          }, function (errors) {
            $scope.errors = errors;
          });
        }
      };

      if ($scope.addMode) {
        $scope.orgHeaderText = "Add an Organization";
      } else {
        $scope.refreshMemberships();
        $scope.refreshOrganizationIps();
        $http.get('/api/organizations/' + organizationId)
            .success(function(data) {
              $scope.organization = data;
              $scope.orgHeaderText = $scope.organization.name;
            })
            .error(function() {
              $scope.errorText = "Error loading organization";
            });
      }
  }])
  .controller('AddOrganizationIpLoginCtrl', ['$scope', '$location', '$http', 'organizationDataService', 'organizationIp', 'organizationId',
      function($scope, $location, $http, organizationDataService, organizationIp, organizationId) {
      $scope.organizationIp = organizationIp;
      $scope.addMode = !$scope.organizationIp;
      $scope.title = ($scope.addMode ? "Add Organization IP" : "Edit Organization IP");


      $scope.saveOrganizationIp = function() {
        $scope.errors = [];

        if ($scope.addMode) {
          $scope.organizationIp.organizationId = organizationId;
          organizationDataService.createOrganizationIp($scope.organizationIp, function () {
            $scope.$close();
          }, function (errors) {
            $scope.errors = errors;
          });
        }
//        else {
//          _.assign(params, {"organization_id": $scope.membershipSummary.membership.id});
//          organizationDataService.updateMembership(params, function () {
//            $scope.$close();
//          }, function (errors) {
//            $scope.errors = errors;
//          });
//        }
      };
    }]);
});

