define('common/directives/components',['angular'], function (angular) {
  'use strict';
  
  var module = angular.module('common.directives.components', []);

  module.directive('footerMarkup', [ function(){
    
    var controller = [
     '$scope', '$http', 'languageConfigService',
      function($scope, $http, languageConfigService){  
        _loadLanguageConfig();
        function _loadLanguageConfig(){
          return languageConfigService.loadConfig().then(function(response){
            $scope.langauge = response;
          });
        }
      }
    ];
    return {
      restrict: 'AE',
      templateUrl: 'assets/javascripts/common/partials/footer.html',
      replace: true,
      transclude: true,
      controller: controller

    };
  }])
  
  .directive('headerMarkup', [ function(){
    var controller = [
      '$scope', '$cookieStore', '$location', '$aside', '_',
      function($scope, $cookieStore, $location, $aside, _){

        /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
         Scope Variables
        ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
        $scope.contributeDataCollapsed = true;
        $scope.dataOverviewCollapsed = true;
        $scope.openAside = function(position, backdrop) {
          $scope.asideState = {
            open: true,
            position: position
          };



          var menuItems = {
            admin_dashboard: ['ADMIN'],
            dashboard: ['CONTRIBUTOR', 'CONTRIBUTOR_ADMIN', 'SUBSCRIBER'],
            add_historic_case: ['ADMIN', 'CONTRIBUTOR', 'CONTRIBUTOR_ADMIN'],
            add_current_case: ['ADMIN', 'CONTRIBUTOR', 'CONTRIBUTOR_ADMIN'],
            view_cases: ['ADMIN', 'CONTRIBUTOR', 'CONTRIBUTOR_ADMIN'],
            view_stats: ['ADMIN', 'CONTRIBUTOR_ADMIN', 'SUBSCRIBER'],
            view_raw_stats: ['ADMIN', 'CONTRIBUTOR_ADMIN', 'SUBSCRIBER'], //enable raw stats for DR role #142056971
            view_developing_stats: ['ADMIN']
          };


          // create new aside instance
          var asideInstance = $aside.open({
            templateUrl: 'assets/javascripts/common/partials/aside.html',
            placement: position,
            size: 'sm',
            backdrop: backdrop,
            windowClass: 'asideNav',
            backdropClass: 'out',
            controller: ['$scope', '$modalInstance', 'securityService', function($scope, $modalInstance, securityService) {
              // Scope Funtions
              $scope.ok = function($event) {
                function rollOut(cb){
                  var asideInstanceElm = angular.element('.ng-aside');
                  asideInstanceElm.addClass('out').removeClass('in');
                  cb();
                }
                rollOut(function(){
                  setTimeout(function(){
                    $modalInstance.close();
                    $event.stopPropagation();
                  }, 100);
                });
              };


              $scope.menuItemEnabled = function(itemKey){
                var menuItemRoles = menuItems[itemKey];
                return !menuItemRoles || _.includes(menuItemRoles, getUserRole());
              };

              // Private Functions
              function getUserRole(){
                return securityService.getLoggedInUserActiveRole();
              }
            }]
          });
          // Custom animation when closing
          asideInstance.opened.then(function(){
            setTimeout(function(){
              angular.element('.ng-aside').on('click', animateClouser);
              function animateClouser(){
                //close all drop downs
                $scope.contributeDataCollapsed = true;
                $scope.dataOverviewCollapsed = true;
                angular.element('.ng-aside').addClass('out').removeClass('in');
                setTimeout(function(){
                  asideInstance.close();
                 }, 100);
              }
            }, 100);
          });

      };

      }
    ];
    return {
      restrict: 'EA',
      templateUrl: 'assets/javascripts/common/partials/header.html',
      replace: true,
      scope: false,
      transclude: true,
      controller: controller
    };
  
  }])

      .directive('errors', [function() {
        var controller = ['$scope', '$filter', '_', function($scope, $filter, _) {
          $scope.showErrors = false;
          $scope.$watch('errors', function(errors) {
            $scope.errorMessages = _.uniq(_.map(errors, function (error) {
              if (_.has(error, 'errorCode')) {
                var translationCode = 'error.'+error.errorCode;
                var translation = $filter('translate')('error.'+error.errorCode);
                if (translation === translationCode) {
                  return "An error occurred with code: " + error.errorCode + ".";
                } else {
                  return translation;
                }
              } else {
                return error;
              }
            }));
            $scope.showErrors = _.size($scope.errorMessages) > 0;
          }, true);
        }];

        return {
          restrict: 'E',
          templateUrl: 'assets/javascripts/common/partials/errors.html',
          replace: false,
          scope: {
            errors: '='
          },
          controller: controller
        };
      }])
  ;

  return module;
});
