define('analytics/widgets/regionMap/regionMap',['angular'], function (angular) {
  'use strict';

  return angular.module('widgets-regionMap', [])
      .factory('regionMapOptions', ['widgetColorSchemes',function(widgetColorSchemes){
        var scheme = widgetColorSchemes._2Green2;
        return {
          fills: {
            default: scheme[0],
            active: scheme[1]
          }
        };
      }])
      .directive('widgetsRegionMap', ['analyticsService', function(analyticsService) {
        return {
          restrict: 'A',
          templateUrl: '/assets/javascripts/analytics/widgets/regionMap/regionMap.html',
          scope: {
            widget: '=',
            filters: '=',
            internal: '=',
            suppression: '='
          },
          controller: ['$scope', '_', '$window','$timeout', 'geographyService', 'standardOptions', 'widgetHelpers',
              'regionMapOptions', 'fonticonService', '$q', '$filter', 'securityService',
            function($scope, _, $window,$timeout, geographyService, standardOptions, widgetHelpers, options,
              fonticonService, $q, $filter, securityService){

            $scope.state = 'LOADING';

            $scope.allowViewCaseTotals = function() {
                return securityService.isLoggedInAdmin();
            };

            $scope.regionNames = {
              'Africa': 'africa',
              'Asia': 'asia',
              'Europe': 'europe',
              'Latin America': 'latinAmerica',
              'Middle East': 'middleEast',
              'North America': 'northAmerica',
              'Oceania': 'oceania'
            };

            /**
              Scope functions
            */
            
            $scope.icon = function(region){
              return fonticonService.getIcon(region);
            };

            /*
              Private Functions
            */

            /**
            * Loads one topojson map into the DOM and set event listner
            * @param mapContainer
            */

            function _buildLabel(item) {
              return item.groups[0];
            }

            function _loadMap(mapContainer){

              var hasMap = document.getElementById(mapContainer).getElementsByTagName('svg').length;
              if(hasMap === 0){
                $scope.mapRegion = new $window.Datamap({
                  element: document.getElementById(mapContainer),
                  responsive: true,
                  scope: 'world', 
                  geographyConfig: {
                    popupOnHover: false,
                    highlightOnHover: false,
                    borderColor: options.fills.default,
                    borderWidth: 1
                  },
                  fills:{
                    defaultFill: options.fills.default,
                    defaultBubble: 'rgba(1, 115, 137, 0.75)' // @app-teal-02
                  },
                  // TL;DR: the bubblesConfig in the region map and bubble map must be the same.
                  //
                  // Something weird happens with these D3 Datamaps where the bubble config set by the first map
                  // loaded is applied to all subsequent maps, regardless of their own bubble config, even if the
                  // first map includes no bubble config at all (then the defaults are used).  For example, since
                  // the region map is usually the first loaded (on the Overview page), the bubble config applied
                  // to the region map will be applied to the bubble maps used in the arbitration and mediation
                  // stats.  A forced page reload on one of the bubble maps will make that bubbles config the
                  // "sticky" one.  The upshot is we need to have the same bubblesConfig for both maps, even though
                  // the bubbles aren't even used in the region map.
                  //
                  // Don't know if this affects other map config items as well.
                  bubblesConfig: {
                    borderWidth: 0,
                    borderColor: '#FFFFFF',
                    popupOnHover: true,
                    radius: null,
                    fillKey: 'defaultBubble',
                    fillOpacity: 0.75,
                    animate: true,
                    highlightOnHover: true,
                    highlightFillColor: '#1b8a8c',
                    highlightBorderWidth: 0,
                    highlightFillOpacity: 0.85,
                    exitDelay: 100
                  },
                  done: function(){
                    /**
                    * Resize map to fit inside the parent container.
                    */
                    window.addEventListener('resize', function() {
                     $scope.mapRegion.resize();
                    });

                    /**
                    * Iterate over the countries and regions appending as attribute.
                    * We will use this to handle to hide show tooltips and set the 
                    * active region background color.
                    */ 
                    var geography = geographyService.getRegions();
                    _.forEach(geography.region, function(v, k){
                      _.forEach(v.countries, function(cName){
                        angular.element('.'+cName).attr('data-region', k);
                      });
                    });

                    // expose tooltip control to the $scope so we can show/hide them from the legend
                    $scope.showTooltip = function (curRegion) {
                      var isNewRegion = $scope.activeRegion !== curRegion ? true : false;

                      clearTimeout($scope.uiMapTimer);

                      //don't show tooltips if there is a select menu open as that may cause overlap issues
                      if(document.querySelector('.ui-select-container.open')){
                        return;
                      }

                      // Reset old style attributes
                      if(isNewRegion){
                        reset(curRegion);
                      }
                      update(curRegion);
                    };

                    $scope.hideTooltip = function() {
                      $scope.uiMapTimer = setTimeout(function(){ reset(null); }, 100);
                    };

                    /**
                    * Add an event listener passing hover events to sibling elements
                    */
                    angular.element('path').on('mousemove', function(){
                      $scope.showTooltip(angular.element(this).attr('data-region'));
                    });

                    angular.element('path').on('mouseleave', function(){
                      $scope.hideTooltip();
                    });

                    angular.element('#'+mapContainer+'-tooltip').on('mouseenter', function(){
                      clearTimeout($scope.uiMapTimer);
                    });

                    angular.element('#'+mapContainer+'-tooltip').on('mouseleave', function(){
                      $scope.hideTooltip();
                    });
                    
                    /**
                    * Helper Functions
                    */
                    function reset(activeRegion){
                      var oldRegionCountries = document.querySelectorAll('[data-region='+ $scope.activeRegion +']');
                      _.forEach(oldRegionCountries, function(c){
                        c.style.fill = options.fills.default;
                        c.style.stroke = options.fills.default;
                      });
                      $scope.activeRegion = activeRegion;
                      
                      // tooltip
                      var tooltip = document.getElementById(mapContainer+'-tooltip');
                      tooltip.innerHTML = '';
                      tooltip.setAttribute('style', 'visibility:hidden;');
                    }
                   
                    function latLngToXY(lat, lng){
                      if(lat || lng){}
                      var s = angular.element(document).find('#' + mapContainer + ' svg'); // SVG element
                      var t = angular.element(document).find('#' + mapContainer + '-tooltip'); // Tooltip element
                      var x =  ((lng + 180) * s.width()) / 360 - (t.outerWidth() / 2); // left or longitude
                      var y =  ((lat + 90) * s.height()) / 180; // bottom or latitude

                      return {posY:y , posX:x };
                    }

                    function update(curRegion){
                      // Region color fill
                      var activeRegionCountries = document.querySelectorAll('[data-region='+curRegion+']');
                      _.forEach(activeRegionCountries, function(c){
                        c.style.fill = options.fills.active;
                        c.style.stroke = options.fills.active;
                      });

                      // Tooltip
                      var metricIndx = _.findKey($scope.tipData, { region: _.camelCase(curRegion) });
                      var metric = $scope.tipData[metricIndx]; 
                      var regionData = _.pluck(geography, _.camelCase(curRegion))[0];
                      var position = latLngToXY(regionData.latitude, regionData.longitude);

                      var tooltipElement = angular.element(document).find('#' + mapContainer+'-tooltip');
                      var mapElement = angular.element(document).find('#' + mapContainer + ' .datamap');
                      var body = angular.element(document).find('body');
                      var minLeft = 0 - mapElement.position().left; // if left is less than this value, then tooltip will be cut off on left-hand side of screen
                      var maxLeft = body.width() - mapElement.position().left - tooltipElement.outerWidth(); // if left is more than this value, then tooltip will be cut off on right-hand side of screen
                      var left = Math.min(position.posX, maxLeft);
                      left = Math.max(left, minLeft);

                      tooltipElement.css('bottom', position.posY)
                              .css('left', left)
                              .css('visibility', 'visible');

                      tooltipElement.html('<div class="row">'+
                        '<div class="col-sm-12 tootip-title">'+
                          '<div class="icon-line pull-left"></div>'+
                          '<div class="icon-line pull-right"></div>'+
                          '<i class="'+metric.icon+'"></i>'+
                          '<div class="number-fact-container">'+
                            '<span class="fact">'+
                              '<p>'+
                              '<span class="clearfix fact-focus">'+
                                metric.label+
                              '</span>'+
                              '</p>'+
                            '</span>'+
                          '</div>'+
                          '<div class="full-line"></div>'+
                        '</div>'+
                        '<div class="col-sm-12 tootip-content">'+

                          '<h6 class="type-centered title">Mediation</h6>'+
                          '<div class="row">'+
                            // 1 Fact
                            '<div class="col-sm-12">'+
                              '<span class="label">'+
                                'Case Percentage'+
                              '</span>'+
                              '<span class="content">'+
                                metric.medPct+
                              '</span>'+
                            '</div>'+
                            // 2 Fact
                            '<div class="col-sm-12">'+
                              '<span class="label">'+
                                'Avg Length'+
                              '</span>'+
                              '<span class="content">'+
                                metric.medLength +
                              '</span>'+
                            '</div>'+
                          '</div>'+
                           // TODO: Get facts from Metric API
                          '<h6 class="type-centered title">Arbitration</h6>'+
                          '<div class="row">'+
                            // 1 Fact
                            '<div class="col-sm-12">'+
                              '<span class="label">'+
                                'Case Percentage'+
                              '</span>'+
                              '<span class="content">'+
                                metric.arbPct+
                              '</span>'+
                            '</div>'+
                            // 2 Fact
                            '<div class="col-sm-12">'+
                              '<span class="label">'+
                                'Avg Length'+
                              '</span>'+
                              '<span class="content">'+
                                metric.arbLength +
                              '</span>'+
                            '</div>'+
                            // 3 Fact
                            '<div class="col-sm-12">'+
                              '<span class="label">'+
                                'Avg Claim Amount'+
                              '</span>'+
                              '<span class="content">'+
                                metric.arbDemandAmt+
                              '</span>'+
                            '</div>'+
                          '</div>'+
                        '</div>'+
                      '</div>');
                    } 
                  }
                });
              }
            }

            _refreshData();
            
            $scope.$on('refreshData', _refreshData);

            $scope.$watch('state', function(){
              if ($scope.state === 'NO_DATA'){
                $scope.$emit('noData');
              }
            });

            function _refreshData(){
              $scope.state = 'LOADING';
              
              /*build the data for the tooltips - for now, at least, this is just going to be hardcoded since we're going
              to be mapping to a very specific UI. This could potentially be expanded in the future to map to a more
              generic tooltip.*/
              var isPublic = $scope.widget.isPublic,
                  organizationId = ($scope.filters ? $scope.filters.organizationId : ''),
                  statuses = ($scope.filters ? $scope.filters.status : ['ACTIVE']),
                  promises = {
                caseRegion: analyticsService.loadMetric(['caseRegion'], {status: statuses, organizationId: organizationId}, undefined, $scope.internal, $scope.suppression, isPublic),
                medTotalDays: analyticsService.loadMetric(['caseRegion'], {processType: 'MEDIATION', status: statuses, organizationId: organizationId}, 'medTotalDays', $scope.internal, $scope.suppression, isPublic),
                arbTotalDays: analyticsService.loadMetric(['caseRegion'], {processType: 'ARBITRATION', status: statuses, organizationId: organizationId}, 'arbTotalDays', $scope.internal, $scope.suppression, isPublic),
                arbDemandAmt: analyticsService.loadMetric(['caseRegion'], {processType: 'ARBITRATION', status: statuses, organizationId: organizationId}, 'normDemandAmt', $scope.internal, $scope.suppression, isPublic)
              };

              /*this isn't great. ideally the back end could support an aggregation where it could give us a percentage
               within a group but since that's not built out yet we have to run a query grouping by processType and filtered
               by each individual caseType. This is going to result in N queries each time the chart is loaded where N is
               the number of possible caseTypes*/
              _.forEach(standardOptions.regions, function(r){
                promises['process_' + r] = analyticsService.loadMetric(['processType'], {caseRegion: r, status: statuses, organizationId: organizationId}, undefined, $scope.internal, $scope.suppression, isPublic);
              });

              $q.all(promises).then(function(results){

              var _wrappedSortedData = _(standardOptions.regions).map(function(v){
                  return _.find(results.caseRegion, function(i){return v === _.first(i.groups);}) || {groups: [v], percentage: 0};
                });

              // Set placholder with expected values
              // Get new data from metrics 
              // Overlay metrics 

              $scope.tipData = _wrappedSortedData.map(function(item){
                return {
                  region: _.camelCase(item.groups[0]),
                  label: _buildLabel(item),
                  value: item.percentage,
                  icon:  fonticonService.getIcon(_buildLabel(item)),
                  medPct: _toolTipPct(_.find(results['process_' + _.first(item.groups)], function(i){
                    return _.first(i.groups) === 'MEDIATION';
                  })),
                  medLength: _toolTipDuration(_.find(results.medTotalDays, _findMatchByGroup)),
                  arbPct: _toolTipPct(_.find(results['process_' + _.first(item.groups)], function(i){
                    return _.first(i.groups) === 'ARBITRATION';
                  })),
                  arbLength: _toolTipDuration(_.find(results.arbTotalDays, _findMatchByGroup)),
                  arbDemandAmt: _toolTipCurrency(_.find(results.arbDemandAmt, _findMatchByGroup))
                };

                function _findMatchByGroup(i){
                  return i.groups[0] === item.groups[0];
                }

                function _toolTipPct(item){
                  var percentage = (!item || !item.percentage ? 0 : item.percentage);
                  return $filter('analyticsPercentageValue')(percentage) + '<sup>%</sup>';
                }

                function _toolTipDuration(item){
                  if(!item || !item.avg){
                    return 'No Data';
                  }

                  return $filter('analyticsDuration')(item.avg);
                }

                function _toolTipCurrency(item){
                  if(!item || !item.avg){
                    return 'No Data';
                  }

                  return $filter('analyticsCurrency')(item.avg);
                }
              }).value();
              
              

              $scope.keyData = standardOptions.regions;
            })
            .then(function () {
              if (_.some($scope.tipData, 'value')) {
                $scope.state = 'DONE';

                // We only want to load the map when there are values to display; if we try to load the map for the
                // first time when there is no data, then the map draws itself with the wrong sizes.
                $timeout(function(){
                  _loadMap($scope.widget.mapContainer);
                });
              } else {
                $scope.state = 'NO_DATA';
              }
            });
            }
          }]
        };
      }]);
});
