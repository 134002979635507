/**
 * Common functionality.
 */
define('common/main',['angular', 
    './services/language', './services/loadIndicator', './services/geography', './services/standardOptions', './services/fonticon', './services/util', './services/services',
    './filters', './directives/components',  './directives/fonticon', './directives/case', './directives/organization','./directives/user',
    './directives/featureToggles', './services/modal', './directives/utilities'],
    function(angular) {
  'use strict';

  return angular.module('common', [ 
    'common.language','common.filters', 'common.directives.components', 
    'common.directives.fonticon', 'common.modal', 'common.geography', 'common.directives.utilities', 'common.directives.case', 'common.directives.organization', 'common.directives.user', 'common.directives.featureToggles',
    'common.loadIndicator', 'common.standardOptions', 'ui.select', 'angularFileUpload',
    'ngSanitize', 'ui.bootstrap', 'ngAside', 'common.fonticon', 'common.util', 'common.services']);
});

define('common', ['common/main'], function (main) { return main; });

