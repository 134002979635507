define('status/routes',['angular'], function (angular) {
  'use strict';

  return angular.module('status.routes', [])
      .config(['$routeProvider', function ($routeProvider) {
        var partialsDir = '/assets/javascripts/status/partials/';

        $routeProvider.when('/status', {
          templateUrl: partialsDir + 'status.html',
          authorizedRoles: ['ADMIN']
        });
      }]);
});

