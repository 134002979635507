define('cases/controllers',['angular'], function(angular) {
  'use strict';

  return angular.module('cases.controllers', [])

  .controller('CaseTableCtrl', ['$scope', '$log', '$location', 'securityService', 'caseDataService', 'standardOptions', '_', 'moment', '$anchorScroll', '$modal',
    function($scope, $log, $location, securityService, caseDataService, standardOptions, _, moment, $anchorScroll, $modal) {

      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Initialization                            
      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
      /*load the config if loadPrevConfig is set in the query string, otherwise
      * initialize the config to an empty/default object. This allows us to
      * selectively reinitialize the page with previously saved searches/filters*/
      var prevConfig = $location.search().loadPrevConfig && caseDataService.getConfig();
      $scope.config = prevConfig || {
        limit: 10,
        offset: 0,
        sort: {},
        filters: {
          caseNum: '',
          caseEntryType: '',
          status: '',
          caseType: '',
          caseRegion: '',
          processType: '',  
          updatedTime: '',
          editorUserEmail: '',
          orgName: '',
          updatedTimeSince: '',
          updatedTimeBefore: '',
          importRowId: ''
        }
      };

      $scope.isContribAdminView = securityService.currentUserHasRole('CONTRIBUTOR_ADMIN');
      $scope.isAdminView = securityService.currentUserHasRole('ADMIN');
      $scope.selectAll = false;
      $scope.caseSelections = [];

      _initOptions();

      //run the data population function once to initialize
      _refreshData();


      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Scope Functions                           
      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

      $scope.changeLimit = function(){
        //changing the limit throws off pagination so start back at page 0
        $scope.config.offset = 0;
        _refreshData();
      };

      $scope.prev = function(){
        $scope.config.offset--;
        _refreshData();
        $anchorScroll();
      };

      $scope.next = function(){
        $scope.config.offset++;
        _refreshData();
        $anchorScroll();
      };

      $scope.goToPage = function(page){
        $scope.config.offset = page-1; //pages are 1-based offset is 0-based
        _refreshData();
        $anchorScroll();
      };

      $scope.updatedTimeSelected = function(item){
        $scope.config.filters.updatedTimeBefore = angular.copy(item.end);
        $scope.config.filters.updatedTimeSince = angular.copy(item.start);
        $scope.config.filters.updatedTime = angular.copy(item.updatedTime);
      };

      $scope.updateSort = function(field){
        if(field === $scope.config.sort.field){
          if($scope.config.sort.direction === 'asc'){
            $scope.config.sort.direction = 'desc';
          } else {
            $scope.config.sort = {};
          }
        } else {
          $scope.config.sort.field = field;
          $scope.config.sort.direction = 'asc';
        }
        _refreshData();
      };

      $scope.sortClass = function(field){
        if(field === $scope.config.sort.field){
          return $scope.config.sort.direction;
        } else {
          return 'none';
        }
      };

      $scope.doSelect = function(caseEntry) {
        var caseSelection = _.find($scope.caseSelections, {id: caseEntry.id});
        if(caseSelection) {
          caseSelection.selected = caseEntry.selected;
        } else {
          $scope.caseSelections.push({
            id: caseEntry.id,
            status: caseEntry.status,
            selected: caseEntry.selected
          });
        }
      };

      $scope.doSelectAll = function() {
        if($scope.selectAll) {
          _.forEach($scope.cases, function(caseEntry) {
            caseEntry.selected = true;
            $scope.doSelect(caseEntry);
          });
        } else {
          _clearSelection();
        }
      };

      $scope.doStatusChange = function() {
        if($scope.selectAll) {
          var notSelected = $scope.getSelectedIds(false);
          caseDataService.selectedStatuses($scope.config.filters, notSelected).then(function (result) {
            $scope.statusSelection = result;
            _showStatusChangeDlg();
          });
        } else {
          var selected = _.filter($scope.caseSelections, { selected: true });
          if(selected.length > 0) {
            var distinct = _.reduce(selected, function(acc, cs) {
                if(!_.contains(acc, cs.status)) {
                   acc.push(cs.status);
                }
                return acc;
              }, []);
            $scope.statusSelection = {
              statuses: distinct,
              count: selected.length
            };
            _showStatusChangeDlg();
          }
        }
      };

      $scope.getSelectedIds = function(selected) {
        return _.filter($scope.caseSelections, { selected: selected }).map(function(cs) {
          return cs.id;
        });
      };

      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Watches                           
      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

      //whenever the filters change, refresh the table data
      $scope.$watch('config.filters', function(newVal, oldVal){
        if(!_.isEqual(newVal, oldVal) && !$scope.cancelFilterChange){
          if(_anySelected()) {
            _confirmFilterChange(oldVal);
          } else {
            $scope.config.offset = 0;
            _clearSelection();
            _refreshData();
          }
        } else {
          $scope.cancelFilterChange = false;
        }
      }, true);

      $scope.$watch('config', function(newVal){
        caseDataService.setConfig(newVal);
      }, true);


      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Private Functions                           
      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

      function _refreshData() {
        var sort = _.isEmpty($scope.config.sort) ? {field: 'updatedTime', direction: 'desc'} : $scope.config.sort;
        caseDataService.load($scope.config.limit, $scope.config.offset, sort, $scope.config.filters)
          .then(function (result) {
            
            $scope.cases = result.data;
            $scope.total = result.total;

            $scope.pagination = new Pagination($scope.config.limit, $scope.config.offset, $scope.cases, $scope.total);

            // apply any existing case selections
            _.forEach($scope.cases, function(caseEntry) {
              var caseSelection = _.find($scope.caseSelections, {id: caseEntry.id});
              if(caseSelection) {
                caseEntry.selected = caseSelection.selected;
              } else if($scope.selectAll) {
                caseEntry.selected = true;
                $scope.caseSelections.push({
                  id: caseEntry.id,
                  status: caseEntry.status,
                  selected: caseEntry.selected
                });
              }
            });

            function Pagination(limit, offset, cases, total){

              var self = this;

              //the current page based on the offset
              this.page = offset + 1;

              //the index of the first item on the current page
              this.pageItemStart = offset*limit+1;

              //the index of the last item on the current page
              this.pageItemEnd = offset*limit+cases.length;

              //the total number of un-paginated items
              this.totalItems = total;

              //the total number of pages 
              this.totalPages = Math.ceil(self.totalItems/limit);

              this.showPrevious = function(){
                return self.page > 1;
              };

              this.showNext = function(){
                return self.page < self.totalPages;
              };

              this.getPreviousLinkablePages = function(){
                var i = self.page - 1;
                var prevPages = [];
                while(i > 0 && prevPages.length <= 2){
                  prevPages.unshift(i);
                  i--;
                }
                return prevPages;
              };

              this.getNextLinkablePages = function(){
                var i = self.page + 1;
                var nextPages = [];
                while(i <= self.totalPages && nextPages.length <= 2){
                  nextPages.push(i);
                  i++;
                }
                return nextPages;
              };

            } 
             
          });
      }
      function _initOptions(){
        $scope.updatedTime = {label: 'All', value: ''};
        $scope.options = {
          processType: _buildOptions('processTypes', true),
          caseType: _buildOptions('caseTypes'),
          caseRegion: _buildOptions('regions'),
          caseEntryType: _buildOptions('caseEntryTypes', true),
          updatedTime: _buildDateOptions(),
          caseStatus: _buildCaseStatusOptions(),
          limits: _buildLimitOptions()
        };

        function _buildOptions(standardOptionsKey, convertKeysToCaps){
          var keyOverrides = {
            "In Review": "REVIEW"
          };
          //turn standardOptions (string array into array of {label: , value: })
          return _.union(
            [{label: 'All', value: ''}],
            _.map(standardOptions[standardOptionsKey], function(o){
              var value = keyOverrides[o] || o;
              return {label: o, value: convertKeysToCaps ? value.toUpperCase() : value};
            })
          );
        }
        function _buildCaseStatusOptions(){
          var keyOverrides = {
            "In Review": "REVIEW"
          };
          //turn standardOptions (string array into array of {label: , value: })
          var standardOptionsArray = angular.copy(standardOptions.caseStatuses);
          var caseStatuses = $scope.isAdminView ? standardOptionsArray : _.pull(standardOptionsArray,  'Cancelled');
          return _.union(
            [{label: 'All', value: ''}],
            _.map(caseStatuses, function(o){
              var value = keyOverrides[o] || o;
              return {label: o, value: value.toUpperCase()};
            })
          );
        }
        function _buildDateOptions(){
          var now = moment().startOf('day');
          var week = now.clone().subtract(6, 'days');
          var month = now.clone().subtract(1, 'months');
          var sixMonths = now.clone().subtract(6, 'months');
          var q1 = _getQuarterRange(1);
          var q2 = _getQuarterRange(2);
          var q3 = _getQuarterRange(3);
          var q4 = _getQuarterRange(4);

          return [
            {label: 'All', value: ''},
            {label: 'Last week', value: {start: '', end: '', updatedTime: week.valueOf()}},
            {label: 'Last month', value: {start: '', end: '', updatedTime: month.valueOf()}},
            {label: 'Last 6 months', value: {start: '', end: '', updatedTime: sixMonths.valueOf()}},
            {label: 'Quarter 1', value: {start: q1.start.valueOf(), end: q1.end.valueOf()}, updatedTime: ''},
            {label: 'Quarter 2', value: {start: q2.start.valueOf(), end: q2.end.valueOf()}, updatedTime: ''},
            {label: 'Quarter 3', value: {start: q3.start.valueOf(), end: q3.end.valueOf()}, updatedTime: ''},
            {label: 'Quarter 4', value: {start: q4.start.valueOf(), end: q4.end.valueOf()}, updatedTime: ''}
          ];
        }
        function _getQuarterRange(quarter) {
          var start = moment().quarter(quarter).startOf('quarter');
          var end = moment().quarter(quarter).endOf('quarter');
          return {start: start, end: end};
        }

        function _buildLimitOptions(){
          return [
            {label: '10 per page',   value: 10},
            {label: '25 per page',   value: 25},
            {label: '50 per page',   value: 50},
            {label: '100 per page',  value: 100},
            {label: '1000 per page', value: 1000}
          ];
        }
      }

      function _anySelected() {
        return $scope.getSelectedIds(true).length > 0 ||
          $scope.selectAll && $scope.getSelectedIds(false).length < $scope.total;
      }

      function _clearSelection() {
        _.forEach($scope.cases, function(caseEntry) {
          caseEntry.selected = false;
        });
        $scope.selectAll = false;
        $scope.caseSelections = [];
      }

      function _showStatusChangeDlg() {
        $modal.open({
          animation: true,
          backdrop: 'static',
          windowClass: 'case-list-select-status-modal',
          templateUrl: 'assets/javascripts/cases/partials/statusChangeDlg.html',
          size: 'large',
          scope: $scope
        }).result.then(function() {
          _showStatusResultDlg();
        });
      }

      function _showStatusResultDlg() {
        $modal.open({
          animation: true,
          backdrop: 'static',
          windowClass: 'case-list-select-status-modal',
          templateUrl: 'assets/javascripts/cases/partials/statusResultDlg.html',
          size: 'large',
          scope: $scope
        }).result.then(function() {
          _clearSelection();
          _refreshData();
        });
      }

      function _confirmFilterChange(oldFilters) {
        $modal.open({
          animation: true,
          backdrop: 'static',
          windowClass: 'case-list-select-status-modal',
          templateUrl: 'assets/javascripts/cases/partials/filterChangeDlg.html',
          size: 'large'
        }).result.then(function(ok) {
          if(ok) {
            $scope.config.offset = 0;
            _clearSelection();
            _refreshData();
          } else {
            $scope.cancelFilterChange = true;
            $scope.config.filters = oldFilters;
          }
        });
      }
  }])
  .controller('CaseListStatusDlgCtrl', ['$scope', '$log', '_', 'standardOptions', 'caseDataService',
    function($scope, $log, _, standardOptions, caseDataService) {

    $scope.available = _.map($scope.statusSelection.statuses, function(name) {
      return _.find(standardOptions.caseStatusTransitions, {name: name}).transitions;
    }).reduce(function(acc, statuses) {
      return _.intersection(acc, statuses);
    });
    $scope.status = {};

    $scope.applyStatus = function() {
      if($scope.status.changeTo) {
        var promise;
        if($scope.selectAll) {
          promise = caseDataService.updateAllStatuses($scope.config.filters, $scope.getSelectedIds(false), $scope.status.changeTo);
        } else {
          promise = caseDataService.updateStatuses($scope.getSelectedIds(true), $scope.status.changeTo);
        }
        promise.then(function() {
          $scope.$close();
        },
        function(error) {
          $scope.error = error.data;
        });
      } else {
        $scope.error = 'You must select a case status.';
      }
    };

    $scope.clearError = function() {
      $scope.error = undefined;
    };
  }]);
});

