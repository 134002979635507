define('common/directives/utilities',['angular'], function(angular) {
  'use strict';

  return angular.module('common.directives.utilities', [])
  
  .directive('modal', ['modalService', function(modalService){
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      templateUrl: '/assets/javascripts/common/partials/modal.html',
      scope: true,
      link: function(scope){
        scope.modal = modalService.get();
        scope.$watch('modal.display', function(){
          if(scope.modal.display) {
            var _el = angular.element('#'+scope.modal.attribute.id);
            _el.modal({ backdrop: 'static', show:true });
          }
        });
      }
    };
  }])
  .directive('tooltip', [function(){
    return{
      restrict: 'A',
      scope : {
         tooltipContents: '='
      },
      link: function(scope, element){
         scope.$watch('tooltipContents', function(value) {
          if(value !== undefined){
            angular.element(element).tooltip({title : value, placement : 'top'});
          }
        });
      }
    };
  }]);
});
