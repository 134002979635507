define('intake/directives',['angular'], function(angular) {
  'use strict';

  return angular.module('intake.directives', [])

  .directive('questionInput', [function() {

    var link = function(scope, elm, attrs) {
      scope.getContentUrl = function() {
        return '/assets/javascripts/intake/partials/inputs/' +
          attrs.template + '.html';
      };
    };

    var controller = [
      '$scope', 'surveyService', 'caseService', 'validationService', 'moment', '_','months',
      function($scope, surveyService, caseService, validationService, moment, _, months) {

        /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
         Initialization                           
         ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

        $scope.filteredBy = $scope.survey.modelField($scope.question.filteredBy);

        $scope.options = _buildInitialOptions();
        $scope.dateOptions = _buildDateOptions();

        $scope.uiSelect = {};

        _initializeSelects();
        _initializeDate();


        /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
         Scope Functions                           
         ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

        // descriptive text to display after the field; used to implement currency type on currency value fields
        $scope.getFieldDescription = function() {
          var fieldDescriptionRef = $scope.question.fieldDescriptionRef;
          return fieldDescriptionRef && $scope.caseEntry[fieldDescriptionRef];
        };

        /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
         Watches                           
         ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

        /**
         * When the answer to the question that THIS question's options are
         * filtered by is changed, recalculate the available options. Ex. when
         * the region value changes we need to recalculate the list of available
         * countries. If the value we already have selected is no longer valid
         * then remove it.
         */
        $scope.$watch(
          function() {
            return $scope.caseEntry[$scope.filteredBy];
          },
          function(newValue) {
            if (newValue && $scope.filteredBy &&
              $scope.question && $scope.question.possibleValues) {
              $scope.options = surveyService.createOptions($scope.question, newValue);
              // should be able to use _.includes() here, but could not get it to work
              // with $scope.options
              if (!_.findWhere($scope.options, { value: $scope.caseEntry[$scope.question.modelField] })) {
                $scope.caseEntry[$scope.question.modelField] = null;
                if($scope.uiSelect){
                  delete $scope.uiSelect.selected;
                }
              }
            }
          }, true);

        /**
         * When a select element changes we need the selected value to the
         * model field that is backing that select element. Normally we would
         * just bind from the select element straight to the model, but because
         * we are supporting an "Other" option in the select in some cases we
         * want to bind the value of another input field to the model. This
         * $watch handles all that
         */
        $scope.$watch('uiSelect', function(newValue) {
          if ('select' !== $scope.question.type) {
            return;
          }
          if (newValue && newValue.selected) {
            if(newValue.selected.name === 'Please Select'){
              $scope.caseEntry[$scope.question.modelField] = null;
            } else {
              $scope.caseEntry[$scope.question.modelField] = newValue.selected.value;
            }
          }

        }, true);

        /**
         * Each of the three select inputs are bound to individual fields on a
         * "date" model object. When any of those fields changes, if any of the 
         * three fields have a value then construct a date and and bind it to the
         * correct model field on the caseEntry 
         */
        $scope.$watch('date', function(date) {
          if ($scope.question.type !== 'date') {
            return;
          }

          var day = date.day !== 'Day' && date.day,
            month = date.month !== 'Month' && date.month,
            year = date.year !== 'Year' && date.year;

          if (day || month || year) {
            day = day && _.padLeft(day, 2, '0');
            var m = moment(day + month + year, 'DDMMMMYYYY', true);
            $scope.caseEntry[$scope.question.modelField] = m;
          } else {
            $scope.caseEntry[$scope.question.modelField] = null;
          }

        }, true);

        /**
         * When the caseEntry modelField associated with the current question
         * changes, rerun the validations and assign any resulting errors to the
         * question.
         */
        $scope.$watch(function() {
            return $scope.caseEntry[$scope.question.modelField];
          }, function(){
            //add errors to the question itself so it's available in the parent scope
            $scope.question.errors = validationService.getErrors(
                ['BASIC', 'DRAFT', 'REVIEW', 'ACTIVE', 'ADMIN', 'DATE'], $scope.question,
                $scope.survey, $scope.caseEntry);
            //also if the question has composition validations, rerun those too.
            //TODO: figure out a better way to do this
            if($scope.question.reviewValidations && $scope.question.reviewValidations.composition){
              _.forEach(
                _.map($scope.question.reviewValidations.composition.sumOf ,function(rQ){
                 return $scope.survey.questionFor(rQ);
                }), function(q){
                   q.errors = validationService.getErrors(
                                   ['BASIC', 'DRAFT', 'REVIEW', 'ACTIVE', 'ADMIN', 'DATE'], q,
                                   $scope.survey, $scope.caseEntry);
                }
              );
            }
             if($scope.question.childFields){
              _.forEach(
                _.map($scope.question.childFields ,function(rQ){
                 return $scope.survey.questionFor(rQ);
                }), function(q){
                   q.errors = validationService.getErrors(
                                   ['BASIC', 'DRAFT', 'REVIEW', 'ACTIVE', 'ADMIN', 'DATE'], q,
                                   $scope.survey, $scope.caseEntry);
                }
              );
            }
           });

        /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
         Private Functions                           
         ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

        /**
         * the initial set of options that should be present when the directive
         * is first loaded.
         * TODO: what happens if the filtering question is on a previous page? 
         *       will the options ever be populated?
         */
        function _buildInitialOptions() {
          if (!$scope.question.filteredBy) {
            return surveyService.createOptions($scope.question);
          } else if($scope.caseEntry[$scope.filteredBy]) {
            return surveyService.createOptions($scope.question, $scope.caseEntry[$scope.filteredBy]);
          } else {
            return [];
          }
        }

        function _buildDateOptions(){
          var now = moment().year();
          var firstYear = $scope.question.firstYear || moment(2005);
          return {
            days: ['Day'].concat(_.range(1, 32)),
            months: ['Month'].concat(months),
            years: ['Year'].concat(_.range(firstYear, now+1).reverse())
          };
        }

        

        function _initializeSelects() {
          var modelVal = $scope.caseEntry[$scope.question.modelField];     
          // Add any previous values for SELECT & SELECT WITH OTHER that are defined 
          // by the model value.
          if ('select' === $scope.question.type && (modelVal || modelVal === 0 || modelVal === false)) {
            $scope.uiSelect.selected = _.findWhere($scope.options, {
              value: modelVal
            });
          }
        }

        function _initializeDate() {
          $scope.date = {};
          var modelVal = $scope.caseEntry[$scope.question.modelField];
          if ($scope.question.type === 'date' && modelVal) {
            var m = moment(modelVal);
            $scope.date = {
              day: m.date(),
              month: m.format('MMMM'),
              year: m.year()
            };
          }
        }
      }
    ];
    return {
      restrict: 'A',
      link: link,
      scope: '=',
      controller: controller,
      template: '<div ng-include="getContentUrl()"></div>'
    };
  }])

  .directive('errorMessage', [function(){
    
    var controller = ['$scope', '$filter', 'moment', '_', function($scope, $filter, moment, _){

      $scope.displayError = function(err){
        return _getMessageBuilder(err.type)(err);
      };

      /*initialize the errors inside of a watch since the errors associated with
      * the question might change after this $scope is initialized*/
      //if the error is composition, we also want to watch all of the questions involved in the composition
      $scope.$watch('question', function(){
        var q = $scope.question;
        $scope.errors = _(q.errors)
            .filter(function(e){
              return _.includes($scope.errorTypes, e.type);
            })
            .filter(function(e){
              /*if the value is a partially completed date, only include date
              errors if the question was submitted with a date error*/
              if(e.type === 'BASIC' && q.type === 'date'){
                /*this is terrible, but checking the _i property was the only way I
                 could figure out if the moment object was partially filled in.
                 isInvalid() won't work here because that's also validating
                 things like February, 31*/
                var m = $scope.caseEntry[q.modelField];
                var partiallyFilled = m && m._i && (m._i.indexOf('false') > -1 || m._i.indexOf('undefined') > -1);
                return !partiallyFilled || _.findWhere(q.submittedErrors, {type: 'BASIC'});
              }
              return true;
            })
            .value();
      }, true);


      var builders = {

        basicBuilder: function(error){
          var questionType = error.questionType;
          switch(questionType){
            case 'date':
              return 'Please enter a valid date';
            case 'currency':
              return 'Please enter a valid currency amount, rounded to the nearest whole number';
            case 'integer':
              return 'Please enter a valid whole number amount';
            default: 
              return error;
          }
        },

        dateBuilder: function(error){
          if(error.dateErr === 'notbefore') {
            return "This date must be on or before " + moment().utcOffset(14).format('LL');
          }
          else {
            var refText = $scope.survey.questionFor(error.ref).text;
            return "This date must be on or after " + refText + " " + moment(error.refDate).format('LL');
          }
        },

        matcherBuilder: function(error){
          var matcher = error.matcher;
          switch(matcher){
            
            case 'present': 
              if(error.type === 'DRAFT'){
                return 'This question must be answered before entering additional case data';
              } else {
                return 'This question must be answered before submitting the case';  
              }
              break;
            case 'any':
              return 'The value for this question must be one of ' + error.condition.join(', ');

            case 'not':
                return 'The value for this question must not be ' + error.condition;
            
            case 'gt':
              return 'The value for this question must be greater than ' + conditionDisplay(error);
            
            case 'gte':
              return 'The value for this question must be greater than or equal to ' + conditionDisplay(error);

            case 'lt':
              return 'The value for this question must be less than ' + conditionDisplay(error);

            case 'lte':
              return 'The value for this question must be less than or equal to ' + conditionDisplay(error);

            case 'integer':
                return "The value for this question must be an integer";
            case 'composition':
                return 'Please make sure that the sum of Female & Male Arbitrators is less than the number of arbitrators specified (' + conditionDisplay(error) + ')';
            default:
              return error;
          }

          function conditionDisplay(error){
            if('currency' === error.questionType){
              return  $filter('currency')(error.condition, '$', 0) + ' in 2013 USD';
            }
            return error.condition;
          }
        }
      };

      function _getMessageBuilder(errorType){
        return {
          'BASIC': builders.basicBuilder,
          'DATE': builders.dateBuilder,
          'DRAFT': builders.matcherBuilder,
          'REVIEW': builders.matcherBuilder,
          'ACTIVE': builders.matcherBuilder,
          'ADMIN': builders.matcherBuilder
        }[errorType];
      }
    }];
    
    return {
      restrict: 'AE',
      scope: {
        question: '=',
        survey: '=',
        caseEntry: '=',
        errorTypes: '='
      },
      templateUrl: '/assets/javascripts/intake/partials/errorMsg.html',
      controller: controller 
    };  
  }])

  .directive('surveyErrorMessage', [function(){

    var controller = ['$scope', '_', function($scope, _){
      $scope.displayError = function(err){
        return _buildErrorMessage(err);
      };

      $scope.$watch('survey', function(survey){
        if(survey) {
          $scope.errors = _(survey.errors)
              .filter(function (e) {
                return _.includes($scope.errorTypes, e.type);
              }).value();
        }
      }, true);

      function _buildErrorMessage(err) {
        var validator = err.customValidator;
        switch(validator){
          case 'atLeastOneOf':

            return "Please specify at least one of the following: [  " +
                _(err.condition)
                .map(function(c){
                      return $scope.survey.questionFor(c).text;
                  })
                .join('  |  ') + "  ]";

          case 'exactlyOneOf':
            return "Please specify one and only one of the following: [  " +
                _(err.condition)
                    .map(function(c){
                      return $scope.survey.questionFor(c).text;
                    })
                    .join('  |  ') + "  ]";
          case 'numOfArbsTotal':
            return "Please make sure that sum of female and male arbitrators is no more than the number of arbitrators specified";
          default :
            return err;
        }
      }


    }];

    return {
      restrict: 'AE',
      scope: {
        survey: '=',
        errorTypes: '='
      },
      templateUrl: '/assets/javascripts/intake/partials/errorMsg.html',
      controller: controller
    };
  }])

  .directive('progressBar', ['_', function(_){
    var controller = [
     '$scope', '$location',
      function($scope, $location){  
        $scope.$watch('survey', function(survey){
          if(!_.isEmpty(survey) && !$scope.progress){
            $scope.progress = angular.copy($scope.survey.asPages());
            $scope.progress.isReview = $location.url().indexOf('/review?') !== -1;       
            $scope.progress.push({section:[], title:'Case Review'});  
          }
        });
      }
    ];
    return {
      restrict: 'AE',
      templateUrl: '/assets/javascripts/intake/partials/progress.html',
      replace: false,
      transclude: true,
      controller: controller
    };

  }])
  
  .directive('payment', [ function(){
    var controller = [
      '$scope','standardOptions', 'caseService', 'moment', '$cookieStore', '_',
      function($scope, standardOptions, caseService, moment, $cookieStore, _){
        

        // Scope variables
        $scope.isAdminView = _checkAdminRole( $cookieStore.get('loggedIn') );
        $scope.casePaymentStatuses = standardOptions.casePaymentStatuses;
        $scope.showCommentSection = false;
        $scope.showPaymentSection = false;
        $scope.showPaymentDelete = false;
        $scope.paymentStatusUpdated = false;
        $scope.paymentStatusError = false;

        // Object to hold case payment information
        $scope.status = { 
          payment: {
            date : null,
            status : ''
          }
        };

        // Only run if admin and feature is toggled "on"
        if($scope.isAdminView && $scope.showAdminFeature){
          // Watches
          $scope.$watch('status.status', function(){
            $scope.togglePayment();
          });

          $scope.$watch('status.payment.status', function(){
            $scope.togglePayment();
            $scope.paymentStatusUpdated = false;
          });

          $scope.$watch('caseEntry', function(){
            if($scope.caseEntry){
              _buildPayments();
            }
          });
        }

        // Scope functions
        // Hide and or show payment IU elements
        $scope.togglePayment = function(forceOpt){
          if(forceOpt){
            $scope.showPaymentSection = forceOpt;
          }
          // Hide section if status is upaid
          if($scope.status.payment.status ===  $scope.casePaymentStatuses[0]){
            $scope.showPaymentSection = true;
          }
          else{
            $scope.showPaymentSection = false;
          }
          // Hide/ show delete payment button
          if($scope.status.payment.status === $scope.casePaymentStatuses[1]){
            if($scope.caseEntry.paymentDate !== undefined){
               $scope.showPaymentDelete = true;
            }
          }
          else if($scope.status.payment.status === $scope.casePaymentStatuses[0] && $scope.showPaymentDelete === true){
            $scope.showPaymentDelete = false;
          }
        };

        // Save case payment information to the API
        $scope.savePaymentStatus = function() {
          var valid = _paymentValidation();
          if(valid){
            // Set case values to somthing the API can handel
            $scope.caseEntry.paymentDate = $scope.status.payment.date;
            caseService.createOrUpdate($scope.caseEntry).then( function(){
              _resetUIelements();
            });
          }
          else{
            $scope.paymentStatusUpdated = false;
            $scope.paymentStatusError = true;
          }
        };

        $scope.removePayment = function() {
          if($scope.status.payment.status === 'Unpaid'){
            $scope.caseEntry.paymentDate = null;
            caseService.createOrUpdate($scope.caseEntry).then( function(){
              _resetUIelements();
            });
          }
        };

        // Private functions
        function _buildPayments(){
          // status
          $scope.status.payment.status = $scope.caseEntry.paymentDate ? $scope.casePaymentStatuses[0] : $scope.casePaymentStatuses[1];
          
          // date
          if($scope.caseEntry.paymentDate){
            var rawDate = $scope.caseEntry.paymentDate;
            var d = moment(rawDate).format('DD');
            var m = moment(rawDate).format('MM');
            var y = moment(rawDate).format('YYYY');
              $scope.status.payment.date = y+ '-' + m + '-' + d;
          }
        }
        // Confim case payment is well formed
        function _paymentValidation(){
          var paymentDate = $scope.status.payment.date;
          var regx = /^\d{4}-\d{2}-\d{2}$/;
          if(paymentDate && regx.test(paymentDate)){
            return true;
          }
          else{
            return false;
          } 
        }

        function _resetUIelements(){
          $scope.togglePayment(false);
          $scope.showPaymentDelete = false;
          $scope.paymentStatusUpdated = true;
          $scope.paymentStatusError = false;
        }

        function _checkAdminRole(user){
          var hasRole = false;
          _.forEach(user.roles, function(n){
            if(n === 'ADMIN'){
              hasRole = true;
            }
          });
          return hasRole;
        }
    }];
      
    return {
      restrict: 'E',
      templateUrl: '/assets/javascripts/intake/partials/payment.html',
      replace: true,
      transclude: true,
      scope: '=',
      controller: controller
    };
  }])

  .directive('paymentview', [ function(){
    var controller = [
      '$scope','standardOptions', 'moment',
      function($scope, standardOptions, moment){
        
        // Scope variables
        $scope.casePaymentStatuses = standardOptions.casePaymentStatuses;

        // Object to hold case payment information
        $scope.paymentStatus = { 
          payment: {
            date : null,
            status : ''
          }
        };

        // Watches
        $scope.$watch('caseEntry', function(){
          if($scope.caseEntry){
            _buildPayments();
          }
        });

        // Private functions
        function _buildPayments(){
          // status
          $scope.paymentStatus.payment.status = $scope.caseEntry.paymentDate ? $scope.casePaymentStatuses[0] : $scope.casePaymentStatuses[1];
          // date
          if($scope.caseEntry.paymentDate){
            var rawDate = $scope.caseEntry.paymentDate;
            $scope.paymentStatus.payment.date = moment(rawDate).format('D MMM YYYY');
          }
        }
    }];
      
    return {
      restrict: 'E',
      templateUrl: '/assets/javascripts/intake/partials/paymentview.html',
      replace: true,
      transclude: true,
      scope: '=',
      controller: controller
    };
  }])
  
  .directive('history',[ function(){
    var controller = [
      '$scope', '$log', 'caseService', 'caseServiceHistory',
      function($scope, $log, caseService, caseServiceHistory){

        // Scope variables
        $scope.caseEntryHistory = {}; //just a wrapper to be populated later
        $scope.showComments = false;
        $scope.status = { 
          comment : '', 
          status : ''
        };

        if($scope.isAdmin){
          _loadCaseHistory()
          .then( 
            function(caseHistoryResponse){
              $scope.caseEntryHistory = caseHistoryResponse;
              $scope.status.status = $scope.caseEntryHistory[0].toStatus;
            }
          );

          // Watches
          $scope.$watch('status.status', function(){
              $scope.toggleComment();
          });
        }

        // Scope functions
        // Clear comment
        $scope.clearComment = function(){
          $scope.status.comment = '';
        };

        // Save modifications to API
        $scope.saveStatus = function() {
          $scope.caseEntry.status = $scope.status.status;
          $scope.caseEntry.statusComment = $scope.status.comment;
          caseService.createOrUpdate($scope.caseEntry).then( function(){
              $scope.caseStatuses = $scope.buildOptions('caseStatuses', true);
              _loadCaseHistory().then(
                function(caseHistoryResponse){
                  $scope.caseEntryHistory = caseHistoryResponse;
              }).then(
              function(){
                $scope.clearComment();
                $scope.toggleComment(false);
              });
          });
        };

        // Hide / show comment section
        $scope.toggleComment = function(forceOpt){
          if(forceOpt){
            $scope.showComments = forceOpt;
          }
          else{
            if($scope.caseEntry.status && $scope.status.status !== $scope.caseEntry.status){
              $scope.showComments = true;
            }
            else{
              $scope.showComments = false;
            }
          }
        };

        // Private functions
        // hit API for cashe history
        function _loadCaseHistory() {
          return caseServiceHistory.load($scope.caseId);
        }

      }
    ];
    return {
      restrict: 'E',
      templateUrl: '/assets/javascripts/intake/partials/history.html',
      replace: false,
      transclude: true,
      scope: '=',
      controller: controller
    };

  }])
  .directive('caseEditor',[ function(){
    var controller = [
      '$scope', 'caseService',
      function($scope, caseService){

        $scope.showSaveEditor = false;

        $scope.caseEditor = {};


        $scope.saveEditor = function() {
          $scope.caseEntry.editorUserId = $scope.caseEditor.editorUserId;
          caseService.createOrUpdate($scope.caseEntry).then(function(caseEntryResponse){
            $scope.caseEntry = caseEntryResponse;
            $scope.toggleCaseEditorSave(false);
          });
        };
        $scope.toggleCaseEditorSave = function(forceOpt){
          if(forceOpt){
            $scope.showSaveEditor = forceOpt;
          }
          else{
            if($scope.caseEntry.editorUserId && $scope.caseEditor.editorUserId !== $scope.caseEntry.editorUserId){
              $scope.showSaveEditor = true;
            }
            else{
              $scope.showSaveEditor = false;
            }
          }
        };
        // Watches
        $scope.$watch('caseEditor.editorUserId', function(){
          $scope.toggleCaseEditorSave();
        });
//        $scope.caseEditorSelected = function(){
//            $scope.toggleCaseEditorSave();
//        };
      }
    ];
    return {
      restrict: 'E',
      templateUrl: '/assets/javascripts/intake/partials/caseEditor.html',
      replace: false,
      transclude: true,
      scope: '=',
      controller: controller
    };

  }]);
});
