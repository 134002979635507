define('analytics/widgets/tooltips/caseTypeOverviewTooltip',['angular'], function (angular) {
  'use strict';
  return angular.module('widgets-caseTypeOverviewTooltip', [])
      .controller('CaseTypeOverviewTooltipCtrl', ['$log', '$scope', '$filter', '_', function ($log, $scope, $filter, _) {

        $scope.$watch('hilighted', function () {
          if ($scope.hilighted) {
            var d = $scope.chartData.raw;

            $scope.tip = {
              medPct: _toolTipPct(_.find(d['process_' + $scope.hilighted.label], function (i) {
                return _.first(i.groups) === 'MEDIATION';
              })),
              medLength: _toolTipDuration(_.find(d.medTotalDays, _findMatchByGroup)),
              arbPct: _toolTipPct(_.find(d['process_' + $scope.hilighted.label], function (i) {
                return _.first(i.groups) === 'ARBITRATION';
              })),
              arbLength: _toolTipDuration(_.find(d.arbTotalDays, _findMatchByGroup)),
              arbDemandAmt: _toolTipCurrency(_.find(d.arbDemandAmt, _findMatchByGroup))
            };
          }

          function _findMatchByGroup(i) {
            return _.first(i.groups) === $scope.hilighted.label;
          }

          function _toolTipPct(item) {
            return (!item || !item.percentage ? 0 : item.percentage);
          }

          function _toolTipDuration(item) {
            if (!item || !item.avg) {
              return 'No Data';
            }

            return $filter('analyticsDuration')(item.avg);
          }

          function _toolTipCurrency(item) {
            if (!item || !item.avg) {
              return 'No Data';
            }

            return $filter('analyticsCurrency')(item.avg);
          }
        }, true);


      }]);
});
