define('cases/routes',['angular'], function(angular) {
    'use strict';

    return angular.module('cases.routes', [])

    .config(['$routeProvider', function($routeProvider) {
        $routeProvider
            .when('/cases/view', {
                templateUrl: '/assets/javascripts/cases/partials/view.html',
                authorizedRoles: ['ADMIN', 'CONTRIBUTOR_ADMIN', 'CONTRIBUTOR']
            });
    }]);
});
