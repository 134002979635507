/**
 * User package module.
 * Manages all sub-modules so other RequireJS modules only have to import the package.
 */
define('user/main',['angular', './routes', './services', './controllers', './directives'], function(angular) {
  'use strict';

  return angular.module('user', ['ngCookies', 'ngRoute', 'ng.shims.placeholder', 
    'user.routes', 'user.services', 'user.controllers', 'user.directives']);
});

define('user', ['user/main'], function (main) { return main; });

