/**
 * Dashboard, shown after user is logged in.
 * dashboard/main.js is the entry module which serves as an entry point so other modules only have
 * to include a single module.
 */
define('dashboard/main',['angular', './routes', './controllers', './directives', './services'], 
  function(angular) {
  'use strict';

  return angular.module('dashboard', [
    'ngRoute',
    'dashboard.routes',
    'dashboard.controllers',
    'dashboard.directives',
    'dashboard.services'
   ]);
});

define('dashboard', ['dashboard/main'], function (main) { return main; });

