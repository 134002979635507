define('intake/routes',['angular', 'common'], function(angular) {
  'use strict';

  var mod = angular.module('intake.routes', ['common']);
  mod.config(['$routeProvider', function($routeProvider) {
    $routeProvider
      .when('/intake',  {
        templateUrl: '/assets/javascripts/intake/partials/intake.html',
        authorizedRoles: ['ADMIN', 'CONTRIBUTOR_ADMIN', 'CONTRIBUTOR']
      })
      .when('/intake/review',  {
        templateUrl: '/assets/javascripts/intake/partials/review.html',
        authorizedRoles: ['ADMIN', 'CONTRIBUTOR_ADMIN', 'CONTRIBUTOR']
      })
      .when('/intake/confirm',  {
        templateUrl: '/assets/javascripts/intake/partials/confirm.html',
        authorizedRoles: ['ADMIN', 'CONTRIBUTOR_ADMIN', 'CONTRIBUTOR']
      });
  }]);
  return mod;
});

