// `main.js` is the file that sbt-web will use as an entry point
(function (requirejs) {
  'use strict';

  // -- RequireJS config --
  requirejs.config({
    // Packages = top-level folders; loads a contained file named 'main.js"
    packages: [
      'admin',
      'analytics',
      'cases',
      'common',
      'dashboard',
      'home',
      'intake',
      'organizations',
      'pattern',
      'membership',
      'search',
      'status',
      'user'
    ],
    shim: {
      'angular': {
        deps: ['jquery'],
        exports: 'angular'
      },
      'chart.js': {
        deps: ['angular', 'chart']
      },
      //angular plugins
      'angular-cookies': ['angular'],
      'angular-file-upload': ['angular'],
      'angular-route': ['angular'],
      'angular-sanitize': ['angular'],
      'angular-select-ui': ['angular'],
      'angular-shims-placeholder': ['angular'],
      'angular-translate': ['angular'],
      'angular-ui-utils': ['angular'],
      'angulartics': ['angular'],
      'angulartics-ga': ['angular'],
      'angular-ui-bootstrap': ['angular'],
      'angular-aside': ['angular'],
      'angular-stripe': ['stripe', 'angular'],

      //other libraries
      'bootstrap': ['jquery', 'angular'],
      'ui-grid': ['angular']
    },
    paths: {
      
      //Angular and plugins
      'angular': ['//cdn.jsdelivr.net/webjars/angularjs/1.3.17/angular.min'],
      'angular-cookies': ['//cdn.jsdelivr.net/webjars/angularjs/1.3.17/angular-cookies.min'],
      'angular-file-upload': ['//cdn.jsdelivr.net/webjars/angular-file-upload/3.2.4/angular-file-upload.min'],
      'angular-route': ['//cdn.jsdelivr.net/webjars/angularjs/1.3.17/angular-route.min'],
      'angular-sanitize': ['//cdn.jsdelivr.net/webjars/angularjs/1.3.17/angular-sanitize.min'],
      'angular-select-ui': ['//cdn.jsdelivr.net/webjars/angular-ui-select/0.9.6/select.min'],
      'angular-shims-placeholder': ['angular-shims-placeholder'],
      'angular-translate': ['//cdn.jsdelivr.net/webjars/angular-translate/2.6.1/angular-translate.min'],
      'angular-ui-utils': ['//cdn.jsdelivr.net/webjars/angular-ui-utils/0.2.2/ui-utils.min'],
      'angulartics': ['../lib/angulartics/angulartics.min'],
      'angulartics-ga': ['../lib/angulartics/angulartics-ga.min'],
      'angular-chart': ['//cdn.jsdelivr.net/webjars/angular-chart.js/1.1.1/angular-chart.min'],
      'angular-ui-bootstrap': ['//cdn.jsdelivr.net/webjars/angular-ui-bootstrap/0.13.0/ui-bootstrap-tpls.min'],
      'angular-aside': ['angular-aside'],
      'angular-stripe': ['angular-stripe'],

      //other libraries
      'requirejs': ['//cdn.jsdelivr.net/webjars/requirejs/2.1.14-1/require.min'],
      'jquery': ['//cdn.jsdelivr.net/webjars/jquery/2.1.3/jquery.min'],
      'bootstrap': ['//cdn.jsdelivr.net/webjars/bootstrap/3.3.2/js/bootstrap.min'],
      'lodash': ['../lib/lodash/lodash'],
      'moment': ['//cdn.jsdelivr.net/webjars/momentjs/2.10.2/moment'],
      'filesaver': ['FileSaver'],
      // append random parameter and value (in this case ?a=a) to the end of stripe path to prevent requirejs from adding .js extension
      'stripe': ['https://js.stripe.com/v2/?a=a']
    },
    config: {
      moment: {
        noGlobal: true
      }
    }
  });

  requirejs.onError = function (err) {
    console.log(err);
  };

  require([
    'angular', 
    'angular-cookies', 
    'angular-file-upload',
    'angular-route', 
    'angular-sanitize',  
    'angular-select-ui', 
    'angular-shims-placeholder', 
    'angular-translate', 
    'angular-ui-utils', 
    'angulartics', 
    'angulartics-ga',
    'angular-chart',
    'angular-ui-bootstrap',
    'angular-aside',
    'angular-stripe',
    'jquery',
    'bootstrap',
    'filesaver',
    'stripe',
    './app'
  ],
    function (angular) {
      angular.bootstrap(document, ['app']);
    }
  );
})(requirejs);

define("main", function(){});

