define('analytics/directives',['angular', 'filesaver'], function(angular) {
  'use strict';

  return angular.module('analytics.directives', [])
  .directive('horizontalFilters', [function(){          
    return {
      restrict: 'E',
      templateUrl: '/assets/javascripts/analytics/partials/horizontalFilters.html',
      transclude: true,
      scope: '=',
      //controller: function controllerConstructor($scope, $element, $attrs, $transclude){},
      link: function postLink(scope) { 
        function elmInView(config){
          var winPos, elmPos;
          winPos = angular.element(window).scrollTop();
          elmPos = angular.element(config.element).offset().top;                     
          return elmPos <= winPos;
        }
        angular.element(window).on('scroll', function(){
          scope.$on('$locationChangeStart', function() {
            angular.element(window).unbind();
          });
          var body = angular.element('body');
          var watchElm = angular.element('.analytics-main-header');
          var inView = elmInView({element: watchElm, watch : 'top'});
          switch(inView)
          {
            case true:
              body.addClass('sticky');
            break;
            case false:
              body.removeClass('sticky');
            break;
          } 
        }); 
      }
    };
    }])
    
    .directive('statsFilters', [function(){          
    return {
      restrict: 'E',
      templateUrl: '/assets/javascripts/analytics/partials/statsFilters.html',
      transclude: true,
      scope: '=',
      link: function postLink(scope) {
        function sticky() {
          var winPos = angular.element(window).scrollTop();
          var elmHeight = angular.element(document).find("nav.navbar").height();
          return winPos > elmHeight;
        }
        function updateStickiness() {
          var body = angular.element('body');
          if (sticky()) {
            body.addClass('sticky');
          } else {
            body.removeClass('sticky');
          }
        }
        angular.element(window).on('scroll', function(){
          scope.$on('$routeChangeStart', function() {
            angular.element(window).unbind();
          });
          updateStickiness();
        });
        updateStickiness(); // run this here so when the directive initially loads we check to set/clear any stickiness as necessary
      }
    };
    }])
  .directive('sideArbFilters', ['_', function(_){
    return{
        restrict: 'AE',
        templateUrl: '/assets/javascripts/analytics/partials/sideFilters/arbFilters.html',
        scope: false,
        link: function(scope){
            scope.$on("$destroy",function() {
              var filters = {
                minDemandAmt : '',
                maxDemandAmt : '',
                clmPartyRegion : {},
                rspPartyRegion : {},
                numArbitrators : '',
                caseConclusion : '',
              };
              _.assign(scope.filters, filters);
            });
        }
    };
  }])
  .directive('sideMedFilters', ['_' ,function(_){
    return{
        restrict: 'AE',
        templateUrl: '/assets/javascripts/analytics/partials/sideFilters/medFilters.html',
        scope: false,
        link: function(scope){
            scope.$on("$destroy",function() {
                var filters = {
                    medCaseConclusion : '',
                    minMedReqFileYear : '',
                    maxMedReqFileYear : '',
                    medPartyRegion : {},
                };
                _.assign(scope.filters, filters);
            });
        }
    };
  }])
  .directive('sideFilters', [function(){
    return{
      restrict: 'AE',
      templateUrl: '/assets/javascripts/analytics/partials/sideFilters.html',
      scope: '=',
      /*link: function(scope){
        function elmInView(config){
          var winPos, elmPos;
          switch(config.watch){
            case 'bottom':
              winPos = angular.element(window).scrollTop();
              elmPos = angular.element(config.element).offset().top + angular.element(config.element).outerHeight();
            break;
            case 'top':
              winPos = angular.element(window).scrollTop() + angular.element(window).innerHeight();
              elmPos = angular.element(config.element).offset().top;              
            break;
          }       
          return elmPos <= winPos;
        }
        function setElmHeight(navElm){
         var viewHeight = angular.element(window).innerHeight();
         var elmHeight = angular.element(navElm).outerHeight();
          if(viewHeight <= elmHeight){
            angular.element(navElm).css('height', (viewHeight - 100)+'px');
          }
        }
        function clearElmHeight(navElm){
          angular.element(navElm).css('height', '');
        }
        angular.element(window).on('scroll', function(){
          scope.$on('$locationChangeStart', function() {
            angular.element(window).unbind();
          });
          var navElm = angular.element('#analytics-filters-conainter');
          var watchElm = angular.element('.analytics-main-header');
          var inView = elmInView({element: watchElm, watch : 'bottom'});
          switch(inView)
          {
            case true:
              navElm.addClass('sticky');
              setElmHeight(navElm);
            break;
            case false:
              navElm.removeClass('sticky');
              clearElmHeight(navElm);
            break;
          } 
          var inViewFooter = elmInView({element:'footer', watch : 'top'});
          switch(inViewFooter){
            case true:
              var viewHeight = angular.element(window).innerHeight();
              angular.element(navElm).css('height', (viewHeight - 300)+'px');
              angular.element(navElm).addClass('tuck');
            break;
            case false:
              if(angular.element(navElm).hasClass('tuck')){
                angular.element('#analytics-filters-conainter').css('height', '');
                angular.element(navElm).removeClass('tuck');
              }
            break;
          }
        }); 
      }*/
    };
  }])

  .directive('caseRegionFilter', [function(){
    return{
      restrict: 'E',
      templateUrl: '/assets/javascripts/analytics/partials/caseRegionFilter.html',
      scope: {
        options: '=',
        filters: '=',
        filter: '@'
      }};
  }])

  .directive('caseTypeFilter', [function(){
    return{
      restrict: 'E',
      templateUrl: '/assets/javascripts/analytics/partials/caseTypeFilter.html',
      scope: {
        options: '=',
        filters: '=',
        filter: '@'
      }};
  }])
  .directive('caseConclusionFilter', [function(){
    return{
      restrict: 'E',
      templateUrl: '/assets/javascripts/analytics/partials/caseConclusionFilter.html',
      scope: {
        options: '=',
        filters: '=',
        filter: '@'
      }};
  }])
  .directive('processTypeFilter', [function(){
    return{
      restrict: 'E',
      templateUrl: '/assets/javascripts/analytics/partials/processTypeFilter.html',
      scope: {
        options: '=',
        filters: '=',
        filter: '@'
      }};
  }])

  .directive('widgetCell', [function(){
    return{
      restrict: 'A',
      templateUrl: '/assets/javascripts/analytics/partials/widgetCell.html',
      scope: {
        widget: '=',
        filters: '=',
        pageId: '=',
        internal: '=',
        suppression: '=',
        filterMask: '=',
        id: '@',
        compareMode: '=',
        right: '=',
        displayConfig: '=',
        detailedStatsHeaders: '='
      }};
  }])

  .directive('widgetHeader', [function(){
    return{
      restrict: 'E',
      templateUrl: '/assets/javascripts/analytics/partials/widgetHeader.html',
      controller: 'WidgetHeaderCtrl',
      scope: true
    };
  }])

  .directive('metricCount', [function(){
    return {
      restrict: 'A',
      templateUrl: '/assets/javascripts/analytics/partials/metrics/basiccount.html',
      scope: {
        metric: '='
      }
    };  
  }])
  
  .directive('metricUngroupedAverage', [function(){
    return {
      restrict: 'A',
      templateUrl: '/assets/javascripts/analytics/partials/metrics/ungroupedAverage.html',
      scope: {
        metric: '='
      }
    };
  }])

  .directive('metricBasicDuration', [function(){
    return {
      restrict: 'A',
      templateUrl: '/assets/javascripts/analytics/partials/metrics/basicDuration.html',
      scope: {
        metric: '='
      }
    };
  }])

  .directive('metricBoolean', [function(){

    var controller = ['$scope', '_', function($scope, _){
      $scope.getYesPercentage = function(){
        var yesElement =  $scope.metric.data && _.find($scope.metric.data.elements, function(element){
          return element.groups && _.intersection(element.groups, ['t', 'Yes', $scope.metric.expectedValue]).length;
        });

        return yesElement ? yesElement.percentage : 0;
        
      };
    }];

    return {
      restrict: 'A',
      templateUrl: '/assets/javascripts/analytics/partials/metrics/yesOnlyBoolean.html',
      scope: {
        metric: '=',
      },
      controller: controller
    };
  }])

  .directive('horizontalBar', [function(){
        var controller = ['$scope', 'analyticsService', '_', function ($scope, analyticsService, _) {
          $scope.$watch('dataElement', function(dataElement){
            var data = dataElement && _.pluck(dataElement.metrics, 'data'); //build an array of just the data
            if(!_.isEmpty(data)){
              $scope.chartData = analyticsService.transformForChart(dataElement);
            }
          }, true);

          $scope.getValueDisplay = function(idx){
            return $scope.chartData && $scope.chartData.data && $scope.chartData.data[idx] + '%';
          };

          $scope.getBarWidth = function(idx){
            return $scope.chartData && $scope.chartData.data && $scope.chartData.data[idx] * 0.85 + '%';
          };

          $scope.hasData = function(){
            var el = $scope.dataElement;
            return el && el.metrics[0].data && el.metrics[0].data.length;
          };
        }];
    return {
      restrict: 'A',
      templateUrl: '/assets/javascripts/analytics/partials/metrics/horizontalBar.html',
      scope: {
        dataElement: '='
      },
      controller: controller
    };
  }])

  .directive('barChart', [function(){
    var controller = ['$scope', 'analyticsService', '_', function($scope, analyticsService, _){
      $scope.$watch('dataElement', function(dataElement){
        var data = dataElement && _.pluck(dataElement.metrics, 'data'); //build an array of just the data
        if(!_.isEmpty(data)){
          $scope.chartData = analyticsService.transformForChart(dataElement);
        }
      }, true);

      $scope.chartOptions = {
        maintainAspectRatio: false,
        animation: true,
        tooltipTemplate: "<%= value %>%",
        tooltipFillColor: "#EDEDED",
        tooltipFontFamily: '"proxima-nova", sans-serif',
        tooltipFontColor: "#017389",
        tooltipFontSize: 27,
        onAnimationComplete: function()
        {
          this.showTooltip(this.datasets[0].bars, true);
        },
        tooltipEvents: [],
        showTooltips: true,
        scaleShowHorizontalLines: false,
        scaleShowVerticalLines: false,
        scaleShowGridLines : false,
        scaleShowLabels:false,
        scaleFontFamily: '"proxima-nova", sans-serif',
        scaleFontSize: 16,
        scaleFontColor: "#3C4142",
        barShowStroke : false,
        responsive: true
      };

      $scope.colors = [{fillColor:'#A9D5DA'}];

      $scope.getColClass = function(){
        return 'col-sm-' + $scope.cols;
      };

      $scope.hasData = function(){
        var el = $scope.dataElement;
        return el && el.metrics[0].data && el.metrics[0].data.length;
      };

    }];
    return {
      restrict: 'A',
      templateUrl: '/assets/javascripts/analytics/partials/metrics/bar.html',
      scope: {
        dataElement: '=',
        cols: '='
      },
      controller: controller
    };
  }])

  .directive('bigPercent', [function(){
    var controller = ['$scope', '$filter', 'analyticsService', '_', function($scope, $filter, analyticsService, _){

      $scope.getNumber = function(metric){
        return metric && $filter('number')(100* analyticsService.getYesPercentage(metric.data), 0);
      };

      $scope.hasData = function(){
        var el = $scope.dataElement;
        return el &&
            el.metrics[0].data &&
            el.metrics[0].data.length &&
            _.isNumber(el.metrics[0].data[0].percentage);
      };
    }];

    return {
      restrict: 'A',
      templateUrl: '/assets/javascripts/analytics/partials/metrics/bigPercent.html',
      scope: {
        dataElement: '='
      },
      controller: controller
    };
  }])

  .directive('bigDuration', [function(){
    var controller = ['$scope', '$filter', '_', function($scope, $filter, _){
      $scope.getNumber = function(metric) {
        return metric && metric.data && metric.data.length && $filter('truncatingNumber')(metric.data[0].avg, 1);
      };

      $scope.hasData = function(){
        var el = $scope.dataElement;
        return el &&
            el.metrics[0].data &&
            el.metrics[0].data.length &&
            _.isNumber(el.metrics[0].data[0].avg);
      };
    }];

    return {
      restrict: 'A',
      templateUrl: '/assets/javascripts/analytics/partials/metrics/bigDuration.html',
      scope: {
        dataElement: '='
      },
      controller: controller
    };
  }])

  .directive('bigMoney', [function(){
    var controller = ['$scope', '$filter', '_', function($scope, $filter, _){
      $scope.getNumber = function(metric) {
        return metric && metric.data && metric.data.length && $filter('number')(metric.data[0].avg, 0);
      };

      $scope.hasData = function(){
        var el = $scope.dataElement;
        return el &&
            el.metrics[0].data &&
            el.metrics[0].data.length &&
            _.isNumber(el.metrics[0].data[0].avg);
      };
    }];

    return {
      restrict: 'A',
      templateUrl: '/assets/javascripts/analytics/partials/metrics/bigMoney.html',
      scope: {
        dataElement: '='
      },
      controller: controller
    };
  }])

  .directive('donut', [function(){
    var controller = ['$scope', 'analyticsService', function($scope, analyticsService){
      $scope.$watch('dataElement', function(dataElement){
        var data = dataElement && dataElement.metrics[0].data;
        if(data){
          $scope.chartData = analyticsService.transformForChart(dataElement);
        }
      }, true);

      $scope.chartOptions = {
        maintainAspectRatio: true,
        animation: true,
        tooltipTemplate: "<%= label %>: <%= value %>%",
        tooltipFillColor: "#EDEDED",
        tooltipFontFamily: '"proxima-nova", sans-serif',
        tooltipFontColor: "#3C4142",
        tooltipFontSize: 14,
        onAnimationComplete: function()
        {
          this.showTooltip(this.segments, true);
        },
        tooltipEvents: [],

        showTooltips: true,
        responsive: true
      };

      $scope.defaultChartColors = [
        '#D6E2D7',
        '#76A27A',
        '#5E7F60',
        '#425E43',
        '#79868A',
        '#555C5E',
        '#3C4142'
      ];

      $scope.hasData = function(){
        var el = $scope.dataElement;
        return el && el.metrics[0].data && el.metrics[0].data.length;
      };
    }];

    return {
      restrict: 'A',
      templateUrl: '/assets/javascripts/analytics/partials/metrics/donut.html',
      scope: {
        dataElement: '='
      },
      controller: controller
    };
  }])

  .directive("analyticsBlank", [function(){
    var controller = ['$scope', '_', function($scope, _){
      $scope.getColClass = function(){
        return _(['bigBar', 'horizontalBar']).includes($scope.dataElement.template) ? 'col-sm-12' : 'col-sm-4';
      };
    }];
    return {
      restrict: 'A',
      templateUrl: '/assets/javascripts/analytics/partials/metrics/blank.html',
      scope: {
        dataElement: '='
      },
      controller: controller
    };
  }])

  .directive("downloadMetric", ['$window', function($window) {
    return {
      restrict: 'E',
      templateUrl: '/assets/javascripts/analytics/partials/metrics/downloadMetric.html',
      scope: true,
      link: function(scope) {
        scope.$on('downloaded', function(event, data) {
          var blob = new Blob([ data.content ], { type : 'image/png' });
          $window.saveAs(blob, data.filename);
        });
      },
      controller: ['$scope', '$attrs', '$http', '$modal', function($scope, $attrs, $http, $modal) {
        $scope.generateModal = function(isCompareMode){
            if (isCompareMode) {
              var isComparativeStatImageModal = $modal.open({
                backdrop: 'static',
                templateUrl: 'assets/javascripts/analytics/partials/isComparativeStatImageModal.html',
                size: 'large',
                scope: $scope
              });

              isComparativeStatImageModal.result.then(function() {
              }).catch(function() {
              });
            }
            else{
              $scope.downloadMetric(false);
            }
        };
        $scope.downloadMetric = function(saveAsCompareMode, isRightSide){
          if (typeof navigator !== "undefined" && /MSIE [1-9]\./.test(navigator.userAgent)) {
            $modal.open({
              backdrop: 'static',
              templateUrl: 'assets/javascripts/common/partials/unsupportedBrowserModal.html',
              size: 'large'
            });
            return;
          }
          $scope.hasError = false;
          var cancelled = false;
          var modal = $modal.open({
            backdrop: 'static',
            templateUrl: 'assets/javascripts/analytics/partials/generatingImageModal.html',
            size: 'large',
            scope: $scope
          });

          modal.result.then(function() {
            cancelled = true;
          }).catch(function() {
            cancelled = true;
          });
          //add in attr to url for compareMode or not
          var downloadUrl = $attrs.url;
          if (saveAsCompareMode) {
            downloadUrl = $attrs.url + '&isCompareMode=true' + '&width=2600';
          }
          if (isRightSide) {
            downloadUrl = $attrs.url + '&isRightSide=true';
          }
          $http.get(downloadUrl, {responseType: "arraybuffer"}).then(function(response) {
            if (!cancelled) {
              $scope.$emit('downloaded', {filename: $attrs.filename+'-'+Date.now()+'.png', content: response.data});
              modal.close();
            }
          }).catch(function() {
            $scope.hasError = true;
          });
        };
      }]
    };
  }])
  ;
});
