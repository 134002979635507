define('status/widgets/basic/basic',['angular'], function (angular) {
  'use strict';

  return angular.module('status.widgets.basic', [])

      .directive('statusWidgetBasic', [ function(){
        return {
          restrict: 'E',
          replace: true,
          templateUrl: '/assets/javascripts/status/widgets/basic/basic.html',
          scope: {
            widget: '='
          },
          controller: ['$scope', 'statusData', 'moment', '_', function($scope, statusData, moment, _){
            statusData[$scope.widget.data]().then(function(resp){
              $scope.data = resp;
            });

            $scope.resultToText = function(){
              var mapping = {
                unknown: 'Unknown',
                grey: 'Offline',
                red: 'Error',
                yellow: 'Warning',
                green: 'Ok'
              };

              return mapping[$scope.data && $scope.data.result];
            };

            $scope.showCircle = function(result) {
              return _(['red', 'yellow', 'green']).contains(result);
            };

            $scope.displayLastCheckTime = function(){
              if($scope.data && $scope.data.lastCheckTime){
                return moment($scope.data.lastCheckTime).fromNow();
              }
            };
          }]
        };
      }]);
});
