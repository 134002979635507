define('status/services',['angular'], function (angular) {
  'use strict';

  return angular.module('status.services', [])


      .service('statusData', ['$q', '$http', function($q, $http){
        return {
          //todo: a real service would make an http call and return a promise in the same format.
          example: function(){
            return $q.when({result: 'green'});
          },
          currencyLayerStatus: function(){
            return $http.get('/api/status/currencylayer')
              .then(function (response) {
                return response.data;
              });
          },
          stripeApiStatus: function(){
            return $http.get('/api/status/stripe')
              .then(function (response) {
                return response.data;
              });
          },
          nbGlobalStatus: function(){
            return $http.get('/api/status/nationbuilder/global')
              .then(function (response) {
                return response.data;
              });
          },
          emailStatus: function(){
            return $http.get('/api/status/email')
              .then(function (response) {
                return response.data;
              });
          },
          envHealthDev: function(){
            return $http.get('/api/status/app-health/drd-dev')
              .then(function (response) {
                return response.data;
              });
          },
          envHealthUAT: function(){
            return $http.get('/api/status/app-health/drd-uat')
              .then(function (response) {
                return response.data;
              });
          },
          envHealthDemo: function(){
            return $http.get('/api/status/app-health/drd-demo')
              .then(function (response) {
                return response.data;
              });
          },
          envHealthProd: function(){
            return $http.get('/api/status/app-health/drd-prod')
              .then(function (response) {
                return response.data;
              });
          },
          nbSiteStatusDD: function(){
            return $http.get('/api/status/nationbuilder/site-health/dd')
              .then(function (response) {
                return response.data;
              });
          },
          nbSiteStatusProd: function(){
            return $http.get('/api/status/nationbuilder/site-health/prod')
              .then(function (response) {
                return response.data;
              });
          },

        };
      }]);
});

