define('membership/directives',['angular'], function (angular) {
	'use strict';

	return angular.module('membership.directives', [])
			.directive("editMembershipModalOpener", ['$modal', function ($modal) {
				return {
					restrict: 'A', scope: true, link: function (scope) {
						scope.openModal = function (pt, individualUserId, organizationId, membershipSummary, onClose) {
							var modal = $modal.open({
								backdrop: 'static',
								templateUrl: 'assets/javascripts/membership/partials/editMembershipModal.html',
								size: 'lg',
								controller: 'AddMembershipCtrl',
								resolve: {
									planType: function() {
										return pt;
									},
									individualUserId: function() {
										return individualUserId;
									},
									organizationId: function () {
										return organizationId;
									},
									membershipSummary: function() {
										return membershipSummary;
									}
								}
							});

							modal.result.then(function() {
								if (onClose) {
									onClose();
								}
							}).catch(function() {
								if (onClose) {
									onClose();
								}
							});
						};
					}
				};
			}])
			.directive("assignSeatsModalOpener", ['$modal', function ($modal) {
				return {
					restrict: 'A', scope: true, link: function (scope) {
						scope.openModal = function (membership) {
							$modal.open({
								backdrop: 'static',
								templateUrl: 'assets/javascripts/membership/partials/assignSeatsModal.html',
								size: 'lg',
								controller: 'AssignSeatsCtrl',
								resolve: {
									membership: function() {
										return membership;
									}
								}
							});
						};
					}
				};
			}]);
});
