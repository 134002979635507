define('membership/services',['angular'], function(angular) {
	'use strict';

	return angular.module('membership.services', [])
			.factory('membershipService', ['$http', '_', 'serviceWrapper',
				function($http, _, serviceWrapper) {
					return {
						fetchOrganizationMemberships: function(organizationId, successHandler, errorHandler) {
							return serviceWrapper.get('/api/memberships?ownerOrganizationId='+organizationId, '', successHandler, errorHandler);
						},
						fetchUserMemberships: function(userId, successHandler, errorHandler) {
							return serviceWrapper.get('/api/memberships?ownerUserId='+userId, '', successHandler, errorHandler);
						},
						createMembership: function(membership, successHandler, errorHandler) {
							return serviceWrapper.post('/api/memberships', membership, successHandler, errorHandler);
						},
						updateMembership: function(membership, successHandler, errorHandler) {
							return serviceWrapper.put('/api/memberships', membership, successHandler, errorHandler);
						}
					};
				}
			]);
});
