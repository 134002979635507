define('analytics/widgets/detailedStatsTable/detailedStatsTable',['angular'], function (angular) {
    'use strict';

    return angular.module('widgets-detailedStatsTable', [])
        .directive('widgetsDetailedStatsTable', ['analyticsService', '_', function(analyticsService, _) {
            return {
                restrict: 'A',
                templateUrl: '/assets/javascripts/analytics/widgets/detailedStatsTable/detailedStatsTable.html',
                scope: {
                    widget: '=',
                    filters: '=',
                    internal: '=',
                    suppression: '=',
                    filterMask: '=',
                    displayConfig: '=',
                    compareMode: '=',
                    right: '=',
                    pageId: '=',
                    detailedStatsHeaders: '='
                },
                controller: ['$scope', 'standardOptions', 'securityService', 'widgetHelpers', 'analyticsService', function($scope, standardOptions, securityService, widgetHelpers, analyticsService){

                    $scope.implicitArbFilters = ['sumJudgementMotionMade','nationalCourtsInvolved','hearingOccurred','counterFiled','counterPrevailed',
                        'awardCostsGranted','amicusCuriaeBriefIncluded','emerArbUsed','intervenerMotionMade','intervenerMotionGranted','ediscoveryUsed',
                        'normDemandAmt', 'normCounterDemandAmt', 'normCounterAwardAmt', 'normClaimantAwardAmt', 'normRespondentAwardAmt', 'normTotalInstFeeAmt',
                        'normTotalArbMedFeeAmt', 'normAwardCostsAmt', 'arbHearingDays', 'arbTotalDays', 'arbSettledDays', 'arbWithdrawnDays', 'arbAwardedDays',
                        'numArbitrators', 'caseConclusion', 'arbSelMethod', 'numMotionsFiled', 'clmPartyRegion', 'rspPartyRegion', 'hearingLocation', 'demandType',
                        'counterDemandType', 'prevailingParty', 'awardedTo', 'rulesUsed', 'numDepositions', 'numInterrogatories', 'claimantAwardToClaimQuartile',
                        'counterclaimantAwardToCounterclaimQuartile'
                    ];

                    $scope.implicitMedFilters = ['normInstFeeAmt', 'normMediatorFeeAmt', 'medTotalDays', 'medConferenceDays', 'medPartyRegion'];


                    $scope.allowViewCaseTotals = function() {
                        return securityService.isLoggedInAdmin();
                    };

                    $scope.isAdmin = function() {
                        return securityService.isLoggedInAdmin();
                    };

                    $scope.showFact = function(fact){
                        var showWidget = true;
                        if($scope.filters.processType && fact.implicitProcessType){
                            showWidget = fact.implicitProcessType ? fact.implicitProcessType === $scope.filters.processType : true;
                        }
                        return showWidget;
                    };

                    function _roundPercentages(data) {
                        var roundedNumbers = analyticsService.roundPercentageMetric(
                            _(data)
                                .map('percentage')
                                .map(function(x){ return x * 100;})
                                .value()
                        );
                        _.forEach(data, function(item, idx){
                             item.percentage = roundedNumbers[idx]/100;
                        });
                        return data;
                    }

                    function _buildBreakdownData(breakdown, metric, expectedValues) {
                        var data;

                        if (!expectedValues || _.size(metric.elements) === 0 || !metric.elements[0].groups) {
                            data = metric;
                        } else {
                            var groups = _.intersection(expectedValues, _.map(metric.elements, function (e) {
                                return e.groups[0];
                            }));
                            data = {
                                elements: _.map(groups, function (group) {
                                    var metricElement = _.find(metric.elements, function (e) {
                                        return e.groups && e.groups[0] === group;
                                    });
                                    var groupDisplay = (breakdown.config.displayMap && breakdown.config.displayMap[group] ? breakdown.config.displayMap[group] : group);
                                    return {groups: [groupDisplay], percentage: metricElement ? metricElement.percentage : 0};
                                })
                            };
                        }

                        return data;
                    }

                    function _buildBreakdownDataWithZeros(breakdown, metric, expectedValues) {
                        var data;

                        if (!expectedValues// || _.size(metric.elements) === 0 || !metric.elements[0].groups
                        ) {
                            data = metric;
                        } else {
                            var groups = _.union(expectedValues, _.map(metric.elements, function (e) {
                                return e.groups[0];
                            }));
                            data = {
                                elements: _.map(groups, function (group) {
                                    var metricElement = _.find(metric.elements, function (e) {
                                        return e.groups && e.groups[0] === group;
                                    });
                                    var groupDisplay = (breakdown.config.displayMap && breakdown.config.displayMap[group] ? breakdown.config.displayMap[group] : group);
                                    return {groups: [groupDisplay], percentage: metricElement ? metricElement.percentage : 0};
                                })
                            };
                        }

                        return data;
                    }

                    function _convertObjectToArray(value) {
                        if (!_.isObject(value) || _.isArray(value)) {
                            return value;
                        } else {
                            return _(value).pick(_.identity).keys().value();
                        }
                    }

                    //TODO: Only works for non-fact based detailed stats
                    var sortFn = function(){
                        if($scope.widget.config.sort === 'valuesDesc'){
                            return widgetHelpers.detStatsValuesDesc;
                        }
                    };


                    _refreshData();
                    $scope.$on('refreshData', _refreshData);
                    function _refreshData(){

                        //if we have


                        var filters = widgetHelpers.standardDetailedStatsFiltersOnly($scope.filters, $scope.filterMask);

                        if ($scope.widget.config.filters) {
                            _.assign(filters, $scope.widget.config.filters);
                        }

                        var expectedValues = $scope.widget.config.useStandardOptions ? standardOptions[$scope.widget.config.expectedValues] : $scope.widget.config.expectedValues;
                        var additionalFilters = {};
                        //if the item.key is in implicitArbFilters then send a filter of processType = 'Arbitration'
                        if(_.includes($scope.implicitArbFilters, $scope.widget.config.key) && !filters.processType){
                            additionalFilters = {processType:'ARBITRATION'};
                        }
                        //if the item.key is in implicitMedFilters then send a filter of processType = 'Mediation'
                        else if(_.includes($scope.implicitMedFilters, $scope.widget.config.key) && !filters.processType){
                            additionalFilters = {processType:'MEDIATION'};
                        }
                        $scope.metric = {};
                        $scope.metric.title = "";
                        $scope.metric.facts = [];

                        $scope.metric.facts = angular.copy($scope.widget.config.facts);

                        $scope.totalCases = 0;
                        if($scope.widget.config.subType === 'count'){
                            if($scope.metric.facts) {
                                _.forEach($scope.metric.facts, function (item, index) {
                                    var filters = widgetHelpers.standardDetailedStatsFiltersOnly($scope.filters, $scope.filterMask);
                                    if(item.filters){
                                        _.assign(filters, item.filters);
                                    }
                                    analyticsService.buildMetric(_.assign(_.mapValues(filters, _convertObjectToArray), additionalFilters), [item.key], undefined,
                                        item.title, undefined, $scope.internal, $scope.suppression).then(function (metric) {
                                        $scope.metric.facts[index].data = _buildBreakdownData($scope.widget, metric, expectedValues);
                                        $scope.metric.facts[index].title = item.title;
                                        $scope.totalCases = $scope.totalCases +  analyticsService.getMetricTotal($scope.widget.config,  $scope.metric.facts[index].data);
                                    });
                                });
                            }
                            else{
                                analyticsService.buildMetric(_.assign(_.mapValues(filters, _convertObjectToArray), additionalFilters), [$scope.widget.config.key], undefined,
                                    $scope.widget.title, undefined, $scope.internal, $scope.suppression).then(function (metric) {
                                    // we need to round percentage data.
                                    if(expectedValues? expectedValues.length === _.size(metric.elements) : true){
                                        metric.elements = _roundPercentages(metric.elements);
                                    }
                                    $scope.metric.data = _buildBreakdownData($scope.widget, metric, expectedValues);
                                    if (sortFn()) {
                                        $scope.metric.data.elements = _.sortBy($scope.metric.data.elements, sortFn());
                                    }
                                    $scope.metric.title = $scope.widget.title;
                                    $scope.totalCases = analyticsService.getMetricTotal($scope.widget.config, metric.elements);
                                });
                            }
                        }
                        if($scope.widget.config.subType === 'countWithZeros'){
                            if($scope.metric.facts) {
                                _.forEach($scope.metric.facts, function (item, index) {
                                    var filters = widgetHelpers.standardDetailedStatsFiltersOnly($scope.filters, $scope.filterMask);
                                    if(item.filters){
                                        _.assign(filters, item.filters);
                                    }
                                    analyticsService.buildMetric(_.assign(_.mapValues(filters, _convertObjectToArray), additionalFilters), [item.key], undefined,
                                        item.title, undefined, $scope.internal, $scope.suppression).then(function (metric) {
                                        $scope.metric.facts[index].data = _buildBreakdownDataWithZeros($scope.widget, metric, expectedValues);
                                        $scope.metric.facts[index].title = item.title;
                                        $scope.totalCases = $scope.totalCases +  analyticsService.getMetricTotal($scope.widget.config,  $scope.metric.facts[index].data);
                                    });
                                });
                            }
                            else{
                                analyticsService.buildMetric(_.assign(_.mapValues(filters, _convertObjectToArray), additionalFilters), [$scope.widget.config.key], undefined,
                                    $scope.widget.title, undefined, $scope.internal, $scope.suppression).then(function (metric) {
                                    // we need to round percentage data.
                                    if(expectedValues? expectedValues.length === _.size(metric.elements) : true){
                                        metric.elements = _roundPercentages(metric.elements);
                                    }
                                    $scope.metric.data = _buildBreakdownDataWithZeros($scope.widget, metric, expectedValues);
                                    if (sortFn()) {
                                        $scope.metric.data.elements = _.sortBy($scope.metric.data.elements, sortFn());
                                    }
                                    $scope.metric.title = $scope.widget.title;
                                    $scope.totalCases = analyticsService.getMetricTotal($scope.widget.config, metric.elements);
                                });
                            }
                        }
                        if($scope.widget.config.subType === 'boolean'){
                            if($scope.metric.facts) {
                                _.forEach($scope.metric.facts, function (item, index) {
                                    var filters = widgetHelpers.standardDetailedStatsFiltersOnly($scope.filters, $scope.filterMask);
                                    if(item.filters){
                                        _.assign(filters, item.filters);
                                    }
                                    analyticsService.buildMetric(_.assign(_.mapValues(filters, _convertObjectToArray),additionalFilters), [item.key], undefined,
                                        item.title, undefined, $scope.internal, $scope.suppression).then(function (metric) {
                                        $scope.metric.facts[index].data = metric;
                                        $scope.metric.facts[index].title = item.title;
                                        $scope.metric.facts[index].expectedValue = item.expectedValue;
                                        $scope.totalCases = $scope.totalCases +  analyticsService.getMetricTotal($scope.widget.config, [analyticsService.getYesElement(metric.elements)]);

                                    });
                                });
                            }
                            else{
                                analyticsService.buildMetric(_.assign(_.mapValues(filters, _convertObjectToArray),additionalFilters), [$scope.widget.config.key], undefined,
                                    $scope.widget.title, undefined, $scope.internal, $scope.suppression).then(function (metric) {
                                    $scope.metric.data = metric;
                                    $scope.metric.title = $scope.widget.title;
                                    $scope.totalCases = analyticsService.getMetricTotal($scope.widget.config, metric.elements);
                                });
                            }
                        }
                        if($scope.widget.config.subType === 'ungroupedAverage'){
                            if($scope.metric.facts) {
                                if($scope.widget.config.format === 'analyticsPercentage100Cutoff') {
                                    _.forEach($scope.metric.facts, function (item, index) {
                                        var filters = widgetHelpers.standardDetailedStatsFiltersOnly($scope.filters, $scope.filterMask);
                                        if(item.filters){
                                            _.assign(filters, item.filters);
                                        }
                                        analyticsService.buildMetric(_.assign(_.mapValues(filters, _convertObjectToArray), additionalFilters), undefined, item.key,
                                            item.title, 'analyticsPercentage100Cutoff', $scope.internal, $scope.suppression).then(function (metric) {
                                            $scope.metric.facts[index].data = metric;
                                            $scope.metric.facts[index].title = item.title;
                                            $scope.totalCases = analyticsService.getMetricTotal($scope.widget.config, $scope.metric.facts);
                                        });
                                    });
                                }
                                else {
                                    _.forEach($scope.metric.facts, function (item, index) {
                                        var filters = widgetHelpers.standardDetailedStatsFiltersOnly($scope.filters, $scope.filterMask);
                                        if(item.filters){
                                            _.assign(filters, item.filters);
                                        }
                                        analyticsService.buildMetric(_.assign(_.mapValues(filters, _convertObjectToArray), additionalFilters), undefined, item.key,
                                            item.title, 'analyticsCurrency', $scope.internal, $scope.suppression).then(function (metric) {
                                            $scope.metric.facts[index].data = metric;
                                            $scope.metric.facts[index].title = item.title;
                                            $scope.totalCases = analyticsService.getMetricTotal($scope.widget.config, $scope.metric.facts);
                                        });
                                    });
                                }
                            }
                            else{
                                analyticsService.buildMetric(_.assign(_.mapValues(filters, _convertObjectToArray), additionalFilters), undefined, $scope.widget.config.key,
                                    $scope.widget.title, 'analyticsCurrency', $scope.internal, $scope.suppression).then(function (metric) {
                                    $scope.metric.data = metric;
                                    $scope.metric.title = $scope.widget.title;
                                    $scope.totalCases = analyticsService.getMetricTotal($scope.widget.config, metric.elements);

                                });
                            }
                        }
                        else if($scope.widget.config.subType === 'basicDuration'){
                            if($scope.metric.facts) {
                                _.forEach($scope.metric.facts, function (item, index) {
                                    if(item.filters){
                                        _.assign(filters, item.filters);
                                    }
                                    analyticsService.buildMetric(_.assign(_.mapValues(filters, _convertObjectToArray), additionalFilters), undefined, item.key,
                                        item.title, undefined, $scope.internal, $scope.suppression).then(function (metric) {
                                        $scope.metric.facts[index].data = metric;
                                        $scope.metric.facts[index].title = item.title;
                                        //TODO: this can't be right. Need to show the lowest case total. There could be overlap. Same goes for other metrics
                                        $scope.totalCases = $scope.totalCases + analyticsService.getMetricTotal($scope.widget.config, metric.elements);
                                    });
                                });
                            }
                            else{
                                analyticsService.buildMetric(_.assign(_.mapValues(filters, _convertObjectToArray), additionalFilters), undefined, $scope.widget.config.key,
                                    $scope.widget.title, undefined, $scope.internal, $scope.suppression).then(function (metric) {
                                    $scope.metric.data = metric;
                                    $scope.metric.title = $scope.widget.title;
                                    $scope.totalCases = analyticsService.getMetricTotal($scope.widget.config, metric.elements);
                                });
                            }
                        }

                    }


                }]
            };
        }]);
});
