define('cases/directives',['angular'], function(angular) {
  'use strict';

  return angular.module('cases.directives', [])
  
  .directive('paginationMsg', [function(){
    return {
      restrict: 'E',
      template: 'Displaying <strong>{{paginationInfo.pageItemStart}}</strong> &ndash;  <strong>{{paginationInfo.pageItemEnd}}</strong> <span class="preposition">of</span> <strong>{{paginationInfo.totalItems}}</strong> results',
      scope: {paginationInfo: '='} 
    };
  }])

	.directive('tabs', function(){
		var controller = [
		'$scope', 'standardOptions', '$cookieStore',
		function($scope, standardOptions, $cookieStore){

			if($scope.isAdminView) {
				$scope.tabTitle = 'All Cases';
			} else if(!$scope.isContribAdminView) {
				$scope.tabTitle = 'My Cases';
			} else {
				$scope.tabTitle = standardOptions.caseViews;
			}
			$scope.setActiveTab = true;
			
			// Scope Functions
			$scope.filterCasesView = function(viewType){
				switch(viewType){
					case 'ORGANIZATION':
						$scope.config.filters.createdOrgId = $cookieStore.get('loggedIn').organization.id;
					break;
					case 'INDIVIDUAL':
						$scope.config.filters.createdOrgId = '';
					break;
				}
				
			};

			$scope.tabToggle = function($event){
				var elm, tab, tabId, tabRm;
				elm = angular.element($event.target);
				tab = elm.attr('id');
				tabId = parseInt(tab.split('_')[1]);
				if(tabId === 1){
					tabRm = tabId + 1;
				}
				else{
					tabRm = tabId - 1; 
				}
				$scope.setActiveTab = false;
				angular.element(elm).addClass('active');
				angular.element('#tab_'+tabRm).removeClass('active');
			}; 

			$scope.setFilterView = function(){
				if($scope.setActiveTab){
					var cF = $scope.config.filters.createdOrgId;
					if(cF){
						angular.element('#tab_2').addClass('active');
					}else{
						angular.element('#tab_1').addClass('active');
					}
				}
			}; 

		}
		];
		return {
			controller: controller,
			restrict: 'E',
			templateUrl: '/assets/javascripts/cases/partials/tabs.html',
			replace: false,	
			scope: '='
		};
	});
});
