define('common/directives/featureToggles',['angular'], function(angular) {
  'use strict';

  return angular.module('common.directives.featureToggles', [])
  
  .factory('featureToggleService', ['$http', 'securityService', '_',
    function($http, securityService, _){
    return {
      isEnabled: function(featureName){
        return $http.get('/api/feature-toggles/' + featureName)
          .then(
            function(response){
              var featureToggle = response.data;
              var defaultEnabled = featureToggle && featureToggle.enabled;
              
              var roleEnabled = _.isEmpty(featureToggle.roles) ||
                  _.includes(featureToggle.roles, securityService.getLoggedInUserActiveRole());
              return defaultEnabled && roleEnabled;
            },
            function(){
              return false;
            }
          );  
      }
    };
  }])

  .directive('toggle', ['featureToggleService',  
    function(featureToggleService){
    
    var link = function(scope, element, attributes) {
      if(scope){}
      element.hide();
      attributes.$observe('feature', function(feature){
        featureToggleService.isEnabled(feature)
          .then(function(enabled){
            if(enabled){
              element.show();
            }
          });

             
      });
      
    };

    return({
        link: link,
        restrict: "A",
        scope: {
          feature: '='
        }
    });

  }]);
});
