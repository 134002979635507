/**
* A service that facilitates request of language settings
**/
define('common/services/language',['angular'], function (angular) {
  'use strict';
  
  return angular.module('common.language', ['pascalprecht.translate'])
  
  .factory('languageConfigService', ['$http', function($http){
      return {
        loadConfig: function(){
          return $http.get('assets/language.json').then(function(response){
            return response.data;
          });
        }
      };
    }])

  .config(['$translateProvider', function ($translateProvider) {
    $translateProvider.translations('en', {
      analytics: {
        caseSettled: 'Settled',
        caseWithdrawn: 'Withdrawn',
        '1': 'One',
        '3': 'Three',
        'UNCITRAL Rules': 'UNCITRAL',
        'Institutional Rules': 'Institutional',
        'Other rules used': 'Other',
        numMotionsFiledSuffix: 'Motions',
        numDepositionsSuffix: 'Depositions',
        numInterrogatoriesSuffix: 'Interrogatories'
      },
      searchTitle: {
        processType: 'Resolution Method',
        caseType: 'Case Type',
        caseRegion: 'Region',
        calculatedOutcome: 'Arbitration Outcome',
        numArbitrators: 'Number of Arbitrators',
        partyRegion: 'Mediation Party(s)',
        claimantRegion: 'Arbitration Claimant',
        respondentRegion: 'Arbitration Respondent',
        demandAmt: 'Claim Amount',
        demandYear: 'Date of Filing',
        organizationId: 'Organization',
        organizationName: 'Organization'
      },
      error: {
        "organization_id_required": "Organization ID is missing.",
        "organization_name_required": "Please enter an organization name.",
        "organization_name_max_length_exceeded": "Please limit organization name to 250 characters.",
        "organization_status_invalid": "Please select a status.",

        "membership_invalid_id": "Invalid subscription ID provided.",
        "membership_plan_required": "Please select a plan.",
        "membership_plan_invalid": "Please select a valid plan.",
        "membership_user_required": "Invalid owner user provided.",
        "membership_owner_required": "Invalid owner organization provided.",
        "membership_start_date_required": "Please enter an Effective Date.",
        "membership_expiration_date_required": "Please enter an Expiration Date.",
        "membership_dates_out_of_order": "Expiration date and time must be after Effective date and time.",
        "membership_num_seats_required": "Please enter the number of seats for this subscription.",
        "membership_num_seats_too_small": "Please assign at least one seat for this subscription.",
        "membership_insufficient_seats": "The number of currently assigned seats exceeds the new value for total seats available. Please unassign seats and try again.",

        "duplicate_user_email": "This email address is already in use for another user.  Please use a unique email address.",
        "first_name_empty": "Please enter First Name.",
        "first_name_too_long": "First Name exceeds maximum length of 250 characters.",
        "last_name_empty": "Please enter Last Name.",
        "last_name_too_long": "First Name exceeds maximum length of 250 characters.",
        "email_empty": "Please enter Email.",
        "email_invalid": "Please enter a valid Email address.",
        "password_empty": "Please enter a Password.",
        "password_too_short": "Password must be at least 8 characters.",
        "password_confirmation_empty": "Please re-enter your password to confirm that it is correct.",
        "password_mismatch": "Password and Password Confirmation do not match.",
        "card_token_empty": "Card payment token not provided.",
        "membership_plan_unsupported": "The plan you selected is not eligible for this subscription. Please select another.",
        "organization_invalid": "The organization is invalid.",
        "roles_invalid": "The selected roles are invalid.",
        "org_not_allowed": "The user cannot be assigned to the selected organization because he/she is currently filling a subscription seat in another organization. Please remove this user from all other seats before changing the organization.",
        "admin_roles_invalid": "DRD Admin cannot be associated with other roles. Please choose only DRD Admin role or other role(s).",
        "admin_org_invalid": "DRD Admin cannot be associated with an organization. Please deselect organization or DRD Admin role.",
        "user_invalid": "The user is invalid.",
        "status_invalid": "The status is invalid.",
        "billing_address_zip_minimum" : "Zip must be at least 2 numbers.",
        "billing_address_state_invalid" : "State must be letters only.",
        "billing_address_city_invalid" : "City must be letters only.",

        "card_processing_error": "An unexpected error occurred while processing your credit card. Please try again later.",
        "card_charge_error": "The transaction could not be processed with the credit card information provided. Please verify that the payment information is correct and try again.",

        "id_required": "Please provide an ID.",

        "invalid_json": "An error occurred processing your request. Please try again.",
        "parse_error": "An error occurred processing your request. Please try again.",
        "unexpected_error": "An error occurred processing your request. Please try again.",
        "unexpected_result": "An error occurred processing your request. Please try again."
      },
      REVIEW: 'In Review',
      DRAFT: 'Draft',
      FLAGGED: 'Flagged',
      ACTIVE: 'Active',
      CURRENT : 'Current',
      HISTORIC : 'Historic',
      MEDIATION: 'Mediation',
      ARBITRATION: 'Arbitration',
      INACTIVE: 'Inactive',
      CANCELLED: 'Cancelled',
      STAGED: 'Staged',

      //analytics fields
      calculatedOutcome: 'Arbitration Outcome',
      caseType: 'Case Type',
      caseRegion: 'Region',
      processType: 'Process',
      filedAsMediation: 'Process',
      partyRegion: 'Region',
      arbSelMethod: 'Selection Method',
      numArbitrators: 'Number',
      caseSettled: 'Case Settled',
      numMotionsFiled: 'Number',
      sumJudgementMotionMade: 'Motion Made',
      claimantRegion: 'Region',
      respondentRegion: 'Region',
      nationalCourtsInvolved: 'Courts Involved',
      hearingOccurred: 'Merits Hearing Occurred',
      hearingLocation: 'Merits Hearing Location',
      demandType: 'Claim Type',
      counterFiled: 'Counterclaim Filed',
      counterDemandType: 'Counterclaim Type',
      counterPrevailed: 'Did the counterclaim prevail',
      prevailingParty: 'Prevailing Party',
      awardedTo: 'Amounts Awarded To',
      awardCostsGranted: 'Costs granted',
      rulesUsed: 'Rules Used',
      amicusCuriaeBriefIncluded: 'Bried Included',
      emerArbUsed: 'Availed',
      intervenerMotionMade: 'Motion Made',
      intervenerMotionGranted: 'Motion Granted',
      numDepositions: 'Number',
      numInterrogatories: 'Number',
      ediscoveryUsed: 'E-Discovery',

      // stats legend
      'Institution Selected': 'Institution',
      'Party Nominated':'Party',
      'Court Appointed':'Court',

      t: 'Yes',
      f: 'No',
      true: 'Yes',
      false: 'No',

      INDIVIDUAL: 'My Cases',
      ORGANIZATION: 'Our Cases',

      CONTRIBUTOR: 'DC',
      CONTRIBUTOR_ADMIN: 'DCA',
      SUBSCRIBER: 'DR',
      ADMIN: 'ADMIN'

    });
    $translateProvider.preferredLanguage('en');
  }]);

});
