define('common/services/util',['angular'], function (angular) {
	'use strict';

	return angular.module('common.util', [])
			.constant('months',[
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December'])
			.factory('util', ['_', 'moment', 'momentLocal', '$window', 'months', function (_, moment, momentLocal, $window, months) {
				return {
					getOrElse: function (value, defaultValue) {
						return (!_.isEmpty(value) ? value : defaultValue);
					},

					stringToBool: function (value) {
						return (value === 'true' || value === 'TRUE' || value === '1');
					},

					encodeUri: function (uri) {
						return $window.encodeURIComponent(uri);
					},

					buildDateOptions: function (yearsBehind, yearsAhead, yearsDescending) {
						var now = moment().year();
						var firstYear = now - (yearsBehind ? yearsBehind : 0);
						var lastYear = now + (yearsAhead ? yearsAhead : 0) + 1;
						var years = _.range(firstYear, lastYear);
						if (yearsDescending) {
							years = years.reverse();
						}
						return {
							days: _.range(1, 32),
							months: months,
							years: years
						};
					},

					buildTimeOptions: function () {
						return {
							hours: _.range(1, 13),
							minutes: ['00','01','02','03','04','05','06','07','08','09'].concat(_.range(10, 60)),
							ampm: ['AM','PM']
						};
					},

					momentDate: function (date) {
						var day = date.day !== 'Day' && date.day,
								month = date.month !== 'Month' && date.month,
								year = date.year !== 'Year' && date.year;

						if (day || month || year) {
							day = day && _.padLeft(day, 2, '0');
							return moment(day + month + year, 'DDMMMMYYYY', true);
						} else {
							return null;
						}
					},

					localMoment: function (date, time) {
						var day = date.day !== 'Day' && date.day,
								month = date.month !== 'Month' && date.month,
								year = date.year !== 'Year' && date.year,
								hour = time.hour !== 'Hour' && time.hour,
								minute = time.minute !== 'Minute' && time.minute,
								ampm = time.ampm !== 'AM/PM' && time.ampm;

						if (day || month || year || hour || minute || ampm) {
							day = day && _.padLeft(day, 2, '0');
							hour = hour && _.padLeft(hour, 2, '0');
							return momentLocal(day + month + year + hour + minute + ampm, 'DDMMMMYYYYhhmmA', true);
						} else {
							return null;
						}
					}
				};
			}]);
});
