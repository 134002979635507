define('cases/services',['angular'], function (angular) {
  'use strict';

  return angular.module('cases.services', [])

      .factory('caseDataService', ['$http', '$log', '_', function ($http, $log, _) {
        var persistentConfig;

        return {
          load: function (limit, offset, sort, filters) {
            return $http.get('/api/cases/summaries',
                {params: buildRequestParams(limit, offset, sort, filters)})
                .then(function (response) {
                  return response.data;
                });

            function buildRequestParams(limit, offset, sort, filters) {
              var params = {};

              params.take = limit;
              params.drop = offset * limit;

              if (sort) {
                params.sort = sort.field;
                params.dir = sort.direction;
              }

              if (filters) {
                //merge in all the defined filters
                _.merge(params, _.pick(filters, _.identity));
              }

              return params;
            }
          },
          selectedStatuses: function (filters, exceptIds) {
            var params = { exceptIds: _.reduce(exceptIds, function(acc, id) { return acc + ',' + id; }) };
            if (filters) {
              _.merge(params, _.pick(filters, _.identity));
            }
            return $http.get('/api/case-statuses', { params: params })
                .then(function (response) {
                  return response.data;
                });
          },
          updateStatuses: function (ids, toStatus) {
            var params = {
              ids: ids,
              toStatus: toStatus
            };
            return $http.put('/api/case-statuses/update', params);
          },
          updateAllStatuses: function (filters, exceptIds, toStatus) {
            var params = {
              filters: filters,
              exceptIds: exceptIds,
              toStatus: toStatus
            };
            return $http.put('/api/case-statuses/update-all', params);
          },
          setConfig: function (config) {
            persistentConfig = config;
          },
          getConfig: function(){
            return persistentConfig;
          }
        };
      }]);

});
