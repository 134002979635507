/****************************************************************************
* @filename      geography.js
* 
* @author        Harrison DeStefano
* @authorURI     sparcdege.com
*
* @description   City, country, and region service data.
***************************************************************************/

define('common/services/geography',['angular'], function (angular) {
  'use strict';
  return angular.module('common.geography', [])
  .factory('geographyService', ['$http', '_', function($http, _){
    
    var defaults = {
      region : {
        africa: { 
          color: '#A9D5DA',
          latitude: 10.07,
          longitude: 18.43,
          regionCode: '002',
          countries: [
            'DZA',
            'AGO',
            'BEN',
            'BWA',
            'BFA',
            'BDI',
            'CMR',
            'CPV',
            'CAF',
            'TCD',
            'COM',
            'COG',
            'COD',
            'CIV',
            'DJI',
            'EGY',
            'GNQ',
            'ERI',
            'ETH',
            'GAB',
            'GMB',
            'GHA',
            'GIN',
            'GNB',
            'KEN',
            'LSO',
            'LBR',
            'LBY',
            'MDG',
            'MWI',
            'MLI',
            'MRT',
            'MUS',
            'MYT',
            'MAR',
            'MOZ',
            'NAM',
            'NER',
            'NGA',
            'REU',
            'RWA',
            'SHN',
            'STP',
            'SEN',
            'SYC',
            'SLE',
            'SOM',
            'SOML', 
            'ZAF',
            'SSD',
            'SDN',
            'SWZ',
            'TZA',
            'TGO',
            'TUN',
            'UGA',
            'ESH',
            'ZMB',
            'ZWE'
          ]
        },
        asia: {
          color :'#67ABB8',
          latitude: 40.07,
          longitude: 89.43,
          regionCode: '142',
           countries: [
            'AFG',
            'ARM',
            'AZE',
            'BGD',
            'BTN',
            'BRN',
            'KHM',
            'CHN',
            'CYP',
            'GEO',
            'HKG',
            'IND',
            'IDN',
            'JPN',
            'KAZ',
            'PRK',
            'KOR',
            'KGZ',
            'LAO',
            'MAC',
            'MYS',
            'MDV',
            'MNG',
            'MMR',
            'NPL',
            'PAK',
            'PHL',
            'SGP',
            'LKA',
            'TWN',
            'TJK',
            'THA',
            'TLS',
            'TKM',
            'UZB',
            'VNM',
          ]
        },
        europe: { 
          color: '#017389',
          latitude: 50.07,
          longitude: 19.43,
          regionCode: '150',
          countries:[
            'ALA',
            'ALB',
            'AND',
            'AUT',
            'BLR',
            'BEL',
            'BIH',
            'BGR',
            'HRV',
            'CZE',
            'DNK',
            'EST',
            'FRO',
            'FIN',
            'FRA',
            'DEU',
            'GIB',
            'GRC',
            'GGY',
            'VAT',
            'HUN',
            'IRL',
            'ISL',
            'IMN',
            'ITA',
            'JEY',
            'LVA',
            'LIE',
            'LTU',
            'LUX',
            'MKD',
            'MLT',
            'MDA',
            'MCO',
            'MNE',
            'NLD',
            'NOR',
            'POL',
            'PRT',
            'ROU',
            'RUS',
            'SMR',
            'SRB',
            'SVK',
            'SVN',
            'TUR',
            'ESP',
            'SJM',
            'SWE',
            'CHE',
            'UKR',
            'GBR'
          ]
        },
        latinAmerica : {
          color: '#135462',
          latitude: -10.07,
          longitude: -58.43,
          regionCode: '019',
          countries:[
            'ARG',
            'BOL',
            'BRA',
            'BLZ',
            'CHL',
            'COL',
            'CRI',
            'CUB',
            'DOM',
            'ECU',
            'FLK',
            'SLV',
            'GUF',
            'GLP',
            'GUY',
            'GTM',
            'HTI',
            'HND',
            'MTQ',
            'MEX',
            'NIC',
            'PAN',
            'PER',
            'PRI',
            'PRY',
            'BLM',
            'MAF',
            'SUR',
            'URY',
            'TTO',
            'VEN',
            'AIA',
            'ATG',
            'ABW',
            'BHS',
            'BRB',
            'BES',
            'VGB',
            'CYM',
            'CUW',
            'DMA',
            'DOM',
            'GRD',
            'GLP',
            'HTI',
            'JAM',
            'MTQ',
            'BLM',
            'KNA',
            'LCA',
            'VCT',
            'SXM',
            'TCA',
            'VIR'
          ]
        },
        middleEast: {
          color: '#1F363C',
          latitude: 27.07,
          longitude: 43.43,
          regionCode: '142',
          countries:[
            'BHR',
            'IRQ',
            'IRN',
            'ISR',
            'JOR',
            'KWT',
            'LBN',
            'OMN',
            'PSE',
            'QAT',
            'SAU',
            'SYR',
            'ARE',
            'YEM'
          ]
        },
        northAmerica:{
          color: '#dadada',
          latitude: 35.03,
          longitude: -97.01,
          regionCode: '019',
          countries:[
            'BMU',
            'CAN',
            'GRL',
            'SPM', 
            'USA'
          ]
        },         
        oceania: {
          color: '#F96A23',
          latitude: -25.00,
          longitude: 135.00,
          regionCode: '019',
          countries:[
           'AUS',
           'FJI',
           'PYF',
           'GUM',
           'KIR',
           'MHL',
           'FSM',
           'NCL',
           'NZL',
           'PNG',
           'WSM',
           'ASM',
           'SLB',
           'TON',
           'VUT'
          ]
        },

        //cities
        newYork: {latitude: 40.7127, longitude: -74.0059, icon: 'icon-flag-usa'},
        miami: { latitude: 25.7753, longitude: -80.2089, icon: 'icon-flag-usa'},
        sanFrancisco: { latitude: 37.7833, longitude: -122.4167, icon: 'icon-flag-usa'},
        geneva: { latitude: 46.2000, longitude: 6.1500, icon: 'icon-flag-switzerland'},
        stockholm: { latitude: 59.3294, longitude: 18.0686, icon: 'icon-flag-sweden'},
        paris: { latitude: 48.8567, longitude: 2.3508, icon: 'icon-flag-france'},
        milano: { latitude: 45.4667, longitude: 9.1833, icon: 'icon-flag-italy'},
        beijing: { latitude: 39.9167, longitude: 116.3833, icon: 'icon-flag-china'},
        hongKong: { latitude: 22.2783, longitude: 114.1747, icon: 'icon-flag-hong-kong'},
        singapore: { latitude: 1.3000, longitude: 103.8000, icon: 'icon-flag-singapore'},
        vienna: { latitude: 48.2000, longitude: 16.3667, icon: 'icon-flag-austria'},
        frankfurt: { latitude: 50.1167, longitude: 8.6833, icon: 'icon-flag-germany'},
        london: { latitude: 51.5072, longitude: 0.1275, icon: 'icon-flag-uk'},
        saoPaulo: { latitude: -23.5500, longitude: -46.6333, icon: 'icon-flag-brazil'},
        mexicoCity: { latitude: 19.0000, longitude: -99.1333, icon: 'icon-flag-mexico'}
      }
    };

    return {
      /**
      * Get the default regions 
      */
      getRegions: function(){
        return defaults;
      },

      /**
      * return latitude and longitude of region geography.
      * @prams (string) region 
      * @return json
      */
      getLatLong: function (region) {
        var raw = _.pluck(defaults, _.camelCase(region.toLowerCase()));
        return _.omit(raw[0], 'color');
      }

    };
  }]);
});
