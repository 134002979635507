/**
 * User controllers.
 */
define('user/controllers',['angular'], function(angular) {
  'use strict';

  return angular.module('user.controllers', ['ui.validate'])

  .controller('LoginCtrl', ['$scope', '$rootScope', '$location', '$cookieStore', 'securityService','$modal', 'languageConfigService',
    function($scope, $rootScope, $location, $cookieStore, securityService, $modal, languageConfigService) {
      if ($location.search().errors === 'membership_expired'){
        if(window.Conf.envConfig.name.toLowerCase() === "dev" || window.Conf.envConfig.name.toLowerCase() === "uat"){
          $scope.errors = [{
            message: 'Please check that you have activated your account and that your Email Address and Password are correct.',
            link: 'http://datadog.nationbuilder.com/data_subscriptions'
          }];
        }
        else if (window.Conf.envConfig.name.toLowerCase() === "demo" || window.Conf.envConfig.name.toLowerCase() === "prod"){
          $scope.errors = [{
            message: 'Please check that you have activated your account and that your Email Address and Password are correct.',
            link: 'http://www.disputeresolutiondata.com/data_subscriptions'
          }];
        }
        else{
          $scope.errors = [{
            message: 'Please check that you have activated your account and that your Email Address and Password are correct.',
            link: ''
          }];
        }
      }
      if ($location.search().from === 'setPassword') {
        $scope.message = "Your password has been set successfully. Please log in.";
      }
      $scope.credentials = {};
      $scope.showPreamble = window.Conf.envConfig.isDemoUi;
      if($scope.showPreamble){
        $scope.$watch('showPreamble', function(){
          window.scrollTo(0, 0);
        });
      }
      
      _loadLanguageConfig();

      function _loadLanguageConfig() {
        return languageConfigService.loadConfig().then(function(response) {
          $scope.language = response;
        });
      }
       function _checkAcademicLogin() {
        if ($location.search().academic === 'true') {
          $scope.loginWithIp();
          $location.search('academic', 'false');
        }
      }

      $scope.modalSetRole = function(role){
        securityService.postLoginSuccess(role);
        $location.path('/');
        $scope.$close();
      };

      $scope.cancelActiveRoleModal = function(){
        securityService.logout().then(function(){
          $location.path('/LOGOUT');
          $scope.$close();
        });
      };
      $scope.loginWithIp = function () {
        /*login user gets the auth token from the server and stores the token as
         well as user information in session cookies*/
        securityService.loginWithIp().then(
            securityService.handleLoginSuccess,
            function (response) {
              $rootScope.$broadcast('pane_success');
              var msg;
              if (response.data.error_description === 'Account Lockout') {
                msg = 'Your account has been temporarily locked due to repeated failed login attempts.  Please try again in a few minutes.';
              } else if (response.data.error_description === 'Membership Expired') {
                msg = 'Please check that you have activated your account and that your Email Address and Password are correct.';
              } else {
                msg = 'Please check that you have activated your account and that your Email Address and Password are correct.';
              }
              $scope.errors = [{
                message: msg
              }];
            });
      };

      _checkAcademicLogin();

      $scope.login = function () {
        /*login user gets the auth token from the server and stores the token as 
         well as user information in session cookies*/
        securityService.login($scope.credentials).then(
            securityService.handleLoginSuccess,
            function (response) {
              $rootScope.$broadcast('pane_success');
              var msg;
              if (response.data.error_description === 'Account Lockout') {
                msg = 'Your account has been temporarily locked due to repeated failed login attempts.  Please try again in a few minutes.';
              } else if (response.data.error_description === 'Membership Expired') {
                msg = 'Please check that you have activated your account and that your Email Address and Password are correct.';
              } else {
                msg = 'Please check that you have activated your account and that your Email Address and Password are correct.';
              }
              $scope.errors = [{
                message: msg
              }];
            });
      };
    }
  ])

  .controller('AccountCtrl', ['$scope', '$log', '$anchorScroll', 'userService', 'userProfileService',
    function($scope, $log, $anchorScroll, userService, userProfileService) {

      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
       Initialization                            
       ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
      
      _loadUserAndProfile();

      $scope.genderOptions = [
        {label: 'Female', value: 'F'}, 
        {label: 'Male', value: 'M'}
      ];

      $scope.experienceOptions = [
        {label: 1, value: 1},
        {label: 2, value: 2},
        {label: '3+', value: 3}
      ];

      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Scope Functions                            
      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

      $scope.submit = function() {
        /*reset the success variable so that if the form was previously saved 
        successfully but now is an error, it won't show the error message*/
        $scope.success = undefined;
        
        if($scope.accountForm.$valid){
          //save the user and user profile
          //console.log($scope.user);
          //console.log($scope.userProfile);
          _saveUserAndProfile()
            .then(
              function(){
                $scope.success = true; 
                $anchorScroll('successAnchor'); 
              },
              function(err){
                $log.error(err);
              }
            );
        } else {
          $anchorScroll('errorAnchor');
        } 

      };


      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
       Private Functions                          
       ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

      function _loadUserAndProfile() {
        //load the user from the DB - use the currently logged in user and set him in the scope
        userService.current()
          .then(function(user) {
            $scope.user = user;
            return user;
          })
          .then(function(user) {
            userProfileService.getFromUser(user)
              .then(function(profile) {
                $scope.userProfile = profile;
              });
          });
      }

      function _saveUserAndProfile(){
        return userService.save($scope.user)
          .then(function(){
            if(!$scope.userProfile.id) {
              // new profile: fill in linked userId, and set a "default" orgId
              $scope.userProfile.userId = $scope.user.id;
              $scope.userProfile.orgId = 135;
            }
            userProfileService.save($scope.userProfile);
          });

      }
    }
  ])
  .controller('SignUpCtrl', ['$scope', '$location', '_', 'moment', 'standardOptions', 'selfEnrollmentService','selfEnrollmentDataService',
    function($scope, $location, _, moment, standardOptions, selfEnrollmentService, selfEnrollmentDataService) {

      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
       Initialization
       ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
      $scope.newUser = {};
      $scope.newUser.membershipPlanId = _.isUndefined($location.search().membershipPlanId) ? 1 : Number($location.search().membershipPlanId);
      $scope.errors = [ ];
      var savedEnrollment = selfEnrollmentDataService.get();
      if(!_.isEmpty(savedEnrollment)){
        $scope.newUser = savedEnrollment;
      }
      _initMembershipPlan();
      _initOptions();



      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Scope Functions
      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
      $scope.submit = function (){
        $scope.errors = [];
        if ($scope.newUser.passwordConfirmation !== $scope.newUser.password) {
          $scope.errors.push("Passwords do not match.");
        }
        if (!_.isEmpty($scope.errors)) {
          return;
        }
        selfEnrollmentService.validatePlanAndUser($scope.newUser)
          .then(function() {
            selfEnrollmentDataService.set($scope.newUser);
            $location.path('signup/payment');
          },
          function(err) {
            if(err && err.data){
              $scope.errors = err.data;
            } else {
              $scope.errors = ["An error occurred. Please try again."];
            }
          });
      };

      $scope.changePlan = function(selectedPlan) {
        if (selectedPlan) {
          $scope.newUser.membershipPlanId = selectedPlan.id;
          _initMembershipPlan();
        }
      };

      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
       Private Functions
       ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

       function _initOptions(){
         $scope.options = {
           countries: _buildCountryOptions()
         };
       }
       function _buildCountryOptions(){

         var countries = _.flattenDeep(
           _.map(standardOptions.groupedCountries, function(o){
            return  o ;
           })
         );
         return _.map(countries, function(c) {
            return {label: c, value: c};
         });

       }
       function _initMembershipPlan() {
         selfEnrollmentService.getMembershipPlan($scope.newUser.membershipPlanId)
           .then(function(membershipPlanResponse) {
             $scope.membershipPlan = membershipPlanResponse;
             $scope.membershipPlan.startDate = moment().local().format('dddd, DD MMMM YYYY');
             $scope.membershipPlan.endDate = moment().add(membershipPlanResponse.durationValue, membershipPlanResponse.durationUnit.unit).format('dddd, DD MMMM YYYY');
           },
           function(err) {
             if(err){
               $scope.errors = err.data;
             }
         });
       }
    }
  ])
  .controller('SelfEnrollPaymentCtrl', ['$scope', '$location', '_', 'standardOptions', 'util', 'moment', 'selfEnrollmentService','selfEnrollmentDataService', 'stripe',
    function($scope, $location, _, standardOptions, util, moment, selfEnrollmentService, selfEnrollmentDataService, stripe) {

      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
       Initialization
       ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

      $scope.newUser = {};
      $scope.errors = [ ];
      var savedEnrollment = selfEnrollmentDataService.get();
      if(_.isEmpty(savedEnrollment)){
        $location.path('/signup');
      }
      else{
        $scope.newUser = savedEnrollment;
      }
      _initOptions();
      _initMembershipPlan();

      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Scope Functions
      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
      $scope.goBack = function (){
        $location.path('/signup');
      };
      $scope.submit = function (){
        $scope.errors = [];
        if (_.isEmpty($scope.newUser.billingAddressLine1)) {
          $scope.errors.push("Please enter your Billing Address.");
        }
        if (_.isEmpty($scope.newUser.billingAddressCity)) {
          $scope.errors.push("Please enter your Billing Address City.");
        }
        if (_.isEmpty($scope.newUser.billingAddressCountry)) {
          $scope.errors.push("Please enter your Billing Address  Country.");
        }
        if (_.isEmpty($scope.newUser.billingAddressState)) {
          $scope.errors.push("Please enter your Billing Address State.");
        }
         if (_.isEmpty($scope.newUser.billingAddressZip)) {
          $scope.errors.push("Please enter your Billing Address Zip.");
        }
        if (_.isEmpty($scope.newUser.payment)) {
          $scope.errors.push("Please enter your Payment Information.");
        } else {
          if (_.isEmpty($scope.newUser.payment.number)) {
            $scope.errors.push("Please enter a Credit Card Number.");
          }
          if (_.isEmpty($scope.newUser.payment.exp_month)) {
            $scope.errors.push("Please enter your Credit Card Expiration Month.");
          }
          if (!_.isNumber($scope.newUser.payment.exp_year)) {
            $scope.errors.push("Please enter your Credit Card Expiration Year.");
          }
          if (_.isEmpty($scope.newUser.payment.cvc)) {
            $scope.errors.push("Please enter your Credit Card CV Code.");
          }
        }

        if (!_.isEmpty($scope.errors)) {
          return;
        }

        stripe.card.createToken($scope.newUser.payment)
            .then(function(response) {
              $scope.newUser.cardToken = response.id;
              return selfEnrollmentService.validatePayment($scope.newUser)
                  .then(function () {
                    selfEnrollmentDataService.set($scope.newUser);
                    $location.path('/signup/summary');
                  }, function (err) {
                    if (err) {
                      $scope.errors = err.data;
                    }
                  });
            }, function(error) {
              if (error && error.message) {
                $scope.errors.push(error.message);
              } else {
                $scope.errors.push("Your payment information is invalid. Please verify that it is correct and try again.");
              }
            })
            .catch(function (error) {
              console.log("error creating token: " + error);
            });
      };

      $scope.changePlan = function(selectedPlan) {
        if (selectedPlan) {
          $scope.newUser.membershipPlanId = selectedPlan.id;
          _initMembershipPlan();
        }
      };

      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
       Private Functions
       ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

       function _initOptions(){
         $scope.options = {
           countries: _buildCountryOptions()
         };
         $scope.dateOptions = util.buildDateOptions(0, 20, false);
         $scope.dateOptions.months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
       }
       function _buildCountryOptions(){
         var countries = _.flattenDeep(
           _.map(standardOptions.groupedCountries, function(o){
            return  o ;
           })
         );
         return _.map(countries, function(c) {
            return {label: c, value: c};
         });
       }
       function _initMembershipPlan() {
         selfEnrollmentService.getMembershipPlan($scope.newUser.membershipPlanId)
           .then(function(membershipPlanResponse) {
             $scope.membershipPlan = membershipPlanResponse;
             $scope.membershipPlan = membershipPlanResponse;
             $scope.membershipPlan.startDate = moment().local().format('dddd, DD MMMM YYYY');
             $scope.membershipPlan.endDate = moment().add(membershipPlanResponse.durationValue, membershipPlanResponse.durationUnit.unit).format('dddd, DD MMMM YYYY');
           },
           function(err) {
             if(err){
               $scope.errors = err.data;
             }
         });
       }
    }
  ])
  .controller('SelfEnrollSummaryCtrl', ['$scope', '$location', '_', 'moment', 'selfEnrollmentService','selfEnrollmentDataService', 'securityService',
    function($scope, $location, _, moment, selfEnrollmentService, selfEnrollmentDataService, securityService) {

      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
       Initialization
       ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

      $scope.newUser = {};
      $scope.errors = [ ];
      var savedEnrollment = selfEnrollmentDataService.get();
      if(_.isEmpty(savedEnrollment)){
        $location.path('/signup');
      }
      else{
        $scope.newUser = savedEnrollment;
      }
      _initOptions();
      _initMembershipPlan();

      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Scope Functions
      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
      $scope.goBack = function (){
        $location.path('/signup/payment');
      };
      $scope.submit = function (){
        if($scope.termsAccepted){
          selfEnrollmentService.enroll($scope.newUser)
            .then(function() {
              selfEnrollmentDataService.set({});
              $scope.credentials = {
                username: $scope.newUser.email,
                password: $scope.newUser.password
              };
              securityService.login($scope.credentials).then(
                securityService.handleLoginSuccess,
                  function () {
                    //TODO: what about login errors?
                    $location.path('/');
                  }
              );
            },
            function(err) {
              if(err){
                $scope.errors = err.data;
              }
            });
      }
      else{
        $scope.errors = ["Please Accept Terms & Conditions"];
      }
      };

      $scope.changePlan = function(selectedPlan) {
        if (selectedPlan) {
          $scope.newUser.membershipPlanId = selectedPlan.id;
          _initMembershipPlan();
        }
      };

      $scope.billingAddress = function() {
        if ($scope.newUser.billingAddressLine1) {
          return ($scope.newUser.billingAddressLine2 ? $scope.newUser.billingAddressLine1 + ', ' + $scope.newUser.billingAddressLine2 : $scope.newUser.billingAddressLine1);
        } else if ($scope.newUser.billingAddressLine2) {
          return $scope.newUser.billingAddressLine2;
        } else {
          return "";
        }
      };

      $scope.billingCityStateZip = function() {
        var stateZip = "";
        if ($scope.newUser.billingAddressState) {
          stateZip = ($scope.newUser.billingAddressZip ? $scope.newUser.billingAddressState + ' ' + $scope.newUser.billingAddressZip : $scope.newUser.billingAddressState);
        } else if ($scope.newUser.billingAddressZip) {
          stateZip = $scope.newUser.billingAddressZip;
        }

        return ($scope.newUser.billingAddressCity ? $scope.newUser.billingAddressCity + ', ' + stateZip : stateZip);
      };

      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
       Private Functions
       ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

       function _initOptions(){
         $scope.options = {
         };
       }
       function _initMembershipPlan() {
         selfEnrollmentService.getMembershipPlan($scope.newUser.membershipPlanId)
           .then(function(membershipPlanResponse) {
             $scope.membershipPlan = membershipPlanResponse;
             $scope.membershipPlan = membershipPlanResponse;
             $scope.membershipPlan.startDate = moment().local().format('dddd, DD MMMM YYYY');
             $scope.membershipPlan.endDate = moment().add(membershipPlanResponse.durationValue, membershipPlanResponse.durationUnit.unit).format('dddd, DD MMMM YYYY');
           },
           function(err) {
             if(err){
               $scope.errors = err.data;
             }
         });
       }

    }
  ])
  .controller('UserAddEditCtrl', ['$scope', '$location', 'userService', 'orgListDataService', 'userRoleService', 'membershipService', 'moment', '$modal', '_',
    function($scope, $location, userService, orgListDataService, userRoleService, membershipService, moment, $modal, _) {
        /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        Initialization
        ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
        $scope.userId = $location.search().id;
        $scope.addMode = _.isEmpty($scope.userId);
        $scope.isContributor = false;
        if ($scope.addMode) {
          $scope.userHeaderText = 'Add User';
        }
        $scope.checkedRoles = {
          ADMIN: false,
          CONTRIBUTOR_ADMIN: false,
          CONTRIBUTOR: false
        };
        $scope.orgChanged = false;
        $scope.orgId = {};
        $scope.currentUser = {};
        $scope.selectedOrg = {};
        $scope.isAdmin = false;

        _loadUser()
          .then(function(user) {
            $scope.currentUser = user;
            $scope.orgId = angular.copy($scope.currentUser.orgId);
            if (!$scope.addMode) {
              $scope.userHeaderText = user.firstName + ' ' + user.lastName;
              $scope.refreshMemberships();
            }
          },
          function(err) {
            console.log('error loading user ' + err);
          }
        ).then(
          function() {
            //get roles - returns and empty role {} set if no id present in currentUser
            return _loadRoles();
          }
        ).then(
          function(rolesResponse){
            _.forEach(rolesResponse, function(role) {
              $scope.checkedRoles[role] = true;
              if (role === "ADMIN") {
                $scope.isAdmin = true;
              }
              if(role === "CONTRIBUTOR" || role === "CONTRIBUTOR_ADMIN"){
                $scope.isContributor = true;
              }
            });
          },
          function(err) {
            console.log('error loading user roles ' + err);
          }
        ).then(
          function() {
            //get roles - returns and empty role {} set if no id present in currentUser
            return _loadLastLogin();
          }
        ).then(
          function(lastLoginResponse){
            if(!_.isEmpty(lastLoginResponse)){
              $scope.currentUser.lastLogin = moment(parseInt(lastLoginResponse)).local().format('DD MMMM YYYY, h:mm:ss a');
            }
            else{
              $scope.currentUser.lastLogin = 'Never';
            }
          },
          function(err) {
            console.log('error loading last login time ' + err);
          }
        );

        _initOptions();

        /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        Scope Functions
        ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
        $scope.clearErrors = function(){
          $scope.errors = [ ];
          $scope.isEmailError = false;
          $scope.isFirstNameError = false;
          $scope.isLastNameError = false;
        };

        $scope.showMemberships = function(){
          return !angular.isUndefined($scope.currentUser.id) && angular.isUndefined($scope.currentUser.orgId) && !$scope.isAdmin;
        };


        $scope.changeOrg = function(org){
          if ($scope.currentUser.id && (org.value !== $scope.orgId)){
            $scope.orgChanged = true;
            $scope.selectedOrg = org.label;
          }
          else if ($scope.currentUser.id && (org.value === $scope.orgId)){
            $scope.orgChanged = false;
          }
        };

        $scope.confirmSubmit = function (){
            if($scope.orgChanged){
              //pop modal up
              $modal.open({
                          animation: true,
                          backdrop: 'static',
                          scope: $scope,
                          windowClass: 'org-change-modal',
                          templateUrl: 'assets/javascripts/user/partials/orgChangeModal.html',
                          size: 'lg'
               });
            }
            else{
              $scope.submitUser();
            }
        };

        $scope.submitUser = function(){
          $scope.errors = [ ];
          //gather up the roles
          $scope.currentUser.roles = _.keys(
            _.pick($scope.checkedRoles, function(value) {
              return (value === true);
            })
          );
          if(_.isEmpty($scope.errors)){
              userService.save($scope.currentUser)
                .then(function() {
                  $location.path('/users/view');
                  $location.search('id', null);
                }, function(err) {
                  $scope.errors = err.data;
                  var errorCodes = _.map($scope.errors, function(e) { return _.get(e, "errorCode", ""); });
                  $scope.isFirstNameError = !_.isEmpty(_.find(errorCodes, function (e) {return e === "first_name_empty" || e === "first_name_too_long";}));
                  $scope.isLastNameError = !_.isEmpty(_.find(errorCodes, function (e) {return e === "last_name_empty" || e === "last_name_too_long";}));
                  $scope.isEmailError = !_.isEmpty(_.find(errorCodes, function (e) {return e === "email_empty" || e === "email_invalid" || e === "duplicate_user_email";}));
              });
          }
        };
      
        $scope.refreshMemberships = function() {
          membershipService.fetchUserMemberships($scope.currentUser.id, function(data) {
            $scope.membershipSummaries = data;
          }, function () {
            $scope.membershipLoadErrors = ["Error loading subscriptions."];
          });
        };

        $scope.$watchGroup(['currentUser.firstName', 'currentUser.lastName', 'currentUser.email'], function(){
          $scope.clearErrors();
        }, true);

        function _initOptions(){
          $scope.options = {
            organizations: _getOrganizationsOptions()
          };
        }

        function _getOrganizationsOptions(){
          var orgsList = [];
          orgListDataService.getOrganizations()
            .then(function(result){
              $scope.organizations = result.data;
                _.forEach($scope.organizations, function(organization) {
                  orgsList.push({label: organization.name,   value: organization.id});
                });
            });
          return orgsList;
        }
        function _loadUser() {
          return userService.get($scope.userId);
        }
        function _loadRoles() {
          return userRoleService.get($scope.userId);
        }
        function _loadLastLogin() {
          return userService.getLastLogin($scope.userId);
        }

   }])

  .controller('SetPasswordCtrl', ['$scope', '$log', '$location', '$route', 'userService', 'securityService', 'languageConfigService',
    function($scope, $log, $location, $route, userService, securityService, languageConfigService) {

      $scope.mode = $location.search().mode;
      $scope.token = $location.search().token;
      $scope.user = { };
      $scope.errors = [ ];
      $scope.badToken = false;

      _loadLanguageConfig();

      $scope.submit = function() {
        $scope.errors = [ ];
        if($scope.passwordForm.$valid) {
          userService.setPwFromToken($scope.token, $scope.password).then(
            function() {
              $location.path("/login").search("from=setPassword");
            },
            function(err) {
              $scope.errors = [ err.data ];
            });
        } else {
          if($scope.passwordForm.$error.minlength) {
            $scope.errors.push("Password is too short.");
          } else if($scope.passwordForm.$error.validator) {
            $scope.errors.push("Passwords do not match.");
          }
        }
      };

      $scope.login = function() {
        $location.path('/');
      };

      function _loadLanguageConfig() {
        return languageConfigService.loadConfig().then(function(response) {
          $scope.language = response;
        });
      }
    }
  ])

  .controller('ChangePasswordCtrl', ['$scope', '$rootScope', '$log', '$location', '$route', 'userService', 'securityService', 'languageConfigService', 'modalService',
    function($scope, $rootScope, $log, $location, $route, userService, securityService, languageConfigService, modalService) {

      $scope.errors = [ ];

      _loadLanguageConfig();

      _loadUser();

      $scope.submit = function() {
        $scope.errors = [ ];
        if($scope.passwordForm.$valid) {
          userService.setPassword($scope.currentPwd, $scope.newPwd).then(
            function(msg) {
              var content = {
                logo: '/assets/images/logo/drd-logo-swoosh.png',
                button: 'Return to dashboard',
                body:'<p>'+msg+'</p>'
              };
              modalService.set({ content : content, display : true });
              // redirect to root
              $location.url('/');
            },
            function(err) {
              $scope.errors = [ err.data ];
            });
        } else {
          if($scope.passwordForm.$error.minlength) {
            $scope.errors.push("Password must be at least 8 characters.");
          } else if($scope.passwordForm.$error.validator) {
            $scope.errors.push("Passwords do not match.");
          }
          else if($scope.passwordForm.$error.required){
            for(var i =0; i < $scope.passwordForm.$error.required.length; i++){
              var response = $scope.passwordForm.$error.required[i];
              var friendlyError;
              switch(response.$name){
                case 'confirmPassword':
                  friendlyError = "Please confirm the new password.";
                break;
                case 'password':
                  friendlyError = "A new password is required.";
                break;
              }
              $scope.errors.push(friendlyError);
            }
          }
        }
      };

      function _loadUser() {
        userService.current()
          .then(function(user) {
            $scope.user = user;
            return user;
          });
      }

      function _loadLanguageConfig() {
        return languageConfigService.loadConfig().then(function(response) {
          $scope.language = response;
        });
      }
    }
  ])

  .controller('ForgotPasswordCtrl', ['$scope', '$http', '$route', 'languageConfigService', 'modalService', '$location',
    function($scope, $http, $route, languageConfigService, modalService, $location) {

      $scope.errors = [ ];

      _loadLanguageConfig();

     
      $scope.submit = function() {
        $scope.errors = [ ];
        if($scope.passwordForm.$valid) {
          $http.put('/api/email/forgotpassword/' + $scope.email)
            .success(function(){
              var configuration = {
                attribute : {
                  id : 'forgot-password-modal'
                }
              };
              var content = {
                logo: '/assets/images/logo/drd-logo-swoosh.png',
                button: 'Login',
                title: 'Password Reset',
                body:'<p>A reset link has been sent to ' + $scope.email + '.</p>'
              };
              modalService.set({ content : content, display : true, configuration: configuration });
              // redirect to login page, else in production we'll get re-routed to NationBuilder site
              $location.url('/login');
            })
            .error(function(err){
              $scope.errors = [ err ];
            });
        } else {
          $scope.errors.push("Enter an email address.");
        }
      };

      function _loadLanguageConfig() {
        return languageConfigService.loadConfig().then(function(response) {
          $scope.language = response;
        });
      }
    }
  ])

  .controller('UserSubscriptionsCtrl', ['$scope', '_', 'membershipService', 'securityService', 'userService',
    function($scope, _, membershipService, securityService, userService) {

      $scope.errors = [ ];
      $scope.currentUser = securityService.getLoggedInUserInfo();
      $scope.showNoMemberships = false;
      _notifyUser();

      _refreshMemberships();

      $scope.membershipUrl = function(){
       return (window.Conf.envConfig.name.toLowerCase() === "prod" || window.Conf.envConfig.name.toLowerCase() === "demo") ? 'https://www.disputeresolutiondata.com/subscribe': 'http://datadog.nationbuilder.com/data_subscription';
      };

      function _refreshMemberships() {
        membershipService.fetchUserMemberships($scope.currentUser.id, function(data) {
          $scope.membershipSummaries = data;
        }, function () {
          $scope.membershipLoadErrors = ["Error loading subscriptions."];
        });
      }


      function _notifyUser() {
        userService.current()
          .then(function(user) {
            $scope.user = user;
            if(_.isEmpty(user.roles)){
              $scope.showNoMemberships = true;
            }
        });
      }

    }
  ])

  .controller('UserTableCtrl', ['$scope', '$log', '$location', 'securityService', 'userTableDataService', 'standardOptions', '_', 'moment', '$anchorScroll',
    function($scope, $log, $location, securityService, userTableDataService, standardOptions, _, moment, $anchorScroll) {

      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Initialization
      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
      /*load the config if loadPrevConfig is set in the query string, otherwise
      * initialize the config to an empty/default object. This allows us to
      * selectively reinitialize the page with previously saved searches/filters*/
      var prevConfig = $location.search().loadPrevConfig && userTableDataService.getConfig();
      $scope.config = prevConfig || {
        limit: 10,
        offset: 0,
        sort: {},
        filters: {
          firstName: '',
          lastName: '',
          role: '',
          email: '',
          created: '',
          userStatus: '',
          orgName: ''
        }
      };
      _initOptions();

      //run the data population function once to initialize
      _refreshData();


      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Scope Functions
      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

      $scope.changeLimit = function(){
        //changing the limit throws off pagination so start back at page 0
        $scope.config.offset = 0;
        _refreshData();
      };

      $scope.prev = function(){
        $scope.config.offset--;
        _refreshData();
        $anchorScroll();
      };

      $scope.next = function(){
        $scope.config.offset++;
        _refreshData();
        $anchorScroll();
      };

      $scope.goToPage = function(page){
        $scope.config.offset = page-1; //pages are 1-based offset is 0-based
        _refreshData();
        $anchorScroll();
      };

      $scope.updateSort = function(field){
        if(field === $scope.config.sort.field){
          if($scope.config.sort.direction === 'asc'){
            $scope.config.sort.direction = 'desc';
          } else {
            $scope.config.sort = {};
          }
        } else {
          $scope.config.sort.field = field;
          $scope.config.sort.direction = 'asc';
        }
        _refreshData();
      };

      $scope.sortClass = function(field){
        if(field === $scope.config.sort.field){
          return $scope.config.sort.direction;
        } else {
          return 'none';
        }
      };

      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Watches
      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

      $scope.$watch('config', function(newVal){
        userTableDataService.setConfig(newVal);
      }, true);

      //whenever the filters change, refresh the table data
      $scope.$watch('config.filters', function(newVal, oldVal){
        if(!_.isEqual(newVal, oldVal) && !$scope.cancelFilterChange){
            $scope.config.offset = 0;
            _clearSelection();
            _refreshData();
        } else {
          $scope.cancelFilterChange = false;
        }
      }, true);

      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Private Functions
      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

      function _refreshData() {

        var sort = _.isEmpty($scope.config.sort) ? {field: 'createdTime', direction: 'desc'} : $scope.config.sort;

        userTableDataService.load($scope.config.limit, $scope.config.offset, sort, $scope.config.filters)
          .then(function (result) {

            $scope.users = result.data;
            $scope.total = result.total;

            $scope.pagination = new Pagination($scope.config.limit, $scope.config.offset, $scope.users, $scope.total);


            function Pagination(limit, offset, users, total){

              var self = this;

              //the current page based on the offset
              this.page = offset + 1;

              //the index of the first item on the current page
              this.pageItemStart = offset*limit+1;

              //the index of the last item on the current page
              this.pageItemEnd = offset*limit+users.length;

              //the total number of un-paginated items
              this.totalItems = total;

              //the total number of pages
              this.totalPages = Math.ceil(self.totalItems/limit);

              this.showPrevious = function(){
                return self.page > 1;
              };

              this.showNext = function(){
                return self.page < self.totalPages;
              };

              this.getPreviousLinkablePages = function(){
                var i = self.page - 1;
                var prevPages = [];
                while(i > 0 && prevPages.length <= 2){
                  prevPages.unshift(i);
                  i--;
                }
                return prevPages;
              };

              this.getNextLinkablePages = function(){
                var i = self.page + 1;
                var nextPages = [];
                while(i <= self.totalPages && nextPages.length <= 2){
                  nextPages.push(i);
                  i++;
                }
                return nextPages;
              };

            }

          });
      }

        function _clearSelection() {
          _.forEach($scope.cases, function(caseEntry) {
            caseEntry.selected = false;
          });
          $scope.selectAll = false;
          $scope.caseSelections = [];
        }

      function _initOptions(){

        $scope.options = {
          userStatus: _buildOptions('activeInactive', true),
          created: _buildDateOptions(),
          limits: _buildLimitOptions()
        };

        function _buildOptions(standardOptionsKey, convertKeysToCaps){
          var keyOverrides = {
          };
          //turn standardOptions (string array into array of {label: , value: })
          return _.union(
            [{label: 'All', value: ''}],
            _.map(standardOptions[standardOptionsKey], function(o){
              var value = keyOverrides[o] || o;
              return {label: o, value: convertKeysToCaps ? value.toUpperCase() : value};
            })
          );
        }

        function _buildLimitOptions(){
          return [
            {label: '10 per page',   value: 10},
            {label: '25 per page',   value: 25},
            {label: '50 per page',   value: 50},
            {label: '100 per page',  value: 100},
            {label: '1000 per page', value: 1000}
          ];
        }
        function _buildDateOptions(){
          var now = moment().startOf('day');
          var week = now.clone().subtract(6, 'days');
          var month = now.clone().subtract(1, 'months');
          var sixMonths = now.clone().subtract(6, 'months');
          var lastYear = now.clone().subtract(1, 'year');
          var overOneYear = now.clone().subtract(1, 'day').subtract(1, 'year');

          return [
            {label: 'All', value: ''},
            {label: 'Last week', value: week.valueOf()},
            {label: 'Last month', value: month.valueOf()},
            {label: 'Last 6 months', value: sixMonths.valueOf()},
            {label: 'Last year', value: lastYear.valueOf()},
            {label: 'Over one year', value: overOneYear.valueOf()}
          ];
        }
      }
  }])

  .controller('ChangePlanCtrl', ['$scope', '$http', '_', 'selectedPlan', function($scope, $http, _, selectedPlan) {
    $scope.changePlanModel = {
      id: selectedPlan.id
    };

    $http.get('/api/membership-plans?planType=' + selectedPlan.planType.planType)
        .success(function (data) {
          $scope.plans = _.filter(data, function (plan){
            return plan.isTrial === false;
          });
        })
        .error(function (err) {
          $scope.errors = err;
        });

    $scope.submitChange = function() {
      $scope.$close(_.find($scope.plans, {id: $scope.changePlanModel.id}));
    };
  }]);

});

