define('status/widgets/section/section',['angular'], function (angular) {
  'use strict';

  return angular.module('status.widgets.section', [])

      .directive('statusWidgetSection', [ function(){
        return {
          restrict: 'E',
          replace: true,
          templateUrl: '/assets/javascripts/status/widgets/section/section.html',
          scope: {
            widget: '='
          },
          controller: [function(){
            // nothing to add
          }]
        };
      }]);
});
