define('organizations/main',['angular', './routes', './controllers', './directives', './services'], 
  function(angular) {
  'use strict';

  return angular.module('organizations', ['ngRoute', 'organizations.routes', 'organizations.controllers',
    'organizations.directives', 'organizations.services']);
});

define('organizations', ['organizations/main'], function (main) { return main; });

