define('common/directives/fonticon',['angular'], function (angular) {
  'use strict';
  
  var module = angular.module('common.directives.fonticon', []);
  
  module.directive('fonticon', [ function(){
    
    var controller = [
     '$scope',
      function($scope){  
        // Private Variables 
        var iconFonts = {
          'All' : 'icon-all',
          'All Case Regions' : 'icon-all',
          'All Case Types' : 'icon-all',
          'Africa' : 'icon-region-africa',
          'Asia' : 'icon-region-asia',
          'Arbitration' : 'icon-all',
          'Europe' : 'icon-region-europe',
          'Latin America' : 'icon-region-latin-amer',
          'Middle East' : 'icon-region-mid-east',
          'North America' : 'icon-region-north-amer',
          'Oceania': 'icon-region-oceania',
          'Americas': 'icon-super-region-americas',
          'EMEA': 'icon-super-region-emea',
          'Asia Pacific': 'icon-super-region-asia-oceania',

          "Aerospace & Defense": 'icon-type-aerospace-defense',
          "Agriculture": 'icon-type-agriculture',
          "Arts, Entertainment & Recreation": 'icon-type-arts-entertainment-recreation',
          "Business Ownership": 'icon-type-business-ownership',
          "Commercial Contracts": 'icon-type-commercial-contracts',
          "Commercial Services": 'icon-type-commercial-services',
          "Construction": 'icon-type-construction',
          "Education": 'icon-type-education',
          "Employment": 'icon-type-employment',
          "Energy": 'icon-type-energy',
          "Environmental": 'icon-type-environmental',
          "Financial Services & Banking": 'icon-type-financial-services-banking',
          "Healthcare": 'icon-type-healthcare',
          "Hospitality & Travel": 'icon-type-hospitality-travel',
          "Insurance & Reinsurance": 'icon-type-insurance-reinsurance',
          "Intellectual Property": 'icon-type-intellectual-property',
          "Licensing Agreements": 'icon-type-licensing-agreements',
          "Manufacturing": 'icon-type-manufacturing',
          "Mass Claims & Class Actions": 'icon-type-mass-claims-class-actions',
          "Mining & Raw Materials": 'icon-type-mining-raw-materials',
          "Nonprofits & Public Administration": 'icon-type-nonprofits-public-administration',
          "Professional & Technical Services": 'icon-type-professional-technical-services',
          "Real Estate": 'icon-type-real-estate',
          "Technology": 'icon-type-technology',
          "Telecommunications": 'icon-type-telecommunications',
          "Transportation & Storage": 'icon-type-transportation-storage',
          "Wholesale & Retail Trade": 'icon-type-wholesale-retail-trade',
          'Other': 'icon-type-other'
        };
       
       // Deep search for match
       $scope.getTranslation = function(rawString){
        for(var prop in iconFonts){
          if(prop === rawString){
            return iconFonts[prop]; 
          }
        }
       };
      }
    ];
    
  return {
    /** 
    * Declare how directive can be used in a template as an element,
    * attribute, class, comment, or any combination.
    * 'A' - only matches attribute name
    * 'E' - only matches element name
    * 'C' - only matches class name 
    * 'M' - only matches comment
    **/
    restrict: 'E',
    
    /** 
    * Set the order of execution in the template relative to other 
    * directives on the element. Higher numbers run first and the 
    * default priority is 0.
    **/
    priority: 0,
    
    /** 
    * A inline template as a string and only used if not using
    * the "templateUrl" directive option.
    **/
    //template: string,
    
    /** 
    * A template to be loaded by URL and only used if not using
    * "template" directive option.
    **/
    templateUrl:  'assets/javascripts/common/partials/fonticon.html',
    
    /** 
    * Replace or append the current element.
    **/
    replace: true,
    
    /** 
    * A new scope for this directive, do not inherit the parent scope.
    * existing scope new scope isolate scope.
    * scope: false (this is the default if unspecified)
    * scope: true
    *   scope: { attribute names and binding strategie 
    *      @ (string) 
    *            = (bind this property with a property from partent scope)
    *            & (function from the parent scope) 
    *       }
    **/
    scope: {
      iconClass: "="
    },
    
    /** 
    * Create a new controller which publishes an API for communicating 
    * across directives.
    **/
    controller: controller,
    
    /** 
    * Programmatically modify resulting DOM element instances, add 
    * event listeners, and set up data binding.
    **/
    link: function postLink(scope, iElement, iAttrs) { 
      // Private Variables
      var observeAttr = 'iconclass';
      /*
      * Observing interpolated attributes: Use $observe to observe the value 
      * changes of attributes that contain interpolation (e.g. src="{{bar}}").
      * Not only is this very efficient but it's also the only way to easily 
      * get the actual value because during the linking phase the interpolation 
      * hasn't been evaluated yet and so the value is at this time set to undefined.
      */
      iAttrs.$observe(observeAttr, function() {
        // Remove any exisiting icon classes
        for(var item in iElement[0].classList){
          var c = iElement[0].classList[item];
          if(typeof c ==='string' && c.match(/icon-/)){
            iElement.removeClass(c);
          }
        }
        // Get the translated icon class
        var translatedClass = scope.getTranslation(iAttrs.iconclass);
        // Add the class to the element
        iElement.addClass(translatedClass);
      });
    }
  };

  }]);

  return module;
});
