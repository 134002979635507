define('analytics/widgets/horizontalBar/horizontalBar',['angular'], function (angular) {
  'use strict';

  return angular.module('widgets-horizontalBar', [])
      .directive('widgetsHorizontalBar', ['analyticsService', function(analyticsService) {
        return {
          restrict: 'A',
          templateUrl: '/assets/javascripts/analytics/widgets/horizontalBar/horizontalBar.html',
          scope: {
            widget: '=',
            filters: '=',
            internal: '=',
            suppression: '=',
            right: '=',
            filterMask: '=',
            displayConfig: '=',
            compareMode: '=',
          },
          controller: ['$log', '$scope', '$q', '_', 'securityService', 'widgetColors', 'widgetColorSchemes', 'widgetHelpers',
           function($log, $scope, $q, _, securityService, widgetColors, widgetColorSchemes, widgetHelpers){

            /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
             Initialization
             ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

            $scope.state = 'LOADING';
            $scope.signalStrength = 0;

           $scope.allowViewCaseTotals = function() {
               return securityService.isLoggedInAdmin();
           };

            var config = $scope.widget.config;

            var sortFn = function(){
              if(config.sort === 'alpha'){
                return widgetHelpers.alphaSortGroup;
              } else if(config.sort === 'expectedValue') {
                return function(item) {
                  return _.indexOf(config.expectedValues, item.groups[0]);
                };
              }
            };

            _refreshData();


            /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
             Scope Functions
             ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

            $scope.getBarWidth = function(item){
              return Math.max(1.0, (item.value / $scope.maxValue) * 95) + '%';
            };

            $scope.getBarHeight = function(){
              return (config.height || '23') + 'px';
            };

            $scope.primaryLabelInline = function(){
              return config.field === 'percentage' || config.overlay;
            };

            $scope.getOverlayWidth = function(item){
              return 100 * item.overlay + '%';
            };

            $scope.getOverlayColor = function(){
              return widgetColorSchemes[config.colorScheme][1]; //get the second value in the color scheme
            };

            $scope.getLabelStyle = function() {
              return config.labelStyle ? 'label-' + config.labelStyle : 'label';
            };

            $scope.getBarItemStyle = function() {
               return config.barItemStyle ? config.barItemStyle : '';
            };

            /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
             Watches
             ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

            $scope.$on('refreshData', _refreshData);

            $scope.$watch('state', function(){
               if ($scope.state === 'NO_DATA'){
                 $scope.$emit('noData');
               }
             });


            /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
             Private Functions
             ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

            function _refreshData(){
              $scope.state = 'LOADING';
              $scope.ssState = 'LOADING';

              var promises,
                  filters = widgetHelpers.standardFiltersOnly($scope.filters, $scope.filterMask);

               if (config.filters) {
                  _.assign(filters, config.filters);
                }

              //handle the various ways that the widget can be configured
              if(_.isArray(config.fact)) {
                promises = _.map(config.fact, function (fact) {
                  return analyticsService.loadMetric(undefined, filters, fact, $scope.internal, $scope.suppression);
                });

                //turn the response into an array of {label, value}
                $q.all(promises)
                    .then(function (metrics) {
                      $scope.chartData = _(metrics)
                          .flatten()
                          .map(function (item, idx) {
                            return {
                              key: _buildKey(item),
                              label: config.labels[idx],
                              value: item[config.field] ? item[config.field] : 'EMPTY_VAL', //if its an average field, we dont want a null to equal 0.0, We want it to be "NOT ENOUGH DATA" #
                              color: widgetColorSchemes[config.colorScheme][idx],
                              rawData: item
                            };
                          })
                          .value();
                        var needsRounding = config.expectedValues ? config.expectedValues.length === _.size($scope.chartData): true;
                        if(needsRounding && config.format === 'analyticsPercentage'){
                            _roundPercentages($scope.chartData);
                        }
                      $scope.maxValue = _.max($scope.chartData, 'value').value;
                      $scope.totalCases = analyticsService.getMetricTotal(config, metrics);

                    })
                    .then(_setAppropriateState)
                    .then(function() {
                      if(config.showSignalStrength){
                        var ssData = _.map(_.filter($scope.chartData, function(item){
                            return item.rawData && item.rawData.total !== 0;
                          }), function(item){
                            return item.rawData;
                          } 
                        );
                        if (ssData.length !== 0) {
                          return analyticsService.getSignalStrength(ssData);
                        }
                      }
                    })
                    .then(function(signalStrength){
                      $scope.signalStrength = signalStrength;
                      $scope.ssState = 'DONE';
                    })
                    ;
              } else if(config.group && config.overlay) {
                var chartDataHolder;
                analyticsService.loadMetric([config.group], filters, config.fact, $scope.internal, $scope.suppression)
                    .then(function (metric) {
                      if (sortFn()) {
                        metric = _.sortBy(metric, sortFn());
                      }

                      // filter "missing" values from this query, so they don't generate a needless follow-up query
                      // for the overlay data; then add them back in so the zero-values appear in the bar chart
                      chartDataHolder = _(metric)
                          .map(_mapGroupedData)
                          .filter(_filterMissingValues)
                          .value();

                      $scope.maxValue = _.max(chartDataHolder, 'value').value;
                      $scope.totalCases = analyticsService.getMetricTotal(config, metric);
                    })
                    .then(function () {
                      var overlayPromises = _.map(chartDataHolder, function (d) {
                        var overlayFilters = _.clone(filters);
                        overlayFilters[config.group] = d.label;
                        return analyticsService.loadMetric([config.overlay.group], overlayFilters, config.fact, $scope.internal, $scope.suppression);
                      });

                      $q.all(overlayPromises)
                        .then(function (results) {
                          $scope.chartData = _.map(chartDataHolder, function (d, idx) {
                            var yesItem = widgetHelpers.findYesItem(results[idx]);
                            d.overlay = yesItem ? yesItem.percentage : 0;
                            return d;
                          });
                          // add place-holders for expected values that aren't present in the actual results
                          if(config.expectedValues && $scope.chartData.length > 0) {
                            $scope.chartData = _addMissingValues($scope.chartData, true);
                          }
                            var needsRounding = config.expectedValues ? config.expectedValues.length === _.size($scope.chartData): true;
                            if(needsRounding && config.format === 'analyticsPercentage'){
                                _roundPercentages($scope.chartData);
                            }
                        })
                        .then(_setAppropriateState)
                        ;
                    });

              } else { //config.group
                var metric;
                analyticsService.loadMetric([config.group], filters, config.fact, $scope.internal, $scope.suppression)
                    .then(function (metricData) {
                      metric = metricData;
                      if(config.extraBar){
                        config.expectedValues = _.union(config.expectedValues, config.extraBar.config.expectedValues);
                        if (config.extraBar.config.filters) {
                          _.assign(filters, config.extraBar.config.filters);
                        }
                        return analyticsService.loadMetric([config.extraBar.config.group], filters, config.extraBar.config.fact, $scope.internal, $scope.suppression);
                      }
                      else {
                        return;
                      }
                    })
                    .then(function(extraMetric){
                      if(extraMetric){
                        //filter down extra metric by expected value (we dont want add all the metrics together)
                        extraMetric = _.filter(extraMetric, function(group){
                          if(_.contains(config.extraBar.config.expectedValues, group.groups[0])){
                            return group;
                          }
                        });
                        _.remove(metric, function(x){
                          return _.contains(config.extraBar.config.expectedValues, x.groups[0]);
                        });
                        metric = _.union(metric, extraMetric);
                      }
                    })
                    .then(function(){
                      if (sortFn()) {
                        metric = _.sortBy(metric, sortFn());
                      }

                      $scope.chartData = _(metric)
                          .map(_mapGroupedData)
                          .value();

                      // add place-holders for expected values that aren't present in the actual results
                      if(config.expectedValues && $scope.chartData.length > 0) {
                        $scope.chartData = _addMissingValues($scope.chartData, false);
                      }

                      $scope.maxValue = _.max($scope.chartData, 'value').value;
                        //need to make sure rounding happens
                        var needsRounding = config.expectedValues ? config.expectedValues.length === _.size($scope.chartData): true;
                        if(needsRounding && config.format === 'analyticsPercentage'){
                            _roundPercentages($scope.chartData);
                        }
                      $scope.totalCases = analyticsService.getMetricTotal(config, metric);
                    })
                    .then(_setAppropriateState)
                    .then(function() {
                      // console.log('Bar Chart Data');
                      // console.log($scope.chartData);
                      if(config.showSignalStrength){
                        var ssData = _.map(_.filter($scope.chartData, function(item){
                            return item.rawData && item.rawData.total !== 0;
                          }), function(item){
                            return item.rawData;
                          } 
                        );
                        if (ssData.length !== 0) {
                          return analyticsService.getSignalStrength(ssData);
                        }
                      }
                    })
                    .then(function(signalStrength){
                      $scope.signalStrength = signalStrength;
                      $scope.ssState = 'DONE';
                    })
                  ;
              }

              function _setAppropriateState(){
                if ($scope.chartData && $scope.chartData.length && $scope.maxValue && $scope.maxValue > 0) {
                  $scope.state = 'DONE';
                } else {
                  $scope.state = 'NO_DATA';
                }
              }

              function _roundPercentages(data){
                    var roundedNumbers = analyticsService.roundPercentageMetric(_.map(data, function(item){
                        return item.value * 100;
                    }));
                    $scope.chartData = _.map($scope.chartData, function(item, idx){
                        item.value = roundedNumbers[idx]/100;
                        return item;
                    });
              }

              function _mapGroupedData(item, idx) {
                var group = _.first(item.groups);
                return {
                  key: _buildKey(item),
                  label: group,
                  value: item[config.field] ? item[config.field] : 'EMPTY_VAL',
                  color: _calculateBarColor(group, idx),
                  icon: widgetHelpers.getIcon(group),
                  rawData: item
                };
              }

              function _filterMissingValues(item){
                return item.value || item.value === 0;
              }

              function _addMissingValues(chartData, overlay) {
                return _.map(config.expectedValues, function(ev, idx) {
                    var item = _.find(chartData, { label: ev });
                    if(item) {
                      return item;
                    } else {
                      item = {
                        label: ev,
                        value: "EMPTY_VAL",
                        color: _calculateBarColor(ev, idx),
                        icon: widgetHelpers.getIcon(ev)
                      };
                      if(overlay) {
                        item.overlay = 0.0;
                      }
                      return item;
                    }
                  });
              }

              function _buildKey(item){
                return config.group + _.kebabCase(item.groups[0]);
              }

              function _calculateBarColor(group, idx){
                var scheme = widgetColorSchemes[config.colorScheme],
                    expectedValues = config.expectedValues;

                if('MONO' === config.colorSchemeStrategy){
                  return _.first(scheme); //all bars the same, always return the first color in the color scheme
                } else if(expectedValues){
                  return scheme[expectedValues.indexOf(group)];
                } else {
                  return scheme[idx];
                }
              }

              //populate the secondary value if necessary
              var secondary = config.secondary;
              if(secondary){
                widgetHelpers.getNumberForDescription(secondary, widgetHelpers.standardFiltersOnly($scope.filters, $scope.filterMask), $scope.internal, $scope.suppression).then(function(value){
                  $scope.secondaryValue = value.number;
                });
              }
            }
          }]
        };
      }]);
});
