/****************************************************************************
* loadindicator.js
* 
* Harrison DeStefano
* sparcedge.com
*
* Listen for broadcast event on route scope, display page/pane loader.
* $rootScope, $location
* March, 12, 2015
***************************************************************************/

define('common/services/loadIndicator',['angular'], function (angular) {
  'use strict';
  
  var module = angular.module('common.loadIndicator', []);
  
  module.factory('loadIndicatorService', ['$rootScope', '$location', function($rootScope, $location){
      // make jslint happy, otherwise junk
      var loc = $location;
      loc = {};
      // the service
      var service = {
        defaults : {
          el_page : angular.element('.page-loader'),
          el_pane : angular.element('.pane-loader')
        },
        animate : {
          pane : function(stage){
            switch(stage){
              case 'sucess':
                  service.defaults.el_pane.addClass('hide').removeClass('show'); 
              break;
              case 'start':
                service.defaults.el_pane.removeClass('hide').addClass('show');
              break;
            }
          },
          page : function(stage){
            switch(stage){
              case 'sucess':
                service.defaults.el_page.addClass('complete');
                setTimeout(function(){ 
                  service.defaults.el_page.addClass('complete-unload'); 
                }, 1000);
              break;
              case 'start':
                service.defaults.el_page.removeClass('loading complete complete-unload').addClass('loading');
                setTimeout(function(){ 
                  if(service.defaults.el_page.hasClass('loading')){
                    service.defaults.el_page.addClass('complete');
                    setTimeout(function(){ 
                      service.defaults.el_page.addClass('complete-unload');
                    }, 500);
                  }
                }, 2000);
              break;
            }
          } 
        }
      };
      // listen for broadcast events
      $rootScope.$on('page_start', function(){
        service.animate.page('start');
      });
      $rootScope.$on('page_success', function(){
        service.animate.page('sucess');
      });
       $rootScope.$on('pane_start', function(){
        service.animate.pane('start');
      });
      $rootScope.$on('pane_success', function(){
        service.animate.pane('sucess');
      });
      return service;
  }]);
  return module;
});
