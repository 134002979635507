define('dashboard/controllers',['angular'], function(angular) {
  'use strict';

 var module = angular.module('dashboard.controllers', []);

  module.controller('UserCtrl', [
    '$scope', 'securityService', '_',
    function($scope, securityService, _) {  

      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
       Scope Variables
       ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
      $scope.activeRole = ''; // holds active user role 

      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
       Scope Functions
       ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
      $scope.showSection = function(roles){
        for(var c = 0; c < roles.length; c++){
          if(securityService.currentUserHasActiveRole(roles[c])){
            return true;
          }
        }
      };

      /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
       Watches
       ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
      $scope.$watch(function() {
        var hasActiveRole = securityService.getLoggedInUserActiveRole();
        $scope.activeRole = $scope.activeRole === hasActiveRole ?  $scope.activeRole : $scope.activeRole = _.camelCase(hasActiveRole);
      });
    }
    ])
   .controller('CaseHistoryCtrl', [
    '$scope', 'caseHistoryService',
    function($scope, caseHistoryService) {
      
      // Public Variables
      $scope.caseHistory = {};
      
      // Private Variables
      var caseLimit = 5; // Number of cases to display on UI

      // Initialization
      loadCaseHistory()
        .then( 
          function(serviceResponse){
            buildCaseHistory(serviceResponse);
          }
        );
      
      /*
      * Fetch the case history for a user.
      */
      function loadCaseHistory(){
        return caseHistoryService.load();
      }

      /*
      * Build case history data
      */
      function buildCaseHistory(serviceResponse){
        var cases = [];
        for(var i = 0; i < serviceResponse.length && i < caseLimit; i++){
          cases.push(serviceResponse[i]);
        }
        $scope.caseHistory = cases;
      }
    }
    ]);

  });

