define('pattern/controllers',['angular'], function(angular) {
  'use strict';
  
  var module = angular.module('pattern.controllers', []);

    module.controller('uiselectCtrl', [
      '$scope',
      function($scope){
        $scope.options = [{ "indy": "Aerospace" },
             { "indy": "Banking"},
             { "indy": "Commercial Contracts"},
             { "indy":"Construction"},
             { "indy": "Employment"},
             { "indy": "Energy"},
             { "indy": "Entertainment"},
             { "indy": "Finance"},
             { "indy": "Franchise Agreements"},
             { "indy": "Healthcare"},
             { "indy": "Hospitality"},
             { "indy": "Insurance / Reinsurance"},
             { "indy": "Intellectual Property"},
             { "indy": "Licensing Agreements"},
             { "indy": "Mass Claims / Class Actions"},
             { "indy": "Mergers & Acquisitions"},
             { "indy": "Oil & Gas"},
             { "indy": "Partnerships"},
             { "indy": "Pharmaceuticals"},
             { "indy": "Securities Investments"},
             { "indy": "Technology"},
             { "indy": "Telecommunications" }];
      }
    ]);
});
