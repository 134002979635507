define('status/main',['angular', './controllers', './directives', './routes', './services', './widgets/basic/basic', './widgets/section/section'],
    function(angular) {
      'use strict';

      return angular.module('status', [
        'ngRoute',
        'status.controllers',
        'status.directives',
        'status.routes',
        'status.services',
        'status.widgets.basic',
        'status.widgets.section'
      ]);
    });
define('status', ['status/main'], function (main) { return main; });

