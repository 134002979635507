define('search/services',['angular'], function (angular) {
  'use strict';

  return angular.module('search.services', [])

      .factory('savedSearchService', ['securityService', '$http', '$rootScope', '$log', '_', function (securityService, $http, $rootScope,  $log, _) {
        /**
         * allow external clients to set a list of filters to associate with
         * a saved search. should be an object with fields for each individual
         * field. Will strip empty fields (if any) prior to saving
         */
        var internalFilters;

        var savedSearchModel;

        return {
          /**
           * Default set of filters
           */
          filterTemplate: {
            status: [ 'ACTIVE' ],
            organizationId: '',
            numArbitrators: '',
            processType: '',
            caseType: '',
            caseRegion: '',
            caseConclusion: '',
            medCaseConclusion: '',
            medPartyRegion: {},
            clmPartyRegion: {},
            rspPartyRegion: {},
            minDemandAmt: '',
            maxDemandAmt: '',
            minDemandYear: '',
            maxDemandYear: '',
            minMedReqFileYear: '',
            maxMedReqFileYear: '',
          },
          /**
           * Set the filters in the service
           * @param filters
           */
          setFilters: function (filters) {
            internalFilters = filters;
          },

          /**
           * Get a filter by ID
           * @param ID
           * @return obj or error
           */       
           getFilter: function(id){
            return $http.get('/api/saved-searches/'+ id).
              success( function(response){
                return response;
              }).
              error( function(err){
                return err;
              });
           },

          setSavedSearchModel: function(savedSearch){
            savedSearchModel = savedSearch;
          },

          getSavedSearchModel: function(){
            return savedSearchModel;
          },

           /**
           * Delete a saved search by ID
           * @param id
           * @return obj or error
           */
           deleteSavedSearch: function () {
             var id = savedSearchModel.id;
             savedSearchModel = null;
             return $http.delete('/api/saved-searches/' + id).
                 success(function (response) {
                   $rootScope.$broadcast('searchDeleted');
                   return response;
                 }).
                 error(function (err) {
                   return err;
                 });
           },   


          getFiltersForDescription: function(){
            return _(internalFilters)
                .pick(['caseType', 'caseRegion'])
                .omit(_.isEmpty)
                .value();
          },

          getFiltersForDisplay: function () {
            var filterOrder = [
              'organizationName',
              'processType',
              'calculatedOutcome',
              'numArbitrators',
              'demandYear',
              'demandAmt',
              'partyRegion',
              'claimantRegion',
              'respondentRegion'
            ];
            var localFilters = _.clone(internalFilters);
            localFilters.demandAmt = buildRange("DemandAmt");
            localFilters.demandYear = buildRange("DemandYear");
            var filtersToOmit = ['caseType', 'caseRegion', 'minDemandAmt', 'maxDemandAmt',
              'minDemandYear', 'maxDemandYear'];

            if(!securityService.currentUserHasRole('ADMIN')){
              filtersToOmit.push('organizationId');
              filtersToOmit.push('organizationName');
            }
            
            var dispFilters = _(localFilters)
                .omit(filtersToOmit)
                .mapValues(function(value, key){
                  //handle all the regions that are passed in as objects
                  if(_.isObject(value)){
                    value = _(value).pick(_.identity).keys(value).sort().join(', ');
                  }
                  return _.isEmpty(value) ? getDefaultDisplay(key) : value;
                })
                .value();
            var sortedFilters = _.map(filterOrder, function (name) {
              return [ name, dispFilters[name] ];
            });
            return sortedFilters;

            function getDefaultDisplay(key){
              return _.includes(['processType'], key) ? "Both" : "All";
            }

            function buildRange(field){
              var min = localFilters['min'+field],
                  max = localFilters['max'+field];
              if(min && max){
                return min + ' - ' + max;
              } else if(min){
                return '> ' + min;
              } else if(max){
                return '< ' + max;
              }
            }
          },

          getFiltersForSave: function(){
            return _.omit(internalFilters, _.isEmpty);
          },
          
          /**
           * Save the search
           * @param savedSearch
           * @return obj success or error
           */
          save: function (savedSearch) {
            return $http.post('/api/saved-searches', savedSearch).
              success(function(response){
                  $rootScope.$broadcast('searchSaved', response.id);
                return response;
              }).
              error( function(err){
                return err;
            });
          },

          /**
           * Get a list of saved searches.
           * initial V5 implementation limits results to 5 and orders by date
           * created (desc) at the API level. this may change in the future
           */
          list: function () {
            return $http.get('/api/saved-searches').then(
                function (response) {
                  return response.data;
                },
                function (err) {
                  return err;
                }
            );
          }
        };
      }]);
});
