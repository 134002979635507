define('cases/main',['angular', './routes', './controllers', './directives', './services'], 
  function(angular) {
  'use strict';

  return angular.module('cases', ['ngRoute', 'cases.routes', 'cases.controllers', 
    'cases.directives', 'cases.services']);
});

define('cases', ['cases/main'], function (main) { return main; });

