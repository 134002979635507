define('analytics/widgets/basicCount/basicCount',['angular'], function (angular) {
  'use strict';

  return angular.module('widgets-basicCount', [])
      .directive('widgetsBasicCount', ['analyticsService', function(analyticsService){
        return {
          restrict: 'A',
          templateUrl: '/assets/javascripts/analytics/widgets/basicCount/basicCount.html',
          scope: {
            widget: '=',
            filters: '=',
            internal: '=',
            right: '=',
            suppression: '='
          },
          controller: ['$scope', 'securityService', function($scope, securityService){
            var config = $scope.widget.config;

            $scope.allowViewCaseTotals = function() {
                return securityService.isLoggedInAdmin();
            };


              _refreshData();
            $scope.$on('refreshData', _refreshData);

            function _refreshData(){
              //expects config to contain group and title
              analyticsService.loadMetric([config.group], $scope.filters, undefined, $scope.internal, $scope.suppression).then(function (metric) {
                $scope.metric = metric;
                $scope.totalCases = analyticsService.getMetricTotal(config, metric);
              })
              .then(_setAppropriateState);
              function _setAppropriateState(){
                if ($scope.metric && $scope.metric.length) {
                  $scope.state = 'DONE';
                } else {
                  $scope.state = 'NO_DATA';
                }
              }
            }
          }]
        };
      }]);
});
