/****************************************************************************
* @filename      fonticon.js
* 
* @author        Harrison DeStefano
* @authorURI     sparcdege.com
*
* @description   Returns a icon class by passing in a string
***************************************************************************/

define('common/services/fonticon',['angular'], function (angular) {
  'use strict';
  return angular.module('common.fonticon', [])
      .constant('fonticonOptions', {
        'All': 'icon-all',
        'Africa': 'icon-region-africa',
        'Asia': 'icon-region-asia',
        'Arbitration': 'icon-all',
        'Europe': 'icon-region-europe',
        'Latin America': 'icon-region-latin-amer',
        'Middle East': 'icon-region-mid-east',
        'North America': 'icon-region-north-amer',
        'Oceania': 'icon-region-oceania',
        'Americas': 'icon-super-region-americas',
        'EMEA': 'icon-super-region-emea',
        'Asia Pacific': 'icon-super-region-asia-oceania',


        "Aerospace & Defense": 'icon-type-aerospace-defense',
        "Agriculture": 'icon-type-agriculture',
        "Arts, Entertainment & Recreation": 'icon-type-arts-entertainment-recreation',
        "Business Ownership": 'icon-type-business-ownership',
        "Commercial Contracts": 'icon-type-commercial-contracts',
        "Commercial Services": 'icon-type-commercial-services',
        "Construction": 'icon-type-construction',
        "Education": 'icon-type-education',
        "Employment": 'icon-type-employment',
        "Energy": 'icon-type-energy',
        "Environmental": 'icon-type-environmental',
        "Financial Services & Banking": 'icon-type-financial-services-banking',
        "Healthcare": 'icon-type-healthcare',
        "Hospitality & Travel": 'icon-type-hospitality-travel',
        "Insurance & Reinsurance": 'icon-type-insurance-reinsurance',
        "Intellectual Property": 'icon-type-intellectual-property',
        "Licensing Agreements": 'icon-type-licensing-agreements',
        "Maritime": 'icon-type-maritime',
        "Manufacturing": 'icon-type-manufacturing',
        "Mass Claims & Class Actions": 'icon-type-mass-claims-class-actions',
        "Mining & Raw Materials": 'icon-type-mining-raw-materials',
        "Nonprofits & Public Administration": 'icon-type-nonprofits-public-administration',
        "Professional & Technical Services": 'icon-type-professional-technical-services',
        "Real Estate": 'icon-type-real-estate',
        "Technology": 'icon-type-technology',
        "Telecommunications": 'icon-type-telecommunications',
        "Transportation & Storage": 'icon-type-transportation-storage',
        "Wholesale & Retail Trade": 'icon-type-wholesale-retail-trade',
        'Other': 'icon-type-other'
      })
  .factory('fonticonService', ['fonticonOptions', function(fonticonOptions){
    
    return {
      /**
      * get and return fonticon.
      */
      getIcon: function(string){
        var self = this;
        var tranlated = self.translate(string);
        return tranlated;
      },

      translate: function(string){
        for(var prop in fonticonOptions){
          if(prop === string){
            return fonticonOptions[prop]; 
          }
        }
      }

    };
  }]);
});
