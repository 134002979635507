define('common/directives/case',['angular'], function(angular) {
  'use strict';

  return angular.module('common.directives.case', [])
  
  .directive('caseheader', [function(){
    return {
      restrict: 'E',
      replace: false,
      transclude: true,
      templateUrl: '/assets/javascripts/common/partials/caseheader.html' 
    };
  }])
  
  .directive('caserow', [function() {
    return {
      restrict: 'E',
      replace: false,
      transclude: true,
      scope: {
        case: '=',
        caseNumLink: '=',
        adminView: '=',
        contribAdminView: '='
      },
      templateUrl: '/assets/javascripts/common/partials/case.html'
    };

  }]);
});
