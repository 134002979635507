define('status/controllers',['angular'], function (angular) {
  'use strict';

  return angular.module('status.controllers', [])

      .factory('statusWidgets', [function () {
        var widgets = [
          {
            title: 'DRD Environments',
            type: 'section'
          },
          {
            title: 'Dev',
            type: 'basic',
            data: 'envHealthDev'
          },
          {
            title: 'UAT',
            type: 'basic',
            data: 'envHealthUAT'
          },
          {
            title: 'Demo',
            type: 'basic',
            data: 'envHealthDemo'
          },
          {
            title: 'Prod',
            type: 'basic',
            data: 'envHealthProd'
          },
          {
            title: 'External Integrations',
            type: 'section'
          },
          {
            title: 'NationBuilder Global Status',
            type: 'basic',
            data: 'nbGlobalStatus'
          },
          {
            title: 'NationBuilder Site (Datadog)',
            type: 'basic',
            data: 'nbSiteStatusDD'
          },          {
            title: 'NationBuilder Pages (Prod)',
            type: 'basic',
            data: 'nbSiteStatusProd'
          },
          {
            title: 'CurrencyLayer',
            type: 'basic',
            data: 'currencyLayerStatus'
          },
          {
            title: 'Stripe Global Status',
            type: 'basic',
            data: 'stripeApiStatus'
          },
          {
            title: 'Amazon SES',
            type: 'basic',
            data: 'emailStatus'
          }
        ];

        return widgets;
      }])

      .controller('StatusCtrl', ['$scope', 'statusWidgets', function($scope, statusWidgets){
        $scope.statusWidgets = statusWidgets;
      }]);
});

