/****************************************************************************
* services.js
* 
* Harrison DeStefano
* sparcedge.com
*
* Return case history for a user
* $http, $cookieStore, user id, 
* March 24, 2015
***************************************************************************/
define('dashboard/services',['angular'], function (angular) {
  'use strict';
  var module = angular.module('dashboard.services', []);
  module.factory('caseHistoryService', ['$http', '$cookieStore', '$q', function($http, $cookieStore, $q){
    return {
      load: function() {
        var userID = $cookieStore.get('loggedIn').id;
        if(userID){
        return $http.get('/api/users/'+ userID +'/case-summaries?top=5&orderby=updated_date')
          .then( function(response){
            return response.data;
          });
        }
        else {
		//just return a new empty object
		return $q(function(resolve) { 
			resolve({});
		});
        }
      }
    };
  }]);
return module;
});
