define('organizations/services',['angular'], function (angular) {
  'use strict';

  return angular.module('organizations.services', [])

      .factory('organizationDataService', ['$http', '$log', '_', 'serviceWrapper', function ($http, $log, _, serviceWrapper) {
        var persistentConfig;

        return {
          load: function (limit, offset, sort, filters) {
            return $http.get('/api/organizations',
                {params: buildRequestParams(limit, offset, sort, filters)})
                .then(function (response) {
                  return response.data;
                });

            function buildRequestParams(limit, offset, sort, filters) {
              var params = {};

              params.take = limit;
              params.drop = offset * limit;

              if (sort) {
                params.sort = sort.field;
                params.dir = sort.direction;
              }

              if (filters) {
                //merge in all the defined filters
                _.merge(params, _.pick(filters, _.identity));
              }

              return params;
            }
          },
          setConfig: function (config) {
            persistentConfig = config;
          },
          getConfig: function(){
            return persistentConfig;
          },

          fetchOrganizationIpLogins: function (organizationId, successHandler, errorHandler) {
            return serviceWrapper.get('/api/organizations/' + organizationId + '/ips', '', successHandler, errorHandler);
          },

          createOrganizationIp: function(organizationIp, successHandler, errorHandler) {
            return serviceWrapper.post('/api/organizations/ips', organizationIp, successHandler, errorHandler);
          },
          create: function(organization, successHandler, errorHandler) {
            return serviceWrapper.post('/api/organizations', organization, successHandler, errorHandler);
          },

          update: function(organization, successHandler, errorHandler) {
            return serviceWrapper.put('/api/organizations', organization, successHandler, errorHandler);
          }
        };
      }]);

});
