define('common/directives/user',['angular'], function(angular) {
  'use strict';

  return angular.module('common.directives.user', [])


  .directive('userrow', [function() {
    return {
      restrict: 'E',
      replace: false,
      transclude: true,
      scope: {
        user: '='
      },
      templateUrl: '/assets/javascripts/common/partials/user.html'
    };

  }]);
});
