define('analytics/filters',['angular'], function (angular) {
  'use strict';

  return angular.module('analytics.filters', [])

      .filter('analyticsCurrency', [function () {
        return function (num) {
          if(!num && num !== 0){
            return;
          }

          var formattedNumber;
          if (num >= 1000000000000) {
            formattedNumber = (num / 1000000000000).toFixed(1).replace(/\.0$/, '') + '<span class="numberDescriptor"> T</span>';
          } else if (num >= 1000000000) {
            formattedNumber = (num / 1000000000).toFixed(1).replace(/\.0$/, '') + '<span class="numberDescriptor"> B</span>';
          } else if (num >= 1000000) {
            formattedNumber = (num / 1000000).toFixed(1).replace(/\.0$/, '') + '<span class="numberDescriptor"> M</span>';
          } else if (num >= 1000) {
            formattedNumber = (num / 1000).toFixed(1).replace(/\.0$/, '') + '<span class="numberDescriptor"> K</span>';
          } else if (num === 'EMPTY_VAL'){
            formattedNumber = '';
          } else {
            formattedNumber = num.toFixed(1).replace(/\.0$/, '');
          }

          return '<sup>$</sup><span class="figure">'+formattedNumber+'</span>';

        };
      }])

      .filter('analyticsPercentageValue', ['$filter', function ($filter) {
        return function (value) {
          return (value > 0 && value < 0.005 ? '<1' : $filter('number')(100 * value, 0));
        };
      }])

      .filter('analyticsPercentage100CutoffValue', ['$filter', function ($filter) {
        return function (value) {
          return (value > 0 && value < 0.005 ? '<1' : (value > 1.0 ? '100+' : $filter('number')(100 * value, 0)));
        };
      }])

      .filter('analyticsPercentage100Cutoff', ['$filter', function ($filter) {
        return function (value, args) {
          var percentage = $filter('analyticsPercentage100CutoffValue')(value);
          if(args){
            return '<span class="figure">'+percentage+'</span><sup>%</sup>';
          }
          else{
            return percentage + ' %';
          }
        };
      }])

      .filter('analyticsPercentage', ['$filter', function ($filter) {
        return function (value, args) {
          var percentage = $filter('analyticsPercentageValue')(value);
          if(args){
            return '<span class="figure">'+percentage+'</span><sup>%</sup>';
          }
          else{
            return percentage + ' %';
          }
        };
      }])

      .filter('analyticsDuration', ['$filter', function ($filter) {
        return function (string, args) {
          var num = Number(string),
              weeks = num / 7,
              useDays = weeks < 1.0,
              valToUse = useDays ? num : weeks,
              exactlyOne = valToUse === 1.0,
              unit = useDays ? 'day' : 'week';

          if(!exactlyOne){
            unit = unit + 's';
          }

          if(args){
            return '<span class="figure">'+$filter('truncatingNumber')(valToUse, 1)+'<span class="figure-description"> ' + unit + '</span></span>';
          } else{
            return $filter('truncatingNumber')(valToUse, 1) + ' <span class="duration-number-description"> ' + unit + '</span>';
          }
        };
      }]);
});
