define('analytics/widgets/verticalBar/verticalBar',['angular'], function (angular) {
  'use strict';

  return angular.module('widgets-verticalBar', ['chart.js'])
      .directive('widgetsVerticalBar', ['analyticsService', function(analyticsService) {
        return {
          restrict: 'A',
          templateUrl: '/assets/javascripts/analytics/widgets/verticalBar/verticalBar.html',
          scope: {
            widget: '=',
            filters: '=',
            internal: '=',
            suppression: '=',
            filterMask: '=',
            displayConfig: '=',
            right: '=',
            compareMode: '=',
          },
          controller: ['$log', '$scope', '$q', '_', 'widgetColors', 'widgetColorSchemes', 'widgetHelpers', 'moment', '$filter',
           function($log, $scope, $q, _, widgetColors, widgetColorSchemes, widgetHelpers, moment, $filter){


            /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
             Initialization
             ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

            $scope.state = 'LOADING';

            $scope.chartOptions = {
              backgroundColor: "#A9D5DA",
              maintainAspectRatio: false,
              responsive: false,
              events: false,
              tooltips: {
                  enabled: false
              },
              hover: {
                  animationDuration: 0
              },
              animation: {
                  duration: 1,
                  onComplete: function () {
                      var chartInstance = this.chart,
                          ctx = chartInstance.ctx;
                      ctx.font = '"proxima-nova", sans-serif';
                      ctx.fontSize = 20;
                      ctx.textAlign = 'center';
                      ctx.textBaseline = 'bottom';

                      this.data.datasets.forEach(function (dataset, i) {
                          var meta = chartInstance.controller.getDatasetMeta(i);
                          meta.data.forEach(function (bar, index) {
                              var data = dataset.data[index];
                              ctx.fillText(data, bar._model.x, bar._model.y - 5);
                          });
                      });
                  }
              },
              scales: {
                fontSize: 16,
                fontColor: "#3C4142",
                FontFamily: '"proxima-nova", sans-serif',
                xAxes: [{
                  gridLines: {
                    display: false
                  },
                  stacked: true
                }],
                yAxes: [{
                  display: false,
                  gridLines: {
                    display: false
                  },
                  stacked: true
                }]
              }
            };

            $scope.colors = [{backgroundColor:'#A9D5DA'}];

            $scope.getColClass = function(){
              return 'col-sm-' + $scope.cols;
            };



            var config = $scope.widget.config;

            var sortFn = function(){
              if(config.sort === 'alpha'){
                return widgetHelpers.alphaSortGroup;
              } else if(config.sort === 'expectedValue') {
                return function(item) {
                  return _.indexOf(config.expectedValues, item.groups[0]);
                };
              }
            };

            _refreshData();


            /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
             Scope Functions
             ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

            /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
             Watches
             ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

            $scope.$on('refreshData', _refreshData);

            $scope.$watch('state', function(){
               if ($scope.state === 'NO_DATA'){
                 $scope.$emit('noData');
               }
             });


            /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
             Private Functions
             ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

            function _refreshData(){
              $scope.state = 'LOADING';

              var promises,
                  filters = widgetHelpers.standardFiltersOnly($scope.filters, $scope.filterMask);

               if (config.filters) {
                  _.assign(filters, config.filters);
                }

              //handle the various ways that the widget can be configured
              if(_.isArray(config.fact)) {
                promises = _.map(config.fact, function (fact) {
                  return analyticsService.loadMetric(undefined, filters, fact, $scope.internal, $scope.suppression);
                });

                //turn the response into an array of {label, value}
                $q.all(promises)
                    .then(function (metrics) {
                      $scope.chartData = _(metrics)
                          .flatten()
                          .map(function (item, idx) {
                            return {
                              label: config.labels[idx],
                              value: item[config.field] ? item[config.field] : 0.0,
//                              color: widgetColorSchemes[config.colorScheme][idx]
                            };
                          })
                          .value();

                      $scope.maxValue = _.max($scope.chartData, 'value').value;
                      $scope.totalCases = analyticsService.getMetricTotal(config, metrics);
                    })
                    .then(_setAppropriateState);
              } else { //config.group
                analyticsService.loadMetric([config.group], filters, config.fact, $scope.internal, $scope.suppression)
                    .then(function (metric) {
                      if (sortFn()) {
                        metric = _.sortBy(metric, sortFn());
                      }

                      $scope.chartData = _(metric)
                          .map(_mapGroupedData)
                          .value();

                      if(config.labelFormat){ //TODO: need to make more abstract...deals with only durations  (maybe take in format function?)
                        $scope.chartData = _formatLabels($scope.chartData, config.labelFormat);
                      }
                      if(config.tooltipFormat){ //TODO: need to make more abstract...deals with only durations  (maybe take in format function?)
                        $scope.chartData = _formatChartData($scope.chartData, config.tooltipFormat);
                      }

                      // add place-holders for expected values that aren't present in the actual results
                      if(config.expectedValues && $scope.chartData.length > 0) {
                        $scope.chartData = _addMissingValues($scope.chartData, false);
                      }

                      $scope.chartLabels = _.map($scope.chartData, 'label');
                      $scope.chartValues = [_.map($scope.chartData, 'value')];

                      $scope.chartDataAdjust = _(metric).map(_mapGroupedData).map(analyticsService.transformForChart);
                      $scope.maxValue = _.max($scope.chartValues[0]);
                      $scope.totalCases = _.result(_.find(_.flatten(metric), function(metric) {
                                            return metric.total;
                                          }), 'total');
                    })
                    .then(_setAppropriateState);
              }

              function _setAppropriateState(){
                if ($scope.chartValues && $scope.chartValues.length && $scope.maxValue && $scope.maxValue > 0) {
                  $scope.state = 'DONE';
                } else {
                  $scope.state = 'NO_DATA';
                }
              }

              function _mapGroupedData(item) {
                var group = _.first(item.groups);
                return {
                  label: group,
                  value: item[config.field] ? item[config.field] : 0.0
//                  color: _calculateBarColor(group, idx),    //TODO: Not supported in vertical bar
//                  icon: widgetHelpers.getIcon(group)
                };
              }
//
//              function _filterMissingValues(item){
//                return item.value || item.value === 0;
//              }

              function _addMissingValues(chartData, overlay) {
                return _.map(config.expectedValues, function(ev) {
                    var item = _.find(chartData, { label: ev });
                    if(item) {
                      return item;
                    } else {
                      item = {
                        label: ev,
                        value: 0.0
//                        color: _calculateBarColor(ev, idx),
//                        icon: widgetHelpers.getIcon(ev)
                      };
                      if(overlay) {
                        item.overlay = 0.0;
                      }
                      return item;
                    }
                  });
              }

              function _formatChartData(chartData, units){
                //right now we only care about one decimal place
                return _.map(chartData, function(item){
                  return {
                    label: item.label,
                    value: _formatDuration(item.value, units)
                  };
                });
              }

              function _formatDuration(value, units){
                var num = Number(value),
                    weeks = num / 7,
                    years = num / 365,
                    valToUse = num;
                if(units === 'years'){
                  valToUse = years;
                }
                else if(units === 'days'){
                  valToUse = num;
                }
                else if(units === 'weeks'){
                  valToUse = weeks;
                }

                return $filter('truncatingNumber')(valToUse, 1);

              }

              function _formatLabels(chartData, format) {
                if(format === 'years'){
                  return _.map(chartData, function(item){
                    return {
                      label: moment(item.label).format('YYYY'),
                      value: item.value
                    };

                  });
                }
                else if (format === 'months'){
                  return _.map(chartData, function(item){
                    return {
                      label: moment(item.label).format('MMM'),
                      value: item.value
                    };
                  });
                }
                else{
                  return chartData;
                }
              }
            }
          }]
        };
      }]);
});
