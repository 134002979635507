define('organizations/routes',['angular'], function(angular) {
    'use strict';

    return angular.module('organizations.routes', [])

    .config(['$routeProvider', function($routeProvider) {
        $routeProvider
            .when('/organizations/view', {
                templateUrl: '/assets/javascripts/organizations/partials/view.html',
                authorizedRoles: ['ADMIN']
            })
            .when('/organization', {
                templateUrl: '/assets/javascripts/organizations/partials/organizationDetail.html',
                authorizedRoles: ['ADMIN']
            });
    }]);
});
