define('admin/directives',['angular'], function (angular) {
	'use strict';

	return angular.module('admin.directives', [])

			.directive('activityUser', [function () {
				return {
					restrict: 'E',
					scope: {
						activity: '='
					},
					template: '<a href="/#/users?id={{activity.userId}}"><span class="subject">{{activity.userName}}<span class="subject"></a>'
				};
			}])

			.directive('activityOrg', [function () {
				return {
					restrict: 'E',
					scope: {
						activity: '='
					},
					template: '<a href="/#/organization?id={{activity.organizationId}}"><span class="subject">{{activity.organizationName}}<span class="subject"></a>'
				};
			}]);
});
