/**
 * Home controllers.
 */
define('home/controllers',[], function() {
  'use strict';


  var WrapperCtrl = function($scope, $rootScope, $location, securityService, _){
    $scope.envConfig = window.Conf.envConfig; 
    $scope.envClass = window.Conf.envConfig.isDemoUi ? 'demo': '';

    $scope.displayContentOnly = _.includes(['/analytics/public/casetype', '/analytics/public/regionmap', '/analytics/stats/save-metric'], $location.path());

    $scope.logout = function() {
      securityService.logout().then(function(){
        // Reliability of securityService and $cookieStore to clear audit trail is inconsistent
        if(window.localStorage.getItem('audit')){
          window.localStorage.removeItem('audit');
        }
        $scope.showRoleToggle = false;
        $rootScope.$broadcast('logout');
        $location.path('/');
      });
    };

    $scope.changePassword = function() {
      $location.path('/users/changepassword');
    };

    $scope.subscriptions = function() {
      $location.path('/users/subscriptions');
    };


    $scope.myDrdLink = $scope.envConfig.publicSiteBaseUrl + '/mydrd';


    /*
    * Parse the URL in the browser and make URL available as variable
    */
    $scope.$on('$locationChangeStart', function() {
      $scope.showHeader =  !_.includes(['/analytics/stats/save-metric'], $location.path());
      $scope.showFooter =  !_.includes(['/login', '/analytics/stats/save-metric', '/signup', '/signup/payment', '/signup/summary'], $location.path());
      $scope.currentView = sanitizeUri( $location.path() );
    });
    /*
    * Split slash from uri
    * @prams (string) uri, (number) pos
    * @return array
    */
    function sanitizeUri(uri, pos){
      var c;
      c = uri.split('/');
      // remove whitespace
      for(var i = 0; i < c.length; i++){
        if(c[i].length === 0){
         c.splice(i,1);
        }
      }
      // check and return string in array
      if(pos !== undefined && c[pos] !== undefined){
         c = c[pos].toString();
      }
      // return string 
      else{
        c = c.join(' ');
      }
      return c;
    }
  };
  
  WrapperCtrl.$inject = ['$scope', '$rootScope', '$location', 'securityService', '_'];

  /** Controls the header */
  var HeaderCtrl = function($scope, securityService, membershipService, $cookieStore, $location, _) {

    $scope.toggleRoles = function(role) {
      $cookieStore.remove('loggedInActiveRole');
        if (role === 'CONTRIBUTOR'){
            var user = securityService.getLoggedInUserInfo();
            var contribRole = _.find(user.roles, function(role){
                if(_.startsWith(role, 'CONTRIBUTOR')){
                  return role;
                }
            });
                  $cookieStore.put('loggedInActiveRole', contribRole);
      }else{
      $cookieStore.put('loggedInActiveRole', role);
      }
      $location.path('/');
    };

    $scope.showRoleToggle = false;
    $scope.showSubscriptions = false;
    $scope.userActiveRole = {};
    $scope.hideSideBar = false;

    // Wrap the current user from the service in a watch expression
    $scope.$watch(function() {
      var role = securityService.getLoggedInUserActiveRole();
      return role;
    }, function(role) {
      var user = securityService.getLoggedInUserInfo();
      $scope.user = user;
      if (user){
        if(securityService.isUserContributor(user) && securityService.isUserSubscriber(user)){
          $scope.showRoleToggle = true;
          var displayRole;
          if(role === "CONTRIBUTOR_ADMIN"){
            displayRole = "CONTRIBUTOR";
          }
          else {
            displayRole = role;
          }
          $scope.userActiveRole = displayRole === 'SUBSCRIBER' ? 'RESEARCHER': displayRole;
        }
      }

    }, true);

    $scope.$watch(function() {
      var user = securityService.getLoggedInUserInfo();
      return user;
    }, function(user) {
      $scope.user = user;
      if (user){
        $scope.hideSideBar = _.isEmpty(user.roles);
        membershipService.fetchUserMemberships($scope.user.id, function(data) {
          $scope.showSubscriptions = _.isEmpty(user.roles) || !_.isEmpty(data);
        }, function () {
            $scope.showSubscriptions = false;
        });
      }
    }, true);

    $scope.$on('logout', function() {
      $scope.showRoleToggle = false;
    });
  };
  HeaderCtrl.$inject = ['$scope', 'securityService', 'membershipService', '$cookieStore', '$location', '_'];

  /** Controls the footer */
  var FooterCtrl = function(/*$scope*/) {
  };
  //FooterCtrl.$inject = ['$scope'];

  return {
    HeaderCtrl: HeaderCtrl,
    FooterCtrl: FooterCtrl,
    WrapperCtrl: WrapperCtrl
  };

});

