define('analytics/widgets/basicDuration/basicDuration',['angular'], function (angular) {
  'use strict';

  return angular.module('widgets-basicDuration', [])
      .directive('widgetsBasicDuration', ['analyticsService', '_', function(analyticsService, _) {
        return {
          restrict: 'A',
          templateUrl: '/assets/javascripts/analytics/widgets/basicDuration/basicDuration.html',
          scope: {
            widget: '=',
            filters: '=',
            internal: '=',
            suppression: '='
          },
          controller: ['$scope', 'securityService', function($scope, securityService){
            var config = $scope.widget.config;

          $scope.allowViewCaseTotals = function() {
              return securityService.isLoggedInAdmin();
          };


              _refreshData();
            $scope.$on('refreshData', _refreshData);

            function _refreshData(){
              //expects config to contain fact, title
              analyticsService.loadMetric(undefined, $scope.filters, config.fact, $scope.internal, $scope.suppression).then(function (metric) {
                $scope.metric = metric[0];
                $scope.totalCases = _.result(_.find(_.flatten(metric), function(metric) {
                                      return metric.total;
                                    }), 'total');
              });
            }


          }]
        };
      }]);
});
